import React from 'react';

const ModalFormHeading = (props) => {
    return (
        <div>
            <div>
                <div className="modal-heading">
                    <h5>{props.title}</h5>
                    {props.description && <span>{props.description}</span>}
                </div>
            </div>
        </div>
    );
};

export default ModalFormHeading;
