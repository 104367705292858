import React from 'react';
import GenericForm from '../../../../../components/Form/GenericForm';
import qs from 'qs';

const AddDepartmentContent = () => {
    let filters = qs.parse(window.location.search, { ignoreQueryPrefix: true });

    const initialValues = {
        Name: '',
        Code: '',
        Description: '',
        DepartmentType: '',
        DepartmentFunction: '',
        Vision: '',
        Mission: '',
        CampusId: filters.CampusId || 0,
        ContactId: null,
        CreatedBy: null,
        Contact: '',
    };

    const fields = [
        {
            type: 'select',
            field: 'CampusId',
            label: 'Select Campus',
            description: 'Department will be added to the selected campus',
            estric: '*',
            options: {
                type: 'dynamic',
                endpoint: 'Infrastructure/Campus',
                paged: true,
                valueKey: 'Id',
                labelKey: 'DisplayName',
            },
        },
        {
            type: 'text',
            field: 'Name',
            label: 'Department Name',
            description: 'Name of the department',
            estric: '*',
        },
        {
            type: 'text',
            field: 'Code',
            label: 'Department Code',
            description: 'Unique identifier for the department',
            estric: '*',
        },
        {
            type: 'textarea',
            field: 'Description',
            label: 'Description',
            description: 'Detailed description of the department',
            required: false,
        },
        {
            type: 'select',
            field: 'DepartmentType',
            label: 'Department Type',
            description: '',
            estric: '*',
            options: {
                type: 'enum',
                value: 'DepartmentType',
            },
        },
        {
            type: 'select',
            field: 'DepartmentFunction',
            label: 'Department Function',
            description: '',
            estric: '*',
            options: {
                type: 'enum',
                value: 'DepartmentFunction',
            },
        },
        {
            type: 'text',
            field: 'Contact',
            label: 'Contact Person',
            description: 'Name and phone number of the someone to be contacted for department-related queries',
            required: false,
        },
    ];

    return (
        <GenericForm
            fields={fields}
            initialValues={initialValues}
            endpoint={'Infrastructure/Department'}
            redirectTo={'/department'}
        />
    );
};

export default AddDepartmentContent;
