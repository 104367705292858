import React, {useRef, useState} from 'react';
import MediaQuery from 'react-responsive';
import qs from 'qs';
import {Modal} from 'react-bootstrap';
import Sidebar from '../../../../../../../components/Sidebar/Sidebar';
import Heading from '../../../../../../../components/Heading/Heading';
import HeaderFilters from '../../../../../../../components/Filters/HeaderFilters';
import Table from '../../../../../../../components/Table/Table';
import useListApi from '../../../../../../../../hooks/useListApi';
import MobileHeader from '../../../../../../../components/Sidebar/Mobile Design/Mobile Header/MobileHeader';
import RollNumberGenerator from '../../../../../../../components/Modals/Roll No Generator/RollNumberGenerator';

const AssignRollNumber = () => {
    const [filters, setFilters] = useState(
        qs.parse(window.location.search, {ignoreQueryPrefix: true}),
    );
    const {
        rows,
        refresh,
        totalRecords,
        currentPage,
        recordsPerPage,
        setCurrentPage,
        setRecordsPerPage,
    } = useListApi('Users/Scholar', false, true, filters);
    const column = [
        {
            heading: 'Name',
            render: (item) => {
                return `${item.FirstName} ${item.LastName}`;
            },
        },

        {
            heading: 'University Roll No.',
            value: 'UniversityRollNo',
        },
    ];

    const [showRollNumberGeneratorModal, setShowRollNumberGeneratorModal] = useState(false);
    const [selectedUsers, setSelectedUsers] = useState([]);

    const tableRef = useRef(null);


    return (
        <div className="main-wrapper">
            <MediaQuery maxWidth={1023}>
                <MobileHeader/>
            </MediaQuery>
            <MediaQuery minWidth={1024}>
                <Sidebar/>
            </MediaQuery>
            <div className="screen">
                {/* <Header
                    homelink="/dashboard"
                    breadcrumbs="Assign Supervisors"
                /> */}
                <Heading
                    title="Assign Roll Number"
                    description="Assign roll number"
                    width="auto"
                    height="40px"
                    padding="0px 20px"
                    tableRef={tableRef}
                    backgroundcolor="none"
                    color="#5D43A5"
                    fontsize="16px"
                    hover="#F9F5FF"
                    className="view-back-button"
                    href="/scholar"
                />
                <HeaderFilters
                    filters={filters}
                    availableFilter={[
                        'campus',
                        'cluster',
                        'researchCenter',
                        'batch',
                    ]}
                    setFilters={setFilters}
                />
                <Table
                    ref={tableRef}
                    shouldView={false}
                    shouldEdit={false}
                    shouldDelete={false}
                    shouldRollNumberGenerator={true}
                    permissionPrefix={'Scholar'}
                    shouldAssignFee={false}
                    assignRollNo={true}
                    data={rows}
                    totalRecords={totalRecords}
                    perPage={recordsPerPage}
                    setPerPage={setRecordsPerPage}
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                    column={column}
                    resource={'Users/Scholar'}
                    tableHeading="All Scholars"
                    name="Scholar"
                    refresh={refresh}
                    multipleDelete="Scholars"
                    onRollNumberGenerator={(items) => {
                        setSelectedUsers(items);
                        setShowRollNumberGeneratorModal(true);
                    }}
                />
            </div>
            {showRollNumberGeneratorModal && (
                <Modal
                    show={true}
                    onHide={() => setShowRollNumberGeneratorModal(false)}
                    centered
                    className="assignrollnoModal"
                >
                    <RollNumberGenerator
                        refresh={refresh}
                        scholars={selectedUsers}
                        onHide={() => {
                            setShowRollNumberGeneratorModal(false)
                        }}
                    />
                </Modal>
            )}
        </div>
    );
};

export default AssignRollNumber;
