import React from 'react';
import GenericForm from '../../../components/Form/GenericForm';

const AddCampusContent = () => {
    const initialValues = {
        UniversityName: '',
        DisplayName: '',
        LegalName: '',
        Code: '',
        Website: '',
        Email: '',
        PhoneNumber: '',
        GSTNo: '',
        Vision: '',
        Mission: '',
        AddressId: null,
        FinanceOfficeId: '0',
        AdmissionOfficeId: '0',
        EnquiryOfficeId: '0',
        TransportOfficeId: '0',
        CreatedBy: null,
        IsDeleted: '',
        PermanentAddress: '',
        CurrentAddress: '',
    };

    const fields = [
        {
            type: 'text',
            field: 'UniversityName',
            label: 'University Name',
            description: 'Name of the university',
            estric: '*',
        },
        {
            type: 'text',
            field: 'DisplayName',
            label: 'Campus Name',
            description: 'Name of the campus',
            estric: '*',
        },
        {
            type: 'text',
            field: 'LegalName',
            label: 'Legal Name',
            description:
                'The legal name of the university which is used in the legal documents',
            estric: '*',
        },
        {
            type: 'text',
            field: 'Code',
            label: 'Campus Code',
            description: 'Unique identifier for the campus',
            estric: '*',
        },
        {
            type: 'text',
            field: 'Website',
            label: 'Website',
            description: 'Website URL of the campus',
            estric: '*',
        },
        {
            type: 'text',
            field: 'Email',
            label: 'Email',
            description:
                'Email address for receiving queries related to the campus',
            estric: '*',
        },
        {
            type: 'text',
            field: 'PhoneNumber',
            label: 'Phone Number',
            description:
                'Phone number of someone to be contacted for campus-related queries',
            estric: '*',
        },
        {
            type: 'text',
            field: 'GSTNo',
            label: 'GST No',
            description:
                'GSTIN for the legal name provided for the campus (if applicable)',
            required: false,
        },
        {
            type: 'html',
            field: 'Vision',
            label: 'Vision',
            description: 'Vision of the campus',
            required: false,
        },
        {
            type: 'html',
            field: 'Mission',
            label: 'Mission',
            description: 'Mission of the campus',
            required: false,
        },
        {
            type: 'textarea',
            field: 'PermanentAddress',
            label: 'Permanent Address',
            description: 'Permanent address of the campus',
            required: false,
        },
        {
            type: 'textarea',
            field: 'CurrentAddress',
            label: 'Current Address',
            description: 'Current address of the campus',
            required: false,
        },
    ];

    return (
        <GenericForm
            fields={fields}
            initialValues={initialValues}
            endpoint={'Infrastructure/Campus'}
            redirectTo={'/campus'}
        />
    );
};

export default AddCampusContent;
