import React, { useState } from 'react';
import Hamburger from '../../../../images/scholar-hamburger.svg';
import Closemenu from '../../../../images/close-sidemenu.svg';
import { Link } from 'react-router-dom';
import PhdCentralLogo from '../../../../images/scholar-board-phd-logo.svg';
import ScholarNavigation from './ScholarNavigation';

const ScholarNavHeader = () => {
    const [showSidebar, setShowSidebar, closeSidebar, setCloseSidebar] =
        useState(false);

    return (
        <>
            <div className="sidebar-mobile">
                <div className="scholar-sidebar-navigation-mobile-fixed">
                    <div className="scholar-phd-logo-mobile">
                        <div className="phd-logo">
                            <Link to="/user">
                                <img
                                    className="phdpad-logo"
                                    src={PhdCentralLogo}
                                    alt={'PhdCentralLogo'}
                                />
                            </Link>
                        </div>

                        <>
                            <img
                                className="scholar-hamburger-icon"
                                src={Hamburger}
                                alt={'Hamburger'}
                                onClick={() => setShowSidebar(!showSidebar)}
                            />
                        </>
                    </div>
                </div>
            </div>
            {showSidebar && (
                <div className="scholar-phd-logo-mobile ">
                    <div
                        className={
                            showSidebar
                                ? 'scholar-mobile-menu open'
                                : 'scholar-mobile-menu close'
                        }
                    >
                        <div className="scholar-navbar-expandedview">
                            <div className="sidebar-nav">
                                <div className="scholar-sidebar-navigation-mobile">
                                    <Link to="/user">
                                        <div className="scholar-phd-logo-mobile">
                                            <div className="phd-logo">
                                                <img
                                                    className="phdpad-logo"
                                                    src={PhdCentralLogo}
                                                    alt={'PhdCentralLogo'}
                                                />
                                            </div>
                                            <div className="scholar-closemenu">
                                                <img
                                                    className="scholar-closemenu"
                                                    src={Closemenu}
                                                    alt={'Closemenu'}
                                                    onClick={() =>
                                                        setShowSidebar(
                                                            !showSidebar,
                                                        )
                                                    }
                                                />
                                            </div>
                                        </div>
                                    </Link>
                                    <ScholarNavigation />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default ScholarNavHeader;
