import React from 'react';
import LoginLeft from './subcomponents/LoginLeft';
import LoginRight from './subcomponents/LoginRight';

const LoginItems = () => {
    return (
        <div className="auth-screen">
            <LoginLeft />
            <LoginRight />
        </div>
    );
};

export default LoginItems;
