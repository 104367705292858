import React from 'react';
import MobileHeader from '../../../../../../../components/Sidebar/Mobile Design/Mobile Header/MobileHeader';
import Sidebar from '../../../../../../../components/Sidebar/Sidebar';
import AddResearchCentreContent from './AddResearchCentreContent';
import MediaQuery from 'react-responsive';
import FormHeading from '../../../../../../../components/Heading/FormHeading';
import FormHeader from '../../../../../../../components/Header/FormHeader';
import { LinkButton } from '../../../../../../../components/Buttons/LinkButton';
import Backarrow from '../../../../../../../../images/back-arrow.svg';

const AddResearchCentre = () => {
    return (
        <div className="main-wrapper">
            <MediaQuery maxWidth={1023}>
                <MobileHeader />
            </MediaQuery>
            <MediaQuery minWidth={1024}>
                <Sidebar />
            </MediaQuery>
            <div className="screen">
                <div className="heading-button-mobile">
                    {/* <FormHeader
                        homelink="/dashboard"
                        title="Campuses"
                        breadcrumb="Disciplines"
                        breadcrumbs="Add Discipline"
                        href="/dashboard"
                        innerpath="/discipline"
                    /> */}
                    <FormHeading
                        title="Add Discipline"
                        description="Enter the following details to add Discipline"
                    />
                    <MediaQuery maxWidth={1023}>
                        <div className="back-button">
                            <LinkButton
                                linktext="Back to Disciplines"
                                icon={Backarrow}
                                href="/discipline"
                                width="204px"
                                height="44px"
                                backgroundcolor="none"
                                color="#5D43A5"
                                fontsize="16px"
                                hover="none"
                            />
                        </div>
                    </MediaQuery>
                    <AddResearchCentreContent />
                </div>
            </div>
        </div>
    );
};

export default AddResearchCentre;
