import React from 'react';
import Sidebar from '../../../../../../../components/Sidebar/Sidebar';
import AddMilestoneContent from './AddMilestoneContent';
import MediaQuery from 'react-responsive';
import MobileHeader from '../../../../../../../components/Sidebar/Mobile Design/Mobile Header/MobileHeader';
import FormHeading from '../../../../../../../components/Heading/FormHeading';
import FormHeader from '../../../../../../../components/Header/FormHeader';
import { LinkButton } from '../../../../../../../components/Buttons/LinkButton';
import Backarrow from '../../../../../../../../images/back-arrow.svg';

const AddMilestone = () => {
    return (
        <div className="main-wrapper">
            <MediaQuery maxWidth={1023}>
                <MobileHeader />
            </MediaQuery>
            <MediaQuery minWidth={1024}>
                <Sidebar />
            </MediaQuery>
            <div className="screen">
                <div className="heading-button-mobile">
                    {/* <FormHeader
                        homelink="/dashboard"
                        breadcrumb="Deliverables"
                        breadcrumbs="Add Deliverable"
                        innerpath="/deliverable"
                    /> */}
                    <FormHeading
                        title="Add Deliverable"
                        description="Enter the following details to add deliverable"
                    />
                    <MediaQuery maxWidth={1023}>
                    <div className='back-button'>
                        <LinkButton
                            linktext="Back to Deliverables"
                            icon={Backarrow}
                            href="/deliverable"
                            width="204px"
                            height="44px"
                            backgroundcolor="none"
                            color="#5D43A5"
                            fontsize="16px"
                            hover="none"
                        />
                    </div>
                    </MediaQuery>
                    <AddMilestoneContent />
                </div>
            </div>
        </div>
    );
};

export default AddMilestone;
