import React, { useEffect, useState } from 'react';
import { Button } from '../../../../../Buttons/Button';
import { ExportButton } from '../../../../../Buttons/ExportButton';
import Api from '../../../../../../../api/api';
import CustomCheckbox from '../../../../../Inputs/CustomCheckbox';

const AssignFeePolicy = ({
    users,
    onHide,
    MilestoneId,
    FeePolicyId,
    refresh,
}) => {
    const [feePolicies, setFeePolicies] = useState([]);
    const [feePolicyId, setFeePolicyId] = useState(FeePolicyId);
    const [selectedDeliverables, setSelectedDeliverables] = useState([]);
    const [showNoDeliverables, setShowNoDeliverables] = useState(false);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setShowNoDeliverables(false);
        Api.get('/AcademicStructure/Deliverable/getEntities', {
            MilestoneId: MilestoneId,
        }).then((response) => {
            setFeePolicies(response.Data);
            if (response.Data.length === 0) {
                setShowNoDeliverables(true);
            }
        });
    }, [MilestoneId]);
    useEffect(() => {
        let id = users[0].User_id;
        Api.get('/AcademicStructure/ScholarDeliverable/getEntities', {
            UserId: id,
            PageNo: 1,
            PerPage: 1000,
        }).then((response) => {
            setSelectedDeliverables(
                response.Data.map((item) => {
                    return String(item.Deliverable.id);
                }),
            );
        });
    }, [users]);

    const submit = () => {
        setLoading(true);
        Api.post(
            '/AcademicStructure/ScholarDeliverable/AssignDeliverables',
            selectedDeliverables.map((deliverable) => {
                return {
                    ScholarId: users[0].Id,
                    DeliverableId: deliverable,
                };
            }),
        )
            .then(() => {
                onHide();
                refresh();
            })
            .catch(console.log)
            .finally(() => {
                setLoading(false);
            });
    };

    return (
        <div className="fee-policy-modal">
            <div className="modal-box">
                <div className="modal-heading">
                    <h5>Assign Resources</h5>
                </div>
                <img
                    className="modal-cross-icon"
                    onClick={onHide}
                    src="/images/cross.svg"
                />
            </div>

            <div className="fee-policy-content">
                <>
                    <div className="assign-fee-policy-content">
                        <div className="assign-fee-policy-amount">
                            {feePolicies.length === 0 && showNoDeliverables && (
                                <div className="no-deliverable">
                                    No resources found
                                </div>
                            )}

                            {feePolicies.map((deliverable) => {
                                return (
                                    <div className="assign-deliverable-modal">
                                        <CustomCheckbox
                                            onChange={(e) => {
                                                if (
                                                    selectedDeliverables.indexOf(
                                                        deliverable.id,
                                                    ) === -1
                                                ) {
                                                    setSelectedDeliverables([
                                                        ...selectedDeliverables,
                                                        deliverable.id,
                                                    ]);
                                                } else {
                                                    let dvs = [
                                                        ...selectedDeliverables,
                                                    ];
                                                    dvs.splice(
                                                        dvs.indexOf(
                                                            deliverable.id,
                                                        ),
                                                        1,
                                                    );
                                                    setSelectedDeliverables(
                                                        dvs,
                                                    );
                                                }
                                            }}
                                            isChecked={
                                                selectedDeliverables.indexOf(
                                                    deliverable.id,
                                                ) > -1
                                            }
                                        />
                                        <span>{deliverable.Title}</span>

                                        <div
                                            className="modal-assign-sub-deliverable"
                                            // style={{ marginLeft: 20 }}
                                        >
                                            <a
                                                href={`/sub-deliverable/${deliverable.id}/view`}
                                                target={'_blank'}
                                            >
                                                View
                                            </a>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </>
            </div>

            {feePolicies.length === 0 ? (
                <div className="cb-buttons-assign-fee-policy">
                    <Button
                        disabled={loading}
                        onClick={submit}
                        width="400px"
                        height="40px"
                        padding="0px 20px !important"
                        hover="#5D43A5"
                    >
                        {loading ? 'Please wait..' : 'Okay'}
                    </Button>
                </div>
            ) : (
                <div className="cb-buttons-assign-fee-policy">
                    <ExportButton
                        width="220px"
                        height="40px"
                        hover="#F9FAFB"
                        border="1px solid #D0D5DD"
                        onClick={onHide}
                    >
                        Cancel
                    </ExportButton>

                    <Button
                        disabled={loading}
                        onClick={submit}
                        width="220px"
                        height="40px"
                        padding="0px 20px !important"
                        hover="#5D43A5"
                    >
                        {loading ? 'Please wait..' : 'Save'}
                    </Button>
                </div>
            )}
        </div>
    );
};

export default AssignFeePolicy;
