import React, { useRef } from 'react';
import GenericForm from '../../../../../../components/Form/GenericForm';
import qs from 'qs';

const AddSupervisorContent = () => {
    let filters = qs.parse(window.location.search, { ignoreQueryPrefix: true });

    const initialValues = {
        UniversityRollNo: '',
        CampusId: filters.CampusId || '',
        ClusterId: filters.ClusterId || '',
        ResearchCenterId: filters.ResearchCenterId || '',
        Honorifics: '',
        FirstName: '',
        Lastname: '',
        Gender: '',
        Email: '',
        PhoneNumber: '',
        EmployeeId: '',
        Designation: '',
        ProfilePhoto: '',
        DisciplineId: [],
    };

    const formRef = useRef(null);

    const fields = [
        {
            type: 'file',
            fileType: 'image/*',
            field: 'ProfilePhoto',
            required: false,
            fullWidth: true,
        },
        {
            type: 'select',
            field: 'CampusId',
            label: 'Select Campus',
            description: 'The employee will be added to the selected campus',
            estric: '*',
            options: {
                type: 'dynamic',
                endpoint: 'Infrastructure/Campus',
                paged: true,
                valueKey: 'Id',
                labelKey: 'DisplayName',
            },
            onChange: (value) => {
                formRef.current.updateData({
                    DisciplineId: [],
                });
            },
        },
        {
            type: 'text',
            field: 'EmployeeId',
            label: 'Employee Id',
            description: 'Unique identifier of the employee',
            estric: '*',
        },
        {
            type: 'text',
            field: 'Designation',
            label: 'Designation',
            description: 'The position of the employee',
            estric: '*',
        },
        {
            type: 'select',
            field: 'Honorifics',
            label: 'Title',
            description: 'Title to be added before the name of the employee',
            estric: '*',
            options: {
                type: 'enum',
                value: 'Honorifics',
            },
        },

        {
            type: 'text',
            field: 'FirstName',
            label: 'First Name',
            description: 'First name of the employee',
            estric: '*',
        },
        {
            type: 'text',
            field: 'LastName',
            label: 'Last Name',
            description: 'Last name of the employee',
            estric: '*',
        },
        {
            type: 'select',
            field: 'Gender',
            label: 'Gender',
            description: 'Gender of the employee',
            estric: '*',
            options: {
                type: 'enum',
                value: 'Gender',
            },
        },
        {
            type: 'text',
            field: 'Email',
            label: 'Email',
            description: 'Email address of the employee',
            estric: '*',
        },
        {
            type: 'text',
            field: 'PhoneNumber',
            label: 'Phone Number',
            description: 'The phone number of the employee',
            estric: '*',
        },
        {
            type: 'select',
            field: 'DisciplineId',
            serverField: 'Disciplines',
            label: 'Select Specializations',
            description: 'The employee will be added to the selected campus',
            estric: '*',
            className: 'select-multi',
            options: {
                type: 'dynamic',
                endpoint: 'AcademicStructure/Discipline',
                paged: true,
                valueKey: 'Id',
                labelKey: 'Name',
                multi: true,
                optionsFilterCallback: (cluster, formValue) => {
                    return cluster.CampusId === formValue.CampusId;
                },
            },
            initialDataMapper: (data) => {
                if (!data.Disciplines) {
                    return [];
                }
                return data.Disciplines.map((d) => {
                    return d.Discipline.Id;
                });
            },
        },
    ];

    return (
        <GenericForm
            fields={fields}
            initialValues={initialValues}
            endpoint={'Users/Guide'}
            redirectTo={'/supervisor'}
        />
    );
};

export default AddSupervisorContent;
