import React, {
    forwardRef,
    useEffect,
    useImperativeHandle,
    useRef,
    useState,
} from 'react';
import { Button } from '../Buttons/Button';
import { ExportButton } from '../Buttons/ExportButton';
import SelectInput from '../Inputs/SelectInput';
import Api from '../../../api/api';
import { useParams } from 'react-router-dom';
import useDetailApi from '../../../hooks/useDetailApi';
import * as PropTypes from 'prop-types';
import { SimpleInput } from '../Inputs/SimpleInput';
import Switch from '../Switch/Switch';
import { TextAreaInput } from '../Inputs/TextAreaInput';
import _ from 'lodash';
import { FixedPlaceholderInput } from '../Inputs/FixedPlaceholderInput';
import { FixedInput } from '../Inputs/FixedInput';
import { FileInput } from '../Inputs/FileInput';
import { HTMLInput } from '../Inputs/HTMLInput';
import { FilesInput } from '../Inputs/FilesInput';
import { SimpleFileInput } from '../Inputs/SimpleFileInput';
import Loader from 'react-js-loader';
import qs from "qs";

export function RenderFormControl({
    type,
    formErrors,
    handleChange,
    field,
    displayName,
    onLabel,
    offLabel,
    formValues,
    options,
    placeholder,
    height,
    color,
    onfocus,
    fixedtext,
    className,
    fileType,
    showPreview = true,
    disabled = false,
    clearable

}) {
    const [_options, setOptions] = useState([]);
    const ref = useRef();

    useEffect(() => {
        if (!options) {
            return;
        }
        if (options.type === 'static') {
            let opts = [];
            if (options.all) {
                opts.push({
                    value: options.showAllValue,
                    label: options.showAllLabel,
                });
            }
            setOptions([...opts, ...options.options]);
        } else if (options.type === 'enum') {
            Api.get(`/Common/CommonEnums/getEnums`).then((response) => {
                let opts = response[options.value].map((item) => {
                    return {
                        value: item,
                        label: item,
                    };
                });
                setOptions(opts);
            });
        } else {
            Api.get(`/${options.endpoint}/getEntities`, {
                ...(options.endpointQueryParams || {}),
                PageNo: 1,
                PerPage: 1000,
            }).then((response) => {
                let rows = response.Data ? response.Data : response;
                let opts = [];
                if (options.all) {
                    opts.push({
                        value: options.showAllValue,
                        label: options.showAllLabel,
                    });
                }
                if (options.optionsFilterCallback) {
                    rows = rows.filter((row) => {
                        return options.optionsFilterCallback(row, formValues);
                    });
                }
                rows.forEach((item) => {
                    opts.push({
                        value: _.get(item, options.valueKey),
                        label: _.get(item, options.labelKey),
                    });
                });
                setOptions(opts);
            });
        }
    }, [formValues, options]);

    console.log("fiels i");
    console.log(field);
    const value = _.get(formValues, field);
    console.log("value in generic form is");
    console.log(value);
    switch (type) {
        case 'select':
            return (
                <SelectInput
                    disabled={disabled}
                    multi={options.multi}
                    className={className}
                    clearable={clearable}
                    error={formErrors[field]}
                    onChange={(selected) => {
                        let value = null;
                        if (selected) {
                            value = selected.value;
                        }
                        if (options.multi && selected) {
                            value = selected.map(({ value }) => value);
                        }
                        handleChange({
                            target: {
                                name: field,
                                value,
                            },
                        });
                    }}
                    value={value}
                    options={_options}
                    placeholder={placeholder}
                    height={height}
                />
            );
        case 'text':
            return (
                <SimpleInput
                    placeholder={placeholder}
                    name={field}
                    readOnly={disabled}
                    label=""
                    value={value}
                    onChange={handleChange}
                    error={formErrors[field]}
                    height={height}
                />
            );
        case 'fixedinput':
            return (
                <FixedInput
                    fixedtext={fixedtext}
                    label=""
                    value={value}
                    onChange={handleChange}
                    error={formErrors[field]}
                />
            );
        case 'fixedplaceholderinput':
            return (
                <FixedPlaceholderInput
                    placeholder={placeholder}
                    name={field}
                    label=""
                    value={value}
                    onChange={handleChange}
                    error={formErrors[field]}
                />
            );
        case 'date':
            return (
                <SimpleInput
                    placeholder={placeholder}
                    name={field}
                    type="date"
                    value={value}
                    onChange={handleChange}
                    error={formErrors[field]}
                    height={height}
                    color={color}
                    ref={ref}
                    className={value ? 'date-input--has-value' : ''}
                    onFocus={()=> (ref.value = 'date')}
                    // onBlur={()=>(ref.current.type = 'text')}
                />
            );

        case 'files':
            return (
                <FilesInput
                    disabled={disabled}
                    placeholder={placeholder}
                    name={field}
                    value={value}
                    type="file"
                    multiple
                    onChange={handleChange}
                    error={formErrors[field]}
                />
            );
        case 'password':
            return (
                <SimpleInput
                    disabled={disabled}
                    placeholder={placeholder}
                    name={field}
                    type="password"
                    value={value}
                    onChange={handleChange}
                    error={formErrors[field]}
                    height={height}
                />
            );
        case 'switch':
            return (
                <Switch
                    disabled={disabled}
                    name={field}
                    onLabel={onLabel}
                    offLabel={offLabel}
                    value={value}
                    onChange={handleChange}
                    error={formErrors[field]}
                />
            );
        case 'textarea':
            return (
                <TextAreaInput
                    disabled={disabled}
                    placeholder={placeholder}
                    name={field}
                    type="date"
                    value={value}
                    onChange={handleChange}
                    error={formErrors[field]}
                />
            );
        case 'html':
            return (
                <HTMLInput
                    disabled={disabled}
                    placeholder={placeholder}
                    name={field}
                    type="date"
                    value={value}
                    onChange={handleChange}
                    error={formErrors[field]}
                />
            );
        case 'file':
            if (!showPreview) {
                return (
                    <SimpleFileInput
                        disabled={disabled}
                        displayName={displayName}
                        placeholder={placeholder}
                        name={field}
                        value={value}
                        type="file"
                        onChange={handleChange}
                        error={formErrors[field]}
                    />
                );
            }
            return (
                <FileInput
                    disabled={disabled}
                    placeholder={placeholder}
                    name={field}
                    showPreview={showPreview}
                    fileType={fileType}
                    value={value}
                    onChange={handleChange}
                    error={formErrors[field]}
                />
            );
        default:
            return '';
    }
}

RenderFormControl.propTypes = {
    formErrors: PropTypes.shape({}),
    onChange: PropTypes.func,
    formValues: PropTypes.shape({
        CreatedBy: PropTypes.number,
        CampusId: PropTypes.number,
        Code: PropTypes.string,
        Name: PropTypes.string,
    }),
};
const GenericForm = (
    {
        fields,
        initialValues,
        endpoint,
        redirectTo,
        twoColumns = false,
        RenderCustomFields,
        initialValuesUpdated,
        id = null,
        doNotAppendWithGetEntity = false,
        doNotAppendWithPutPostEntity = false,
        putEndpoint,
        disabled,
        validateCustomFields
    },
    ref,
) => {
    const [initialData, setInitialData] = useState(null);
    const [formValues, setFormValues] = useState(initialValues);
    const [formErrors, setFormErrors] = useState({});
    const [loading, setLoading] = useState(false);
    const [loading2, setLoading2] = useState(false);



    useImperativeHandle(ref, () => ({
        updateData: (d) => {
            setFormValues((v) => {
                return { ...v, ...d };
            });
        },
    }));

    const { id: idFromRoute } = useParams();
    if (!id && idFromRoute) {
        id = idFromRoute;
    }

    const { loading: initialising, item } = useDetailApi(
        endpoint,
        id,
        doNotAppendWithGetEntity,
    );

    useEffect(() => {
        if (item) {
            let d = {};
            Object.keys(item).map((k) => {
                let value = item[k];
                fields.forEach((f) => {
                    if (f.serverField === k && f.initialDataMapper) {
                        value = f.initialDataMapper(item);
                        d[f.field] = value;
                    }
                });
                d[k] = value;
            });
            setFormValues({ ...d });
            setInitialData(d);
            if (initialValuesUpdated) {
                initialValuesUpdated(d);
            }
        }
    }, [item]);

    const handleChange = (event) => {
        let i = { ...formValues };
        _.set(i, event.target.name, event.target.value);
        setFormValues(i);

        let f = fields.find((field) => field.field === event.target.name);
        if (f && f.onChange) {
            f.onChange(event.target.value);
        }
    };

    const submit = (createAnother = false) => {
        if (createAnother) {
            window.scrollTo({
                top: 0,
                behavior: 'smooth'
            });
            // setLoading2(true);
        } else {
            setLoading(true);
        }
        Api.createObject(
            putEndpoint || endpoint,
            formValues,
            id,
            doNotAppendWithPutPostEntity,
        )
            .then((response) => {
                if (createAnother) {
                    window.location.reload();
                    return;
                }
                const {next} = qs.parse(window.location.search, { ignoreQueryPrefix: true });
                if(next) {
                    window.location.href = decodeURIComponent(next);
                    return;
                }
                window.location.href = redirectTo;
            })
            .catch((e) => {
                if (e && e.Fields) {
                    setFormErrors({ ...e.Fields });
                } else if (e && e.Message) {
                    alert(e.Message);
                }
            })
            .finally(() => {
                setLoading(false);
                setLoading2(false);
            });
    };

    const handleNext = (e, createAnother = false) => {
        e.preventDefault();
        const errors = validate(formValues);
        setFormErrors(errors);
        if (Object.keys(errors).length === 0) {
            submit(createAnother);
        }
    };

    const validate = (values) => {
        const errors = {};
        fields.forEach((field) => {
            if (!field.field) {
                //This is just a filler
                return;
            }
            if(field.required === false){
                return;
            }
            const value = _.get(values, field.field);
            let hasError = false;
            if ((!value || value === 'undefined')){
                hasError = true;
            }
            if (field.type === 'select' && field.options.multi && value && value.length === 0) {
                hasError = false;
            }
            if (hasError) {
                errors[field.field] = field.errorMessage?.required ||`${field.label}  is required`;
            }
        });
        if (validateCustomFields) {
            const { success, errors: customFieldErrors } = validateCustomFields(values);
            if (Object.keys(customFieldErrors).length > 0) {
                errors.customFields = customFieldErrors;
            }
        }
        return errors;
    };


    const updateQueryParams = (e) => {
        if ('URLSearchParams' in window) {
            var searchParams = new URLSearchParams(window.location.search)
            searchParams.set(e.target.name, e.target.value);
            var newRelativePathQuery = window.location.pathname + '?' + searchParams.toString();
            window.history.replaceState(null, '', newRelativePathQuery);
        }
    }

    if (twoColumns) {
        const splitedFields = fields.filter(({ hide }) => !hide).reduce(function (
            accumulator,
            currentValue,
            currentIndex,
            array,
        ) {
            if (currentIndex % 2 === 0)
                accumulator.push(array.slice(currentIndex, currentIndex + 2));
            return accumulator;
        },
            []);
        let previousFieldFullWidth = false;
        return (
            <>
                <div className="invite-supervisor-content">
                    <div className="invite-supervisor">
                        {splitedFields.map((fields) => {
                            return (
                                <>
                                    <div className="invite-supervisor-inputs">
                                        {fields.map(
                                            ({
                                                label,
                                                description,
                                                field,
                                                type,
                                                options,
                                                estric,
                                                fullWidth,
                                                fileType,
                                                preChange,
                                                shouldUpdateQueryParams
                                            }) => {
                                                if (
                                                    !field &&
                                                    previousFieldFullWidth
                                                ) {
                                                    return <></>;
                                                }
                                                previousFieldFullWidth =
                                                    fullWidth;
                                                return (
                                                    <>
                                                        <div className="supervisor-name">
                                                            {label && (
                                                                <label>
                                                                    {label}
                                                                    {estric}
                                                                </label>
                                                            )}
                                                            <RenderFormControl
                                                                disabled={disabled}
                                                                options={
                                                                    options
                                                                }
                                                                placeholder={
                                                                    label
                                                                }
                                                                type={type}
                                                                field={field}
                                                                formValues={
                                                                    formValues
                                                                }
                                                                formErrors={
                                                                    formErrors
                                                                }
                                                                handleChange={(e) => {
                                                                    if (preChange) {
                                                                        preChange(e, handleChange);
                                                                        return;
                                                                    }
                                                                    handleChange(e);
                                                                    if (shouldUpdateQueryParams) {
                                                                        updateQueryParams(e);
                                                                    }
                                                                }}

                                                                fileType={
                                                                    fileType
                                                                }
                                                            />
                                                        </div>
                                                    </>
                                                );
                                            },
                                        )}
                                    </div>
                                    {fields[0]['field'] === 'ProgramId' &&
                                        fields[1]['field'] ===
                                        'DisciplineId' && (
                                            <>
                                                {/* <div className="bottom-line-scholar"></div> */}
                                            </>
                                        )}
                                    {fields[0]['field'] === 'Nationality' && (
                                        <>
                                            {/* <div className="bottom-line-scholar"></div> */}
                                        </>
                                    )}
                                </>
                            );
                        })}
                    </div>
                </div>
                {!disabled &&
                    <div className="cb-button-save-create-another">
                        <div className="cb-buttons">
                            <ExportButton
                                hover="#f9fafb"
                                onClick={() => {
                                    window.history.back();
                                }}
                            >
                                Cancel
                            </ExportButton>

                            <Button
                                disabled={loading}
                                loading={loading}
                                width="auto"
                                height="40px"
                                padding="0px 20px !important"
                                hover="#5D43A5"
                                onClick={handleNext}
                            >
                                Save
                            </Button>

                        </div>
                        <div className="save-create-another">
                            {id === null && (
                                <div className="create-another-button">
                                    <Button
                                        disabled={loading2}
                                        loading={loading2}
                                        className="create-another-button"
                                        width="auto"
                                        height="40px"
                                        padding="0px 20px 0px 20px !important"
                                        hover="#5D43A5"
                                        onClick={(e) => {
                                            handleNext(e, true);
                                        }}
                                    >
                                        Save & Create another
                                    </Button>
                                </div>
                            )}
                        </div>
                    </div>
                }
            </>
        );
    }

    return (
        <>
            <div className="tabs-border-bottom">
                {fields.map(
                    ({
                        label,
                        description,
                        field,
                        type,
                        options,
                        estric,
                        className,
                        fullWidth,
                        fileType,
                        preChange,
                        shouldUpdateQueryParams,
                        hide
                    }) => {
                        if (hide) {
                            return null;
                        }
                        return (
                            <div key={field}>
                                <div className="university-input">
                                    {fullWidth !== true && (
                                        <div className="university-input-heading">
                                            <h4
                                                style={{
                                                    textTransform: 'capitalize',
                                                }}
                                            >
                                                {label}
                                                {estric}
                                            </h4>
                                            <div className="span-text">
                                                <span>{description}</span>
                                            </div>
                                        </div>
                                    )}
                                    <div className="university">
                                        <div
                                            className={`university-name-input ${fullWidth ? 'fullWidth' : ''
                                                }`}
                                        >
                                            <RenderFormControl
                                                className={className}
                                                fileType={fileType}
                                                options={options}
                                                placeholder={label}
                                                type={type}
                                                field={field}
                                                formValues={formValues}
                                                formErrors={formErrors}

                                                handleChange={(e) => {
                                                    if (preChange) {
                                                        preChange(e, handleChange);
                                                        return;
                                                    }
                                                    handleChange(e);
                                                    if (shouldUpdateQueryParams) {
                                                        updateQueryParams(e);
                                                    }
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="bottom-line"></div>
                            </div>
                        );
                    },
                )}
                {RenderCustomFields !== undefined && (
                    <RenderCustomFields
                        handleChange={handleChange}
                        initialData={initialData}
                        errors={formErrors.customFields}
                    />
                )}
            </div>
            <div className="cb-button-save-create-another">
                <div className="cb-buttons">
                    <ExportButton
                        hover="#f9fafb"
                        onClick={() => {
                            window.history.back();
                        }}
                    >
                        Cancel
                    </ExportButton>
                    <Button
                        disabled={loading}
                        width="80px"
                        loading={loading}
                        height="40px"
                        padding="0px 20px 0px 20px !important"
                        hover="#5D43A5"
                        onClick={(e) => {
                            handleNext(e);
                        }}
                    >
                        Save
                    </Button>
                </div>
                <div className="save-create-another">
                    {id === null && (
                        <Button
                            disabled={loading2}
                            loading={loading2}
                            className="save-create-another"
                            width="auto"
                            height="40px"
                            padding="0px 20px 0px 20px !important"
                            hover="#5D43A5"
                            onClick={(e) => {
                                handleNext(e, true);
                            }}
                        >
                            Save & Create another
                        </Button>
                    )}
                </div>
            </div>
        </>
    );
};

export default forwardRef(GenericForm);
