import React, {useState} from 'react';
import Header from '../../../../../../../components/Header/Header';
import Heading from '../../../../../../../components/Heading/Heading';
import Progressbar from '../../../../../../../components/Progress Bar/Progressbar';
import MobileHeader from '../../../../../../../components/Sidebar/Mobile Design/Mobile Header/MobileHeader';
import Sidebar from '../../../../../../../components/Sidebar/Sidebar';
import MediaQuery from 'react-responsive';
import GenericContent from '../Generic/GenericContent';
import {useParams} from 'react-router-dom';

export const PriorScholarInfoContent = ({id}) => {
    const [dateType, setDateType] = useState('estimate');
    return <GenericContent
        initialValuesUpdated={(values) => {
            setDateType(values.Status ? 'completion' : 'estimate');
        }}
        id={id}
        title={'Research Scholar Info'}
        description={'Add your Research Scholar Info details here.'}
        endpoint={'PriorScholarInfo'}
        column={[

            {
                heading: 'First Name',
                value: 'FirstName',
            },
            {
                heading: 'Last Name',
                value: 'LastName',
            },
            {
                heading: 'Institute',
                value: 'Institute',
            },
            {
                heading: 'Discipline',
                value: 'Discipline',
            },
            ,
            {
                heading: 'Admission Date',
                value: 'AdmissionDate',
            },
            {
                heading: 'Status',
                value: 'Status',
                render: (value) => {
                    return value.Status ? 'Completed' : 'Ongoing';
                }
            }
        ]}
        fields={
            [
                {
                    type: 'text',
                    field: 'FirstName',
                    label: 'First Name',
                    description: 'This will be displayed on your profile.',
                    estric: '*',
                },
                {
                    type: 'text',
                    field: 'LastName',
                    label: 'Last Name',
                    estric: '*',
                },
                {
                    type: 'text',
                    field: 'Institute',
                    label: 'Institute',
                    description: 'This will be displayed on your profile.',
                    estric: '*',
                },
                {
                    type: 'text',
                    field: 'Discipline',
                    label: 'Discipline',
                    description: 'This will be displayed on your profile.',
                    estric: '*',
                },
                {
                    type: 'select',
                    field: 'Status',
                    label: 'Status',
                    description: 'This will be displayed on your profile.',
                    estric: '*',
                    clearable: false,
                    required: false,
                    options: {
                        type: 'static',
                        options: [
                            {value: true, label: 'Completed'},
                            {value: false, label: 'Ongoing'},
                        ]
                    },
                    onChange: (value) => {
                        console.log(value)
                        setDateType(value ? 'completion' : 'estimate');
                    }
                },
                {
                    type: 'date',
                    field: 'CompletionDate',
                    label: 'Completion Date',
                    description: 'This will be displayed on your profile.',
                    estric: '*',
                    hide: dateType !== 'completion',
                    required: dateType === 'completion'
                },
                {
                    type: 'text',
                    field: 'EstimateTime',
                    label: 'Estimated Time (DD/MM/YYYY)',
                    description: 'This will be displayed on your profile.',
                    estric: '*',
                    hide: dateType !== 'estimate',
                    required: dateType === 'estimate'
                },
                {
                    type: 'date',
                    field: 'AdmissionDate',
                    label: 'Admission Date',
                    description: 'This will be displayed on your profile.',
                    estric: '*',
                },

            ]}
        initialValues={{
            "FirstName": "",
            "LastName": "",
            "Institute": "",
            "Discipline": "",
            "CompletionDate": null,
            "Status": false,
            "EstimateTime": null,
            CreatedAt: null,
            CreatedBy: null,
            IsDeleted: false,
            UserProfile: id,
        }}
    />
}
const PriorScholarInfo = () => {
    let {scholarId} = useParams();
    return (
        <div className="main-wrapper">
            <MediaQuery maxWidth={1023}>
                <MobileHeader/>
            </MediaQuery>
            <MediaQuery minWidth={1024}>
                <Sidebar/>
            </MediaQuery>
            <div className="screen">
                {/* <Header
                    homelink="/dashboard"
                    title="Scholars"
                    breadcrumbs="My Profile"
                    href="/scholar"
                /> */}
                <Heading title="My Profile" description=""/>
                <div className="user-profile-section">
                    <Progressbar/>
                    <PriorScholarInfoContent id={scholarId}/>
                </div>
            </div>
        </div>
    );
};

export default PriorScholarInfo;
