import React from 'react';
import Header from './Header';
import { useParams } from 'react-router-dom';

const FormHeader = (props) => {
    let { breadcrumbs } = props;
    let { id } = useParams();
    if (id) {
        breadcrumbs = breadcrumbs.replace('Add ', 'Edit ');
    }
    return <Header {...props} breadcrumbs={breadcrumbs} />;
};

export default FormHeader;
