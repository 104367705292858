import React, { useState } from 'react';
import Header from '../../../../../../components/Header/Header';
import Heading from '../../../../../../components/Heading/Heading';
import MobileHeader from '../../../../../../components/Sidebar/Mobile Design/Mobile Header/MobileHeader';
import Sidebar from '../../../../../../components/Sidebar/Sidebar';
import UploadFile from '../../../../../../components/Upload File/UploadFile';
import MediaQuery from 'react-responsive';

const ImportScholar = () => {
    const [filters, setFilters] = useState({});
    let fields = [
        {
            type: 'text',
            field: 'UniversityRollNo',
            label: 'University Roll No',
            description: 'This will be displayed on your profile.',
            estric: '*',
        },
        {
            type: 'select',
            field: 'Honorifics',
            label: 'Title',
            description: 'This will be displayed on your profile.',
            estric: '*',
            options: {
                type: 'enum',
                value: 'Honorifics',
            },
        },

        {
            type: 'text',
            field: 'FirstName',
            label: 'First Name',
            description: 'This will be displayed on your profile.',
            estric: '*',
        },
        {
            type: 'text',
            field: 'LastName',
            label: 'Last Name',
            description: 'This will be displayed on your profile.',
            estric: '*',
        },
        {
            type: 'select',
            field: 'Gender',
            label: 'Gender',
            description: 'This will be displayed on your profile.',
            estric: '*',
            options: {
                type: 'enum',
                value: 'Gender',
            },
        },
        {
            type: 'text',
            field: 'Email',
            label: 'Email',
            description: 'This will be displayed on your profile.',
            estric: '*',
        },
        {
            type: 'text',
            field: 'PhoneNumber',
            label: 'Phone Number',
            description: 'This will be displayed on your profile.',
            estric: '*',
        },
        {
            type: 'text',
            field: 'MotherName',
            label: 'Mother Name',
            description: 'This will be displayed on your profile.',
            estric: '*',
        },
        {
            type: 'text',
            field: 'FatherName',
            label: 'Father Name',
            description: 'This will be displayed on your profile.',
            estric: '*',
        },
        {
            type: 'date',
            field: 'BirthDate',
            label: 'Date of Birth',
            description: 'This will be displayed on your profile.',
            estric: '*',
        },
        {
            type: 'select',
            field: 'Domicile',
            label: 'Domicile',
            description: 'This will be displayed on your profile.',
            estric: '*',
            options: {
                type: 'enum',
                value: 'Domicile',
            },
        },
        {
            type: 'select',
            field: 'Category',
            label: 'Category',
            description: 'This will be displayed on your profile.',
            estric: '*',
            options: {
                type: 'enum',
                value: 'Category',
            },
        },
        {
            type: 'select',
            field: 'Quota',
            label: 'Quota',
            description: 'This will be displayed on your profile.',
            estric: '*',
            options: {
                type: 'enum',
                value: 'Quota',
            },
        },
        {
            type: 'text',
            field: 'City',
            label: 'City',
            description: 'This will be displayed on your profile.',
            estric: '*',
        },
        {
            type: 'text',
            field: 'Nationality',
            label: 'Nationality',
            description: 'This will be displayed on your profile.',
            estric: '*',
        },
        {
            type: 'textarea',
            field: 'CurrentAddress',
            label: 'Current Address',
            description: 'This will be displayed on your profile.',
            estric: '*',
        },
        {
            type: 'textarea',
            field: 'PermanentAddress',
            label: 'Permanent Address',
            description: 'This will be displayed on your profile.',
            estric: '*',
        },
    ];
    return (
        <div className="main-wrapper">
            <MediaQuery maxWidth={1023}>
                <MobileHeader />
            </MediaQuery>
            <MediaQuery minWidth={1024}>
                <Sidebar />
            </MediaQuery>
            <div className="screen">
                <Header
                    homelink="/dashboard"
                    breadcrumb="Scholars"
                    breadcrumbs="Add Scholar"
                    innerpath="/scholar"
                />
                <Heading
                    title="Import Scholars"
                    description="Upload CSV files to upload scholars"
                />

                <UploadFile
                    filters={filters}
                    setFilters={setFilters}
                    fields={fields}
                    resource={'/Users/Scholar'}
                    sampleFile={'/samples/Scholars.csv'}
                    redirectTo={'/scholar'}
                    availableFilter={[
                        'campus',
                        'cluster',
                        'researchCenter',
                        'batch',
                        'program',
                        'discipline',
                    ]}
                    showImport={
                        filters.CampusId &&
                        filters.ClusterId &&
                        filters.ResearchCenterId &&
                        filters.BatchId &&
                        filters.ProgramId &&
                        filters.DisciplineId
                    }
                />
            </div>
        </div>
    );
};

export default ImportScholar;
