import React from 'react';
import { Button } from '../../Buttons/Button';
import Errordelete from '../../../../images/error.svg';

const ErrorModal = (props) => {
    return (
        <div className="delete-modal">
            <div className="delete-modal-img">
                <img src={Errordelete} alt={'Errordelete'} />
                <img onClick={props.onClose} src="/images/cross.svg" />
            </div>
            {props.isMulti ? (
                <div className="delete-modal-heading">
                    <h5>Delete Records</h5>
                    <span>{props.errorText}</span>
                </div>
            ) : (
                <div className="delete-modal-heading">
                    <h5>Error</h5>
                    <span>
                        {/* Are you sure you want to delete this record? This action
                        cannot be undone. */}
                        {props.errorText}
                    </span>
                </div>
            )}

            <div className="error-modals-cb-buttons">
                <Button
                    className="error-confirm-button"
                    onClick={props.onClick}
                    width="350px"
                    height="44px"
                    backgroundcolor="#D92D20"
                    fontweight="300"
                    hover="#B42318"
                    padding="0px 20px !important"
                >
                    Okay, got it
                </Button>
            </div>
        </div>
    );
};

export default ErrorModal;
