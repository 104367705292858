import React from 'react';
import Header from '../../../../../../../components/Header/Header';
import Heading from '../../../../../../../components/Heading/Heading';
import Progressbar from '../../../../../../../components/Progress Bar/Progressbar';
import MobileHeader from '../../../../../../../components/Sidebar/Mobile Design/Mobile Header/MobileHeader';
import Sidebar from '../../../../../../../components/Sidebar/Sidebar';
import MediaQuery from 'react-responsive';
import GenericContent from '../Generic/GenericContent';
import {useParams} from 'react-router-dom';

export const AwardDetailContent = ({id}) => {
    return <GenericContent
        id={id}
        title={'Awards'}
        description={'Add your award details here'}
        endpoint={'Award'}
        column={[

            {
                heading: 'Title',
                value: 'Title',
            },
            {
                heading: 'Level',
                value: 'AwardLevel',
            },
            {
                heading: 'Awarding Body',
                value: 'Issuer',
            },
            {
                heading: 'Year',
                value: 'Year',
            }
        ]}
        fields={
            [
                {
                    type: 'select',
                    field: 'AwardLevel',
                    label: 'Award Level',
                    description: 'This will be displayed on your profile.',
                    estric: '*',
                    options: {
                        type: 'static',
                        options: [
                            {value: 'National', label: 'National'},
                            {value: 'International', label: 'International'},
                        ]
                    }
                },
                {
                    type: 'text',
                    field: 'Title',
                    label: 'Title',
                    estric: '*',
                },
                {
                    type: 'text',
                    field: 'Issuer',
                    label: 'Awarding Body',
                    description: 'This will be displayed on your profile.',
                    estric: '*',
                },
                {
                    type: 'text',
                    field: 'Year',
                    label: 'Year',
                    description: 'This will be displayed on your profile.',
                    estric: '*',
                },
                {
                    type: 'date',
                    field: 'Date',
                    label: 'Date',
                    description: 'This will be displayed on your profile.',
                    required: false,
                },
                {
                    type: 'textarea',
                    field: 'Description',
                    label: 'Description',
                    description: 'This will be displayed on your profile.',
                    estric: '*'
                }

            ]}
        initialValues={{
            Title: '',
            Issuer: '',
            Description: '',
            AwardLevel: '',
            Date: null,
            Year: null,
            CreatedAt: null,
            CreatedBy: null,
            IsDeleted: false,
            UserProfile: id,
        }}
    />
}
const AwardDetail = () => {
    let {scholarId} = useParams();
    return (
        <div className="main-wrapper">
            <MediaQuery maxWidth={1023}>
                <MobileHeader/>
            </MediaQuery>
            <MediaQuery minWidth={1024}>
                <Sidebar/>
            </MediaQuery>
            <div className="screen">
                {/* <Header
                    homelink="/dashboard"
                    title="Scholars"
                    breadcrumbs="My Profile"
                    href="/scholar"
                /> */}
                <Heading title="My Profile" description=""/>
                <div className="user-profile-section">
                    <Progressbar/>
                    <AwardDetailContent id={scholarId}/>
                </div>
            </div>
        </div>
    );
};

export default AwardDetail;
