import React, { useRef } from 'react';
import styled from 'styled-components';
import ReactTooltip from 'react-tooltip';
import Api from '../../../api/api';
import RemovedImageIcon from '../../../images/remove-image.svg';

const InputContainer = styled.div`
  width: 100%;
  float: left;

  > label {
    font-size: 14px;
    font-weight: 400;
    color: #344054;
    padding-bottom: 4px;
  }

  > span {
    font-size: 14px;
    color: #f04438;
  }
`;

const GroupContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 40px;
  border: 1px solid ${(props) => (props.hasError ? '#F04438' : '#D0D5DD')};
  border-radius: 4px;
  padding: 0 10px;

  &:hover {
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05), 0px 0px 0px 2px #f4ebff;
  }

  > img {
    width: 18px;
    height: 18px;
    margin: 8px;
    color: #c1c1c1;
  }
`;

const InputBase = styled.input`
  height: auto;
  width: 100%;
  color: #1d293;
  font-size: 14px;
  font-weight: 400;
  border: none;
  background: transparent;
  outline: none;

  &::placeholder {
    color: #c1c1c1 !important;
    font-size: 14px;
    font-weight: 300;
  }
`;

export const SimpleFileInput = ({
                               onClick,
                               onChange,
                               type,
                               value,
                               placeholder,
                               name,
                               error,
                               righticon,
                               lefticon,
                               label,
                               successMsg,
                               tooltipicon,
                               tooltiptext,
                               readOnly = false,
                               multiple = false,
                           }) => {
    let v = JSON.parse(value || '[]') || [];
    const ref = useRef(null);
    console.log({v});
    return (
        <InputContainer>
            {label && <label>{label}</label>}
            <GroupContainer hasError={error}>
                <div className="files-upload">
                    <div className="files">
                        <InputBase
                            ref={ref}
                            readOnly={readOnly}
                            type="file"
                            multiple={multiple}
                            onChange={(e) => {
                                let localValue = [...v];
                                if(localValue.length > 0){
                                    ref.current.value = '';

                                    return;
                                }
                                let files = e.target.files;
                                let promises = Object.keys(files).map((k) => {
                                    let file = files[k];
                                    const data = new FormData();
                                    data.append('File', file);
                                    return Api.post(
                                        '/Files/FileUpload/uploadFiles',
                                        data,
                                    );
                                });

                                Promise.all(promises)
                                    .then((response) => {
                                        response.forEach((res) => {
                                            localValue.push({
                                                FileName: res.FileName,
                                                DisplayName: res.DisplayName,
                                            });
                                        });
                                        onChange({
                                            target: {
                                                value: JSON.stringify(
                                                    localValue,
                                                ),
                                                name,
                                            },
                                        });
                                    })
                                    .catch((e) => {
                                        console.log(e);
                                    })
                                    .finally(() => {
                                        ref.current.value = '';
                                    });
                            }}
                            name={name}
                            placeholder={placeholder}
                        />
                    </div>
                    {righticon && <img src={righticon} onClick={onClick} />}
                    {tooltipicon && (
                        <>
                            <ReactTooltip
                                id={tooltiptext}
                                type="dark"
                                effect="solid"
                                place="left"
                            >
                                {tooltiptext}
                            </ReactTooltip>
                            <img
                                data-tip
                                data-for={tooltiptext}
                                src={tooltipicon}
                                onClick={onClick}
                            />
                        </>
                    )}
                </div>
            </GroupContainer>
            {successMsg ? (
                <p style={{ color: 'green' }}>{successMsg}</p>
            ) : (
                <span>{error}</span>
            )}
            <div className="files-select">
                {v.map((file, index) => {
                    return (
                        <div className="uploaded-file-remove">
                            <a
                                href={`${Api.api_url}/uploads/${file.FileName}`}
                                target="_blank"
                            >
                                {file.DisplayName}
                            </a>
                            <span
                                onClick={() => {
                                    v.splice(index, 1);
                                    onChange({
                                        target: {
                                            value: JSON.stringify(v),
                                            name,
                                        },
                                    });
                                }}
                            >
                                <div className="uploaded-file-remove-icon">
                                    <span>
                                        <img
                                            className="remove-image-icon"
                                            src={RemovedImageIcon}
                                        ></img>
                                    </span>
                                </div>
                            </span>
                        </div>
                    );
                })}
            </div>
        </InputContainer>
    );
};
