import React from 'react';
import Header from '../../../../Header/Header';
import MobileHeader from '../../../../Sidebar/Mobile Design/Mobile Header/MobileHeader';
import Sidebar from '../../../../Sidebar/Sidebar';
import InviteSupervisorDefaultContent from './InviteSupervisorDefaultContent';
import MediaQuery from 'react-responsive';

const InviteSupervisorDefault = () => {
    return (
        <div className="main-wrapper">
            <MediaQuery maxWidth={1023}>
                <MobileHeader />
            </MediaQuery>
            <MediaQuery minWidth={1024}>
                <Sidebar />
            </MediaQuery>
            <div className="screen">
                <Header
                    homelink="/dashboard"
                    breadcrumb="Invite Supervisors"
                    breadcrumbs="Invite Supervisor List"
                    innerpath="/invite-Supervisor"
                />
                <div className="user-profile-section">
                    <InviteSupervisorDefaultContent
                        heading="No invite supervisors found"
                        subheading="There are no invite supervisors added yet. Please invite supervisors from below."
                    />
                </div>
            </div>
        </div>
    );
};

export default InviteSupervisorDefault;
