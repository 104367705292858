import React from 'react';
import MobileHeader from '../../../../../components/Sidebar/Mobile Design/Mobile Header/MobileHeader';
import Sidebar from '../../../../../components/Sidebar/Sidebar';
import AddDepartmentContent from './AddDepartmentContent';
import MediaQuery from 'react-responsive';
import FormHeading from '../../../../../components/Heading/FormHeading';
import FormHeader from '../../../../../components/Header/FormHeader';
import Backarrow from '../../../../../../images/back-arrow.svg';
import { LinkButton } from '../../../../../components/Buttons/LinkButton';

const AddDepartment = () => {
    return (
        <div className="main-wrapper">
            <MediaQuery maxWidth={1023}>
                <MobileHeader />
            </MediaQuery>
            <MediaQuery minWidth={1024}>
                <Sidebar />
            </MediaQuery>
            <div className="screen">
                <div className="heading-button-mobile">
                    <FormHeader
                        homelink="/dashboard"
                        breadcrumb="Departments"
                        breadcrumbs=" Add Department"
                        innerpath="/department"
                    />

                    <FormHeading
                        title="Add Department"
                        description="Enter the following details to add department"
                    />
                    <MediaQuery maxWidth={1023}>
                    <div className='back-button'>
                        <LinkButton
                            linktext="Back to Departments"
                            icon={Backarrow}
                            href="/department"
                            width="204px"
                            height="44px"
                            backgroundcolor="none"
                            color="#5D43A5"
                            fontsize="16px"
                            hover="none"
                        />
                        </div>
                    </MediaQuery>
                    <AddDepartmentContent />
                </div>
            </div>
        </div>
    );
};

export default AddDepartment;
