import React, {forwardRef, useImperativeHandle, useRef} from 'react';
import GenericProfileForm from '../../../../../../../components/Form/GenericProfileForm';

const AddProfessional = ({onClose, id, fields, endpoint, initialValues, title, initialValuesUpdated}, ref) => {
    const formRef = useRef(null);
    useImperativeHandle(ref, () => ({
        updateData: (d) => {
            formRef.current.updateData(d);
        },
    }));

    return (
        <div className="userprofile-modal">
            <div className="modal-box">
                <div className="modal-heading">
                    <h5>{title}</h5>
                    <span>Update your {title} details here</span>
                </div>
                <img
                    className="modal-cross-icon"
                    onClick={onClose}
                    src="/images/cross.svg"
                    alt={'Close Modal'}
                />
            </div>

            <GenericProfileForm
                fields={fields}
                id={id}
                initialValuesUpdated={initialValuesUpdated}
                initialValues={initialValues}
                onClose={onClose}
                endpoint={endpoint}
                ref={formRef}
            />
        </div>
    );
};

export default forwardRef(AddProfessional);
