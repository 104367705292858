import React, { useEffect, useState } from 'react';
import { Button } from '../../../../../Buttons/Button';
import { ExportButton } from '../../../../../Buttons/ExportButton';
import Api from '../../../../../../../api/api';
import SelectInput from '../../../../../Inputs/SelectInput';

const AssignSupervisors = ({
    batch,
    onHide,
    ResearchCenterId,
    refresh,
    CampusId,
}) => {
    const [supervisors, setSupervisors] = useState([]);
    const [coSupervisors, setCoSupervisors] = useState([]);
    const [selectedDeliverables, setSelectedDeliverables] = useState([]);

    const [input, setInput] = useState({
        MilestoneId: batch.MilestoneId,
        BatchId: batch.Id,
        ResourcePerson1: batch.ResourcePerson1Id,
        ResourcePerson2: batch.ResourcePerson2Id,
    });

    useEffect(() => {
        console.log({ input });
    }, [input]);

    useEffect(() => {
        Api.get('/AcademicStructure/Milestone/getEntities', {
            CampusId,
            StaffStatus: 'Active',
        }).then((response) => {
            setSupervisors(response.Data);
        });
        Api.get('/Users/Staff/getEntities', {
            CampusId,
            StaffStatus: 'Active',
            StaffType: 'Teaching',
        }).then((response) => {
            setCoSupervisors(response.Data);
        });
    }, [ResearchCenterId]);

    const submit = () => {
        Api.post(`/AcademicStructure/ResourcePerson/postEntity`, input)
            .then(() => {
                onHide();
                refresh();
            })
            .catch(console.log);
    };

    return (
        <div className="fee-policy-modal">
            <div className="modal-box">
                <div className="modal-heading">
                    <h5>Assign Resources</h5>
                </div>
                <img
                    className="modal-cross-icon"
                    onClick={onHide}
                    src="/images/cross.svg"
                />
            </div>

            <div className="fee-policy-content">
                <>
                    <div className="assign-fee-policy-content">
                        <div className="assign-fee-policy-amount">
                            <div className="milestone-fees">
                                Select Deliverable
                                <div className="select-supervisors-input">
                                    <SelectInput
                                        options={supervisors.map((user) => {
                                            return {
                                                value: user.id,
                                                label: `${user.Title}`.trim(),
                                            };
                                        })}
                                        onChange={(value) => {
                                            setInput({
                                                ...input,
                                                MilestoneId: value.value,
                                            });
                                        }}
                                        value={input.MilestoneId}
                                    />
                                </div>
                            </div>
                            <div className="milestone-fees">
                                <div className="select-supervisors-input-heading">
                                    Select Resource Person 1
                                </div>
                                <div className="select-supervisors-input">
                                    <SelectInput
                                        onChange={(value) => {
                                            setInput({
                                                ...input,
                                                ResourcePerson1: value.value,
                                            });
                                        }}
                                        options={coSupervisors.map((user) => {
                                            return {
                                                value: user.id,
                                                label: `${user.FirstName} ${user.LastName} - ${user.EmployeeId} - ${user.ResearchCenterName}`.trim(),
                                            };
                                        })}
                                        value={input.ResourcePerson1}
                                    />
                                </div>
                            </div>
                            <div className="milestone-fees">
                                Select Resource Person 2
                                <div className="select-supervisors-input">
                                    <SelectInput
                                        options={coSupervisors.map((user) => {
                                            return {
                                                value: user.id,
                                                label: `${user.FirstName} ${user.LastName} - ${user.EmployeeId} - ${user.ResearchCenterName}`.trim(),
                                            };
                                        })}
                                        onChange={(value) => {
                                            setInput({
                                                ...input,
                                                ResourcePerson2: value.value,
                                            });
                                        }}
                                        value={input.ResourcePerson2}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            </div>

            <div className="bottom-line-assign-fee-policy"></div>

            <div className="cb-buttons-assign-fee-policy">
                <ExportButton
                    width="220px"
                    height="40px"
                    hover="#F9FAFB"
                    border="1px solid #D0D5DD"
                    onClick={onHide}
                >
                    Cancel
                </ExportButton>
                <Button
                    onClick={submit}
                    width="220px"
                    height="40px"
                    padding="0px 20px !important"
                    hover="#5D43A5"
                >
                    Save
                </Button>
            </div>
        </div>
    );
};

export default AssignSupervisors;
