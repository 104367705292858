import React, { useState } from 'react';
import Header from '../../../../../../components/Header/Header';
import Heading from '../../../../../../components/Heading/Heading';
import MobileHeader from '../../../../../../components/Sidebar/Mobile Design/Mobile Header/MobileHeader';
import Sidebar from '../../../../../../components/Sidebar/Sidebar';
import UploadFile from '../../../../../../components/Upload File/UploadFile';
import MediaQuery from 'react-responsive';

const ImportDRCFaculty = () => {
    const [filters, setFilters] = useState({});

    const fields = [
        {
            type: 'text',
            field: 'EmployeeId',
            label: 'Employee Id',
            description: 'This will be displayed on your profile.',
            required: false,
        },
        {
            type: 'text',
            field: 'Designation',
            label: 'Designation',
            description: 'This will be displayed on your profile.',
            estric: '*',
        },
        {
            type: 'select',
            field: 'Honorifics',
            label: 'Title',
            description: 'This will be displayed on your profile.',
            estric: '*',
            options: {
                type: 'enum',
                value: 'Honorifics',
            },
        },

        {
            type: 'text',
            field: 'FirstName',
            label: 'First Name',
            description: 'This will be displayed on your profile.',
            estric: '*',
        },
        {
            type: 'text',
            field: 'LastName',
            label: 'Last Name',
            description: 'This will be displayed on your profile.',
            required: false,
        },
        {
            type: 'text',
            field: 'MiddleName',
            label: 'Middle Name',
            description: 'This will be displayed on your profile.',
            required: false,
        },
        {
            type: 'select',
            field: 'Gender',
            label: 'Gender',
            description: 'This will be displayed on your profile.',
            estric: '*',
            options: {
                type: 'enum',
                value: 'Gender',
            },
        },
        {
            type: 'text',
            field: 'Email',
            label: 'Email',
            description: 'This will be displayed on your profile.',
            estric: '*',
        },
        {
            type: 'text',
            field: 'PhoneNumber',
            label: 'Phone Number',
            description: 'This will be displayed on your profile.',
            estric: '*',
        },
        {
            type: 'text',
            field: 'StaffType',
            label: 'Staff Type',
            description: 'This will be displayed on your profile.',
            estric: '*',
        },
        {
            type: 'text',
            field: 'StaffStatus',
            label: 'Staff Status',
            description: 'This will be displayed on your profile.',
            estric: '*',
        },
        {
            type: 'date',
            field: 'DateOfJoining',
            label: 'Date Of Joining',
            description: 'This will be displayed on your profile.',
            estric: '*',
        },
        {
            type: 'date',
            field: 'FacultyType',
            label: 'Faculty Type',
            description: 'This will be displayed on your profile.',
            estric: '*',
        },
    ];

    return (
        <div className="main-wrapper">
            <MediaQuery maxWidth={1023}>
                <MobileHeader />
            </MediaQuery>
            <MediaQuery minWidth={1024}>
                <Sidebar />
            </MediaQuery>
            <div className="screen">

                <Heading
                    title="Import Staff"
                    description="Upload CSV files to upload staff"
                />
                <UploadFile
                    sampleFile={'/samples/Staff.csv'}
                    filters={filters}
                    setFilters={setFilters}
                    fields={fields}
                    resource={'/Users/Staff'}
                    redirectTo={'/staff'}
                    availableFilter={['campus', 'cluster', 'researchCenter']}
                    showImport={
                        filters.CampusId &&
                        filters.ClusterId &&
                        filters.ResearchCenterId
                    }
                />
            </div>
        </div>
    );
};

export default ImportDRCFaculty;
