import React, { useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import Completeprofile from '../../../../images/complete-profile.svg';
import Activeprofile from '../../../../images/active-progress-line.svg';
import Inactiveprofile from '../../../../images/inactive-progress-line.svg';
import MediaQuery from 'react-responsive';
import UserProfileSelectDropdown from "../../../components/User Profile Mobile Design/UserProfileSelectDropdown";

const Complete = () => {
    return (
        <img
            className="progress-complete"
            src={Completeprofile}
            alt={'Inactiveprofile'}
        />
    );
};
const InComplete = () => {
    return (
        <img
            className="progress-complete"
            src={Inactiveprofile}
            alt={'Inactiveprofile'}
        />
    );
};
const Active = () => {
    return (
        <img
            className="progress-complete"
            src={Activeprofile}
            alt={'Inactiveprofile'}
        />
    );
};

const StatusIcon = ({ section }) => {
    if (window.location.href.endsWith(`/${section}`)) {
        return <Active />;
    }
    return <InComplete />;
};

const Progressbar = () => {
    const [condition] = useState(false);
    const { scholarId } = useParams();

    return (
        <div className="user-profile-select">
            <MediaQuery maxWidth={1023}>
                {/* <UserProfileSelectDropdown /> */}
            </MediaQuery>
            <div className="progress-bar">
                <div className="progress-bar-content">
                    <ul>
                        <li>
                            <Link to={`/user/profile/personal`}>
                                <div className="personal-info-tab">
                                    <StatusIcon
                                    section={'personal'} />
                                    <span className={window.location.pathname === `/user/profile/personal` ? 'active':''}>Personal Information</span>
                                </div>
                            </Link>
                        </li>

                        <li>
                            <Link to={`/user/profile/address`}>
                                <div className="personal-info-tab">
                                    <StatusIcon section={'address'} />
                                    <span className={window.location.pathname === `/user/profile/address` ? 'active':''}>Address</span>
                                </div>
                            </Link>
                        </li>


                        <li>
                            <Link to={`/user/profile/experience`}>
                                <div className="personal-info-tab">
                                    <StatusIcon section={'experience'} />
                                    <span className={window.location.pathname === `/user/profile/experience` ? 'active':''}>Experience</span>
                                </div>
                            </Link>
                        </li>

                        <li>
                            <Link to={`/user/profile/qualifications`}>
                                <div className="personal-info-tab">
                                    <StatusIcon section={'qualifications'} />
                                    <span className={window.location.pathname === `/user/profile/qualifications` ? 'active':''}>Qualification</span>
                                </div>
                            </Link>
                        </li>

                        <li>
                            <Link to={`/user/profile/projects`}>
                                <div className="personal-info-tab">
                                    <StatusIcon section={'projects'} />
                                    <span className={window.location.pathname === `/user/profile/projects` ? 'active':''}>Projects</span>
                                </div>
                            </Link>
                        </li>

                        <li>
                            <Link to={`/user/profile/patents`}>
                                <div className="personal-info-tab">
                                    <StatusIcon section={'patents'} />
                                    <span className={window.location.pathname === `/user/profile/patents` ? 'active':''}>Patent Details</span>
                                </div>
                            </Link>
                        </li>

                        <li>
                            <Link to={`/user/profile/awards`}>
                                <div className="personal-info-tab">
                                    <StatusIcon section={'awards'} />
                                    <span className={window.location.pathname === `/user/profile/awards` ? 'active':''}>Award Details</span>
                                </div>
                            </Link>
                        </li>


                        <li>
                            <Link to={`/user/profile/social`}>
                                <div className="personal-info-tab">
                                    <StatusIcon section={'social'} />
                                    <span className={window.location.pathname === `/user/profile/social` ? 'active':''}>Social Sites</span>
                                </div>
                            </Link>
                        </li>


                        <li>
                            <Link to={`/user/profile/publications`}>
                                <div className="personal-info-tab">
                                    <StatusIcon section={'publications'} />
                                    <span className={window.location.pathname === `/user/profile/publications` ? 'active':''}>Publications Details</span>
                                </div>
                            </Link>
                        </li>

                        <li>
                            <Link to={`/user/profile/documents`}>
                                <div className="personal-info-tab">
                                    <StatusIcon section={'documents'} />
                                    <span  className={window.location.pathname === `/user/profile/documents` ? 'active':''}>Upload Documents</span>
                                </div>
                            </Link>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    );
};

export default Progressbar;
