import React from 'react';
import Home from '../../../images/home.svg';
import Forwardarrow from '../../../images/forward-arrow.svg';

const Header = (props) => {
    return (
        <div className="header">
            <div className="breadcrumbs">
                <a className="home" href={props.homelink}>
                    <img className="home-icon" src={Home} alt={'Home'} />
                </a>
                {props.homelink && (
                    <img
                        className="arrow-icon"
                        src={Forwardarrow}
                        alt={'Forwardarrow'}
                    />
                )}
                <a className="breadcrumbs" href={props.href}>
                    {props.title}
                </a>
                {props.href && (
                    <img
                        className="arrow-icon"
                        src={Forwardarrow}
                        alt={'Forwardarrow'}
                    />
                )}
                <a className="breadcrumbs" href={props.innerpath}>
                    {props.breadcrumb}
                </a>
                {props.innerpath && (
                    <img
                        className="arrow-icon"
                        src={Forwardarrow}
                        alt={'Forwardarrow'}
                    />
                )}
                <span className="breadcrumb-lasttitle">
                    {props.breadcrumbs}
                </span>
            </div>
        </div>
    );
};

export default Header;
