import React, { useEffect, useState } from 'react';
import Header from '../../../components/Header/Header';
import Heading from '../../../components/Heading/Heading';
import Sidebar from '../../../components/Sidebar/Sidebar';
import GeneralView from '../View Campus/Subcomponents/GeneralView';
import { useParams } from 'react-router-dom';
import useDetailApi from '../../../../hooks/useDetailApi';
import Backarrow from '../../../../images/back-arrow.svg';
import MediaQuery from 'react-responsive';
import MobileHeader from '../../../components/Sidebar/Mobile Design/Mobile Header/MobileHeader';
import formatNumber from '../../../../helpers/formatNumber';

const ViewDepartment = () => {
    const { id } = useParams();
    const { loading, item } = useDetailApi('FeeStructure/FeePolicy', id);
    const [milestones, setMilestones] = useState({});

    useEffect(() => {
        if (!item) {
            return;
        }
        let ms = {};
        item.MilestoneFees.forEach((mileStoneFee) => {
            ms[mileStoneFee.Milestone.FeeHeader] = mileStoneFee.FeeAmount;
        });
        setMilestones(ms);
    }, [item]);

    const fields = [
        {
            type: 'select',
            field: 'CampusName',
            label: 'Campus',
            description: 'This will be displayed on your profile.',
            estric: '*',
            options: {
                type: 'dynamic',
                endpoint: 'Infrastructure/Campus',
                paged: true,
                valueKey: 'Id',
                labelKey: 'DisplayName',
            },
        },
        {
            type: 'select',
            field: 'ClusterName',
            label: 'Registry',
            description: 'This will be displayed on your profile.',
            estric: '*',
            options: {
                type: 'dynamic',
                endpoint: 'Infrastructure/Cluster',
                paged: true,
                valueKey: 'Id',
                labelKey: 'Name',
                optionsFilterCallback: (cluster, formValue) => {
                    return cluster.CampusId === formValue.CampusId;
                },
            },
        },
        {
            type: 'select',
            field: 'ResearchCenterName',
            label: 'Discipline',
            description: 'This will be displayed on your profile.',
            estric: '*',
            options: {
                type: 'dynamic',
                endpoint: 'Infrastructure/ResearchCenter',
                paged: true,
                valueKey: 'Id',
                labelKey: 'Name',
                optionsFilterCallback: (cluster, formValue) => {
                    return cluster.ClusterId === formValue.ClusterId;
                },
            },
        },
        {
            type: 'text',
            field: 'Name',
            label: 'Name',
            description: 'This will be displayed on your profile.',
            estric: '*',
        },
        {
            type: 'text',
            field: 'Code',
            label: 'Fee Code',
            description: 'This will be displayed on your profile.',
            estric: '*',
        },
    ];

    let total = 0;
    Object.keys(milestones).forEach((k) => {
        fields.push({
            type: 'text',
            field: k,
            label: k,
            description: 'This will be displayed on your profile.',
            estric: '*',
            render: (item) => {
                return `₹${formatNumber(item[k])}`;
            },
        });
        total += milestones[k];
    });

    fields.push({
        type: 'text',
        field: 'TotalAmount',
        label: 'Total Amount',
        description: 'This will be displayed on your profile.',
        estric: '*',
        render: (item) => {
            return `₹${formatNumber(item['TotalAmount'])}`;
        },
    });

    let updatedItem = { ...item, ...milestones, TotalAmount: total };


    return (
        <div className="main-wrapper">
            <MediaQuery maxWidth={1023}>
                <MobileHeader />
            </MediaQuery>
            <Sidebar />

            <div className="screen">
                {/* <Header
                    homelink="/dashboard"
                    breadcrumb="Fee Policies"
                    breadcrumbs="View Fee Policy"
                    innerpath="/fee-policy"
                /> */}
                <Heading
                    title="Fee Policy Details"
                    description="View all details of the fee policy below"
                    linktext="Back to Fee Policies"
                    icon={Backarrow}
                    href="/fee-policy"
                    width="auto"
                    height="44px"
                    backgroundcolor="none"
                    color="#5D43A5"
                    fontsize="16px"
                    hover="#F9F5FF"
                    padding="0px 20px !important"
                    className="view-back-button"
                />
                {loading ? (
                    <div> Loading... </div>
                ) : (
                    <>
                        <GeneralView campus={updatedItem} fields={fields} />
                    </>
                )}
            </div>
        </div>
    );
};

export default ViewDepartment;
