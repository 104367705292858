import React from 'react';
import Errordelete from '../../../../images/error.svg';
import { Button } from '../../Buttons/Button';

const AlertModal = (props) => {
    return (
        <div>
            <div className="delete-modal">
                <div className="delete-modal-img">
                    <img src={Errordelete} alt={'Errordelete'} />
                    <img onClick={props.onClose} src="/images/cross.svg" />
                </div>
                <div className="delete-modal-heading">
                    <h5>Alert</h5>
                    <span>{props.errorMessage}</span>
                </div>
                <div className="error-modals-cb-buttons">
                    <Button
                        className="error-confirm-button"
                        onClick={props.onClose}
                        width="auto"
                        height="44px"
                        backgroundcolor="#D92D20"
                        fontweight="300"
                        hover="#B42318"
                        padding="0px 20px !important"
                    >
                        Okay
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default AlertModal;
