import React, { useEffect, useState } from 'react';
import { Button } from '../../../../../Buttons/Button';
import { ExportButton } from '../../../../../Buttons/ExportButton';
import SelectInput from '../../../../../Inputs/SelectInput';
import Api from '../../../../../../../api/api';
import { isArray } from 'lodash';

const selectmode = [{ value: 'fee policy 2022', label: 'Fee Policy 2022' }];

const AssignFeePolicy = ({
    users,
    onHide,
    ResearchCenterId,
    FeePolicyId,
    refresh,
}) => {
    const [feePolicies, setFeePolicies] = useState([]);
    const [feePolicyId, setFeePolicyId] = useState(FeePolicyId);
    const [feePolicy, setFeePolicy] = useState(null);

    useEffect(() => {
        Api.get('/FeeStructure/FeePolicy/getEntities', {
            ResearchCenterId: ResearchCenterId,
        }).then((response) => {
            setFeePolicies(response.Data);
        });
    }, [ResearchCenterId]);

    useEffect(() => {
        Api.get(`/FeeStructure/FeePolicy/getEntity/${feePolicyId}`).then(
            (response) => {
                setFeePolicy(response);
            },
        );
    }, [feePolicyId]);

    const submit = () => {
        Api.post('/FeeStructure/ScholarFee/AssignFeeList', users.map((user) => ({
            ScholarId: user.Id,
            FeePolicyId: feePolicyId,
        })))
            .then(() => {
                onHide();
                refresh();
            })
            .catch(console.log);
    };

    return (
        <div className="fee-policy-modal">
            <div className="modal-box">
                <div className="modal-heading">
                    <h5>Assign Fee Policy</h5>
                </div>
                <img
                    className="modal-cross-icon"
                    onClick={onHide}
                    src="/images/cross.svg"
                />
            </div>

            <div className="fee-policy-content">
                <div className="assign-fee-policy-content">
                    <div className="assign-fee-policy-amount">
                        <div className="assign-fees">Fee Policy</div>
                        <SelectInput
                            placeholder="Select fee policy"
                            options={feePolicies.map((feePolicy) => ({
                                value: feePolicy.id,
                                label: feePolicy.Name,
                            }))}
                            onChange={(e) => {
                                setFeePolicyId(e.value);
                            }}
                            value={feePolicyId}
                        />
                    </div>
                </div>
                {feePolicy && (
                    <>
                        <div className="assign-fee-policy-content">
                            <div className="assign-fee-policy-amount">
                                {isArray(feePolicy.MilestoneFees) &&
                                    feePolicy.MilestoneFees.map(
                                        (MilestoneFee) => {
                                            return (
                                                <div className="milestone-fees">
                                                    <span>
                                                        {
                                                            MilestoneFee
                                                                .Milestone.FeeHeader
                                                        }
                                                    </span>
                                                    <div className="milestone-fee-amount">
                                                        <span>
                                                            ₹
                                                            {
                                                                MilestoneFee.FeeAmount
                                                            }
                                                        </span>
                                                    </div>
                                                </div>
                                            );
                                        },
                                    )}
                            </div>
                        </div>
                    </>
                )}
            </div>

            <div className="bottom-line-assign-fee-policy"></div>

            {feePolicy && (
                <div className="all-milestones-amount">
                    <span>Total Amount</span>
                    <div className="milestone-fee-amount">
                        <span>
                            ₹{' '}
                            {feePolicy.MilestoneFees.reduce((p, t) => {
                                return p + t.FeeAmount;
                            }, 0)}
                        </span>
                    </div>
                </div>
            )}

            <div className="cb-buttons-assign-fee-policy">
                <ExportButton
                    width="220px"
                    height="40px"
                    hover="#F9FAFB"
                    border="1px solid #D0D5DD"
                    onClick={onHide}
                >
                    Cancel
                </ExportButton>
                <Button
                    onClick={submit}
                    width="220px"
                    height="40px"
                    padding="0px 20px !important"
                    hover="#5D43A5"
                >
                    Save
                </Button>
            </div>
        </div>
    );
};

export default AssignFeePolicy;
