import React from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';

const SidebarActiveIcon = (props) => {
    const getClassNames = (defaultClass, active) => {
        return classNames(defaultClass, { active: active });
    };
    return (
        <div className="sidebar-active-icon">
            <Link
                to={props.url}
                onClick={(e) => {
                    if (props.url) {
                        e.stopPropagation();
                    }
                }}
                className={getClassNames('nav-item1', props.isActive)}
            >
                <div className="nav-content">
                    {props.active && (
                        <img
                            className="sidebar-icons active-icons"
                            src={props.active}
                            alt={'Dashboard'}
                        />
                    )}
                    {props.inactive && (
                        <img
                            className="sidebar-icons inactive-icons"
                            src={props.inactive}
                            alt={'Home'}
                        />
                    )}

                    {props.isActive && (
                        <span className="dropdown-active-tab">
                            {props.tabname}
                        </span>
                    )}

                    {!props.isActive && <span>{props.tabname}</span>}
                </div>
                <div className="sidebar-drop-down">
                    {props.activearrow && (
                        <img
                            className="drop-down active-icon"
                            src={props.activearrow}
                            alt={'Dropdown'}
                        />
                    )}
                    {props.inactivearrow && (
                        <img
                            className="drop-down inactive-icon"
                            src={props.inactivearrow}
                            alt={'Dropdown'}
                        />
                    )}
                </div>
            </Link>
        </div>
    );
};

export default SidebarActiveIcon;
