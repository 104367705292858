import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import ReactTooltip from 'react-tooltip';
import { Editor } from 'react-draft-wysiwyg';
import { ContentState, convertToRaw, EditorState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import Api from '../../../api/api';

const InputContainer = styled.div`
    width: 100%;
    float: left;

    > label {
        font-size: 14px;
        font-weight: 400;
        color: #344054;
        padding-bottom: 4px;
    }

    > span {
        font-size: 14px;
        color: #f04438;
    }
`;

const GroupContainer = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px solid ${(props) => (props.hasError ? '#F04438' : '#D0D5DD')};
    border-radius: 8px;
    padding: 10px 10px;

    &:hover {
        box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05), 0px 0px 0px 2px #f4ebff;
    }

    > img {
        width: 18px;
        height: 18px;
        margin: 8px;
        color: #c1c1c1;
    }
`;

const InputBase = styled.textarea`
    height: auto;
    width: 100%;
    color: #1d2939;
    font-size: 15px;
    font-weight: 400;
    border: none;
    background: transparent;
    outline: none;

    &::placeholder {
        color: #c1c1c1;
        font-weight: 300;
    }
`;

export const HTMLInput = ({
    onClick,
    onChange,
    type,
    value,
    placeholder,
    name,
    error,
    righticon,
    lefticon,
    label,
    successMsg,
    tooltipicon,
    tooltiptext,
}) => {
    const [state, setState] = useState(EditorState.createEmpty());
    const [uploadedImages, setUploadedImages] = useState([]);
    const [val, setVal] = useState('');

    useEffect(() => {
        if (val !== value) {
            const contentBlock = htmlToDraft(value);
            if (contentBlock) {
                const contentState = ContentState.createFromBlockArray(
                    contentBlock.contentBlocks,
                );
                setState(EditorState.createWithContent(contentState));
            }
        }
    }, [value]);
    const _uploadImageCallBack = (file) => {
        return new Promise((resolve, reject) => {
            Api.getInstance()
                .uploadFile(
                    {
                        extension: file.name.split('.').pop(),
                        'Content-Type': file.type,
                    },
                    file,
                )
                .then(({ key }) => {
                    resolve({
                        data: { link: Api.getInstance().getImageUrl(key) },
                    });
                })
                .catch(console.log);
        });
    };
    return (
        <InputContainer>
            {label && <label>{label}</label>}
            <GroupContainer hasError={error}>
                {lefticon && <img src={lefticon} />}
                <Editor
                    editorState={state}
                    toolbarClassName="toolbarClassName"
                    wrapperClassName="wrapperClassName"
                    editorClassName="editorClassName"
                    onEditorStateChange={(e) => {
                        setState(e);
                        let v = draftToHtml(
                            convertToRaw(state.getCurrentContent()),
                        );
                        setVal(v);
                        onChange({ target: { name, value: v } });
                    }}
                    toolbar={{
                        options: [
                            'inline',
                            'blockType',
                            'fontSize',
                            'fontFamily',
                            'list',
                            'textAlign',
                            'colorPicker',
                            'link',
                            'emoji',
                            'remove',
                            'history',
                        ],
                    }}
                />
                {righticon && <img src={righticon} onClick={onClick} />}
                {tooltipicon && (
                    <>
                        <ReactTooltip
                            id={tooltiptext}
                            type="dark"
                            effect="solid"
                            place="left"
                        >
                            {tooltiptext}
                        </ReactTooltip>
                        <img
                            data-tip
                            data-for={tooltiptext}
                            src={tooltipicon}
                            onClick={onClick}
                        />
                    </>
                )}
            </GroupContainer>
            {successMsg ? (
                <p style={{ color: 'green' }}>{successMsg}</p>
            ) : (
                <span>{error}</span>
            )}
        </InputContainer>
    );
};
