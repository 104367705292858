import React, {useRef, useState} from 'react';
import { Button } from '../../../../../../../components/Buttons/Button';
import { ExportButton } from '../../../../../../../components/Buttons/ExportButton';
import Heading from '../../../../../../../components/Heading/Heading';
import { SimpleInput } from '../../../../../../../components/Inputs/SimpleInput';
import Userprofile from '../../../../../../../../images/profile.svg';
import UploadDragDrop from '../../../../../../../components/Upload File/UploadDragDrop';
import Calendar from '../../../../../../../../images/calendar.svg';
import Email from '../../../../../../../../images/email.svg';
import Call from '../../../../../../../../images/call.svg';
import SelectInput from '../../../../../../../components/Inputs/SelectInput';
import AddScholarContent from "../../Add Scholars/AddScholarContent";
import GenericForm from "../../../../../../../components/Form/GenericForm";
import qs from "qs";
import {useParams} from "react-router-dom";

const gender = [
    { value: 'male', label: 'Male' },
    { value: 'female', label: 'Female' },
    { value: 'other', label: 'Others' },
];

const bloodgroup = [{ value: 'a', label: 'A+' }];

const nationality = [{ value: 'indian', label: 'Indian' }];

const maritalstatus = [
    { value: 'single', label: 'Single' },
    { value: 'married', label: 'Married' },
];

const physicallydisabled = [
    { value: 'yes', label: 'Yes' },
    { value: 'no', label: 'No' },
];

const quota = [{ value: 'defence', label: 'Defence' }];

const category = [{ value: 'sc', label: 'SC' }];

const PersonalInfoContent = ({id,redirectTo}) => {
    let {scholarId} = useParams();
    if(id){
        scholarId = id;
    }
    let filters = qs.parse(window.location.search, { ignoreQueryPrefix: true });

    const initialValues = {
        UniversityRollNo: '',
        CampusId: filters.CampusId || '',
        ClusterId: filters.ClusterId || '',
        ResearchCenterId: filters.ResearchCenterId || '',
        BatchId: filters.BatchId || '',
        ProgramId: filters.ProgramId || '',
        DisciplineId: filters.DisciplineId || '',
        Honorifics: '',
        FirstName: '',
        Lastname: '',
        Gender: '',
        Email: '',
        PhoneNumber: '',
        MotherName: '',
        FatherName: '',
        BirthDate: '',
        Domicile: '',
        Category: '',
        Quota: '',
        City: '',
        Nationality: '',
        CurrentAddress: '',
        PermanentAddress: '',
        ProfilePhoto: '',
        AadharCardNumber: '',
        PanCardNumber: '',
    };

    const formRef = useRef(null);

    const fields = [

        {
            type: 'textarea',
            field: 'CurrentAddress',
            label: 'Current Address',
            description: 'This will be displayed on your profile.',
            estric: '*'
        },
        {
            type: 'textarea',
            field: 'PermanentAddress',
            label: 'Permanent Address',
            description: 'This will be displayed on your profile.',
            estric: '*'
        },
    ];

    return (
        <div className="profile-screen">
            <div className="profile-heading">
                <Heading
                    title="Address"
                    description="Add your addresses here"
                />
            </div>


            <div className="personal-info">
                <GenericForm
                    id={scholarId}
                    twoColumns={true}
                    ref={formRef}
                    fields={fields}
                    initialValues={initialValues}
                    endpoint={'UserProfile/UserProfile/getPersonalInfo'}
                    doNotAppendWithGetEntity={true}
                    doNotAppendWithPutPostEntity={true}
                    putEndpoint={'UserProfile/UserProfile/UpdateAddress'}
                    redirectTo={window.location.href}
                />
            </div>
        </div>
    );
};

export default PersonalInfoContent;
