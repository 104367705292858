import React, {useEffect, useRef, useState} from 'react';
import Header from '../../components/Header/Header';
import Heading from '../../components/Heading/Heading';
import Sidebar from '../../components/Sidebar/Sidebar';
import Table from '../../components/Table/Table';
import useListApi from '../../../hooks/useListApi';
import MediaQuery from 'react-responsive';
import MobileHeader from '../../components/Sidebar/Mobile Design/Mobile Header/MobileHeader';
import Plus from '../../../images/plus.svg';
import ExportIcon from '../../../images/export.svg';

const Dashboard = () => {
    const {
        rows,
        refresh,
        totalRecords,
        currentPage,
        recordsPerPage,
        setCurrentPage,
        setRecordsPerPage,
    } = useListApi('Infrastructure/Campus', false, true);
    const [condition, setCondition] = useState(false);

    useEffect(() => {
        if (window.innerWidth > 500) {
            setCondition(true);
        } else {
            setCondition(false);
        }
    }, [window.innerWidth, condition]);
    const column = [
        {
            heading: 'Name',
            value: 'DisplayName',
        },
        {
            heading: 'Code',
            value: 'Code',
        },
        {
            heading: 'Phone Number',
            value: 'PhoneNumber',
        },
        {
            heading: 'Email',
            value: 'Email',
        },
        {
            heading: 'Registry',
            value: 'cluster',
        },
        {
            heading: 'Created at',
            value: 'CreatedAt',
        },
    ];

    const renderColumn = (row, column, rendered) => {
        if (column.value === 'cluster') {
            return (
                <td>
                    <a href={`/registry?CampusId=${row.Id}`}>Registries</a>
                </td>
            );
        }
        return rendered;
    };

    const tableRef = useRef(null);

    return (
        <div className="main-wrapper">
            <MediaQuery maxWidth={1023}>
                <MobileHeader/>
            </MediaQuery>
            <MediaQuery minWidth={1024}>
                <Sidebar/>
            </MediaQuery>
            <div className="screen">
                {/* <Header homelink="/dashboard" breadcrumbs="Campuses"/> */}
                <Heading
                    title="Campuses"
                    description="List of Campuses"
                    linktext="Add Campus"
                    icon={Plus}
                    width="auto"
                    height="40px"
                    padding="0px 20px"
                    href="/campus/new"
                    tableRef={tableRef}
                    permissionPrefix={'CAMPUS'}
                    exporticon={ExportIcon}
                />

                <Table
                    ref={tableRef}
                    permissionPrefix={'CAMPUS'}
                    shouldView={true}
                    shouldAssignFee={false}
                    shouldAssignRole={false}
                    showSortIcon = {true}
                    checkboxshouldView={true}
                    resource={'Infrastructure/Campus'}
                    refresh={refresh}
                    data={rows}
                    totalRecords={totalRecords}
                    perPage={recordsPerPage}
                    setPerPage={setRecordsPerPage}
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                    column={column}
                    renderColumn={renderColumn}
                    tableHeading="All Campuses"
                    name="Campus"
                    multipleDelete="Campuses"
                    onView={(item) => {
                        window.location.href = `/campus/${item.Id}`;
                    }}
                    onEdit={(item) => {
                        window.location.href = `/campus/${item.Id}/edit?next=${encodeURIComponent(`${window.location.pathname}${window.location.search}`)}`;
                    }}
                />
            </div>
        </div>
    );
};

export default Dashboard;
