import React from 'react';
import { LinkButton } from '../../../../Buttons/LinkButton';
import Plus from '../../../../../../images/plus.svg';

const InviteSupervisorDefaultContent = (props) => {
    return (
        <div className="profile-screen-default-page">
            <div className="default-page">
                <div className="default-content-scholar">
                    <h6>{props.heading}</h6>
                    <span>{props.subheading}</span>
                    <div className="default-button-invite">
                        <div className="default-link-buttons">
                            <LinkButton
                                width="auto"
                                height="40px"
                                padding="0px 10px !important"
                                hover="#5D43A5"
                                icon={Plus}
                                linktext="Invite Supervisors"
                                href="/invite-Supervisor/new"
                            ></LinkButton>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default InviteSupervisorDefaultContent;
