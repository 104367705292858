import React from 'react';
import Footer from './components/Footer';
import Navigation from './components/Navigation';
import Hamburger from '../../../images/hamburger.svg';
import PhdCentralLogo from '../../../images/phd-central-logo.png';
import { Link } from 'react-router-dom';
import Scrollbar from 'smooth-scrollbar';

const Sidebar = () => {
    return (
        <div className="sidebar">
            <div className="sidebar-navigation">
                <Link to="/dashboard">
                    <div className="phd-logo">
                        <img
                            className="phdpad-logo"
                            src={PhdCentralLogo}
                            alt={'PhdCentralLogo'}
                        />
                        <img
                            className="hamburger-icon"
                            src={Hamburger}
                            alt={'Menu Icon'}
                        />
                    </div>
                </Link>

                <div className="navbar-with-drop">
                    <Navigation />
                </div>
            </div>

            <div className="sidebar-footer">
                <Footer />
            </div>
        </div>
    );
};

export default Sidebar;
