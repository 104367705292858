import React from 'react';
import MediaQuery from 'react-responsive';
import ScholarNavHeader from '../../Scholar Sidebar/ScholarNavHeader';

const DeliverableHeading = () => {
    return (
        <div className="scholar-home-page">
            <MediaQuery maxWidth={1023}>
                <ScholarNavHeader />
            </MediaQuery>
            <div className="scholar-heading">
                <div className="deliverable-details-heading">
                    <h3>Deliverables</h3>
                    <span>Manage your deliverables</span>
                </div>
                <div className="bottom-line-deliverables"></div>
            </div>
        </div>
    );
};

export default DeliverableHeading;
