import React, { useRef } from 'react';
import GenericForm from '../../../../../../../components/Form/GenericForm';
import qs from 'qs';

const AddDeliverableContent = () => {
    let filters = qs.parse(window.location.search, { ignoreQueryPrefix: true });

    const initialValues = {
        Title: '',
        Subtitle: '',
        Code: '',
        Description: '',
        DeliverableType: 0,
        SubmissionType: 0,
        FilePath: '',
        Attachments: '',
        IsDeleted: false,
        ClusterId: filters.ClusterId || 0,
        CampusId: filters.CampusId || 0,
        ResearchCenterId: filters.ResearchCenterId || 0,
        MilestoneId: filters.MilestoneId || 0,
    };

    const formRef = useRef(null);

    const fields = [
        {
            type: 'select',
            field: 'CampusId',
            label: 'Select Campus',
            description: 'Resource will be added to the selected campus',
            estric: '*',
            options: {
                type: 'dynamic',
                endpoint: 'Infrastructure/Campus',
                paged: true,
                valueKey: 'Id',
                labelKey: 'DisplayName',
            },
            onChange: (value) => {
                formRef.current.updateData({
                    ClusterId: '',
                    ResearchCenterId: '',
                    MilestoneId: '',
                });
            },
            shouldUpdateQueryParams: true
        },
        {
            type: 'select',
            field: 'ClusterId',
            label: 'Select Registry',
            description:
                'Resource will be added to the selected registry',
            estric: '*',
            options: {
                type: 'dynamic',
                endpoint: 'Infrastructure/Cluster',
                paged: true,
                valueKey: 'Id',
                labelKey: 'Name',
                optionsFilterCallback: (cluster, formValue) => {
                    return cluster.CampusId === formValue.CampusId;
                },
            },
            onChange: (value) => {
                formRef.current.updateData({
                    ResearchCenterId: '',
                    MilestoneId: '',
                });
            },
            shouldUpdateQueryParams: true
        },
        {
            type: 'select',
            field: 'ResearchCenterId',
            label: 'Select Discipline',
            description:
                'Resource will be added to the selected discipline',
            estric: '*',
            options: {
                type: 'dynamic',
                endpoint: 'Infrastructure/ResearchCenter',
                paged: true,
                valueKey: 'Id',
                labelKey: 'Name',
                optionsFilterCallback: (cluster, formValue) => {
                    return cluster.ClusterId === formValue.ClusterId;
                },
            },
            onChange: (value) => {
                formRef.current.updateData({ MilestoneId: '' });
            },
            shouldUpdateQueryParams: true
        },
        {
            type: 'select',
            field: 'MilestoneId',
            label: 'Select Deliverable',
            estric: '*',

            description:
                'Resource will be added to the selected deliverable',
            options: {
                type: 'dynamic',
                endpoint: 'AcademicStructure/Milestone',
                valueKey: 'id',
                labelKey: 'Title',
                optionsFilterCallback: (cluster, formValue) => {
                    return (
                        cluster.ResearchCenterId === formValue.ResearchCenterId
                    );
                },
            },
            shouldUpdateQueryParams: true
        },
        {
            type: 'text',
            field: 'Title',
            label: 'Resource Name',
            description: 'Name of the resource',
            estric: '*',
        },
        {
            type: 'text',
            field: 'Code',
            label: 'Resource Code',
            description: 'Unique identifier for the resource',
            required: false
        },
        {
            type: 'select',
            field: 'DeliverableType',
            label: 'Resource Type',
            description: 'The type of content to be added for the scholars',
            estric: '*',
            options: {
                type: 'enum',
                value: 'DeliverableType',
            },
        },
        {
            type: 'select',
            field: 'SubmissionType',
            label: 'Submission Type',
            description: 'The type of content to be submitted by the scholars',
            estric: '*',
            options: {
                type: 'enum',
                value: 'SubmissionType',
            },
        },
        {
            type: 'html',
            field: 'Description',
            label: 'Description',
            description: 'Detailed description of the resource',
            required: false
        },
        {
            type: 'files',
            field: 'Attachments',
            label: 'Attachments',
            description: 'Detailed description of the resource',
            required: false
        },
    ];

    return (
        <GenericForm
            ref={formRef}
            fields={fields}
            initialValues={initialValues}
            endpoint={'AcademicStructure/Deliverable'}
            redirectTo={'/sub-deliverable'}
        />
    );
};

export default AddDeliverableContent;
