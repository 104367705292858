import React from 'react';
import useListApi from '../../../../../../../hooks/useListApi';
import Header from '../../../../../../components/Header/Header';
import Heading from '../../../../../../components/Heading/Heading';
import Sidebar from '../../../../../../components/Sidebar/Sidebar';
import Plus from '../../../../../../../images/plus.svg';
import Table from '../../../../../../components/Table/Table';
import MobileHeader from '../../../../../../components/Sidebar/Mobile Design/Mobile Header/MobileHeader';
import MediaQuery from 'react-responsive';

const InviteSupervisorList = () => {
    const {
        rows,
        refresh,
        totalRecords,
        currentPage,
        recordsPerPage,
        setCurrentPage,
        setRecordsPerPage,
    } = useListApi('Cluster');
    const column = [
        {
            heading: 'Name',
            value: 'DisplayName',
        },
        {
            heading: 'Status',
            value: '',
        },
        {
            heading: 'Specializations',
            value: '',
        },
    ];

    const renderColumn = (row, column, rendered) => {
        if (column.value === 'personalinfo') {
            return (
                <td>
                    <a href={`/personalinfo?ClusterId=${row.ClusterId}`}>
                        User Profile
                    </a>
                </td>
            );
        }
        return rendered;
    };

    return (
        <div className="main-wrapper">
            <MediaQuery maxWidth={1023}>
                <MobileHeader />
            </MediaQuery>
            <MediaQuery minWidth={1024}>
                <Sidebar />
            </MediaQuery>
            <div className="screen">
                <Header
                    homelink="/dashboard"
                    breadcrumbs="Invite Supervisors List"
                />
                <Heading
                    title="Invite Supervisors"
                    description="List of Invite Supervisors"
                    linktext="Invite Supervisor"
                    icon={Plus}
                    width="auto"
                    height="40px"
                    padding="0px 20px"
                    href="/invite-Supervisor/new"
                />
                <Table
                    shouldView={false}
                    shouldDelete={true}
                    data={rows}
                    totalRecords={totalRecords}
                    perPage={recordsPerPage}
                    setPerPage={setRecordsPerPage}
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                    column={column}
                    renderColumn={renderColumn}
                    tableHeading="All Invite Supervisors"
                    onView={(item) => {
                        window.location.href = `/registry/${item.ClusterId}`;
                    }}
                />
            </div>
        </div>
    );
};

export default InviteSupervisorList;
