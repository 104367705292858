import React from 'react';
import Header from '../../../components/Header/Header';
import Heading from '../../../components/Heading/Heading';
import Sidebar from '../../../components/Sidebar/Sidebar';
import GeneralView from '../View Campus/Subcomponents/GeneralView';
import { useParams } from 'react-router-dom';
import useDetailApi from '../../../../hooks/useDetailApi';
import Backarrow from '../../../../images/back-arrow.svg';
import useEnums from '../../../../hooks/useEnums';
import MediaQuery from 'react-responsive';
import MobileHeader from '../../../components/Sidebar/Mobile Design/Mobile Header/MobileHeader';
import Api from '../../../../api/api';

const ViewDepartment = () => {
    const { id } = useParams();
    const { loading, item } = useDetailApi('AcademicStructure/Deliverable', id);

    const { renderEnum } = useEnums();

    const fields = [
        {
            type: 'select',
            field: 'CampusName',
            label: 'Campus',
            description: 'This will be displayed on your profile.',
            estric: '*',
            options: {
                type: 'dynamic',
                endpoint: 'Infrastructure/Campus',
                paged: true,
                valueKey: 'Id',
                labelKey: 'DisplayName',
            },
        },
        {
            type: 'select',
            field: 'ClusterName',
            label: 'Registry',
            description: 'This will be displayed on your profile.',
            estric: '*',
            options: {
                type: 'dynamic',
                endpoint: 'Infrastructure/Cluster',
                paged: true,
                valueKey: 'Id',
                labelKey: 'Name',
                optionsFilterCallback: (cluster, formValue) => {
                    return cluster.CampusId === formValue.CampusId;
                },
            },
        },
        {
            type: 'select',
            field: 'ResearchCenterName',
            label: 'Discipline',
            description: 'This will be displayed on your profile.',
            estric: '*',
            options: {
                type: 'dynamic',
                endpoint: 'Infrastructure/ResearchCenter',
                paged: true,
                valueKey: 'Id',
                labelKey: 'Name',
                optionsFilterCallback: (cluster, formValue) => {
                    return cluster.ClusterId === formValue.ClusterId;
                },
            },
        },
        {
            type: 'select',
            field: 'MilestoneTitle',
            label: 'Deliverable',
            description: 'This will be displayed on your profile.',
            estric: '*',
            options: {
                type: 'dynamic',
                endpoint: 'Infrastructure/ResearchCenter',
                paged: true,
                valueKey: 'Id',
                labelKey: 'Name',
                optionsFilterCallback: (cluster, formValue) => {
                    return cluster.ClusterId === formValue.ClusterId;
                },
            },
        },
        {
            type: 'text',
            field: 'Title',
            label: 'Resource Name',
            description: 'This will be displayed on your profile.',
            estric: '*',
        },
        {
            type: 'text',
            field: 'Code',
            label: 'Resource Code',
            description: 'This will be displayed on your profile.',
            estric: '*',
        },
        {
            type: 'select',
            field: 'DeliverableType',
            label: 'Resource Type',
            description: 'This will be displayed on your profile.',
            estric: '*',
            render: (item) => {
                return renderEnum('DeliverableType', item.DeliverableType);
            },
        },
        {
            type: 'select',
            field: 'SubmissionType',
            label: 'Submission  Type',
            description: 'This will be displayed on your profile.',
            estric: '*',
            render: (item) => {
                return renderEnum('SubmissionType', item.SubmissionType);
            },
        },
        {
            type: 'html',
            field: 'Description',
            label: 'Description',
            description: 'This will be displayed on your profile.',
            estric: '*'
        },
        {
            type: 'files',
            field: 'Attachments',
            label: 'Attachments',
            description: 'Detailed description of the sub-deliverable',
            render: (item) => {
                if(!item.Attachments){
                    return '-';
                }
                const v = JSON.parse(item.Attachments);
                if(v.length < 1){
                    return '-';
                }
                return (
                    <div className="files-select">
                        {v.map((file, index) => {
                            return (
                                <div className="uploaded-file-remove">
                                    <a
                                        href={`${Api.api_url}/uploads/${file.FileName}`}
                                        target="_blank"
                                    >
                                        {file.DisplayName}
                                    </a>
                                </div>
                            );
                        })}
                    </div>
                );
            },
        },
    ];
    return (
        <div className="main-wrapper">
            <MediaQuery maxWidth={1023}>
                <MobileHeader />
            </MediaQuery>
            <Sidebar />

            <div className="screen">
                {/* <Header
                    homelink="/dashboard"
                    breadcrumb="Sub Deliverables"
                    breadcrumbs="View Sub Deliverable"
                    innerpath="/sub-deliverable"
                /> */}

                <Heading
                    title="Resource Details"
                    description="View all details of the resource below"
                    linktext="Back to Resources"
                    icon={Backarrow}
                    href="/sub-deliverable"
                    width="auto"
                    height="44px"
                    backgroundcolor="none"
                    color="#5D43A5"
                    fontsize="16px"
                    hover="#F9F5FF"
                    padding="0px 20px !important"
                    className="view-back-button"
                />
                {loading ? (
                    <div> Loading... </div>
                ) : (
                    <>
                        <GeneralView campus={item} fields={fields} />
                    </>
                )}
            </div>
        </div>
    );
};

export default ViewDepartment;
