import React, { useRef, useState } from 'react';
import GenericForm from '../../../../../../components/Form/GenericForm';
import qs from 'qs';

const AddDRCFacultyContent = () => {
    let filters = qs.parse(window.location.search, { ignoreQueryPrefix: true });


    const initialValues = {
        CampusId: filters.CampusId || '',
        ClusterId: filters.ClusterId || '',
        ResearchCenterId: filters.ResearchCenterId || '',
        Honorifics: '',
        FirstName: '',
        Gender: '',
        Email: '',
        PhoneNumber: '',
        EmployeeId: '',
        Designation: '',
        ProfilePhoto: '',
        MiddleName: null,
        LastName: '',
        UserType: '',
        StaffType: filters.StaffType || '',
        FacultyType: filters.FacultyType || '',
        StaffStatus: filters.StaffStatus || '',
        DisciplineId: [],
    };


    const [hideFacultyType, setHideFacultyType] = useState(initialValues.StaffType !== 'Teaching');
    const [isEmployeeIdRequired, setIsEmployeeIdRequired] = useState(true);
    const formRef = useRef(null);

    const fields = [
        {
            type: 'file',
            fileType: 'image/*',
            field: 'ProfilePhoto',
            required: false,
            fullWidth: true,
        },
        {
            type: 'select',
            field: 'CampusId',
            label: 'Select Campus',
            description: 'The employee will be added to the selected campus',
            required: false,
            options: {
                type: 'dynamic',
                endpoint: 'Infrastructure/Campus',
                paged: true,
                valueKey: 'Id',
                labelKey: 'DisplayName',
            },
            onChange: (value) => {
                formRef.current.updateData({
                    ClusterId: '',
                    ResearchCenterId: '',
                    MilestoneId: '',
                });
            },
            shouldUpdateQueryParams: true
        },
        {
            type: 'select',
            field: 'ClusterId',
            label: 'Select Registry',
            description: 'The employee will be added to the selected Registry',
            required: false,
            options: {
                type: 'dynamic',
                endpoint: 'Infrastructure/Cluster',
                paged: true,
                valueKey: 'Id',
                labelKey: 'Name',
                optionsFilterCallback: (cluster, formValue) => {
                    return cluster.CampusId === formValue.CampusId;
                },
            },
            onChange: (value) => {
                formRef.current.updateData({
                    ResearchCenterId: '',
                    BatchId: '',
                    ProgramId: '',
                    DisciplineId: '',
                });
            },
            shouldUpdateQueryParams: true
        },
        {
            type: 'select',
            field: 'ResearchCenterId',
            label: 'Select Discipline',
            description:
                'The employee will be added to the selected discipline',
            required: false,
            className: 'select-multi',
            options: {
                type: 'dynamic',
                endpoint: 'Infrastructure/ResearchCenter',
                paged: true,
                valueKey: 'Id',
                labelKey: 'Name',
                optionsFilterCallback: (cluster, formValue) => {
                    return cluster.ClusterId === formValue.ClusterId;
                },
            },
            onChange: (value) => {
                formRef.current.updateData({
                    BatchId: '',
                    ProgramId: '',
                });
            },
            shouldUpdateQueryParams: true
        },
        {
            type: 'select',
            field: 'DisciplineId',
            label: 'Select Specializations',
            description:
                'The employee will be added to the selected specialization',
            required: false,
            className: 'select-multi',
            options: {
                type: 'dynamic',
                multi: true,
                endpoint: 'AcademicStructure/Discipline',
                paged: true,
                valueKey: 'Id',
                labelKey: 'Name',
                optionsFilterCallback: (cluster, formValue) => {
                    return (
                        cluster.ResearchCenterId === formValue.ResearchCenterId
                    );
                },
            },
            serverField: 'Disciplines',
            initialDataMapper: (data) => {
                if (!data.Disciplines) {
                    return [];
                }
                return data.Disciplines.map((d) => {
                    return d.Discipline.Id;
                });
            },
        },
        {
            type: 'text',
            field: 'Designation',
            label: 'Designation',
            description: 'The position of the employee',
            estric: '*',
        },
        {
            type: 'select',
            field: 'Honorifics',
            label: 'Title',
            description: 'Title to be added before the name of the employee',
            estric: '*',
            options: {
                type: 'enum',
                value: 'Honorifics',
            },
        },

        {
            type: 'text',
            field: 'FirstName',
            label: 'First Name',
            description: 'First name of the employee',
            estric: '*',
            preChange: (e, handleChange) => {
                e.target.value = e.target.value.toUpperCase();
                handleChange(e);
            }
        },
        {
            type: 'text',
            field: 'MiddleName',
            label: 'Middle Name',
            description: 'Middle name of the employee',
            required: false,
            preChange: (e, handleChange) => {
                e.target.value = e.target.value.toUpperCase();
                handleChange(e);
            }
        },
        {
            type: 'text',
            field: 'LastName',
            label: 'Last Name',
            description: 'Last name of the employee',
            required: false,
            preChange: (e, handleChange) => {
                e.target.value = e.target.value.toUpperCase();
                handleChange(e);
            }
        },
        {
            type: 'select',
            field: 'Gender',
            label: 'Gender',
            description: 'Gender of the employee',
            estric: '*',
            options: {
                type: 'enum',
                value: 'Gender',
            },
        },
        {
            type: 'text',
            field: 'PhoneNumber',
            label: 'Phone Number',
            description: 'The phone number of the employee',
            estric: '*',
        },
        {
            type: 'text',
            field: 'Email',
            label: 'Email',
            description: 'Email address of the employee',
            estric: '*',
        },
        {
            type: 'date',
            field: 'DateOfJoining',
            label: 'Date of joining',
            description: 'The joining date of the employee',
            estric: '*',
        },

        {
            type: 'select',
            field: 'StaffType',
            label: 'Staff Category',
            description: 'The category to which a staff belongs',
            estric: '*',
            options: {
                type: 'enum',
                value: 'StaffType',
            },
            onChange: (value) => {
                setHideFacultyType(value === 'Non Teaching');
                setIsEmployeeIdRequired(value === 'Non Teaching');
            },
        },
        {
            type: 'text',
            field: 'EmployeeId',
            label: 'Employee Id',
            description: ' Unique identifier for the employee',
            estric: isEmployeeIdRequired ? '*' : '',
            required: isEmployeeIdRequired,
        },
        {
            type: 'select',
            field: 'StaffStatus',
            label: 'Staff Status',
            description: 'Status of the employee in the university',
            estric: '*',
            options: {
                type: 'enum',
                value: 'StaffStatus',
            },
        },
    ];

    if (!hideFacultyType) {
        fields.splice(15, 0, {
            type: 'select',
            field: 'FacultyType',
            label: 'Faculty Type',
            description: 'The type of faculty member',
            estric: '*',
            options: {
                type: 'static',
                options: [
                    {
                        value: 'Internal',
                        label: 'Internal',
                    },
                    {
                        value: 'External',
                        label: 'External',
                    },
                ],
            },
            onChange: (value) => {
                setIsEmployeeIdRequired(value === 'Internal');
            },
        });
    }

    return (
        <GenericForm
            ref={formRef}
            fields={fields}
            initialValues={initialValues}
            endpoint={'Users/Staff'}
            redirectTo={'/staff'}
            initialValuesUpdated={(initialValues) => {
                setHideFacultyType(initialValues.StaffType !== 'Teaching');
                setIsEmployeeIdRequired(
                    initialValues.FacultyType === 'Internal',
                );
            }}
        />
    );
};

export default AddDRCFacultyContent;
