import React, {useRef, useState} from 'react';
import GenericForm from '../../../../../../../components/Form/GenericForm';
import qs from 'qs';

const AddSupervisorContent = () => {
    let filters = qs.parse(window.location.search, {ignoreQueryPrefix: true});

    const formRef = useRef(null);

    const [showFeeHeader, setShowFeeHeader] = useState(false);

    const initialValues = {
        Title: '',
        Subtitle: '',
        Code: '',
        Description: '',
        MilestoneOrder: '',
        IsFirst: false,
        IsLast: false,
        ClusterId: filters.ClusterId || 0,
        CampusId: filters.CampusId || 0,
        ResearchCenterId: filters.ResearchCenterId || 0,
        CreatedBy: null,
        IsDeleted: false,
        ActiveForPolicy: false,
        FeeHeader: '',
    };

    const fields = [
        {
            type: 'select',
            field: 'CampusId',
            label: 'Select Campus',
            description: 'Deliverable will be added to the selected campus',
            estric: '*',
            options: {
                type: 'dynamic',
                endpoint: 'Infrastructure/Campus',
                paged: true,
                valueKey: 'Id',
                labelKey: 'DisplayName',
            },
            onChange: (value) => {
                formRef.current.updateData({
                    ClusterId: '',
                    ResearchCenterId: '',
                });
            },
            shouldUpdateQueryParams: true
        },
        {
            type: 'select',
            field: 'ClusterId',
            label: 'Select Registry',
            description: 'Deliverable will be added to the selected registry',
            estric: '*',
            options: {
                type: 'dynamic',
                endpoint: 'Infrastructure/Cluster',
                paged: true,
                valueKey: 'Id',
                labelKey: 'Name',
                optionsFilterCallback: (cluster, formValue) => {
                    return cluster.CampusId === formValue.CampusId;
                },
            },
            onChange: (value) => {
                formRef.current.updateData({ResearchCenterId: ''});
            },
            shouldUpdateQueryParams: true
        },
        {
            type: 'select',
            field: 'ResearchCenterId',
            label: 'Select Discipline',
            description: 'Deliverable will be added to the Discipline',
            estric: '*',
            options: {
                type: 'dynamic',
                endpoint: 'Infrastructure/ResearchCenter',
                paged: true,
                valueKey: 'Id',
                labelKey: 'Name',
                optionsFilterCallback: (cluster, formValue) => {
                    return cluster.ClusterId === formValue.ClusterId;
                },
            },
            shouldUpdateQueryParams: true
        },
        {
            type: 'text',
            field: 'Title',
            label: 'Deliverable Name',
            description: 'Name of the deliverable',
            estric: '*',
        },
        {
            type: 'text',
            field: 'Code',
            label: 'Deliverable Code',
            description: 'Unique identifier of the deliverable',
            estric: '*',
        },
        {
            type: 'html',
            field: 'Description',
            label: 'Description',
            description: 'Detailed description of the deliverable',
            estric: '*',
        },
        {
            type: 'text',
            field: 'MilestoneOrder',
            label: 'Deliverable Order',
            description:
                'A position/number in which the deliverable should appear in the list of deliverables',
            estric: '*',
        },
        {
            type: 'select',
            field: 'ActiveForPolicy',
            label: 'Has Fees',
            description:
                'Should the deliverable be considered for fee',
            options: {
                type: 'static',
                options: [
                    {label: 'Yes', value: '1'},
                    {label: 'No', value: '0'},
                ]
            },
            estric: '*',
            onChange: (value) => {
                setShowFeeHeader(value === '1');
            },
            required: false
        },
        {
            type: 'text',
            field: 'FeeHeader',
            label: 'Fee Head Label',
            description:
                'Label for the fee head',
            estric: '*',
            hide: !showFeeHeader,
            required: showFeeHeader,
        },
        // {
        //     type: 'switch',
        //     field: 'IsFirst',
        //     label: 'First',
        //     useBoolean: true,
        //     required: false,
        //     description: 'This will be displayed on your profile.',
        // },
        // {
        //     type: 'switch',
        //     field: 'IsLast',
        //     label: 'Last',
        //     useBoolean: true,
        //     required: false,
        //     description: 'This will be displayed on your profile.',
        // },
    ];

    return (
        <GenericForm
            ref={formRef}
            fields={fields}
            initialValues={initialValues}
            initialValuesUpdated={values => {
                setShowFeeHeader(values.ActiveForPolicy === '1')
            }}
            endpoint={'AcademicStructure/Milestone'}
            redirectTo={'/deliverable'}
        />
    );
};

export default AddSupervisorContent;
