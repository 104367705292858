import React from 'react';
import { useParams } from 'react-router-dom';
import Heading from './Heading';

const FormHeading = (props) => {
    let { title } = props;
    let { id } = useParams();
    let { description } = props;

    if (id && title.includes('Add')) {
        title = title.replace('Add ', 'Edit ');
    }

    if (id) {
        description = 'Update the following details';
    }

    return (
        <>
            <Heading {...props} title={title} description={description} />
        </>
    );
};

export default FormHeading;
