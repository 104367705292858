import React from 'react';
import Header from '../../../../../../components/Header/Header';
import Heading from '../../../../../../components/Heading/Heading';
import MobileHeader from '../../../../../../components/Sidebar/Mobile Design/Mobile Header/MobileHeader';
import Sidebar from '../../../../../../components/Sidebar/Sidebar';
import InviteSupervisorContent from './InviteSupervisorContent';
import MediaQuery from 'react-responsive';
import { LinkButton } from '../../../../../../components/Buttons/LinkButton';
import Backarrow from '../../../../../../../images/back-arrow.svg';

const InviteSupervisor = () => {
    return (
        <div className="main-wrapper">
            <MediaQuery maxWidth={1023}>
                <MobileHeader />
            </MediaQuery>
            <MediaQuery minWidth={1024}>
                <Sidebar />
            </MediaQuery>
            <div className="screen">
                <div className="heading-button-mobile">
                    <Header
                        homelink="/dashboard"
                        breadcrumb="Invite Supervisors List"
                        breadcrumbs="Invite Supervisor"
                        innerpath="/invite-Supervisor"
                    />
                    <Heading
                        title="Invite Supervisor"
                        description="Enter the following details to invite Supervisor"
                    />
                    <MediaQuery maxWidth={1023}>
                        <div className="back-button">
                            <LinkButton
                                linktext="Back to Invite Supervisors"
                                icon={Backarrow}
                                href="/invite-Supervisor"
                                width="204px"
                                height="44px"
                                backgroundcolor="none"
                                color="#5D43A5"
                                fontsize="16px"
                                hover="none"
                            />
                        </div>
                    </MediaQuery>
                    <InviteSupervisorContent />
                </div>
            </div>
        </div>
    );
};

export default InviteSupervisor;
