import React from 'react';
import ScholarHeader from "./ScholarHeader";
import {ChangePasswordContent} from "../Dashboard/Add Campus/ChangePassword";
import MediaQuery from 'react-responsive';
import ScholarNavHeader from './Scholar Sidebar/ScholarNavHeader';

const Home = ()=>{
    return <div className='scholar-home-page'>
           <>
                <MediaQuery maxWidth={1023}>
                    <ScholarNavHeader/>
                </MediaQuery>
            </>
        <ScholarHeader active={'settings'}/>
        <div className='scholar-heading'>
            <div className='settings-heading'>
                <h3>Settings</h3>
                <div className='bottom-line-deliverables'></div>
                </div>
                <div className='password-heading'>
                <h3>Password</h3>
                <span>Please enter your current password to change your password.</span>
                <div className='bottom-line-deliverables'></div>
                </div>
        <div className='scholar-settings-container'>
        <ChangePasswordContent/>
        </div>
        
        </div>
    </div>;
}

export default Home;
