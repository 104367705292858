import React, { useRef, useState } from 'react';
import Sidebar from '../../../../../components/Sidebar/Sidebar';
import Header from '../../../../../components/Header/Header';
import Heading from '../../../../../components/Heading/Heading';
import Table from '../../../../../components/Table/Table';
import useListApi from '../../../../../../hooks/useListApi';
import MediaQuery from 'react-responsive';
import MobileHeader from '../../../../../components/Sidebar/Mobile Design/Mobile Header/MobileHeader';
import HeaderFilters from '../../../../../components/Filters/HeaderFilters';
import qs from 'qs';
import { Modal } from 'react-bootstrap';
import AssignSupervisors from '../../../../../components/Modals/Fee Modals/Assign Fee Policy/Subcomponents/Assign Fee Policy/AssignSupervisors';
import ExportIcon from '../../../../../../images/export.svg';
import Backarrow from '../../../../../../images/back-arrow.svg';
import AssignResourcePersons
    from "../../../../../components/Modals/Fee Modals/Assign Fee Policy/Subcomponents/Assign Fee Policy/AssignResourcePersons";

const AssignResourcePersonList = () => {
    const [filters, setFilters] = useState(
        qs.parse(window.location.search, { ignoreQueryPrefix: true }),
    );
    const {
        rows,
        refresh,
        totalRecords,
        currentPage,
        recordsPerPage,
        setCurrentPage,
        setRecordsPerPage,
    } = useListApi('AcademicStructure/ResourcePerson', false, true, filters);
    const column = [

        {
            heading: 'Batch',
            value: 'Name',
        },
        // {
        //     heading: 'Discipline',
        //     value: 'ResearchCenterName',
        // },
        // {
        //     heading: 'Batch',
        //     value: 'BatchName',
        // },
        {
            heading: 'Deliverable',
            value: 'MilestoneName',
        },
        {
            heading: 'Resource Person 1',
            value: 'ResourcePerson1Name',
        },
        {
            heading: 'Resource Person 2',
            value: 'ResourcePerson2Name',
        },
    ];

    const [showFeePolicyModal, setShowFeePolicyModal] = useState(false);
    const [selectedUsers, setSelectedUsers] = useState([]);

    const tableRef = useRef(null);

    return (
        <div className="main-wrapper">
            <MediaQuery maxWidth={1023}>
                <MobileHeader />
            </MediaQuery>
            <MediaQuery minWidth={1024}>
                <Sidebar />
            </MediaQuery>
            <div className="screen">
                {/* <Header
                    homelink="/dashboard"
                    breadcrumbs="Assign Supervisors"
                /> */}
                <Heading
                    title="Resource Person Assignment"
                    description="Assign resources persons to deliverables"
                    width="auto"
                    height="40px"
                    padding="0px 20px"
                    tableRef={tableRef}
                    backgroundcolor="none"
                    color="#5D43A5"
                    fontsize="16px"
                    hover="#F9F5FF"
                    className="view-back-button"
                    icon={Backarrow}
                />
                <HeaderFilters
                    filters={filters}
                    availableFilter={[
                        'campus',
                        'cluster',
                        'researchCenter',
                        'academicYear',
                    ]}
                    setFilters={setFilters}
                />
                <Table
                    ref={tableRef}
                    permissionPrefix={'AssignGuide'}
                    shouldView={false}
                    shouldAssignFee={false}
                    shouldAssignSupervisor={false}
                    shouldEdit={true}
                    shouldDelete={false}
                    data={rows}
                    totalRecords={totalRecords}
                    checkboxshouldView={false}
                    perPage={recordsPerPage}
                    setPerPage={setRecordsPerPage}
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                    column={column}
                    resource={'Users/Scholar'}
                    tableHeading="All Batches"
                    name="Scholar"
                    refresh={refresh}
                    multipleDelete="Scholars"
                    onEdit={(item) => {
                        setSelectedUsers([item]);
                        setShowFeePolicyModal(true);
                    }}
                />
            </div>
            {showFeePolicyModal && (
                <Modal
                    show={true}
                    onHide={() => setShowFeePolicyModal(false)}
                    centered
                    className="assignfeeModal"
                >
                    <AssignResourcePersons
                        refresh={refresh}
                        batch={selectedUsers[0]}
                        ResearchCenterId={
                            selectedUsers[0].ResearchCenterId || ''
                        }
                        CampusId={selectedUsers[0].CampusId || ''}
                        onHide={() => {
                            setShowFeePolicyModal(false);
                        }}
                    />
                </Modal>
            )}
        </div>
    );
};

export default AssignResourcePersonList;
