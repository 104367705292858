import React, { useRef, useState} from 'react';
import useListApi from '../../../../../../hooks/useListApi';
import Header from '../../../../../components/Header/Header';
import Heading from '../../../../../components/Heading/Heading';
import MobileHeader from '../../../../../components/Sidebar/Mobile Design/Mobile Header/MobileHeader';
import Sidebar from '../../../../../components/Sidebar/Sidebar';
import Table from '../../../../../components/Table/Table';
import Plus from '../../../../../../images/plus.svg';
import MediaQuery from 'react-responsive';
import ExportIcon from '../../../../../../images/export.svg';
import HeaderFilters from "../../../../../components/Filters/HeaderFilters";
import qs from "qs";

const FeePolicyList = () => {
    const [filters, setFilters] = useState(
        qs.parse(window.location.search, { ignoreQueryPrefix: true }),
    );
    const {
        rows,
        refresh,
        totalRecords,
        currentPage,
        recordsPerPage,
        setCurrentPage,
        setRecordsPerPage,
    } = useListApi('FeeStructure/FeePolicy', false, true, filters);

    const column = [
        {
            heading: 'Name',
            value: 'Name',
        },
        {
            heading: 'Code',
            value: 'Code',
        },
        {
            heading: 'Discipline',
            value: 'ResearchCenterName',
        },
        {
            heading: 'Total Amount',
            value: 'TotalAmount',
        },
        {
            heading: 'Created',
            value: 'CreatedAt',
        },
    ];

    const renderColumn = (row, column, rendered) => {
        if (column.value === 'cluster') {
            return (
                <td>
                    <a href={`/registry?CampusId=${row.CampusId}`}>Registry</a>
                </td>
            );
        }
        return rendered;
    };

    const tableRef = useRef(null);

    return (
        <div className="main-wrapper">
            <MediaQuery maxWidth={1023}>
                <MobileHeader />
            </MediaQuery>
            <MediaQuery minWidth={1024}>
                <Sidebar />
            </MediaQuery>
            <div className="screen">
                {/* <Header
                    homelink="/dashboard"
                    title="Fee"
                    breadcrumbs="Fee Policies"
                    href="/fee"
                /> */}
                <Heading
                    title="Fee Policies"
                    permissionPrefix={'FeePolicy'}
                    description="List of all Fee Policies"
                    linktext="Add Fee Policy"
                    icon={Plus}
                    width="auto"
                    height="40px"
                    padding="0px 20px"
                    href={`/fee-policy/new?ClusterId=${filters.ClusterId}&CampusId=${filters.CampusId}&ResearchCenterId=${filters.ResearchCenterId}`}
                    tableRef={tableRef}
                    exporticon={ExportIcon}
                />
                <HeaderFilters
                    filters={filters}
                    availableFilter={['campus', 'cluster', 'researchCenter']}
                    setFilters={setFilters}
                />
                <Table
                    permissionPrefix={'FeePolicy'}
                    ref={tableRef}
                    shouldView={true}
                    shouldDelete={true}
                    shouldEdit={true}
                    shouldAssignFee={false}
                    shouldProfile = {false}
                    checkboxshouldView={true}
                    resource={'FeeStructure/FeePolicy'}
                    refresh={refresh}
                    data={rows}
                    totalRecords={totalRecords}
                    perPage={recordsPerPage}
                    setPerPage={setRecordsPerPage}
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                    column={column}
                    renderColumn={renderColumn}
                    tableHeading="All Fee Policies"
                    exportFileName={'Fee Policies'}
                    name="Fee"
                    multipleDelete="Fees"
                    onEdit={(item) => {
                        window.location.href = `/fee-policy/${item.id}/edit?next=${encodeURIComponent(`${window.location.pathname}${window.location.search}`)}`;
                    }}
                    onView={(item) => {
                        window.location.href = `/fee-policy/${item.id}/view?next=${encodeURIComponent(`${window.location.pathname}${window.location.search}`)}`;
                    }}
                />
            </div>
        </div>
    );
};

export default FeePolicyList;
