import React from 'react';
import PersonalInfoContent
    from "../../Dashboard/Users/Scholars/Subcomponents/UserProfile/Personal Info/PersonalInfoContent";
import ProgressBar from "./ProgressBar";
const Personal = ({id, redirectTo, isScholar}) => {
    return <>
        <div className="user-profile-section">
            <ProgressBar />
            <PersonalInfoContent id={id} disabled={isScholar} redirecTo={redirectTo}/>
        </div>
    </>
}
export default Personal;
