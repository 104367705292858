import { useEffect, useState } from 'react';
import Api from '../api/api';

const useEnums = () => {
    const [enums, setEnums] = useState({});

    useEffect(() => {
        Api.get(`/Common/CommonEnums/getEnums`)
            .then((response) => {
                setEnums(response);
            })
            .catch(() => {})
            .finally(() => {});
    }, []);

    const renderEnum = (type, value) => {
        if (enums[type]) {
            return enums[type][value] || value;
        }
        return value;
    };

    return { enums, renderEnum };
};

export default useEnums;
