import React from 'react';
import ProgressBar from "./ProgressBar";
import {SocialSiteContent} from "../../Dashboard/Users/Scholars/Subcomponents/UserProfile/Social Sites/SocialSite";
const Personal = ({id}) => {
    return <>
        <div className="user-profile-section">
            <ProgressBar />
            <SocialSiteContent scholarId={id}/>
        </div>
    </>
}
export default Personal;
