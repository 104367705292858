import React from 'react';
import { LinkButton } from '../../../../Buttons/LinkButton';
import Plus from '../../../../../../images/plus.svg';

const AddConferenceDefaultContent = (props) => {
    return (
        <div className="profile-screen">
            <div className="default-page">
                <div className="default-content">
                    <h6>{props.heading}</h6>
                    <span>{props.subheading}</span>
                </div>
                <div className="default-button">
                    <LinkButton
                        linktext="Add Conference Details"
                        icon={Plus}
                        width="auto"
                        height="40px"
                        padding="0px 20px"
                        href="/conference-detail"
                    />
                </div>
            </div>
        </div>
    );
};

export default AddConferenceDefaultContent;
