import React, {useState} from 'react';
import {Button} from '../../Buttons/Button';
import {SimpleInput} from '../../Inputs/SimpleInput';
import SelectInput from '../../Inputs/SelectInput';
import Api from "../../../../api/api";

const options = [
    {value: true, label: 'Yes'},
    {value: false, label: 'No'},
];

const RollNumberGenerator = ({onHide, scholars, refresh}) => {
    const [state, setState] = useState({
        AlphabeticalOrder: false,
        AlreadyAssigned: false,
        Prefix: '',
        SeriesStart: '',
    });

    const handleChange = (e) => {
        setState({...state, [e.target.name]: e.target.value});
    }

    const [errors, setErrors] = useState({});

    const submit = () => {
        let errs = {};
            if (!state.Prefix) {
                errs.Prefix = 'Field is required';
            }
            if (!state.SeriesStart) {
                errs.SeriesStart = 'Field is required';
            } else if (isNaN(state.SeriesStart)) {
                errs.SeriesStart = 'Numeric value is required';
            }

            if (state.SeriesStart && state.Prefix) {
                if (previewRollNumber(state.SeriesStart, state.Prefix)) {
                    errs.SeriesStart = 'Roll number already exists';
                    errs.Prefix = 'Roll number already exists';
                }
            }

            setErrors(errs);

        Api.post('/Users/Scholar/AssignRollNumbers', {
            ...state,
            Scholars: scholars.map((scholar) => ({ScholarId: scholar.Id})),
        }).then((res) => {
            refresh();
            onHide();
        });
    }

    const previewRollNumber = (state) => {
        return `${state.Prefix}${state.SeriesStart}`.trim();
    }

    return (
        <div className="assign-roll-no-modal">
            <div className="fee-policy-modal">
                <div className="modal-box">
                    <div className="modal-heading">
                        <h5>Assign Roll Number</h5>
                    </div>
                    <img
                        className="modal-cross-icon"
                        onClick={onHide}
                        src="/images/cross.svg"
                    />
                </div>

                <div className="assign-roll-no-input">
                    <div className="fee-mode-name">
                        <div className="payment-label">Prefix Value*</div>
                        <SimpleInput
                            value={state.Prefix}
                            onChange={handleChange}
                            name={'Prefix'}
                            placeholder="Enter prefix value roll no (Eg : 9876543)"
                            error={errors.Prefix}
                            estr
                        />
                    </div>
                    <div className="fee-mode-name">
                        <div className="payment-label">Start Series*</div>
                        <SimpleInput
                            value={state.SeriesStart}
                            onChange={handleChange}
                            name={'SeriesStart'}
                            placeholder="Enter start series roll no (Eg : 001)"
                            error={errors.SeriesStart}
                        />
                    </div>
                </div>

                <div className="assign-roll-no-input">
                    <div className="fee-mode-name">
                        <div className="payment-label">Already Assigned</div>
                        <SelectInput
                            value={state.AlreadyAssigned}
                            onChange={e =>
                                handleChange({target: {name: 'AlreadyAssigned', value: e.value}})
                            }
                            name={'AlreadyAssigned'}
                            placeholder="Select already assigned "
                            options={options}
                        />
                    </div>
                    <div className="fee-mode-name">
                        <div className="payment-label">Alphabetical Order</div>
                        <SelectInput

                            value={state.AlphabeticalOrder}
                            onChange={e =>
                                handleChange({target: {name: 'AlphabeticalOrder', value: e.value}})
                            }
                            name={'AlphabeticalOrder'}
                            placeholder="Select alphabetical order "
                            options={options}
                        />
                    </div>
                </div>

                <div className='preview-heading'>
                    <h6>Preview</h6>
                </div>
                <div className='preview-assign-roll-no'>
                    <h6>Roll Number : <span>{previewRollNumber(state)}</span></h6>
                </div>

                <div className="modals-cb-buttons">
                    <Button onClick={onHide}
                            width="370px"
                            height="44px"
                            color="#1D2939"
                            border="1px solid #D0D5DD"
                            backgroundcolor="#ffffff"
                            fontweight="300"
                            hover="#F9FAFB"
                            marginright="12px"
                    >
                        Cancel
                    </Button>

                    <Button
                        onClick={submit}
                        width="370px"
                        height="44px"
                        backgroundcolor="#7f56d9"
                        fontweight="400"
                        font-size="14px"
                        hover="#5d43a5"
                    >
                        Assign Roll Number
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default RollNumberGenerator;
