import React, { useState } from 'react';
import PhdCentralLogo from '../../../../../images/phd-central-logo.png';
import Hamburger from '../../../../../images/hamburger.svg';
import Closemenu from '../../../../../images/close-sidemenu.svg';
import MobileNavigation from '../Mobile Navigation/MobileNavigation';
import { Link } from 'react-router-dom';
import MobileFooter from '../Mobile Footer/MobileFooter';

const MobileHeader = () => {
    const [showSidebar, setShowSidebar, closeSidebar, setCloseSidebar] =
        useState(false);

    return (
        <>
            <div className="sidebar-mobile">
                <div className="sidebar-navigation-mobile-fixed">
                    <div className="phd-logo-mobile">
                        <div className="phd-logo">
                            <Link to="/dashboard">
                                <img
                                    className="phdpad-logo"
                                    src={PhdCentralLogo}
                                    alt={'PhdCentralLogo'}
                                />
                            </Link>
                        </div>

                        <>
                            <img
                                className="hamburger-icon"
                                src={Hamburger}
                                alt={'Hamburger'}
                                onClick={() => setShowSidebar(!showSidebar)}
                            />
                        </>
                    </div>
                </div>
            </div>
            {showSidebar && (
                <div
                    className={
                        showSidebar ? 'mobile-menu open' : 'mobile-menu close'
                    }
                >
                    <div className='navbar-expandedview'>
                        <div className='sidebar-nav' >
                            <div className="sidebar-navigation-mobile">
                                <Link to="/dashboard">
                                    <div className="phd-logo-mobile">
                                        <div className="phd-logo">
                                            <img
                                                className="phdpad-logo"
                                                src={PhdCentralLogo}
                                                alt={'PhdCentralLogo'}
                                            />
                                        </div>
                                    </div>
                                </Link>
                            </div>
                            <MobileNavigation />
                        </div>
                        <MobileFooter />
                    </div>
                    <div>
                        <img
                            className="closemenu"
                            src={Closemenu}
                            alt={'Closemenu'}
                            onClick={() => setShowSidebar(!showSidebar)}
                        />
                    </div>
                </div>
            )}
            
        </>
        
    );
};

export default MobileHeader;
