import React from 'react';
import Header from '../../../components/Header/Header';
import Heading from '../../../components/Heading/Heading';
import Sidebar from '../../../components/Sidebar/Sidebar';
import GeneralView from '../View Campus/Subcomponents/GeneralView';
import { useParams } from 'react-router-dom';
import useDetailApi from '../../../../hooks/useDetailApi';
import Backarrow from '../../../../images/back-arrow.svg';
import MediaQuery from 'react-responsive';
import MobileHeader from '../../../components/Sidebar/Mobile Design/Mobile Header/MobileHeader';

const ViewDepartment = () => {
    const { id } = useParams();
    const { loading, item } = useDetailApi('Users/Guide', id);

    const fields = [
        {
            type: 'select',
            field: 'CampusName',
            label: 'Campus',
            description: 'This will be displayed on your profile.',
            estric: '*',
            options: {
                type: 'dynamic',
                endpoint: 'Infrastructure/Campus',
                paged: true,
                valueKey: 'Id',
                labelKey: 'DisplayName',
            },
        },

        {
            type: 'text',
            field: 'EmployeeId',
            label: 'Employee Id',
            description: 'This will be displayed on your profile.',
            estric: '*',
        },
        {
            type: 'text',
            field: 'Designation',
            label: 'Designation',
            description: 'This will be displayed on your profile.',
            estric: '*',
        },
        {
            type: 'select',
            field: 'Honorifics',
            label: 'Title',
            description: 'This will be displayed on your profile.',
            estric: '*',
            options: {
                type: 'enum',
                value: 'Honorifics',
            },
        },

        {
            type: 'text',
            field: 'FirstName',
            label: 'First Name',
            description: 'This will be displayed on your profile.',
            estric: '*',
        },
        {
            type: 'text',
            field: 'LastName',
            label: 'Last Name',
            description: 'This will be displayed on your profile.',
            estric: '*',
        },
        {
            type: 'select',
            field: 'Gender',
            label: 'Gender',
            description: 'This will be displayed on your profile.',
            estric: '*',
            options: {
                type: 'enum',
                value: 'Gender',
            },
        },
        {
            type: 'text',
            field: 'Email',
            label: 'Email',
            description: 'This will be displayed on your profile.',
            estric: '*',
        },
        {
            type: 'text',
            field: 'PhoneNumber',
            label: 'Phone Number',
            description: 'This will be displayed on your profile.',
            estric: '*',
        },
        {
            type: 'text',
            field: 'Disciplines',
            label: 'Specializations',
            render: (item) => {
                return item.Disciplines.map((discipline) => {
                    return discipline.Discipline.Name;
                }).join(', ');
            },
            description: 'This will be displayed on your profile.',
            estric: '*',
        },
    ];
    return (
        <div className="main-wrapper">
            <MediaQuery maxWidth={1023}>
                <MobileHeader />
            </MediaQuery>
            <Sidebar />

            <div className="screen">
                <Header
                    homelink="/dashboard"
                    breadcrumb="Supervisors"
                    breadcrumbs="View Supervisor"
                    innerpath="/supervisor"
                />
                <Heading
                    title="Supervisor Details"
                    description="View all details of the Supervisor below"
                    linktext="Supervisors"
                    icon={Backarrow}
                    href="/supervisor"
                    width="auto"
                    height="44px"
                    backgroundcolor="none"
                    color="#5D43A5"
                    fontsize="16px"
                    hover="#F9F5FF"
                    padding="0px 20px !important"
                    className="view-back-button"
                />
                {loading ? (
                    <div> Loading... </div>
                ) : (
                    <>
                        <GeneralView campus={item} fields={fields} />
                    </>
                )}
            </div>
        </div>
    );
};

export default ViewDepartment;
