import React from 'react';
import styled from 'styled-components';
import ReactTooltip from 'react-tooltip';

const InputContainer = styled.div`
  width: 100%;
  float: left;

  > label {
    font-size: 14px;
    font-weight: 400;
    color: #344054;
    padding-bottom: 4px;
  }

  > span {
    font-size: 14px;
    color: #f04438;
  }
`;

const GroupContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: ${(props) => props.height || '40px'};
  border: 1px solid ${(props) => (props.hasError ? '#F04438' : '#D0D5DD')};
  border-radius: 4px;
  padding: 0 10px;

  &:hover {
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05), 0px 0px 0px 2px #f4ebff;
  }

  > img {
    width: 18px;
    height: 18px;
    margin: 8px;
    color: #c1c1c1;
  }
`;

const InputBase = styled.input`
  height: auto;
  width: 100%;
  color: #1d2939;
  font-size: 14px;
  font-weight: 400;
  border: none;
  background: transparent;
  outline: none;

  &::placeholder {
    color: #c1c1c1 !important;
    font-size: 14px;
    font-weight: 300;
  }
`;

export const SimpleInput = ({
                                onClick,
                                onChange,
                                type,
                                value,
                                placeholder,
                                name,
                                error,
                                righticon,
                                lefticon,
                                label,
                                successMsg,
                                tooltipicon,
                                tooltiptext,
                                readOnly = false,
                                height,
                                onBlur,
                                onFocus,
                                className,
                                
                            }) => {
    console.log({value})
    if (type === 'date' && value && typeof value === "object") {
        onChange({target: {name, value: value.date.split(' ')[0]}});
    }

    return (

        <InputContainer>
            {label && <label>{label}</label>}
            <GroupContainer hasError={error}>
                {lefticon && <img src={lefticon}/>}
                <InputBase
                    readOnly={readOnly}
                    type={type}
                    onChange={onChange}
                    name={name}
                    value={value}
                    placeholder={placeholder}
                    height={height}
                    onFocus={onFocus}
                    onBlur={onBlur}
                    className={className}
                    error={error}
                />
                {righticon && <img src={righticon} onClick={onClick}/>}
                {tooltipicon && (
                    <>
                        <ReactTooltip
                            id={tooltiptext}
                            type="dark"
                            effect="solid"
                            place="left"
                        >
                            {tooltiptext}
                        </ReactTooltip>
                        <img
                            data-tip
                            data-for={tooltiptext}
                            src={tooltipicon}
                            onClick={onClick}
                        />
                    </>
                )}
            </GroupContainer>
            {successMsg ? (
                <p style={{color: 'green'}}>{successMsg}</p>
            ) : (
                <span>{error}</span>
            )}
        </InputContainer>
    );
}
