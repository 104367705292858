import React, {useState} from 'react';
import Heading from '../../../../../../../components/Heading/Heading';
import Plus from '../../../../../../../../images/plus.svg';
import {useNavigate} from 'react-router-dom';
import {Modal} from 'react-bootstrap';
import AddQualification
    from '../../../../../../../components/Modals/User Profile Modals/Subcomponents/Add Qualification Details/AddQualification';
import useProfileListApi from '../../../../../../../../hooks/useProfileListApi';
import ProfileTable from '../../../../../../../components/Table/ProfileTable';
import Api from "../../../../../../../../api/api";
import Card from '../../../../../../../components/Cards/Card';


const QualificationContent = ({id}) => {
    const {rows, refresh} = useProfileListApi('Qualification', id);

    const navigate = useNavigate();

    const [objectId, setObjectId] = useState(null);
    const [userprofileModal, setuserprofileModal] = useState(false);
    const userprofileModalClose = () => {
        refresh();
        setuserprofileModal(false);
    };
    const userprofileModalShow = () => setuserprofileModal(true);

    const column = [
        {
            heading: 'Institution',
            value: 'SchoolName',
        },
        {
            heading: 'Type',
            value: 'QualificationType',
        },
        {
            heading: 'Degree',
            value: 'Degree',
        },
        {
            heading: 'Percentage/CGPA',
            value: 'Percentage',
            render: (row) => {
                if(!row.Percentage){
                    return '-';
                }
                return <>{row.Percentage}%</>;
            }
        },
        {
            heading: 'Passing Out Year',
            value: 'PassingOutYear',
        },
        {
            heading: 'Document',
            render: (row) => {
                let parsed = JSON.parse(row.Attachments);
                if(!parsed){
                    return '-';
                }
                return (
                    <a
                        href={`${Api.api_url}/uploads/${parsed.FileName}`}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        {parsed.DisplayName}
                    </a>
                );
            }
        },
    ];

    const renderColumn = (row, column, rendered) => {
        if (column.value === 'cluster') {
            return (
                <td>
                    <a href={`/registry?CampusId=${row.CampusId}`}>Registry</a>
                </td>
            );
        }
        return rendered;
    };

    return (
        <div className="profile-screen">
            <div className="profile-heading">
                <Heading
                    title="Qualifications"
                    description="Add your qualifications here"
                    linktext="Add More"
                    icon={Plus}
                    width="auto"
                    height="40px"
                    padding="0px 20px"
                    onClick={() => {
                        setObjectId(null);
                        setuserprofileModal(true);
                    }}
                />
            </div>


            <ProfileTable
                shouldView={false}
                checkboxshouldView={false}
                resource={'Qualification'}
                refresh={refresh}
                data={rows}
                column={column}
                shouldAssignFee={false}
                renderColumn={renderColumn}
                tableHeading="All Qualifications"
                onEdit={(item) => {
                    setObjectId(item.id);
                    setuserprofileModal(true);
                }}
            />

            {/* Modal */}

            <Modal
                show={userprofileModal}
                onHide={userprofileModalClose}
                centered
                className="userprofileModal"
            >
                <AddQualification
                    onShow={userprofileModal}
                    onClose={userprofileModalClose}
                    scholarId={id}
                    id={objectId}
                />
            </Modal>
        </div>
    );
};

export default QualificationContent;
