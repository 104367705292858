import React, {useRef, useState} from 'react';
import MediaQuery from 'react-responsive';
import MobileHeader from '../../../../../components/Sidebar/Mobile Design/Mobile Header/MobileHeader';
import Sidebar from '../../../../../components/Sidebar/Sidebar';
import Heading from '../../../../../components/Heading/Heading';
import HeaderFilters from '../../../../../components/Filters/HeaderFilters';
import Table from '../../../../../components/Table/Table';
import useListApi from '../../../../../../hooks/useListApi';
import qs from 'qs';
import ExportIcon from "../../../../../../images/export.svg";
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import renderValue from "../../../../../../helpers/renderValue";

const FeeReport = () => {
    const [filters, setFilters] = useState(
        qs.parse(window.location.search, { ignoreQueryPrefix: true }),
    );

    const {
        rows,
        refresh,
        totalRecords,
        currentPage,
        recordsPerPage,
        setCurrentPage,
        setRecordsPerPage,
    } = useListApi('FeeStructure/Transaction/getEntitiesForReport', false, true, filters, 'all', false);

    const column = [
        {
            heading: 'Roll No.',
            value: 'UniversityRollNo',
        },
        {
            heading: 'Scholar Name',
            value: 'ScholarName',
        },
        {
            heading: 'Batch',
            value: 'BatchName',
        },
        {
            heading: 'Discipline',
            value: 'ResearchCenterName',
        },
        {
            heading: 'Payment Date',
            render: (item) => {
                let date = item.Date
                try {
                    const transactionDetails = JSON.parse(item.TransactionDetails || '{}');
                    date = transactionDetails.date || item.Date.date.split(' ')[0];
                }catch (e){

                }
                return renderValue(date);
            }
        },
        {
            heading: 'Receipt No.',

            render: (item) => {
                try {
                    const transactionDetails = JSON.parse(item.TransactionDetails || '{}');
                    return item.TransactionId || transactionDetails.transactionNumber;
                }catch (e){
                    return item.TransactionId;
                }
            }
        },
        {
            heading: 'Payment Mode',
            value: 'PaymentType',
        },
        {
            heading: 'Academic Year',
            value: 'AcademicYearName',
            aggregate: (rows) => {
                return `Total:`;
            }
        },
        {
            heading: 'Transaction Details',
            render: (item) => {
                try {
                    const transactionDetails = JSON.parse(item.TransactionDetails || '{}');
                    const parts = [];
                    if(transactionDetails.bank_name){
                        parts.push(transactionDetails.bank_name);
                    }
                    if(transactionDetails.dd_neft_rtgs_cheque_number){
                        parts.push(transactionDetails.dd_neft_rtgs_cheque_number);
                    }
                    if(transactionDetails.dd_neft_rtgs_cheque_date){
                        parts.push(transactionDetails.dd_neft_rtgs_cheque_date);
                    }
                    return parts.join(', ').trim() || '-';
                }catch (e){
                    return '-';
                }
            }
        },
        {
            heading: 'Notes',
            render: (item) => {
                try {
                    const transactionDetails = JSON.parse(item.TransactionDetails || '{}');
                    return transactionDetails.notes || '-';
                }catch (e){
                    return '-';
                }
            }
        },

        {
            heading: 'Amount Paid',
            render: (item) => {
                return `₹${item.AmountPaid}`;
            },
            aggregate: (rows) => {
                return `₹${rows.reduce((acc, item) => acc + parseFloat(item.AmountPaid), 0)}`;
            }
        },
    ];

    const renderColumn = (row, column, rendered) => {
        if (column.value === 'cluster') {
            return (
                <td>
                    <a href={`/registry?CampusId=${row.CampusId}`}>Registry</a>
                </td>
            );
        }
        return rendered;
    };

    const tableRef = useRef(null);
    return (
        <>
            <div className="main-wrapper" id="divToPrint">
                <MediaQuery maxWidth={1023}>
                    <MobileHeader />
                </MediaQuery>
                <MediaQuery minWidth={1024}>
                    <Sidebar />
                </MediaQuery>
                <div className="screen">
                    <Heading
                        title="Fee Report"
                        description="List of transactions"
                        href={`/fee-policy/new?ClusterId=${filters.ClusterId}&CampusId=${filters.CampusId}&ResearchCenterId=${filters.ResearchCenterId}`}
                        tableRef={tableRef}

                        exporticon={ExportIcon}
                    />

                    <HeaderFilters
                        
                        filters={filters}
                        availableFilter={[
                            'fromDate',
                            'toDate',
                            'campus',
                            'cluster',
                            'researchCenter',
                            'academicYear',
                        ]}
                        setFilters={setFilters}
                    />

                    <Table
                        ref={tableRef}
                        checkboxshouldView={false}
                        shouldView={false}
                        shouldEdit={false}
                        shouldDelete={false}
                        shouldProfile={false}
                        shouldAssignFee={false}
                        showPagination={false}
                        totalRecords={totalRecords}
                        resource={'Campus'}
                        refresh={refresh}
                        data={rows}
                        column={column}
                        tableHeading="Fee Report"
                        exportFileName={'Fee Report'}
                        showSortIcon={false}
                    />
                </div>
            </div>
        </>
    );
};

export default FeeReport;
