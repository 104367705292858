import React, { useState } from 'react';
import { LinkButton } from '../../../../../../../components/Buttons/LinkButton';
import Discount from '../../../../../../../../images/discount.svg';
import { Modal } from 'react-bootstrap';
import Collection from '../../../../../../../components/Modals/Fee Modals/Assign Fee Policy/Subcomponents/Collection/Collection';
import FeeDiscount from '../../../../../../../components/Modals/Fee Modals/Assign Fee Policy/Subcomponents/Discount/FeeDiscount';
import formatNumber from '../../../../../../../../helpers/formatNumber';
import CollectionIcon from '../../../../../../../../images/collection.svg';
import MediaQuery from 'react-responsive';
import { Link } from 'react-router-dom';
import Activeprofile from '../../../../../../../../images/active-progress-line.svg';
import Inactiveprofile from '../../../../../../../../images/inactive-progress-line.svg';

const AssigendFeePolicyAmount = ({ summary, id, refresh, scholar, isScholar = false, userId = null, payable }) => {
    const [collectionModal, setcollectionModal] = useState(false);
    const collectionModalClose = () => {
        setcollectionModal(false);
    };
    const collectionModalShow = () => {
        setcollectionModal(true)
    };

    const [discountModal, setdiscountModal] = useState(false);
    const discountModalClose = () => {
        refresh();
        setdiscountModal(false);
    };
    const discountModalShow = () => {
        setdiscountModal(true);
    };

    const InComplete = () => {
        return (
            <img
                className="progress-complete"
                src={Inactiveprofile}
                alt={'Inactiveprofile'}
            />
        );
    };
    const Active = () => {
        return (
            <img
                className="progress-complete"
                src={Activeprofile}
                alt={'Inactiveprofile'}
            />
        );
    };

    const StatusIcon = (section) => {
        if (window.location.href.endsWith(`/${section}`)) {
            return <Active />;
        }
        return <InComplete />;
    };

    return (
        <div className="assigned-fee-policy-amount">
            <div className="assigned-amount">
                <div className="amount-status">
                    <div className="total-amount">
                        <h4>Total Amount</h4>
                        <span>₹{formatNumber(summary.Total)}</span>
                    </div>
                    <div className="total-paid">
                        <h4>Total Paid</h4>
                        <span>₹{formatNumber(summary.AmountPaid)}</span>
                    </div>
                    <div className="remaining-amount">
                        <h4>Remaining Amount</h4>
                        <span>₹{formatNumber(summary.PendingAmount)}</span>
                    </div>
                </div>
                <div className="fee-policy-buttons">
                    <div className="collection-button">
                        {(!isScholar || payable > 0) && (
                            <LinkButton
                                width="auto"
                                height="40px"
                                padding="0px 20px !important"
                                border="1px solid #7F56D9"
                                backgroundcolor="none"
                                hover="#F9F5FF"
                                color="#7F56D9"
                                icon={CollectionIcon}
                                linktext={isScholar ? 'Pay Now' : 'Collection'}
                                onClick={collectionModalShow}
                            ></LinkButton>
                        )}
                    </div>
                    {isScholar ? null : (
                        <div className="discount-button">
                            <LinkButton
                                width="auto"
                                height="40px"
                                padding="0px 20px !important"
                                hover="#5D43A5"
                                icon={Discount}
                                linktext="Fee Waiver"
                                onClick={discountModalShow}
                            ></LinkButton>
                        </div>
                    )}
                </div>

                {/* Scholar Fee - Status Heading */}
                {/* <MediaQuery maxWidth={1023}>
                    <div className='scholar-fee-mobile'>
                        <div className="progress-bar">
                            <div className="progress-bar-content">
                                <ul>
                                    <li>
                                        <Link to={`/`}>
                                            <div className="personal-info-tab">
                                                <StatusIcon
                                                    section={'paid'}
                                                />
                                                <span className='active'>Paid</span>
                                            </div>
                                        </Link>
                                    </li>
                                    <li>
                                    <Link to={`/unpaid`}>
                                            <div className="personal-info-tab">
                                                <StatusIcon
                                                    section={'paid'}
                                                />
                                                <span className='inactive'>Unpaid</span>
                                            </div>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to={`/all-transaction`}>
                                        <div className="personal-info-tab">
                                                <StatusIcon
                                                    section={'paid'}
                                                />
                                                <span className='inactive'>All Transaction</span>
                                            </div>
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </MediaQuery> */}
            </div>

            {/* Scholar Fee Tab - Pay Now */}

            <MediaQuery maxWidth={1023}>
                {(!isScholar || payable > 0) && (
                    <div className='fee-pay-now-scholar'>
                        <div className="pay-now-scholar">
                            <LinkButton
                                width="auto"
                                height="40px"
                                padding="0px 20px !important"
                                border="1px solid #7F56D9"
                                backgroundcolor="none"
                                hover="#F9F5FF"
                                color="#7F56D9"
                                icon={CollectionIcon}
                                linktext={isScholar ? 'Pay Now' : 'Collection'}
                                onClick={collectionModalShow }
                            ></LinkButton>
                        </div>
                    </div>)}
            </MediaQuery>

            {/* ModalCollection */}

            <Modal
                show={collectionModal}
                onHide={collectionModalClose}
                centered
                className={isScholar ? 'feepaymentModal' : 'collectionModal'}
            >

                <Collection
                    isScholar={isScholar}
                    id={userId ? userId : scholar.User_id}
                    payable={payable || summary.PendingAmount}
                    isAmountEditable={!isScholar}
                    onShow={collectionModal}
                    onClose={collectionModalClose}
                />
            </Modal>

            {/* ModalDiscount */}

            <Modal
                show={discountModal}
                onHide={discountModalClose}
                centered
                className='collectionModal'
            >
                <FeeDiscount
                    paid={summary.AmountPaid}
                    id={userId}
                    onShow={discountModal}
                    onClose={discountModalClose}
                    scholarId={id}
                />
            </Modal>
        </div>
    );
};

export default AssigendFeePolicyAmount;
