import React, {useRef, useState} from 'react';
import Sidebar from '../../../../../components/Sidebar/Sidebar';
import Header from '../../../../../components/Header/Header';
import Heading from '../../../../../components/Heading/Heading';
import Table from '../../../../../components/Table/Table';
import useListApi from '../../../../../../hooks/useListApi';
import MediaQuery from 'react-responsive';
import MobileHeader from '../../../../../components/Sidebar/Mobile Design/Mobile Header/MobileHeader';
import HeaderFilters from '../../../../../components/Filters/HeaderFilters';
import qs from 'qs';
import {Modal} from 'react-bootstrap';
import AssignFeePolicy
    from '../../../../../components/Modals/Fee Modals/Assign Fee Policy/Subcomponents/Assign Fee Policy/AssignFeePolicy';
import ExportIcon from '../../../../../../images/export.svg';
import Backarrow from '../../../../../../images/back-arrow.svg';
import {ExportButton} from "../../../../../components/Buttons/ExportButton";

const ScholarsList = () => {
    const [filters, setFilters] = useState(
        qs.parse(window.location.search, {ignoreQueryPrefix: true}),
    );
    const {
        rows,
        refresh,
        totalRecords,
        currentPage,
        recordsPerPage,
        setCurrentPage,
        setRecordsPerPage,
    } = useListApi('Users/Scholar', false, true, filters);
    const column = [
        {
            heading: 'Name',
            render: (item) => {
                return `${item.FirstName} ${item.LastName}`;
            },
        },

        {
            heading: 'University Roll No.',
            value: 'UniversityRollNo',
        },
        {
            heading: 'Discipline',
            value: 'ResearchCenterName',
        },
        {
            heading: 'Batch',
            value: 'BatchName',
        },
        {
            heading: 'Quota',
            value: 'Quota',
        },
        {
            heading: 'Fee Policy',
            value: 'FeePolicy',
        },
    ];

    const [showFeePolicyModal, setShowFeePolicyModal] = useState(false);
    const [selectedUsers, setSelectedUsers] = useState([]);

    const tableRef = useRef(null);

    return (
        <div className="main-wrapper">
            <MediaQuery maxWidth={1023}>
                <MobileHeader/>
            </MediaQuery>
            <MediaQuery minWidth={1024}>
                <Sidebar/>
            </MediaQuery>
            <div className="screen">
                {/* <Header homelink="/dashboard" breadcrumbs="Assign Fee Policy" /> */}
                <Heading
                    title="Assign Fee Policy"
                    description="Assign fee policy to scholars"
                    width="auto"
                    height="40px"
                    padding="0px 20px"
                    tableRef={tableRef}
                    exporticon={ExportIcon}
                    linktext="Back to Fee Policies"
                    backgroundcolor="none"
                    color="#5D43A5"
                    fontsize="16px"
                    hover="#F9F5FF"
                    className="view-back-button"
                    href="/assign-fee-policy"
                    icon={Backarrow}
                />
                <HeaderFilters
                    filters={filters}
                    availableFilter={[
                        'campus',
                        'cluster',
                        'researchCenter',
                        'batch',
                        'quota',
                        'feePolicyStatus',
                    ]}
                    setFilters={setFilters}
                />
                <Table
                    permissionPrefix={'AssignFee'}
                    ref={tableRef}
                    shouldView={true}
                    shouldAssignFee={false}
                    shouldDelete={false}
                    shouldProfile={false}
                    checkboxshouldView={true}
                    data={rows}
                    showPagination={true}
                    renderBatchActions={(selected) => {
                        if (selected.length) {
                            return <div className="delete-row-button">
                                <ExportButton
                                    width="auto"
                                    height="36px"
                                    hover="#FEF9F9"
                                    border="1px solid #000"
                                    color="#000"
                                    padding="0px 10px"
                                    borderradius="6px"
                                    onClick={() => {
                                        setSelectedUsers(selected);
                                        setShowFeePolicyModal(true);
                                    }}
                                >
                                    Edit
                                </ExportButton>
                            </div>
                        }
                        return null;
                    }}
                    totalRecords={totalRecords}
                    perPage={recordsPerPage}
                    setPerPage={setRecordsPerPage}
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                    column={column}
                    resource={'Users/Scholar'}
                    tableHeading="All Scholars"
                    name="Scholar"
                    refresh={refresh}
                    multipleDelete="Scholars"
                    onEdit={(item) => {
                    setSelectedUsers([item]);
                    setShowFeePolicyModal(true);
                }}
                    onView={(item) => {
                    window.location.href = `/assign-fee-policy/scholar/${item.Id}/fee?next=${encodeURIComponent(`${window.location.pathname}${window.location.search}`)}`;
                }}
                    />
            </div>
            {showFeePolicyModal && (
            <Modal
                show={true}
                onHide={() => setShowFeePolicyModal(false)}
                centered
                className="assignfeeModal"
            >
                <AssignFeePolicy
                    refresh={refresh}
                    users={selectedUsers}
                    FeePolicyId={selectedUsers.reduce((acc, item) => {
                        return acc === item.FeePolicyId ? acc : '';
                    }, selectedUsers[0].FeePolicyId)}
                    ResearchCenterId={
                        selectedUsers[0].ResearchCenterId || ''
                    }
                    onHide={() => {
                        setShowFeePolicyModal(false);
                    }}
                />
            </Modal>
            )}
        </div>
    );
}
    ;

    export default ScholarsList;
