import React from 'react';
import PersonalInfoContent
    from "../../Dashboard/Users/Scholars/Subcomponents/UserProfile/Personal Info/PersonalInfoContent";
import ProgressBar from "./ProgressBar";
import PublicationDetailContent
    from "../../Dashboard/Users/Scholars/Subcomponents/UserProfile/Publications Details/PublicationDetailContent";
const Personal = ({id}) => {
    return <>
        <div className="user-profile-section">
            <ProgressBar />
            <PublicationDetailContent id={id}/>
        </div>
    </>
}
export default Personal;
