import React, {useEffect, useState} from 'react';
import {Button} from '../../../../../Buttons/Button';
import {ExportButton} from '../../../../../Buttons/ExportButton';
import {FixedPlaceholderInput} from '../../../../../Inputs/FixedPlaceholderInput';
import SelectInput from '../../../../../Inputs/SelectInput';
import Api from '../../../../../../../api/api';
import {TextAreaInput} from '../../../../../Inputs/TextAreaInput';
import {SimpleInput} from "../../../../../Inputs/SimpleInput";

const Collection = ({payable, onClose, id, isScholar, isAmountEditable}) => {
    const [amount, setAmount] = useState(isScholar ? payable : '');
    const [notes, setNotes] = useState({transactionNumber: '', notes: '', date: ''});
    const [mode, setMode] = useState(isScholar ? 'Online' : '');
    const [modes, setModes] = useState([]);
    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (isScholar) {
            setModes([{label: 'Online', value: 'Online'}]);
            return;
        }
        Api.get('/Common/CommonEnums/getEnums').then((response) => {
            setModes(
                response.PaymentType.map((m) => {
                    return {label: m, value: m};
                }),
            );
        });
    }, [isScholar]);

    const submit = () => {
        console.log("Submitting")
        let errs = {};
        if (!isScholar) {
            if (!amount) {
                errs.amount = 'Please enter amount';
            }
            if (!mode) {
                errs.mode = 'Please select payment mode';
            }
            if (!notes.transactionNumber.trim()) {
                errs.transactionNumber = 'Transaction number is required';
            }
            if (!notes.date.trim()) {
                errs.date = 'Date is required';
            } else {
                const today = new Date();
                const date = new Date(notes.date);
                if (date > today) {
                    errs.date = 'Date cannot be greater than today';
                }
            }
            //Check if date is greater than today
            console.log({errs})
            if (Object.keys(errs).length > 0) {
                setErrors(errs);
                return;
            }
        }
        setErrors({});
        setLoading(true);

        if (mode === 'Online') {
            Api.post('/FeeStructure/FeePayment/CreateRequestPacket', {PayableAmount: amount, UserId: id})
                .then(response => {
                    onClose();
                    window.location.href = response.PaymentUrl;
                })
            return;
        }
        Api.post('/FeeStructure/Transaction/PostEntity', {
            UserId: id,
            AmountPaid: amount,
            PaymentType: mode,
            TransactionId: null,
            Date: notes.date,
            Status: 1,
            CreatedBy: null,
            TransactionDetails: JSON.stringify(notes),
        })
            .then(() => {
                onClose();
            })
            .catch((e) => {
                setErrors(e.Fields);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    return (
        <div className="fee-policy-modal">
            <div className="modal-box">
                <div className="modal-heading">
                    <h5>{isScholar ? 'Fee Payment' : 'Collection'}</h5>
                    <span>{isScholar ? 'Pay your fees easily and securely with our simple payment options' : 'Enter the amount you have received'}</span>
                </div>
                <img
                    className="modal-cross-icon"
                    onClick={onClose}
                    src="/images/cross.svg"
                    alt="close"
                />
            </div>

            <div className={isScholar ? 'paybal-amount-scholar' : 'paybal-amount'}>
                <h6>Payable Amount</h6>
                <span>₹{payable}</span>
            </div>
            
            <div className="fee-policy-content">
                <div className="fee-policy-input">
                    <div className='fee-input'>
                    {!isScholar &&
                        <div className="collection-mode-name">
                            <div className="payment-label">Select Mode*</div>
                            <SelectInput
                                error={errors.mode}
                                onChange={(selected) => {
                                    let value = null;
                                    if (selected) {
                                        value = selected.value;
                                    }
                                    setMode(value);
                                }}
                                placeholder="Select mode"
                                options={modes}
                                value={mode}
                            />
                        </div>
                    }
                    {!isScholar &&
                        <div className="collection-mode-name">
                            <div className="payment-label">Amount</div>
                            <FixedPlaceholderInput
                                placeholder="Enter amount"
                                fixedtext="₹"
                                value={amount}
                                error={errors.amount}
                                disabled={!isAmountEditable}
                                onChange={(e) => {
                                    setAmount(e.target.value);
                                }}
                                width="45px"
                                name="website"
                                label=""
                            />
                        </div>
                    }
                    </div>
                    
                    {!isScholar && <>
                        <div className='fee-input'>
                        <div className="collection-mode-name">
                            <div className="payment-label">Transaction / Receipt Number*</div>
                            <SimpleInput
                                placeholder="Enter Transaction / Receipt Number"
                                error={errors.transactionNumber}
                                value={notes.transactionNumber}
                                onChange={(e) => {
                                    setNotes(notes => ({...notes, transactionNumber: e.target.value}));
                                }}
                                label=""
                            />
                        </div>

                        <div className="collection-mode-name">
                            <div className="payment-label">Date*</div>
                            <SimpleInput
                                error={errors.date}
                                type={'date'}
                                className={notes.date? 'date-input--has-value' : ''}
                                placeholder="Start Date"
                                value={notes.date}
                                onChange={(e) => {
                                    setNotes(notes => ({...notes, date: e.target.value}));
                                }}
                                label=""
                            />
                        </div>
                      </div>
                      <div className='fee-input'>
                        <div className="collection-mode-name">
                            <div className="payment-label">Bank Name</div>
                            <SimpleInput
                                error={errors.bank_name}
                                className={notes.bank_name? 'date-input--has-value' : ''}
                                placeholder="Bank Name"
                                value={notes.bank_name}
                                onChange={(e) => {
                                    setNotes(notes => ({...notes, bank_name: e.target.value}));
                                }}
                                label=""
                            />
                        </div>
                        <div className="collection-mode-name">
                            <div className="payment-label">DD/NEFT/RTGS/Cheque Number</div>
                            <SimpleInput
                                error={errors.dd_neft_rtgs_cheque_number}
                                className={notes.dd_neft_rtgs_cheque_number? 'date-input--has-value' : ''}
                                placeholder="Enter DD/NEFT/RTGS/Cheque Date"
                                value={notes.dd_neft_rtgs_cheque_number}
                                onChange={(e) => {
                                    setNotes(notes => ({...notes, dd_neft_rtgs_cheque_number: e.target.value}));
                                }}
                                label=""
                            />
                        </div>
                        </div>
                        <div className='fee-input'>
                        <div className="collection-mode-name">
                            <div className="payment-label">DD/NEFT/RTGS/Cheque Date</div>
                            <SimpleInput
                                type={'date'}
                                error={errors.dd_neft_rtgs_cheque_date}
                                className={notes.dd_neft_rtgs_cheque_date? 'date-input--has-value' : ''}
                                placeholder="Enter DD/NEFT/RTGS/Cheque Date"
                                value={notes.dd_neft_rtgs_cheque_date}
                                onChange={(e) => {
                                    setNotes(notes => ({...notes, dd_neft_rtgs_cheque_date: e.target.value}));
                                }}
                                label=""
                            />
                        </div>

                        <div className="collection-mode-name">
                            <div className="payment-label">Notes</div>
                            <TextAreaInput
                                className="hello"
                                placeholder="Enter notes"
                                value={notes.notes}
                                onChange={(e) => {
                                    setNotes(notes => ({...notes, notes: e.target.value}));
                                }}
                                label=""
                            />
                        </div>
                        </div>
                    </>
                    }
                    
                </div>

                {isScholar ? (<div className="cb-buttons-fee-policy">
                    <Button
                        loading={loading}
                        onClick={submit}
                        width="540px"
                        height="40px"
                        padding="0px 20px !important"
                        hover="#5D43A5"
                    >
                        Pay Now
                    </Button>
                </div>) : (<div className="cb-buttons-fee-policy">
                    <ExportButton
                        width="540px"
                        height="40px"
                        hover="#F9FAFB"
                        onClick={onClose}
                        border="1px solid #D0D5DD"
                    >
                        Cancel
                    </ExportButton>
                    <Button
                        loading={loading}
                        onClick={submit}
                        width="540px"
                        height="40px"
                        padding="0px 20px !important"
                        hover="#5D43A5"
                    >
                        Submit
                    </Button>
                </div>)}
            </div>
        </div>
    );
};

export default Collection;
