import React from 'react';
import Header from '../../../components/Header/Header';
import Heading from '../../../components/Heading/Heading';
import Sidebar from '../../../components/Sidebar/Sidebar';
import GeneralView from '../View Campus/Subcomponents/GeneralView';
import { useParams } from 'react-router-dom';
import useDetailApi from '../../../../hooks/useDetailApi';
import Backarrow from '../../../../images/back-arrow.svg';
import MediaQuery from 'react-responsive';
import MobileHeader from '../../../components/Sidebar/Mobile Design/Mobile Header/MobileHeader';

const ViewDepartment = () => {
    const { id } = useParams();
    const { loading, item } = useDetailApi(
        'AcademicStructure/AcademicYear',
        id,
    );

    const fields = [
        {
            type: 'select',
            field: 'CampusName',
            label: 'Campus Name',
            description: 'This will be displayed on your profile.',
        },
        {
            type: 'text',
            field: 'Name',
            label: 'Academic year name',
            description: 'This will be displayed on your profile.',
            estric: '*',
        },
        {
            type: 'date',
            field: 'StartDate',
            label: 'Start Date',
            description: 'This will be displayed on your profile.',
            estric: '*',
        },
        {
            type: 'date',
            field: 'EndDate',
            label: 'End Date',
            description: 'This will be displayed on your profile.',
            estric: '*',
        },
    ];
    return (
        <div className="main-wrapper">
            <MediaQuery maxWidth={1023}>
                <MobileHeader/>
            </MediaQuery>
            <Sidebar />

            <div className="screen">
                {/* <Header
                    homelink="/dashboard"
                    breadcrumb="Academic Years"
                    breadcrumbs="View Academic Year"
                    innerpath="/academic-year"
                /> */}
                <div className='back-button'>
                <Heading
                    title="Academic Year Details"
                    description="View all details of the academic year below"
                    linktext="Back to Academic Years"
                    icon={Backarrow}
                    href="/academic-year"
                    width="auto"
                    height="44px"
                    backgroundcolor="none"
                    color="#5D43A5"
                    fontsize="16px"
                    hover="#F9F5FF"
                    padding="0px 20px !important"
                    className="view-back-button"
                />
                </div>
                {loading ? (
                    <div> Loading... </div>
                ) : (
                    <>
                        <GeneralView campus={item} fields={fields} />
                    </>
                )}
            </div>
        </div>
    );
};

export default ViewDepartment;
