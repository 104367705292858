import React, {useState} from 'react';
import GenericProfileForm from '../../../../Form/GenericProfileForm';

const AddProfessional = ({onClose, id, scholarId}) => {
    const initialValues = {
        Headline: '',
        OrganizationName: '',
        JobStatus: false,
        StartDate: null,
        EndDate: null,
        CreatedBy: null,
        IsDeleted: false,
        UserProfile: scholarId,
    };

    const [showEndDate, setShowEndDate] = useState(true);

    const fields = [
        {
            type: 'text',
            field: 'OrganizationName',
            label: 'Organization Name',
            description: 'This will be displayed on your profile.',
            estric: '*',
        },
        {
            type: 'text',
            field: 'DepartmentName',
            label: 'Department',
            description: 'This will be displayed on your profile.',
            required: false
        },
        {
            type: 'text',
            field: 'Headline',
            label: 'Designation',
            description: 'This will be displayed on your profile.',
            estric: '*',
        },
        {
            type: 'select',
            field: 'ExperienceType',
            label: 'Experience Type',
            description: 'This will be displayed on your profile.',
            estric: '*',
            options: {
                type: 'enum',
                value: 'ExperienceType',
            },
        },
        {
            type: 'date',
            field: 'StartDate',
            label: 'Start Date',
            description: 'This will be displayed on your profile.',
            required: true,
            estric: '*',
            color: '#c1c1c1',
        },
        {
            type: 'date',
            field: 'EndDate',
            label: 'End Date',
            description: 'This will be displayed on your profile.',
            estric: '*',
            required: showEndDate,
            hide: !showEndDate,
        },
        {
            type: 'switch',
            field: 'JobStatus',
            label: 'Still Working',
            description: 'This will be displayed on your profile.',
            estric: '*',
            required: false,
            onChange: (value) => {
                setShowEndDate(!value)
            }
        },
    ];


    return (
        <div className="userprofile-modal">
            <div className="modal-box">
                <div className="modal-heading">
                    <h5>{id ? 'Edit':'Add'} Experience</h5>
                    <span>{id ? 'Edit':'Add'} your experience details here</span>
                </div>
                <img
                    className="modal-cross-icon"
                    onClick={onClose}
                    src="/images/cross.svg"
                    alt={'Close Modal'}
                />
            </div>

            <GenericProfileForm
                fields={fields}
                id={id}
                initialValuesUpdated={values => {
                    setShowEndDate(!values.JobStatus)
                }}
                initialValues={initialValues}
                onClose={onClose}
                endpoint={'ProfessionInfo'}
            />
        </div>
    );
};

export default AddProfessional;
