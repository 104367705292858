import React from 'react';
import Header from '../../../components/Header/Header';
import Heading from '../../../components/Heading/Heading';
import Sidebar from '../../../components/Sidebar/Sidebar';
import GeneralView from '../View Campus/Subcomponents/GeneralView';
import {useParams} from 'react-router-dom';
import useDetailApi from '../../../../hooks/useDetailApi';
import Backarrow from '../../../../images/back-arrow.svg';
import MediaQuery from 'react-responsive';
import MobileHeader from '../../../components/Sidebar/Mobile Design/Mobile Header/MobileHeader';
import Api from "../../../../api/api";

const ViewDepartment = () => {
    const {id} = useParams();
    const {loading, item} = useDetailApi('Users/Scholar', id);

    const fields = [
        {
            type: 'file',
            fileType: 'image/*',
            field: 'ProfilePhoto',
            label: 'Image',
            required: true,
            fullWidth: true,
            render: (item) => {
                if (!item.ProfilePhoto) {
                    return '-';
                }
                return <img src={`${Api.api_url}/uploads/${item.ProfilePhoto}`} alt="image" width={100} height={100}/>;
            }
        },
        {
            type: 'select',
            field: 'CampusName',
            label: 'Campus',
            description: 'This will be displayed on your profile.',
            estric: '*',
            options: {
                type: 'dynamic',
                endpoint: 'Infrastructure/Campus',
                paged: true,
                valueKey: 'Id',
                labelKey: 'DisplayName',
            },
        },
        {
            type: 'select',
            field: 'ClusterName',
            label: 'Registry',
            description: 'This will be displayed on your profile.',
            estric: '*',
            options: {
                type: 'dynamic',
                endpoint: 'Infrastructure/Cluster',
                paged: true,
                valueKey: 'Id',
                labelKey: 'Name',
                optionsFilterCallback: (cluster, formValue) => {
                    return cluster.CampusId === formValue.CampusId;
                },
            },
        },
        {
            type: 'select',
            field: 'ResearchCenterName',
            label: 'Discipline',
            description: 'This will be displayed on your profile.',
            estric: '*',
            options: {
                type: 'dynamic',
                endpoint: 'Infrastructure/ResearchCenter',
                paged: true,
                valueKey: 'Id',
                labelKey: 'Name',
                optionsFilterCallback: (cluster, formValue) => {
                    return cluster.ClusterId === formValue.ClusterId;
                },
            },
        },
        {
            type: 'select',
            field: 'BatchName',
            label: 'Batch',
            description: 'This will be displayed on your profile.',
            estric: '*',
            options: {
                type: 'dynamic',
                endpoint: 'AcademicStructure/Batch',
                paged: true,
                valueKey: 'Id',
                labelKey: 'Name',
                optionsFilterCallback: (cluster, formValue) => {
                    return (
                        cluster.ResearchCenterId === formValue.ResearchCenterId
                    );
                },
            },
        },
        {
            type: 'select',
            field: 'DisciplineName',
            label: 'Specialization',
            description: 'This will be displayed on your profile.',
            estric: '*',
            options: {
                type: 'dynamic',
                endpoint: 'AcademicStructure/Discipline',
                paged: true,
                valueKey: 'Id',
                labelKey: 'Name',
                optionsFilterCallback: (cluster, formValue) => {
                    return cluster.ProgramId === formValue.ProgramId;
                },
            },
            onChange: (value) => {
            },
        },
        {
            type: 'text',
            field: 'UniversityRollNo',
            label: 'University Roll No',
            description: 'This will be displayed on your profile.',
            estric: '*',
        },
        {
            type: 'select',
            field: 'Honorifics',
            label: 'Title',
            description: 'This will be displayed on your profile.',
            estric: '*',
            options: {
                type: 'enum',
                value: 'Honorifics',
            },
        },

        {
            type: 'text',
            field: 'FirstName',
            label: 'First Name',
            description: 'This will be displayed on your profile.',
            estric: '*',
        },
        {
            type: 'text',
            field: 'MiddleName',
            label: 'Middle Name',
            description: 'This will be displayed on your profile.',
            estric: '*',
        },
        {
            type: 'text',
            field: 'LastName',
            label: 'Last Name',
            description: 'This will be displayed on your profile.',
            estric: '*',
        },
        {
            type: 'select',
            field: 'Gender',
            label: 'Gender',
            description: 'This will be displayed on your profile.',
            estric: '*',
            options: {
                type: 'enum',
                value: 'Gender',
            },
        },
        {
            type: 'text',
            field: 'Email',
            label: 'Email',
            description: 'This will be displayed on your profile.',
            estric: '*',
        },
        {
            type: 'text',
            field: 'PhoneNumber',
            label: 'Phone Number',
            description: 'This will be displayed on your profile.',
            estric: '*',
        },
        {
            type: 'text',
            field: 'MotherName',
            label: 'Mother Name',
            description: 'This will be displayed on your profile.',
            estric: '*',
        },
        {
            type: 'text',
            field: 'FatherName',
            label: 'Father Name',
            description: 'This will be displayed on your profile.',
            estric: '*',
        },
        {
            type: 'date',
            field: 'BirthDate',
            label: 'Date of Birth',
            description: 'This will be displayed on your profile.',
            estric: '*',
        },
        {
            type: 'select',
            field: 'Domicile',
            label: 'Domicile',
            description: 'This will be displayed on your profile.',
            estric: '*',
            options: {
                type: 'enum',
                value: 'Domicile',
            },
        },
        {
            type: 'select',
            field: 'Category',
            label: 'Category',
            description: 'This will be displayed on your profile.',
            estric: '*',
            options: {
                type: 'enum',
                value: 'Category',
            },
        },
        {
            type: 'select',
            field: 'Quota',
            label: 'Quota',
            description: 'This will be displayed on your profile.',
            estric: '*',
            options: {
                type: 'enum',
                value: 'Quota',
            },
        },
        {
            type: 'text',
            field: 'City',
            label: 'City',
            description: 'This will be displayed on your profile.',
            estric: '*',
        },
        {
            type: 'text',
            field: 'Nationality',
            label: 'Nationality',
            description: 'This will be displayed on your profile.',
            estric: '*',
        },
        {
            type: 'text',
            field: 'PanCardNumber',
            label: 'PAN Card Number',
            description: 'This will be displayed on your profile.',
            estric: '*',
        },
        {
            type: 'text',
            field: 'AadharCardNumber',
            label: 'Aadhar Card Number',
            description: 'This will be displayed on your profile.',
            estric: '*',
        },
        {
            type: 'text',
            field: 'PassportNumber',
            label: 'Passport Number',
            description: 'This will be displayed on your profile.',
            estric: '*',
        },

        {
            type: 'textarea',
            field: 'CurrentAddress',
            label: 'Current Address',
            description: 'This will be displayed on your profile.',
            estric: '*',
        },
        {
            type: 'textarea',
            field: 'PermanentAddress',
            label: 'Permanent Address',
            description: 'This will be displayed on your profile.',
            estric: '*',
        },

        {
            type: 'text',
            field: 'EnrolmentType',
            label: 'Enrolment Type',
        },
        {
            type: 'text',
            field: 'DateOfAdmission',
            label: 'Date Of Admission',
        },
        {
            type: 'text',
            field: 'AcceptanceLetter',
            label: 'Acceptance / Reference Number',
        },
        {
            type: 'text',
            field: 'ScholarStatus',
            label: 'Scholar Status',
        }
    ];


    const resendLoginCredentials = ()=>{
        Api.post(`/Users/Scholar/ResendEmail/${id}`).then((res)=>{
        alert('Login credentials successfully sent.')
        });
    };
    return (
        <div className="main-wrapper">
            <MediaQuery maxWidth={1023}>
                <MobileHeader/>
            </MediaQuery>
            <Sidebar/>

            <div className="screen">
                {/* <Header
                    homelink="/dashboard"
                    breadcrumb="Scholars"
                    breadcrumbs="View Scholar"
                    innerpath="/scholar"
                /> */}
                <Heading
                    title="Scholar Details"
                    description="View your personal details and profile photo here"
                    link="(Resend login credentials to scholar)"
                    onLinkClick={resendLoginCredentials}
                    linktext="Back to Scholars"
                    icon={Backarrow}
                    href="/scholar"
                    width="auto"
                    height="44px"
                    backgroundcolor="none"
                    color="#5D43A5"
                    fontsize="16px"
                    hover="#F9F5FF"
                    padding="0px 20px !important"
                    className="view-back-button"
                />
                {loading ? (
                    <div> Loading... </div>
                ) : (
                    <>
                        <GeneralView campus={item} fields={fields}/>
                    </>
                )}
            </div>
        </div>
    );
};

export default ViewDepartment;
