import React, { useRef } from 'react';
import Upload from '../../../images/upload.svg';
import Api from '../../../api/api';

const UploadDragDrop =({ onChange, fileType, className } ) => {
    const ref = useRef(null);
    const handleChange = (event) => {
        const fileUploaded = event.target.files[0];
        const data = new FormData();
        data.append('File', fileUploaded);

        Api.post('/Files/FileUpload/uploadFiles', data)
            .then((response) => {
                onChange(response.FileName);
            })
            .catch(console.log)
            .finally(() => {
                ref.current.value = '';
            });
    };
    return (
        <div className="upload-drag-drop">
            <input
                type="file"
                ref={ref}
                accept={fileType}
                style={{ display: 'none' }}
                onChange={handleChange}
            />
            <div
                className='file-upload-admin'
                onClick={() => {
                    ref.current.click();
                }}
            >
                <div className="upload">
                    <img className="upload-image" src={Upload} alt={'Upload'} />
                    <div className="drag-text">
                        Click to upload
                        <span>
                            {' '}
                            {/* or drag and drop SVG, PNG, JPG or GIF
                            (max.800x400px) */}
                        </span>
                    </div>
                </div>
            </div>
        </div>
        
    );
};

export default UploadDragDrop;
