import React from 'react';
import LoginRight from './components/subcomponents/LoginRight';
import ForgotLeft from './components/subcomponents/ForgotLeft';

const Login = () => {
    return (
        <div className="auth-screen">
            <ForgotLeft />
            <LoginRight />
        </div>
    );
};

export default Login;
