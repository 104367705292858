import React, {useEffect, useRef, useState} from 'react';
import GenericForm from '../../../../../../../components/Form/GenericForm';
import Api from '../../../../../../../../api/api';
import {FixedPlaceholderInput} from '../../../../../../../components/Inputs/FixedPlaceholderInput';
import {FixedInput} from '../../../../../../../components/Inputs/FixedInput';
import {isArray} from 'lodash';
import formatNumber from '../../../../../../../../helpers/formatNumber';
import qs from "qs";
import {useParams} from "react-router-dom";

const RenderMilestones = ({researchCenterId, handleChange, initialData, errors, isEdit}) => {
    const [milestones, setMilestones] = useState([]);
    const [milestoneFees, setMilestoneFees] = useState([]);

    useEffect(() => {
        if(isEdit){
            return;
        }
        setMilestoneFees(
            milestones.map((milestone) => {
                return {
                    MilestoneId: milestone.id,
                    MilestoneFees: '',
                    MilestoneTitle: milestone.FeeHeader,
                };
            }),
        );
    }, [milestones]);

    useEffect(() => {
        if (!researchCenterId) {
            setMilestones([]);
            return;
        }
        Api.get('/AcademicStructure/Milestone/getEntities', {
            ResearchCenterId: researchCenterId,
            ActiveForPolicy: '1'
        }).then((response) => {
            setMilestones(response.Data);
        });
    }, [researchCenterId]);

    useEffect(() => {
        handleChange({
            target: {name: 'MilestoneFees', value: milestoneFees},
        });
    }, [milestoneFees]);

    const convertServerToLocal = (MilestoneFee) => {
        console.log({MilestoneFee})
        return {
            MilestoneId: MilestoneFee.Milestone.id,
            MilestoneFees: MilestoneFee.FeeAmount,
            MilestoneTitle: MilestoneFee.Milestone.FeeHeader,
        };
    };

    useEffect(() => {
        if (initialData && isArray(initialData.MilestoneFees)) {
            setMilestoneFees(
                initialData.MilestoneFees.map(convertServerToLocal),
            );
        }
    }, [initialData]);

    let total = milestoneFees.reduce((p, c) => {
        return p + parseFloat(c.MilestoneFees);
    }, 0);

    return (
        <>
            <div className="add-fee-policy-field">

                <>
                    <div className="fee-policy-heading">
                        <h4>Choose Deliverable and Amount</h4>
                        <span>
                                Select deliverable and enter amount for each
                                deliverable
                            </span>
                    </div>
                </>
                <div className="choose-milestone-amount">

                    {milestoneFees.length === 0 && (
                        <>
                            <div className="add-fee-policy-input">
                                <div className="milestone-fixed-input">
                                    No Deliverables Found
                                </div>
                            </div>
                        </>
                    )}
                    {milestoneFees.length !== 0 && (
                        <>
                            <div className="add-fee-policy-input">
                                <div className="milestone-fixed-input">
                                    Total
                                </div>
                                <div className="enter-fee-amount">
                                    ₹{formatNumber(total || 0)}
                                </div>
                            </div>
                        </>
                    )}

                    {milestoneFees.map((milestoneFee, index) => {
                        return (
                            <div className="add-fee-policy-input">
                                <div className="milestone-fixed-input">
                                    <FixedInput
                                        fixedtext={milestoneFee.MilestoneTitle}
                                        width="288px"
                                        margin-right="40px"
                                    />

                                </div>
                                <div className="enter-fee-amount">
                                    <FixedPlaceholderInput
                                        error={errors?.[index]?.MilestoneFees}
                                        width="50px"
                                        fixedtext="₹"
                                        value={milestoneFee.MilestoneFees}
                                        onChange={(e) => {
                                            let mfs = [...milestoneFees];
                                            mfs[index].MilestoneFees =
                                                e.target.value;
                                            setMilestoneFees(mfs);
                                        }}
                                    />
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div>
        </>
    );
};

const AddFeePolicyContent = () => {
    let filters = qs.parse(window.location.search, {ignoreQueryPrefix: true});

    const initialValues = {
        Name: '',
        Code: '',
        CampusId: filters.CampusId || '',
        ClusterId: filters.ClusterId || '',
        ResearchCenterId: filters.ResearchCenterId || '',
        IsDefault: true,
        CreatedBy: null,
        MilestoneFees: [],
    };

    const formRef = useRef(null);
    const [researchCenterId, setResearchCenterId] = useState(filters.ResearchCenterId || '');
    const fields = [
        {
            type: 'select',
            field: 'CampusId',
            label: 'Select Campus',
            description: 'Fee will be added to the selected campus',
            estric: '*',
            options: {
                type: 'dynamic',
                endpoint: 'Infrastructure/Campus',
                paged: true,
                valueKey: 'Id',
                labelKey: 'DisplayName',
            },
            onChange: (value) => {
                formRef.current.updateData({
                    ClusterId: '',
                    ResearchCenterId: '',
                });
            },
            shouldUpdateQueryParams: true
        },
        {
            type: 'select',
            field: 'ClusterId',
            label: 'Select Registry',
            description: 'Fee will be added to the selected registry',
            estric: '*',
            options: {
                type: 'dynamic',
                endpoint: 'Infrastructure/Cluster',
                paged: true,
                valueKey: 'Id',
                labelKey: 'Name',
                optionsFilterCallback: (cluster, formValue) => {
                    return cluster.CampusId === formValue.CampusId;
                },
            },
            onChange: (value) => {
                formRef.current.updateData({
                    ResearchCenterId: '',
                });
            },
            shouldUpdateQueryParams: true
        },
        {
            type: 'select',
            field: 'ResearchCenterId',
            label: 'Select Discipline',
            description: 'Fee will be added to the selected Discipline',
            estric: '*',
            options: {
                type: 'dynamic',
                endpoint: 'Infrastructure/ResearchCenter',
                paged: true,
                valueKey: 'Id',
                labelKey: 'Name',
                optionsFilterCallback: (cluster, formValue) => {
                    return cluster.ClusterId === formValue.ClusterId;
                },
            },
            onChange: (value) => {
                setResearchCenterId(value);
            },
            shouldUpdateQueryParams: true
        },
        {
            type: 'text',
            field: 'Name',
            label: 'Fee Policy',
            description: 'Name of the fee policy',
            estric: '*',
            options: {
                type: 'dynamic',
                endpoint: 'Infrastructure/Campus',
                paged: true,
                valueKey: 'Id',
                labelKey: 'DisplayName',
            },
        },
        {
            type: 'text',
            field: 'Code',
            label: 'Fee Code',
            description: 'Unique identifier of the fee',
            estric: '*',
        },
    ];

    const { id } = useParams();

    return (
        <GenericForm
            ref={formRef}
            fields={fields}
            initialValues={initialValues}
            endpoint={'FeeStructure/FeePolicy'}
            redirectTo={'/fee-policy'}
            RenderCustomFields={({handleChange, initialData, errors}) => {
                return (
                    <RenderMilestones
                        isEdit={!!id}
                        errors={errors}
                        researchCenterId={researchCenterId}
                        handleChange={handleChange}
                        initialData={initialData}
                    />
                );
            }}
            validateCustomFields={(values) => {
                let errors = {};
                values.MilestoneFees.forEach((mf, index) => {
                    if (!parseFloat(mf.MilestoneFees)) {
                        errors[index] = {
                            MilestoneFees: 'Please enter a valid amount'
                        };
                    }
                });
                return {success: errors.length === 0, errors};
            }}
            initialValuesUpdated={(initialValues) => {
                if (initialValues.ResearchCenterId) {
                    setResearchCenterId(initialValues.ResearchCenterId);
                }
            }}
        />
    );
};

export default AddFeePolicyContent;
