import React, {useState} from 'react';
import {Link} from 'react-router-dom';
import {LinkButton} from '../Buttons/LinkButton';
import SelectInput from '../Inputs/SelectInput';
import {CSVLink} from 'react-csv';
import usePermissions from '../../../hooks/userPermissions';
import qs from "qs";

const Heading = (props) => {
    const {next} = qs.parse(window.location.search, {ignoreQueryPrefix: true});

    const [selectedOption, setSelectedOption] = useState(null);
    const [exportData, setExportData] = useState([]);
    const {hasPermission} = usePermissions();
    const shouldAdd =
        props.linktext &&
        (props.linktext.startsWith('Add') ||
            props.linktext.startsWith('Edit') ||
            props.linktext.startsWith('Back') ||
            props.linktext.startsWith('Download') ||
            props.linktext.startsWith('Import')) &&
        (!props.permissionPrefix ||
            hasPermission(props.permissionPrefix + '_Create'));
    return (
        <div className="dashboard-heading">
            <div className="dash-description">
                <h1>{props.title}</h1>
                {props.description && <span>{props.description}</span>}
                <span className="dash-description-text">{props.para}</span>
                {props.link && <div className="resend-option">
                    <p onClick={() => {
                        if (props.onLinkClick) {
                            props.onLinkClick();
                        }
                    }}>{props.link}</p>
                </div>
                }
            </div>

            <div className="heading-buttons">
                {props.importtext && shouldAdd && (
                    <Link className="heading-link" to={props.importonClick}>
                        <img className="import-icon" src={props.importicon}/>
                        <img className="plus-icon" src={props.plusicon}/>
                        {props.importtext}
                    </Link>
                )}

                {props.linktext && shouldAdd && (
                    <LinkButton
                        className={props.className}
                        linktext={props.linktext}
                        icon={props.icon}
                        width={props.width}
                        height={props.height}
                        href={next || props.href}
                        backgroundcolor={props.backgroundcolor}
                        color={props.color}
                        hover={props.hover}
                        fontsize={props.fontsize}
                        padding={props.padding}
                        onClick={props.onClick}
                    />
                )}
                {props.exporticon && !props.onExport && (
                    <CSVLink
                        data={exportData}
                        asyncOnClick={true}
                        onClick={(event, done) => {
                            if (!props.tableRef.current) {
                                return;
                            }
                            let data = props.tableRef.current.exportData();
                            console.log({data});
                            setExportData(data);
                            done(true);
                        }}
                        filename={props.exportFileName || props.title}
                    >
                        <div
                            className={
                                props.importtext
                                    ? 'export-button'
                                    : 'export-button-link'
                            }
                        >
                            <img
                                className="export-icon"
                                src={props.exporticon}
                            />
                        </div>
                    </CSVLink>
                )}
                {props.exporticon && props.onExport && (

                    <div onClick={props.onExport}
                        className={
                            props.importtext
                                ? 'export-button'
                                : 'export-button-link'
                        }
                    >
                        <img
                            className="export-icon"
                            src={props.exporticon}
                        />
                    </div>)
                }
            </div>

            {props.options && (
                <div className="select-dropdown">
                    <SelectInput
                        defaultValue={selectedOption}
                        onChange={setSelectedOption}
                        options={props.options}
                    />
                </div>
            )}
        </div>
    );
};

export default Heading;
