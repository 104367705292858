import React from 'react';
import MediaQuery from 'react-responsive';
import ScholarHeader from './ScholarHeader';
import { Route, Routes } from 'react-router-dom';
import Personal from './Profile/Personal';
import Address from './Profile/Address';
import Documents from './Profile/Documents';
import Qualification from './Profile/Qualification';
import Projects from './Profile/Projects';
import Patents from './Profile/Patents';
import Awards from './Profile/Awards';
import SocialSites from './Profile/SocialSites';
import Publications from './Profile/Publications';
import Experience from './Profile/Experience';
import useProfile from '../../../hooks/useProfile';
import Project, {
    ProjectDetail,
} from '../Dashboard/Users/Scholars/Subcomponents/UserProfile/Projects/Project';
import ScholarNavHeader from './Scholar Sidebar/ScholarNavHeader';

const Home = ({ match }) => {
    const { profile } = useProfile();
    if (!profile.userProfileId) {
        return (
            <div className="topnav">
                <ScholarHeader active={'profile'} />
                Loading..
            </div>
        );
    }

    return (
        <div className="scholar-user-proifle-page">
                            <MediaQuery maxWidth={1023}>
                    <ScholarNavHeader/>
                </MediaQuery>
            <ScholarHeader active={'profile'} />
            <div className="scholar-personal-profile">
                <Routes>
                    <Route
                        path={`/personal`}
                        element={
                            <Personal
                                isScholar={true}
                                id={profile.userProfileId}
                                redirectTo={'/user/profile/personal'}
                            />
                        }
                    />
                    <Route
                        path="/address"
                        element={
                            <Address
                                id={profile.userProfileId}
                                redirectTo={'/user/profile/address'}
                            />
                        }
                    />
                    <Route
                        path="/documents"
                        element={<Documents id={profile.userProfileId} />}
                    />
                    <Route
                        path="/experience"
                        element={<Experience id={profile.userProfileId} />}
                    />
                    <Route
                        path="/qualifications"
                        element={<Qualification id={profile.userProfileId} />}
                    />
                    <Route
                        path="/projects"
                        element={<Projects id={profile.userProfileId} />}
                    />
                    <Route
                        path="/patents"
                        element={<Patents id={profile.userProfileId} />}
                    />
                    <Route
                        path="/awards"
                        element={<Awards id={profile.userProfileId} />}
                    />
                    <Route
                        path="/social"
                        element={<SocialSites id={profile.userProfileId} />}
                    />
                    <Route
                        path="/publications"
                        element={<Publications id={profile.userProfileId} />}
                    />
                </Routes>
            </div>
            <div className="topnav">
                <ScholarHeader active={'profile'} />
                Loading..
            </div>
        </div>
    );
};

export default Home;
