import React, {useState} from 'react';
import {Importer, ImporterField} from 'react-csv-importer';
import 'react-csv-importer/dist/index.css';
import Api from '../../../api/api';
import HeaderFilters from '../Filters/HeaderFilters';
import {Button} from "../Buttons/Button";
import { Modal } from 'react-bootstrap';
import AlertModal from '../Modals/Alert Modal/AlertModal';

const UploadFile = ({
                        filters,
                        setFilters,
                        fields,
                        resource,
                        redirectTo,
                        showImport,
                        availableFilter,
                        sampleFile,
                    }) => {
    const [excelFields, setExcelFields] = useState([]);
    const [importedRows, setImportedRows] = useState([]);
    const [alertMessage, setAlertMessage] = useState('');
    const [showAlertModal, setShowAlertModal] = useState(false);

    const [importing, setImporting] = useState(false);
    const [errors, setErrors] = useState([]);

    const startImport = () => {
        setImporting(true);
        Api.post(
            `${resource}/postList`,
            importedRows.map((row) => {
                return {...row, ...filters};
            }),
        )
            .then((response) => {
                alert('Data imported successfully');
                window.location.href = redirectTo;
            })
            .catch((e) => {
                if (Array.isArray(e)) {
                    setErrors(e);
                    return;
                }
                if (e && e['For User']) {
                    let message = `Error in record ${e['For User']}: `;
                    Object.keys(e.Fields).forEach((k) => {
                        message += 'Field: ' + k + ', Error: ' + e.Fields[k];
                    });
                    console.log('message is');
                    console.log(message);
                    setAlertMessage(message);
                    setShowAlertModal(true);
                    alert(message);
                } else if (e && e.Message) {
                    alert(e.Message);
                } else {
                    alert('An error has occurred');
                }
                window.location.reload();
            }).finally(() => {
            setImporting(false);
        });
    };

    if (errors.length > 0) {
        return <div>
            Following errors has occurred:
            {
                errors.map((e, i) => {
                    return <div key={i} style={{marginTop: 10}}>
                        Row {e.Row}:
                        {
                            Object.keys(e.Fields).map((k, i) => {
                                return <div>
                                    Field: {k}, Error: {e.Fields[k]}
                                </div>
                            })
                        }
                    </div>
                })
            }
            <Button onClick={() => {
                setImportedRows([])
                setErrors([])
            }}>Try Again</Button>
        </div>
    }

    if (importing) {
        return <div className="upload-file">Importing...</div>;
    }

    return (
        <div className="upload-file">
            <div style={{marginBottom: 30}}>
                <HeaderFilters
                    showAll={false}
                    filters={filters}
                    availableFilter={availableFilter}
                    setFilters={setFilters}
                />
            </div>
            {showImport && (
                <>
                    <Importer
                        chunkSize={100000} // optional, internal parsing chunk size in bytes
                        assumeNoHeaders={false} // optional, keeps "data has headers" checkbox off by default
                        restartable={true} // optional, lets user choose to upload another file when import is complete
                        onStart={({file, fields}) => {
                            setExcelFields(fields);
                            // optional, invoked when user has mapped columns and started import
                        }}
                        processChunk={async (rows) => {
                            // required, receives a list of parsed objects based on defined fields and user column mapping;
                            // may be called several times if file is large
                            // (if this callback returns a promise, the widget will wait for it before parsing more data)
                            setImportedRows((v) => [...v, ...rows]);
                        }}
                        onComplete={({file, fields}) => {
                            startImport();
                        }}
                        onClose={() => {
                            // optional, invoked when import is done and user clicked "Finish"
                            // (if this is not specified, the widget lets the user upload another file)
                        }}
                    >
                        {fields.map((f) => {
                            return (
                                <ImporterField
                                    key={f.field}
                                    optional={f.estric !== '*'}
                                    name={f.field}
                                    label={f.label}
                                />
                            );
                        })}
                    </Importer>
                    <div className="upload-footer">
                        {sampleFile && (
                            <span
                                onClick={() => {
                                    window.location.href = sampleFile;
                                }}
                            >
                                Download sample csv
                            </span>
                        )}
                    </div>
                </>
            )}
            {showAlertModal && (
                <Modal
                    show={true}
                    onHide={() => setShowAlertModal(false)}
                    centered
                    className="assignfeeModal"
                >
                    <AlertModal errorMessage={alertMessage} />
                </Modal>
            )}
        </div>
    );
};

export default UploadFile;
