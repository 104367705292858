import React, { useEffect, useState } from 'react';
import { Button } from '../../../../../Buttons/Button';
import { ExportButton } from '../../../../../Buttons/ExportButton';
import { FixedInput } from '../../../../../Inputs/FixedInput';
import { FixedInputPlaceholder } from '../../../../../Inputs/FixedInputPlaceholder';
import SelectInput from '../../../../../Inputs/SelectInput';
import MediaQuery from 'react-responsive';
import FeeDiscountSelectDropdown from './Subcomponent/Mobile Design/Fee Discount/FeeDiscountSelectDropdown';
import Api from '../../../../../../../api/api';
import formatNumber from '../../../../../../../helpers/formatNumber';
import { SimpleInput } from '../../../../../Inputs/SimpleInput';
import FeeWaiver from './Subcomponent/Mobile Design/Fee Waiver/FeeWaiver';
import { TextAreaInput } from '../../../../../Inputs/TextAreaInput';
import Loader from 'react-js-loader';
import {Modal} from "react-bootstrap";
import AlertModal from "../../../../Alert Modal/AlertModal";

const amount = [
    { value: 'amount', label: 'Amount' },
    // { value: '% percent', label: '% Percent' },
];

const FeeDiscount = ({ onClose, id, paid, scholarId }) => {
    const [condition] = useState(false);
    const [scholarFees, setScholarFees] = useState([]);
    const [discounts, setDiscounts] = useState([]);
    const [comment, setComment] = useState('');
    const [loading, setLoading] = useState(false);
    const [showAlertModal, setShowAlertModal] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');

    useEffect(() => {
        setLoading(true);
        Api.get(`/FeeStructure/ScholarFee/GetEntities/${id}`)
            .then((response) => {
                let left = paid;
                let sf = response.ScholarFees.sort((ScholarFee1, ScholarFee2) => {
                    return ScholarFee1.Milestone.MilestoneOrder - ScholarFee2.Milestone.MilestoneOrder;
                }).map((ScholarFee) => {
                    let paidAmount =
                        left > ScholarFee.PayableAmount
                            ? ScholarFee.PayableAmount
                            : left > 0
                            ? left
                            : 0;
                    let out = {
                        ...ScholarFee,
                        Paid: paidAmount === ScholarFee.PayableAmount,
                    };
                    left -= ScholarFee.PayableAmount;
                    return out;
                });
                setScholarFees(sf);
                setComment(response.Comment);
            })
            .catch()
            .finally(() => setLoading(false));
    }, [paid, id]);

    useEffect(() => {
        setDiscounts(
            scholarFees.map((scholarFee) => {
                let discount =
                    scholarFee.PolicyFeeAmount - scholarFee.PayableAmount;
                return {
                    ScholarFeeId: scholarFee.id,
                    MilestoneTitle: scholarFee.Milestone.Code,
                    PolicyFeeAmount: scholarFee.PolicyFeeAmount,
                    PayableAmount: scholarFee.PayableAmount,
                    Discount: discount,
                    Paid: scholarFee.Paid,
                };
            }),
        );
    }, [scholarFees]);

    const submit = () => {
        Api.post('/FeeStructure/ScholarFee/ApplyDiscount', {
            ScholarFees: discounts.map((d) => {
                return {
                    ScholarFeeId: d.ScholarFeeId,
                    Discount: null,
                    PayableAmount: d.PayableAmount,
                };
            }),
            Comment: comment,
            ScholarId: scholarId,
        })
            .then(() => {
                onClose();
            })
            .catch(e=>{
                setAlertMessage(e.Message);
                setShowAlertModal(true);
            });
    };

    if (loading) {
        return (
            <div className="fee-policy-modal">
                <div className="modal-box">
                    <div className="modal-heading">
                        <h5>Fee Waiver</h5>
                        <span>Enter the fee waiver</span>
                    </div>
                    <img
                        className="modal-cross-icon"
                        onClick={onClose}
                        src="/images/cross.svg"
                    />
                </div>
                <div>Please wait...</div>
            </div>
        );
    }

    return (
        <div className="fee-policy-modal">
            <div className="modal-box">
                <div className="modal-heading">
                    <h5>Fee Waiver</h5>
                    <span>Enter the fee waiver</span>
                </div>
                <img
                    className="modal-cross-icon"
                    onClick={onClose}
                    src="/images/cross.svg"
                />
            </div>
            {/* <div>
                <MediaQuery maxWidth={1023}>
                    {!condition && <FeeWaiver/>}
                </MediaQuery>
            </div> */}
            <div className="fee-discount-inputs">
                <div className="fixed-milestone-input">
                    <div className="fee-discount-headings">Deliverables</div>
                </div>
                <div className="fixed-amount-input">
                    <div className="fee-discount-headings">Amounts</div>
                </div>
                <div className="discount-type-input">
                    <div className="fee-discount-headings">Fee Waiver Type</div>
                </div>
                <div className="discount-input">
                    <div className="fee-discount-headings">Fee Waiver</div>
                </div>
                <div className="after-discount-input">
                    <div className="fee-discount-headings">
                        After Fee Waiver
                    </div>
                </div>
            </div>
            {discounts.map((discount, index) => {
                return (
                    <>
                        <div className="fee-discount-inputs">
                            <div className="fixed-milestone-input">
                                <MediaQuery maxWidth={1023}>
                                    <h6 className="fee-wavier-mob-input-headings">
                                        Deliverables
                                    </h6>
                                    <FixedInput
                                        fixedtext={discount.MilestoneTitle}
                                    />
                                </MediaQuery>
                                <div className="fee-wavier-mob-input">
                                    <FixedInput
                                        fixedtext={discount.MilestoneTitle}
                                    />
                                </div>
                            </div>

                            <div className="fixed-amount-input">
                                <MediaQuery maxWidth={1023}>
                                    <h6 className="fee-wavier-mob-amount-input-heading">
                                        Amounts
                                    </h6>
                                    <FixedInputPlaceholder
                                        fixedinputtext={formatNumber(
                                            discount.PolicyFeeAmount,
                                        )}
                                        fixedtext="₹"
                                        width="40px"
                                    />
                                </MediaQuery>
                                <div className="fee-wavier-mob-input">
                                    <FixedInputPlaceholder
                                        fixedinputtext={formatNumber(
                                            discount.PolicyFeeAmount,
                                        )}
                                        fixedtext="₹"
                                        width="40px"
                                    />
                                </div>
                            </div>
                            <div className="discount-type-input">
                                <MediaQuery maxWidth={1023}>
                                    <h6 className="fee-wavier-mob-input-heading">
                                        Fee Waiver Type
                                    </h6>
                                    <SelectInput
                                        options={amount}
                                        placeholder="Select fee waiver type"
                                        value={'amount'}
                                    />
                                </MediaQuery>
                                <div className="fee-wavier-mob-input">
                                    <SelectInput
                                        options={amount}
                                        placeholder="Select fee waiver type"
                                        value={'amount'}
                                    />
                                </div>
                            </div>
                            <div className="discount-type-input">
                                <MediaQuery maxWidth={1023}>
                                    <h6 className="fee-wavier-mob-input-heading">
                                        Fee Waiver
                                    </h6>
                                    <SimpleInput
                                        onChange={(e) => {
                                            let d = [...discounts];
                                            d[index].Discount = e.target.value;
                                            d[index].PayableAmount =
                                                d[index].PolicyFeeAmount -
                                                d[index].Discount;
                                            setDiscounts(d);
                                        }}
                                        placeholder="Enter fee waiver"
                                        value={discount.Discount}
                                    />
                                </MediaQuery>
                                <div className="fee-wavier-mob-input">
                                    <SimpleInput
                                        onChange={(e) => {
                                            let d = [...discounts];
                                            d[index].Discount = e.target.value;
                                            d[index].PayableAmount =
                                                d[index].PolicyFeeAmount -
                                                d[index].Discount;
                                            setDiscounts(d);
                                        }}
                                        placeholder="Enter fee waiver"
                                        value={discount.Discount}
                                    />
                                </div>
                            </div>
                            <div className="after-discount-input">
                                <MediaQuery maxWidth={1023}>
                                    <h6 className="fee-wavier-mob-input-heading">
                                        After Fee Waiver
                                    </h6>
                                    <FixedInputPlaceholder
                                        fixedinputtext={formatNumber(
                                            discount.PayableAmount,
                                        )}
                                        fixedtext="₹"
                                        width="40px"
                                    />
                                </MediaQuery>
                                <div className="fee-wavier-mob-input">
                                    <FixedInputPlaceholder
                                        fixedinputtext={formatNumber(
                                            discount.PayableAmount,
                                        )}
                                        fixedtext="₹"
                                        width="40px"
                                    />
                                </div>
                            </div>
                        </div>
                    </>
                );
            })}

            <div className="discount-footer-content">
                <div className="discount-footer">
                    <div className="total-heading">
                        <h4>Total amount</h4>
                    </div>
                    <div className="total-amount-heading">
                        <h4>
                            ₹
                            {formatNumber(
                                discounts.reduce((p, t) => {
                                    return p + t.PolicyFeeAmount;
                                }, 0),
                            )}
                        </h4>
                    </div>
                </div>
                <div className="after-discount-footer">
                    <div className="discount-footer">
                        <MediaQuery maxWidth={1023}>
                            <div className="total-heading">
                                <h4>Fee wavier</h4>
                            </div>
                        </MediaQuery>
                        <div className="total-amount-heading">
                            <h4>
                                ₹
                                {formatNumber(
                                    discounts.reduce((p, t) => {
                                        return (
                                            p + (parseFloat(t.Discount) || 0)
                                        );
                                    }, 0),
                                )}
                            </h4>
                        </div>
                    </div>

                    <div className="after-discount-footer">
                        <div className="discount-footer">
                            <MediaQuery maxWidth={1023}>
                                <div className="total-heading">
                                    <h4>After Fee wavier</h4>
                                </div>
                            </MediaQuery>
                            <div className="total-amount-heading">
                                <h4>
                                    ₹
                                    {formatNumber(
                                        discounts.reduce((p, t) => {
                                            return p + t.PayableAmount;
                                        }, 0),
                                    )}
                                </h4>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="discount-footer-content">
                <TextAreaInput
                    onChange={(e) => setComment(e.target.value)}
                    value={comment}
                    label={'Comment'}
                    className="comment-box"
                />
                </div>
            {/* <div className="bottom-line-discount"></div> */}
            <div className="cb-buttons-discount">
                <ExportButton
                    width="460px"
                    height="44px"
                    onClick={onClose}
                    hover="#f9fafb"
                    border="1px solid #d0d5dd"
                >
                    Cancel
                </ExportButton>
                <Button
                    onClick={submit}
                    width="460px"
                    height="44px"
                    hover="#5D43A5"
                    padding="0px 20px"
                >
                    Save
                </Button>
            </div>

                <Modal
                    show={showAlertModal}
                    onHide={() => setShowAlertModal(false)}
                    centered
                    className="assignfeeModal"
                >
                    <AlertModal onClose={()=> setShowAlertModal(false)} errorMessage={alertMessage} />
                </Modal>

        </div>
    );
};

export default FeeDiscount;
