import React from 'react';
import Sidebar from '../../../components/Sidebar/Sidebar';
import MediaQuery from 'react-responsive';
import MobileHeader from '../../../components/Sidebar/Mobile Design/Mobile Header/MobileHeader';
import AddRoleContent from './AddRoleContent';
import FormHeader from '../../../components/Header/FormHeader';
import FormHeading from '../../../components/Heading/FormHeading';
import { LinkButton } from '../../../components/Buttons/LinkButton';
import Backarrow from '../../../../images/back-arrow.svg';

const AddRole = () => {
    return (
        <div className="main-wrapper">
            <MediaQuery maxWidth={1023}>
                <MobileHeader />
            </MediaQuery>
            <MediaQuery minWidth={1024}>
                <Sidebar />
            </MediaQuery>
            <div className="screen">
                <div className="heading-button-mobile">
                    {/* <FormHeader
                        homelink="/dashboard"
                        breadcrumb="Roles"
                        breadcrumbs="Role"
                        innerpath="/dashboard"
                    /> */}
                    <FormHeading
                        title="Add Role"
                        description="Enter the following details to add Role"
                    />
                    <MediaQuery maxWidth={1023}>
                    <div className='back-button'>
                        <LinkButton
                            linktext="Back to Roles"
                            icon={Backarrow}
                            href="/roles"
                            width="204px"
                            height="44px"
                            backgroundcolor="none"
                            color="#5D43A5"
                            fontsize="16px"
                            hover="none"
                            border="none"
                            className="view-back-button"
                        />
                    </div>
                    </MediaQuery>
                    <AddRoleContent />
                </div>
            </div>
        </div>
    );
};

export default AddRole;
