import { useEffect, useState } from 'react';
import Api from '../api/api';

const useProfileDetailApi = (url, id) => {
    const [item, setItem] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (!id) {
            setLoading(false);
            return;
        }
        Api.get(`/UserProfile/${url}/getEntity/${id}`)
            .then((response) => {
                setItem(response);
            })
            .catch(() => {})
            .finally(() => {
                setLoading(false);
            });
    }, [id, url]);

    return { loading, item };
};

export default useProfileDetailApi;
