import React, {useEffect, useState} from 'react';
import Completed from '../../../../../images/deliverable-completed.svg';
import DeliverableDetailRightContent from './DeliverableDetailRightContent';
import useListApi from "../../../../../hooks/useListApi";

const DeliverableDetailProgressbar = ({id, profile}) => {
    const {rows, loading} = useListApi('AcademicStructure/Milestone', false, true);

    const [items, setItems] = useState([]);
    useEffect(() => {
        let i =(rows.sort(function (a, b) {
            let x = a.MilestoneOrder;
            let y = b.MilestoneOrder;
            return ((x < y) ? -1 : ((x > y) ? 1 : 0));
        }));

        if (i.length > 0) {
            setSelectedMilestone(i[0])
        }
        setItems(i);
    }, [rows])
    const [selectedMilestone, setSelectedMilestone] = useState(null);

    const [active, setActive] = useState(false);

    return (
        <div>
            <div className="scholar-heading">
                <div className="assign-deliverable-content">
                    <div className="scholar-progress-deliverable">
                        {items.map(milestone => {
                            return (
                                <div className="deliverable-left" onClick={() => {
                                    setSelectedMilestone(milestone)
                                }}>
                                    <div className="deliverable-progressbar-content">
                                        <div>
                                            {/* <img
                                                className="complete-status-icon"
                                                src={Completed}
                                                alt={Completed}
                                            /> */}

                                            <h4 className={`deliverable-status ${selectedMilestone == milestone && 'active'}`}>{milestone.Title}</h4>
                                        </div>
                                    </div>
                                    {/* <div className="deliverable-progressbar-content">
                                        <p>6</p>
                                    </div> */}
                                </div>
                            )
                        })
                        }
                    </div>
                    <DeliverableDetailRightContent profile={profile} milestone={selectedMilestone}/>
                </div>
            </div>
        </div>
    );
};

export default DeliverableDetailProgressbar;
