import React from 'react';
import { Button } from '../../Buttons/Button';
import Modaldelete from '../../../../images/promote-scholar.svg';

const PromoteScholar = ({onHide,onPromote, scholar}) => {
    return (
        <div>
            <div className="delete-modal">
                <div className="delete-modal-img">
                    <img src={Modaldelete} alt={'Modaldelete'} />
                    <img onClick={onHide} src="/images/cross.svg" />
                </div>
                <div className="delete-modal-heading">
                    <h5>Promote Scholar</h5>
                    <span>
                        Are you sure you want to promote "{scholar.FirstName} {scholar.LastName} - {scholar.UniversityRollNo}"?
                    </span>
                </div>

                <div className="modals-cb-buttons">
                    <Button onClick={onHide}
                        width="170px"
                        height="44px"
                        color="#1D2939"
                        border="1px solid #D0D5DD"
                        backgroundcolor="#ffffff"
                        fontweight="300"
                        hover="#F9FAFB"
                        marginright="12px"
                    >
                        No
                    </Button>

                    <Button
                        onClick={onPromote}
                        width="170px"
                        height="44px"
                        backgroundcolor="#7f56d9"
                        fontweight="300"
                        hover="#5d43a5"
                    >
                        Yes, promote
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default PromoteScholar;
