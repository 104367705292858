import React, { useState } from 'react';
import PhdCentralLogo from '../../../images/scholar-board-phd-logo.svg';
import { Link } from 'react-router-dom';
import Settings from '../../../images/scholar-settings.svg';
import Logout from '../../../images/scholar-logout.svg';
import FooterAvatar from '../../../images/footer-avatar.svg';
import useProfile from "../../../hooks/useProfile";
import getInitials from "../../../helpers/getInitials";

const ScholarHeader = ({active}) => {
    const {profile, loading} = useProfile();
    return (
        <div className="topnav">
            <div className="scholar-phd-logo">
                <div className="scholar-nav-left">
                <Link to="/user">
                    <img
                        className="scholar-phdpad-logo"
                        src={PhdCentralLogo}
                        alt={'PhdCentralLogo'}
                    />
                    </Link>
                    <div className="scholar-navigation">
                        <a className={active === 'home' ? 'active':''} href="/user">
                            Home
                        </a>
                        <a
                            className={active === 'profile' ? 'active':''}
                            href="/user/profile/personal"
                        >
                            My Profile
                        </a>
                        {/* <a
                            className={active === 'deliverable' ? 'active':''}
                            href="/user/deliverables"
                        >
                            Deliverables
                        </a> */}
                        {/* <a
                            className={active === 'submission' ? 'active':''}
                            href="/submission-home"
                        >
                            Submissions
                        </a> */}
                        <a className={active === 'fee' ? 'active':''} href="/user/fee">
                            Fee
                        </a>
                    </div>
                </div>
                <div className="scholar-nav-right">
                        <div className="right-nav">
                            <a href="/user/settings">
                            <img
                                className="right-nav-icons"
                                src={Settings}
                                alt={'Settings'}
                            />
                            </a>
                            </div>
                            <div className="right-nav">
                            <img
                                onClick={()=>{
                                    if(!window.confirm('Are you sure you want to logout?')){
                                        return;
                                    }
                                    localStorage.removeItem('token');
                                    window.location.href = '/login';
                                }}
                                className="right-nav-icons"
                                src={Logout}
                                alt={'Logout'}
                            />
                            <div className='outline'><span>{getInitials(`${profile.firstName} ${profile.lastName}`)}</span></div>
                        </div>
                </div>
            </div>
        </div>
    );
};
export default ScholarHeader;
