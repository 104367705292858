import React from 'react';
import Header from '../../../components/Header/Header';
import Heading from '../../../components/Heading/Heading';
import Sidebar from '../../../components/Sidebar/Sidebar';
import GeneralView from '../View Campus/Subcomponents/GeneralView';
import { useParams } from 'react-router-dom';
import useDetailApi from '../../../../hooks/useDetailApi';
import Backarrow from '../../../../images/back-arrow.svg';
import MediaQuery from 'react-responsive';
import MobileHeader from '../../../components/Sidebar/Mobile Design/Mobile Header/MobileHeader';

const ViewDepartment = () => {
    const { id } = useParams();
    const { loading, item } = useDetailApi('AcademicStructure/Milestone', id);

    const fields = [
        {
            type: 'select',
            field: 'CampusName',
            label: 'Campus',
            description: 'This will be displayed on your profile.',
            estric: '*',
            options: {
                type: 'dynamic',
                endpoint: 'Infrastructure/Campus',
                paged: true,
                valueKey: 'Id',
                labelKey: 'DisplayName',
            },
        },
        {
            type: 'select',
            field: 'ClusterName',
            label: 'Registry',
            description: 'This will be displayed on your profile.',
            estric: '*',
            options: {
                type: 'dynamic',
                endpoint: 'Infrastructure/Cluster',
                paged: true,
                valueKey: 'Id',
                labelKey: 'Name',
                optionsFilterCallback: (cluster, formValue) => {
                    return cluster.CampusId === formValue.CampusId;
                },
            },
        },
        {
            type: 'select',
            field: 'ResearchCenterName',
            label: 'Discipline',
            description: 'This will be displayed on your profile.',
            estric: '*',
            options: {
                type: 'dynamic',
                endpoint: 'Infrastructure/ResearchCenter',
                paged: true,
                valueKey: 'Id',
                labelKey: 'Name',
                optionsFilterCallback: (cluster, formValue) => {
                    return cluster.ClusterId === formValue.ClusterId;
                },
            },
        },
        {
            type: 'text',
            field: 'Title',
            label: 'Deliverable Name',
            description: 'This will be displayed on your profile.',
            estric: '*',
        },
        {
            type: 'text',
            field: 'Code',
            label: 'Deliverable Code',
            description: 'This will be displayed on your profile.',
            estric: '*',
        },
        {
            type: 'html',
            field: 'Description',
            label: 'Description',
            description: 'This will be displayed on your profile.',
            estric: '*',
        },
        {
            type: 'text',
            field: 'MilestoneOrder',
            label: 'Deliverable Order',
            description: 'This will be displayed on your profile.',
            estric: '*',
        },
        {
            type: 'select',
            field: 'ActiveForPolicy',
            label: 'Has Fees',
            description:
                'Should the deliverable be considered for fee',
            options: {
                type: 'static',
                options: [
                    {label: 'Yes', value: '1'},
                    {label: 'No', value: '0'},
                ]
            },
            estric: '*',
            required: false,
            render: (value) => {
                return value ? 'Yes' : 'No';
            }
        },
        {
            type: 'text',
            field: 'FeeHeader',
            label: 'Fee Head Label',
            description:
                'Label for the fee head',
            estric: '*'
        }
    ];
    return (
        <div className="main-wrapper">
            <MediaQuery maxWidth={1023}>
                <MobileHeader />
            </MediaQuery>
            <Sidebar />

            <div className="screen">
                {/* <Header
                    homelink="/dashboard"
                    breadcrumb="Deliverables"
                    breadcrumbs="View Deliverables"
                    innerpath="/deliverable"
                /> */}
                <Heading
                    title="Deliverable Details"
                    description="View all details of the deliverable below"
                    linktext="Back to Deliverables"
                    icon={Backarrow}
                    href="/deliverable"
                    width="auto"
                    height="44px"
                    backgroundcolor="none"
                    color="#5D43A5"
                    fontsize="16px"
                    hover="#F9F5FF"
                    padding="0px 20px !important"
                    className="view-back-button"
                />
                {loading ? (
                    <div> Loading... </div>
                ) : (
                    <>
                        <GeneralView campus={item} fields={fields} />
                    </>
                )}
            </div>
        </div>
    );
};

export default ViewDepartment;
