import React, { useRef, useState } from 'react';
import Sidebar from '../../../components/Sidebar/Sidebar';
import Header from '../../../components/Header/Header';
import Heading from '../../../components/Heading/Heading';
import Table from '../../../components/Table/Table';
import useListApi from '../../../../hooks/useListApi';
import Plus from '../../../../images/plus.svg';
import MediaQuery from 'react-responsive';
import MobileHeader from '../../../components/Sidebar/Mobile Design/Mobile Header/MobileHeader';
import qs from 'qs';
import HeaderFilters from '../../../components/Filters/HeaderFilters';
import ExportIcon from '../../../../images/export.svg';

const ClustersList = () => {
    const [filters, setFilters] = useState(
        qs.parse(window.location.search, { ignoreQueryPrefix: true }),
    );
    const {
        rows,
        refresh,
        totalRecords,
        currentPage,
        recordsPerPage,
        setCurrentPage,
        setRecordsPerPage,
    } = useListApi('Infrastructure/Cluster', false, true, filters);
    const column = [
        {
            heading: 'Name',
            value: 'Name',
        },
        {
            heading: 'Campus',
            value: 'CampusName',
        },
        {
            heading: 'Code',
            value: 'Code',
        },

        {
            heading: 'Created at',
            value: 'CreatedAt',
        },
        {
            heading: 'Discipline',
            value: 'researchcentre',
        },
    ];

    const renderColumn = (row, column, rendered) => {
        if (column.value === 'researchcentre') {
            return (
                <td>
                    <a
                        href={`/discipline?ClusterId=${row.Id}&CampusId=${row.CampusId}`}
                    >
                        Disciplines
                    </a>
                </td>
            );
        }
        return rendered;
    };

    const tableRef = useRef(null);

    return (
        <div className="main-wrapper">
            <MediaQuery maxWidth={1023}>
                <MobileHeader />
            </MediaQuery>
            <MediaQuery minWidth={1024}>
                <Sidebar />
            </MediaQuery>
            <div className="screen">
                {/* <Header
                    homelink="/dashboard"
                    title="Campuses"
                    breadcrumbs="Registries"
                    href="/dashboard"
                /> */}
                <Heading
                    title="Registries"
                    description="List of Registries"
                    linktext="Add Registry"
                    permissionPrefix={'Cluster'}

                    icon={Plus}
                    width="auto"
                    height="40px"
                    padding="0px 20px"
                    tableRef={tableRef}
                    exporticon={ExportIcon}
                    href={`/registry/new${
                        filters.CampusId ? `?CampusId=${filters.CampusId}` : ''
                    }`}
                />

                <HeaderFilters
                    filters={filters}
                    availableFilter={['campus']}
                    setFilters={setFilters}
                />

                <Table
                    permissionPrefix={'Cluster'}

                    ref={tableRef}
                    shouldView={true}
                    shouldAssignFee={false}
                    shouldProfile = {false}
                    refresh={refresh}
                    resource={'Infrastructure/Cluster'}
                    data={rows}
                    totalRecords={totalRecords}
                    perPage={recordsPerPage}
                    setPerPage={setRecordsPerPage}
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                    column={column}
                    renderColumn={renderColumn}
                    tableHeading="All Registries"
                    name="Registry"
                    multipleDelete="Registries"
                    onView={(item) => {
                        window.location.href = `/registry/${item.Id}?next=${encodeURIComponent(`${window.location.pathname}${window.location.search}`)}`;
                    }}
                    onEdit={(item) => {
                        window.location.href = `/registry/${item.Id}/edit?next=${encodeURIComponent(`${window.location.pathname}${window.location.search}`)}`;
                    }}
                />
            </div>
        </div>
    );
};

export default ClustersList;
