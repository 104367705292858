import React, { useRef, useState } from 'react';
import Sidebar from '../../../components/Sidebar/Sidebar';
import Header from '../../../components/Header/Header';
import Heading from '../../../components/Heading/Heading';
import Table from '../../../components/Table/Table';
import useListApi from '../../../../hooks/useListApi';
import Plus from '../../../../images/plus.svg';
import MediaQuery from 'react-responsive';
import MobileHeader from '../../../components/Sidebar/Mobile Design/Mobile Header/MobileHeader';
import qs from 'qs';
import HeaderFilters from '../../../components/Filters/HeaderFilters';
import useEnums from '../../../../hooks/useEnums';
import ExportIcon from '../../../../images/export.svg';

const DepartmentList = () => {
    const [filters, setFilters] = useState(
        qs.parse(window.location.search, { ignoreQueryPrefix: true }),
    );
    const { renderEnum } = useEnums();

    const {
        rows,
        refresh,
        totalRecords,
        currentPage,
        recordsPerPage,
        setCurrentPage,
        setRecordsPerPage,
    } = useListApi('Infrastructure/Department', false, true, filters);
    const column = [
        {
            heading: 'Name',
            value: 'Name',
        },
        {
            heading: 'Code',
            value: 'Code',
        },
        {
            heading: 'Function',
            value: 'DepartmentFunction',
            render: (item) => {
                return renderEnum(
                    'DepartmentFunction',
                    item.DepartmentFunction,
                );
            },
        },
        {
            heading: 'Created at',
            value: 'CreatedAt',
        },
        {
            heading: 'Contact Person',
            value: 'Contact',
        },
    ];

    const renderColumn = (row, column, rendered) => {
        if (column.value === 'researchcentres') {
            return (
                <td>
                    <a href={`/researchcentres?ClusterId=${row.ClusterId}`}>
                        Disciplines
                    </a>
                </td>
            );
        }
        return rendered;
    };

    const tableRef = useRef(null);

    return (
        <div className="main-wrapper">
            <MediaQuery maxWidth={1023}>
                <MobileHeader />
            </MediaQuery>
            <MediaQuery minWidth={1024}>
                <Sidebar />
            </MediaQuery>
            <div className="screen">
                <Header homelink="/dashboard" breadcrumbs="Departments" />
                <Heading
                    title="Departments"
                    description="List of Departments"
                    linktext="Add Department"
                    icon={Plus}
                    width="auto"
                    height="40px"
                    padding="0 20px 0 20px"
                    tableRef={tableRef}
                    exporticon={ExportIcon}
                    href={`/department/new${
                        filters.CampusId ? `?CampusId=${filters.CampusId}` : ''
                    }`}
                />
                <HeaderFilters
                    filters={filters}
                    availableFilter={['campus']}
                    setFilters={setFilters}
                />
                <Table
                    ref={tableRef}
                    shouldView={true}
                    shouldAssignFee={false}
                    shouldProfile = {false}
                    data={rows}
                    totalRecords={totalRecords}
                    perPage={recordsPerPage}
                    setPerPage={setRecordsPerPage}
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                    refresh={refresh}
                    resource={'Infrastructure/Department'}
                    column={column}
                    renderColumn={renderColumn}
                    tableHeading="All Departments"
                    name="Department"
                    multipleDelete="Departments"
                    onEdit={(item) => {
                        window.location.href = `/department/${item.Id}/edit?next=${encodeURIComponent(`${window.location.pathname}${window.location.search}`)}`;
                    }}
                    onView={(item) => {
                        window.location.href = `/department/${item.Id}?next=${encodeURIComponent(`${window.location.pathname}${window.location.search}`)}`;
                    }}
                />
            </div>
        </div>
    );
};

export default DepartmentList;
