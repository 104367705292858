import React, { useEffect, useState,  useRef  } from 'react';
import ResumeImage from '../../../../../images/profile.svg';
import { Link, useParams } from 'react-router-dom';
import useProfileListApi from "../../../../../hooks/useProfileListApi";
import renderValue from "../../../../../helpers/renderValue";
import useDetailApi from "../../../../../hooks/useDetailApi";
import getFullName from "../../../../../helpers/getFullName";
import Api from "../../../../../api/api";
import { useReactToPrint } from 'react-to-print';

const ProfileResume = () => {
    const { id } = useParams();

    const componentRef = useRef();
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
        documentTitle: 'download-receipt',
        orientation: 'landscape',
        // onAfterPrint: () => alert('Print success')
    });

    const {
        loading: initialising,
        item: personalInfo
    } = useDetailApi('UserProfile/UserProfile/getPersonalInfo', id, true,);

    const { rows: qualifications, loading: qualificationsLoading } = useProfileListApi('Qualification', id);
    const { rows: projects, loading: projectsLoading } = useProfileListApi('Project', id);
    const { rows: publications, loading: publicationsLoading } = useProfileListApi('Publication', id);
    const { rows: socialSites, loading: socialSitesLoading } = useProfileListApi('SocialSites', id);
    const { rows: priorScholarInfo, loading: priorScholarInfoLoading } = useProfileListApi('PriorScholarInfo', id);
    const { rows: patents, loading: patentsLoading } = useProfileListApi('Patent', id);
    const { rows: awards, loading: awardsLoading } = useProfileListApi('Award', id);
    const { rows: experience, loading: experienceLoading } = useProfileListApi('ProfessionInfo', id);


    if (initialising || qualificationsLoading || projectsLoading || experienceLoading) {
        return <div>Loading...</div>;
    }

    return (
        <>
            <div className="fee-receipt-download-pdf">
                <Link
                    onClick={handlePrint}
                    hover="#53389e"
                    width="auto"
                    padding="0px 20px 0px 20px"
                    border-radius="4px"
                >
                Download Resume{' '}
                </Link>
            </div>
            <div ref={componentRef} style={{ width: '100%', height: window.innerHeight }}>
                <div className="profile-resume-container">
                    <div className="profile-resume-personal-content">
                        <div className="profile-resume-left">
                            <div className="profile-resume-image">
                                {personalInfo.ProfilePhoto &&
                                    <img
                                        className="profile-resume-pic"
                                        src={personalInfo.ProfilePhoto ? `${Api.api_url}/uploads/${personalInfo.ProfilePhoto}` : ResumeImage}
                                        alt={'Profile Photo'}
                                    />
                                }
                                <div className="profile-resume-image-content">
                                    <h1>{getFullName(personalInfo)}</h1>
                                    <p>{personalInfo.Email}</p>
                                </div>
                            </div>
                        </div>
                        <div className="profile-resume-right">
                            <p>{personalInfo.CurrentAddress}</p>
                            <p>{personalInfo.PhoneNumber}</p>
                        </div>
                    </div>
                    <div className="bottom-line"></div>
                    <>
                        <div className="resume">
                            <div className="resume-content">
                                <div className="resume-details-experience">
                                    <div className="resume-details-heading">
                                        <div>
                                            <h5>Experience</h5>
                                        </div>
                                    </div>
                                    <div className="experience-content">
                                        {experience.length === 0 && 'No experiences found'}
                                        {experience.map((item, index) => {
                                            return <div className="experience-type">
                                                <div className="resume-details">
                                                    <div className="resume-inner-details">
                                                    <h6>{renderValue(item.StartDate)} : </h6>
                                                    <p>{item.JobStatus ? 'Currently Working' : renderValue(item.EndDate)}</p>
                                                    </div>
                                                    <div className="resume-inner-details">
                                                        <h6>Company name </h6>
                                                        <p>
                                                            {item.OrganizationName}
                                                        </p>
                                                    </div>
                                                    <div className="resume-inner-detail">
                                                        <h6>Designation </h6>
                                                        <p>{item.Headline}</p>
                                                    </div>
                                                    <div className="resume-inner-detail">
                                                        <h6>Experience type</h6>
                                                        <p>{item.ExperienceType}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                        )}
                                    </div>
                                </div>
                            </div>

                            <div className="resume-content">
                                <div className="resume-details-experience">
                                    <div className="resume-details-heading">
                                        <div>
                                            <h5>Qualifications</h5>
                                        </div>
                                    </div>
                                    <div className="experience-content">
                                        {qualifications.length === 0 && 'No Qualification Found'}
                                        {qualifications.map((item, index) => {
                                            return <div className="experience-type">
                                                <div className="resume-details">
                                                <div className="resume-inner-details">
                                                    <h6>Passing out year :</h6>
                                                    <p> {item.PassingOutYear}</p>
                                                </div>
                                                    <div className="resume-inner-details">
                                                        <h6>Institution</h6>
                                                        <p>{item.SchoolName}</p>
                                                    </div>
                                                    <div className="resume-inner-detail">
                                                        <h6>Field of Study</h6>
                                                        <p>{item.FieldOfStudy}</p>
                                                    </div>
                                                    <div className="resume-inner-detail">
                                                        <h6>Qualification Type</h6>
                                                        <p>{item.QualificationType}</p>
                                                    </div>
                                                    <div className="resume-inner-detail">
                                                        <h6>CGPA/Percentage</h6>
                                                        <p>{item.Percentage}%</p>
                                                    </div>
                                                </div>
                                            </div>
                                        })}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="resume-content">
                            <div className="resume-details-experience">
                                <div className="resume-details-heading">
                                    <div>
                                        <h5>Projects</h5>
                                    </div>
                                </div>
                                <div className="experience-content">
                                    {projects.length === 0 && 'No Projects Found'}
                                    {projects.map((item, index) => {
                                        return <div className="experience-type">
                                            <div className="resume-details">
                                            <div className="resume-inner-details">
                                                <h6>{renderValue(item.StartDate)}: </h6>
                                                <p>
                                                    {item.ProjectStatus === 'Ongoing' ? 'Ongoing' : renderValue(item.EndDate)}
                                                </p>
                                            </div>
                                                <div className="resume-inner-details">
                                                    <h6>Project Title </h6>
                                                    <p>
                                                        {item.Title}
                                                    </p>
                                                </div>
                                                <div className="resume-inner-detail">
                                                    <h6>Project duration</h6>
                                                    <p>{item.Duration} months</p>
                                                </div>
                                                <div className="resume-inner-detail">
                                                    <h6>Funding Agency</h6>
                                                    <p>{item.FundingAgency}</p>
                                                </div>
                                                <div className="resume-inner-detail">
                                                    <h6>Principal Investigator</h6>
                                                    <p>{item.PrincipleInvestigator}</p>
                                                </div>
                                            </div>
                                        </div>
                                    })}
                                </div>
                            </div>
                        </div>

                        <div className="resume-content">
                            <div className="resume-details-experience">
                                <div className="resume-details-heading">
                                    <div>
                                        <h5>Patents</h5>
                                    </div>
                                </div>
                                <div className="experience-content">
                                    {patents.length === 0 && 'No Patents Found'}
                                    {patents.map((item, index) => {
                                        return <div className="experience-type">
                                            <div className="resume-details">
                                            <div className="resume-inner-details">
                                                <h6>{renderValue(item.PatentFileDate)}: </h6>
                                                <p>

                                                </p>
                                            </div>
                                                <div className="resume-inner-details">
                                                    <h6>Title </h6>
                                                    <p>
                                                        {item.Title}
                                                    </p>
                                                </div>
                                                <div className="resume-inner-detail">
                                                    <h6>Patent Number</h6>
                                                    <p>{item.PatentNumber}</p>
                                                </div>
                                                <div className="resume-inner-detail">
                                                    <h6>URL</h6>
                                                    <a href={item.PatentURL} target="_blank">{item.PatentURL}</a>
                                                </div>
                                                <div className="resume-inner-detail">
                                                    <h6>Applicants</h6>
                                                    <p>{item.Applicants}</p>
                                                </div>
                                                <div className="resume-inner-detail">
                                                    <h6>Category</h6>
                                                    <p>{item.Category}</p>
                                                </div>
                                            </div>
                                        </div>
                                    })}
                                </div>
                            </div>
                        </div>

                        <div className="resume-content">
                            <div className="resume-details-experience">
                                <div className="resume-details-heading">
                                    <div>
                                        <h5>Awards</h5>
                                    </div>
                                </div>
                                <div className="experience-content">
                                    {awards.length === 0 && 'No Awards Found'}
                                    {awards.map((item, index) => {
                                        return <div className="experience-type">
                                        
                                            <div className="resume-details">
                                            <div className="resume-inner-details">
                                                <h6>{renderValue(item.Date)}</h6>
                                                <p>

                                                </p>
                                            </div>
                                                <div className="resume-inner-details">
                                                    <h6>Title</h6>
                                                    <p>
                                                        {item.Title}
                                                    </p>
                                                </div>
                                                <div className="resume-inner-detail">
                                                    <h6>Level</h6>
                                                    <p>{item.AwardLevel}</p>
                                                </div>
                                                <div className="resume-inner-detail">
                                                    <h6>Issuer</h6>
                                                    <p>{item.Issuer}</p>
                                                </div>
                                                <div className="resume-inner-detail">
                                                    <h6>Description</h6>
                                                    <p>{item.Description}</p>
                                                </div>
                                            </div>
                                        </div>
                                    })}
                                </div>
                            </div>
                        </div>


                        <div className="resume-content">
                            <div className="resume-details-experience">
                                <div className="resume-details-heading">
                                    <div>
                                        <h5>Social Sites</h5>
                                    </div>
                                </div>
                                <div className="experience-content">
                                    {socialSites.length === 0 && 'No Social Sites Found'}
                                    <div className="experience-type">
                                        <div className="current-status">
                                            <h6></h6>
                                            <p>

                                            </p>
                                        </div>
                                        <div className="resume-details">
                                            {socialSites.map((item, index) => {
                                                return <div className="resume-inner-detail">
                                                    <h6>{item.SocialSiteType}</h6>
                                                    <a href={item.Link} target="_blank">{item.Link}</a>
                                                </div>
                                            })}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div className="resume-content">
                            <div className="resume-details-experience">
                                <div className="resume-details-heading">
                                    <div>
                                        <h5>Publications</h5>
                                    </div>
                                </div>
                                <div className="experience-content">
                                    {publications.length === 0 && 'No Publications Found'}
                                    {publications.map((item, index) => {
                                        return <div className="experience-type">
                                            <div className="resume-details">
                                            <div className="resume-inner-details">
                                                <h6>{renderValue(item.PublicationDate)}:</h6>
                                                <p>

                                                </p>
                                            </div>
                                                <div className="resume-inner-details">
                                                    <h6>Title</h6>
                                                    <p>
                                                        {item.Title}
                                                    </p>
                                                </div>
                                                <div className="resume-inner-detail">
                                                    <h6>Type</h6>
                                                    <p>{item.PublicationType}</p>
                                                </div>
                                                <div className="resume-inner-detail">
                                                    <h6>Name of authors</h6>
                                                    <p>{item.Authors}</p>
                                                </div>
                                                <div className="resume-inner-detail">
                                                    <h6>Name of Conference</h6>
                                                    <p>{item.PublicationName}</p>
                                                </div>
                                                <div className="resume-inner-detail">
                                                    <h6>Publisher</h6>
                                                    <p>{item.Publisher}</p>
                                                </div>
                                                <div className="resume-inner-detail">
                                                    <h6>Online URL</h6>
                                                    <a href={item.URL} target="_blank">{item.URL}</a>
                                                </div>
                                            </div>
                                        </div>
                                    })}
                                </div>
                            </div>
                        </div>

                        {personalInfo.UserType == 'Staff' &&
                            <div className="resume-content">
                                <div className="resume-details-experience">
                                    <div className="resume-details-heading">
                                        <div>
                                            <h5>Prior Scholar Information</h5>
                                        </div>
                                    </div>
                                    <div className="experience-content">
                                        {priorScholarInfo.length === 0 && 'No Prior Scholar Info Found'}
                                        {priorScholarInfo.map((item, index) => {
                                            return <div className="experience-type">
                                        
                                                <div className="resume-details">
                                                <div className="resume-inner-details">
                                                    <h6>Status</h6>
                                                    <p>
                                                        {item.Status ? 'Ongoing' : 'Completed'}
                                                    </p>
                                                </div>
                                                    <div className="resume-inner-details">
                                                        <h6>Name</h6>
                                                        <p>
                                                            {getFullName(item)}
                                                        </p>
                                                    </div>
                                                    <div className="resume-inner-detail">
                                                        <h6>Institute</h6>
                                                        <p>{item.Institute}</p>
                                                    </div>
                                                    <div className="resume-inner-detail">
                                                        <h6>Discipline</h6>
                                                        <p>{item.Discipline}</p>
                                                    </div>

                                                </div>
                                            </div>
                                        })}
                                    </div>
                                </div>
                            </div>
                        }
                    </>
                </div>
            </div>
        </>
    );
};

export default ProfileResume;
