import {useCallback, useEffect, useState} from 'react';
import Api from '../api/api';
import FileSaver from 'file-saver';

const useExport = (
    url,
    paged = true,
    isNewListApi = false,
    filters = null,
    perPage = 10,
    appendGetEntities = true
) => {
    const [rows, setRows] = useState([]);
    const [loading, setLoading] = useState(false);

    const startExport = useCallback(() => {
        setLoading(true);
        Api.get(`/${url}/GenerateCSV`, {
            ...filters
        }, () => {
            window.location.href = '/unauthorized-access';
        })
            .then((response) => {
                setRows(response);
            })
            .catch(() => {
            })
            .finally(() => {
                setLoading(false);
            });
    }, [filters, url]);

    useEffect(() => {
        startExport();
    }, [startExport]);

    const doExport = (name) => {
        var blob = new Blob([rows], {type: "text/csv;charset=utf-8"});
        FileSaver.saveAs(blob, name);
    }

    return {
        doExport
    };
};

export default useExport;
