import React from 'react';
import Header from '../../../../../../../components/Header/Header';
import Heading from '../../../../../../../components/Heading/Heading';
import Progressbar from '../../../../../../../components/Progress Bar/Progressbar';
import Sidebar from '../../../../../../../components/Sidebar/Sidebar';
import QualificationContent from './QualificationContent';
import MediaQuery from 'react-responsive';
import MobileHeader from '../../../../../../../components/Sidebar/Mobile Design/Mobile Header/MobileHeader';
import { useParams } from 'react-router-dom';

const Qualification = () => {
    const { scholarId } = useParams();

    return (
        <div className="main-wrapper">
            <MediaQuery maxWidth={1023}>
                <MobileHeader />
            </MediaQuery>
            <MediaQuery minWidth={1024}>
                <Sidebar />
            </MediaQuery>
            <div className="screen">
                {/* <Header
                    homelink="/dashboard"
                    title="Scholars"
                    breadcrumbs="My Profile"
                    href="/scholar"
                /> */}
                <Heading title="My Profile" description="" />
                <div className="user-profile-section  quali-table">
                    <Progressbar />
                    <QualificationContent id={scholarId} />
                </div>
            </div>
        </div>
    );
};

export default Qualification;
