import React from 'react';
import Header from '../../Header/Header';
import MobileHeader from '../../Sidebar/Mobile Design/Mobile Header/MobileHeader';
import Sidebar from '../../Sidebar/Sidebar';
import ScholarDefaultContent from './ScholarDefaultContent';
import MediaQuery from 'react-responsive';

const ScholarDefault = () => {
    return (
        <div className="main-wrapper">
            <MediaQuery maxWidth={1023}>
                <MobileHeader />
            </MediaQuery>
            <MediaQuery minWidth={1024}>
                <Sidebar />
            </MediaQuery>
            <div className="screen">
                <Header
                    homelink="/dashboard"
                    breadcrumb="Scholars"
                    breadcrumbs="Scholar List"
                    innerpath="/scholar"
                />
                <ScholarDefaultContent
                    heading="No scholars found"
                    subheading="There are no scholars added yet. Please Import or add scholars from below."
                />
            </div>
        </div>
    );
};

export default ScholarDefault;
