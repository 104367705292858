import React from 'react';
import MobileHeader from '../../../../../../components/Sidebar/Mobile Design/Mobile Header/MobileHeader';
import Sidebar from '../../../../../../components/Sidebar/Sidebar';
import AddSupervisorContent from './AddSupervisorContent';
import MediaQuery from 'react-responsive';
import FormHeading from '../../../../../../components/Heading/FormHeading';
import FormHeader from '../../../../../../components/Header/FormHeader';
import { LinkButton } from '../../../../../../components/Buttons/LinkButton';
import Backarrow from '../../../../../../../images/back-arrow.svg';

const AddSupervisor = () => {
    return (
        <div className="main-wrapper">
            <MediaQuery maxWidth={1023}>
                <MobileHeader />
            </MediaQuery>
            <MediaQuery minWidth={1024}>
                <Sidebar />
            </MediaQuery>
            <div className="screen">
                <div className="heading-button-mobile">
                    <FormHeader
                        homelink="/dashboard"
                        breadcrumb="Supervisors"
                        breadcrumbs="Add Supervisor"
                        innerpath="/supervisor"
                    />
                    <FormHeading
                        title="Add Supervisor"
                        description="Enter the following details to add Supervisor"
                    />
                    <MediaQuery maxWidth={1023}>
                        <div className="back-button">
                            <LinkButton
                                linktext="Back to Supervisors"
                                icon={Backarrow}
                                href="/supervisor"
                                width="204px"
                                height="44px"
                                backgroundcolor="none"
                                color="#5D43A5"
                                fontsize="16px"
                                hover="none"
                            />
                        </div>
                    </MediaQuery>
                    <AddSupervisorContent />
                </div>
            </div>
        </div>
    );
};

export default AddSupervisor;
