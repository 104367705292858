import React, { useState } from 'react';
import InactiveDropdown from '../../../../images/dropdown.svg';
import ActiveDropdown from '../../../../images/arrow-active.svg';
import Infrastructure from '../../../../images/infrastructure.svg';
import Users from '../../../../images/users.svg';
import Campus from '../../../../images/campus.svg';
import Fee from '../../../../images/fee.svg';
import SidebarActiveIcon from '../../Sidebar Active Icon/SidebarActiveIcon';
import classNames from 'classnames';
import Campusactive from '../../../../images/campus-active.svg';
import Infrastructureactive from '../../../../images/infrastructure-active.svg';
import Academic from '../../../../images/academic.svg';
import Academicactive from '../../../../images/academic-active.svg';
import Useractive from '../../../../images/user-active.svg';
import Feeactive from '../../../../images/fee-active.svg';
import DRC from '../../../../images/drc.svg';
import DRCactive from '../../../../images/drc-active.svg';
import usePermissions from "../../../../hooks/userPermissions";
import Rolesactive from '../../../../images/roles-active.svg';
import Roles from '../../../../images/roles-inactive.svg';

const getActiveMenuIndex = (url) => {
    let parts = url.split('/');
    switch (parts[3]) {
        case 'campus':
        case 'department':
        case 'dashboard':
            return 10;
        case 'registry':
        case 'discipline':
        case 'specialization':
            return 1;
        case 'academic-year':
            return 2;
        case 'batch':
            return 2;
        case 'program':
        case 'milestone':
        case 'deliverable':
            return 2;
        case 'sub-deliverable':
            return 2;
        case 'resource-persons':
            return 3;
        case 'staff':
            return 5;
        case 'supervisor':
            return 4;
        case 'fee-policy':
        case 'assign-fee-policy':
            return 6;
        case 'assign-deliverables':
        case 'assign-supervisors':
        case 'promote-scholar':
        case 'scholar':
            return 3;
        case 'roles':
        case 'assign-roles':
            return 11;
        default:
            return 0;
        case 'assign-roll-number':
            return 3;
    }
};
const getActiveModule = (url) => {
    let parts = url.split('/');
    return parts[3];
};

const Navigation = () => {
    const { hasPermissionStartingWith, menus } = usePermissions();
    const [showDrop, setShowDrop] = useState(getActiveMenuIndex(window.location.href),);
    const getClassNames = (defaultClass, active) => {
        return classNames(defaultClass, { active: active });
    };

    const activeModule = getActiveModule(window.location.href);
    return (<ul className="main-navigation">
        {menus.setup && (<li
            onClick={() => setShowDrop(showDrop === 10 ? null : 10)}
            className={getClassNames('nav-item', showDrop === 10)}
        >
            <SidebarActiveIcon
                className="nav-link"
                active={Campusactive}
                inactive={Campus}
                url=""
                tabname="Campus Setup"
                activearrow={ActiveDropdown}
                inactivearrow={InactiveDropdown}
            />

            {showDrop === 10 && (<ul className="list-drop-down">
                {hasPermissionStartingWith('Campus') && <li>
                    <SidebarActiveIcon
                        isActive={activeModule === 'campus' || activeModule === 'dashboard'}
                        url="/campus"
                        tabname="Campuses"
                    />
                </li>}
            </ul>)}
        </li>)}
        {menus.infrastructure && <li
            onClick={() => setShowDrop(showDrop === 1 ? null : 1)}
            className={getClassNames('nav-item', showDrop === 1)}
        >
            <SidebarActiveIcon
                className="nav-link"
                active={Infrastructureactive}
                inactive={Infrastructure}
                url=""
                tabname="Infrastructure"
                activearrow={ActiveDropdown}
                inactivearrow={InactiveDropdown}

            />

            {showDrop === 1 && (<ul className="list-drop-down">
                {hasPermissionStartingWith('CLUSTER') && <li>
                    <SidebarActiveIcon
                        isActive={activeModule === 'registry'}
                        className="nav-link"
                        url="/registry"
                        tabname="Registries"
                    />
                </li>}
                {hasPermissionStartingWith('ResearchCenter') &&
                    <li>
                        <SidebarActiveIcon
                            isActive={activeModule === 'discipline'}
                            className="nav-link"
                            url="/discipline"
                            tabname="Disciplines"
                        />
                    </li>
                }
                {hasPermissionStartingWith('Discipline') &&
                    <li>
                        <SidebarActiveIcon
                            isActive={activeModule === 'specialization'}
                            className="nav-link"
                            url="/specialization"
                            tabname="Specializations"
                        />
                    </li>
                }
            </ul>)}
        </li>}
        {menus.infrastructure && <li
            onClick={() => setShowDrop(showDrop === 2 ? null : 2)}
            className={getClassNames('nav-item', showDrop === 2)}
        >
            <SidebarActiveIcon
                className="nav-link"
                active={Academicactive}
                inactive={Academic}
                url=""
                tabname="Academics Setup"
                activearrow={ActiveDropdown}
                inactivearrow={InactiveDropdown}
            />

            {showDrop === 2 && (<ul className="list-drop-down">
                {hasPermissionStartingWith('AcademicYear') && <li>
                    <SidebarActiveIcon
                        isActive={activeModule === 'academic-year'}
                        className="nav-link"
                        url="/academic-year"
                        tabname="Academic Years"
                    />
                </li>
                }
                {hasPermissionStartingWith('Batch') &&
                    <li>
                        <SidebarActiveIcon
                            isActive={activeModule === 'batch'}
                            className="nav-link"
                            url="/batch"
                            tabname="Batches"
                        />
                    </li>
                }
                {/* {hasPermissionStartingWith('Discipline') &&
                    <li>
                        <SidebarActiveIcon
                            isActive={activeModule === 'specialization'}
                            className="nav-link"
                            url="/specialization"
                            tabname="Specializations"
                        />
                    </li>
                } */}
                {hasPermissionStartingWith('Milestone') &&
                    <li>
                        <SidebarActiveIcon
                            isActive={activeModule === 'deliverable'}
                            className="nav-link"
                            url="/deliverable"
                            tabname="Deliverables"
                        />
                    </li>
                }
                {hasPermissionStartingWith('Deliverable') &&
                    <li>
                        <SidebarActiveIcon
                            isActive={activeModule === 'sub-deliverable'}
                            className="nav-link"
                            url="/sub-deliverable"
                            tabname="Resources"
                        />
                    </li>
                }
            </ul>)}
        </li>}
        {menus.staff && <li
            onClick={() => setShowDrop(showDrop === 5 ? null : 5)}
            className={getClassNames('nav-item', showDrop === 5)}
        >
            <SidebarActiveIcon
                className="nav-link"
                active={DRCactive}
                inactive={DRC}
                url=""
                tabname="Staff"
                activearrow={ActiveDropdown}
                inactivearrow={InactiveDropdown}
            />

            {showDrop === 5 && (<ul className="list-drop-down">
                {hasPermissionStartingWith('Staff') &&
                    <li>
                        <SidebarActiveIcon
                            isActive={activeModule === 'staff'}
                            stopPropagation={true}
                            className="nav-link"
                            url="/staff"
                            tabname="Staff"
                        />
                    </li>
                }
            </ul>)}
        </li>}

        {menus.scholars && <li
            onClick={() => setShowDrop(showDrop === 3 ? null : 3)}
            className={getClassNames('nav-item', showDrop === 3)}
        >
            <SidebarActiveIcon
                className="nav-link"
                active={Useractive}
                inactive={Users}
                url=""
                tabname="Scholars"
                activearrow={ActiveDropdown}
                inactivearrow={InactiveDropdown}
            />

            {showDrop === 3 && (<ul className="list-drop-down">
                {hasPermissionStartingWith('Scholar') &&

                    <li>
                        <SidebarActiveIcon
                            isActive={activeModule === 'scholar'}
                            className="nav-link"
                            url="/scholar"
                            tabname="Scholars"
                        />
                    </li>
                }
                {hasPermissionStartingWith('PromoteScholar') &&
                    <li>
                        <SidebarActiveIcon
                            isActive={activeModule === 'promote-scholar'}
                            className="nav-link"
                            url="/promote-scholar"
                            tabname="Promote Scholar"
                        />
                    </li>
                }
                {hasPermissionStartingWith('ScholarMilestone') &&

                    <li>
                        <SidebarActiveIcon
                            isActive={activeModule === 'assign-deliverables'}
                            className="nav-link"
                            url="/assign-deliverables"
                            tabname="Assign Resources"
                        />
                    </li>}
                {hasPermissionStartingWith('AssignGuide') &&
                    <li>
                        <SidebarActiveIcon
                            isActive={activeModule === 'assign-supervisors'}
                            className="nav-link"
                            url="/assign-supervisors"
                            tabname="Assign Supervisors"
                        />
                    </li>}

                {hasPermissionStartingWith('ResourcePerson') &&
                    <li>
                        <SidebarActiveIcon
                            isActive={activeModule === 'resource-persons'}
                            className="nav-link"
                            url="/resource-persons"
                            tabname="Assign Resource Persons"
                        />
                    </li>
                }

                {/* {hasPermissionStartingWith('AssignRollNumbers') &&
                    <li>
                        <SidebarActiveIcon
                            isActive={activeModule === 'roll-numbers'}
                            className="nav-link"
                            url="/roll-numbers"
                            tabname="Assign Roll Numbers"
                        />
                    </li>
                } */}

            </ul>)}
        </li>}
        {menus.fees && <li
            onClick={() => setShowDrop(showDrop === 6 ? null : 6)}
            className={getClassNames('nav-item', showDrop === 6)}
        >
            <SidebarActiveIcon
                className="nav-link"
                active={Feeactive}
                inactive={Fee}
                url=""
                tabname="Fees"
                activearrow={ActiveDropdown}
                inactivearrow={InactiveDropdown}
            />

            {showDrop === 6 && (<ul className="list-drop-down">
                {hasPermissionStartingWith('FeePolicy') &&
                    <li>
                        <SidebarActiveIcon
                            isActive={activeModule === 'fee-policy'}
                            className="nav-link"
                            url="/fee-policy"
                            tabname="Fee Policy"
                        />
                    </li>
                }
                {hasPermissionStartingWith('AssignFee_Update') &&
                    <li>
                        <SidebarActiveIcon
                            isActive={activeModule === 'assign-fee-policy'}
                            className="nav-link"
                            url="/assign-fee-policy"
                            tabname="Assign Fee Policy"
                        />
                    </li>}
                {hasPermissionStartingWith('AssignFee_Update') &&
                    <li>
                        <SidebarActiveIcon
                            isActive={activeModule === 'fee-report'}
                            className="nav-link"
                            url="/fee-report"
                            tabname="Fee Report"
                        />
                    </li>}
            </ul>)}
        </li>}
        {menus.roles && <li
            onClick={() => setShowDrop(showDrop === 11 ? null : 11)}
            className={getClassNames('nav-item', showDrop === 11)}
        >
            <SidebarActiveIcon
                className="nav-link"
                active={Rolesactive}
                inactive={Roles}
                url=""
                tabname="Roles"
                activearrow={ActiveDropdown}
                inactivearrow={InactiveDropdown}
            />
            {showDrop === 11 && (<ul className="list-drop-down">
                {hasPermissionStartingWith('Role') && <li>
                    <SidebarActiveIcon
                        isActive={activeModule === 'roles'}
                        className="nav-link"
                        url="/roles"
                        tabname="Roles"
                    />
                </li>
                }
                {hasPermissionStartingWith('AssignRole') &&
                    <li>
                        <SidebarActiveIcon
                            isActive={activeModule === 'assign-roles'}
                            className="nav-link"
                            url="/assign-roles"
                            tabname="Assign Roles"
                        />
                    </li>
                }
            </ul>)}
        </li>}
    </ul>);
};

export default Navigation;
