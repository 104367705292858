import React, {useEffect, useState} from 'react';
import Table from '../../../../../../../components/Table/Table';
import Api from '../../../../../../../../api/api';
import formatNumber from '../../../../../../../../helpers/formatNumber';
import CustomCheckbox from '../../../../../../../components/Inputs/CustomCheckbox';
import {Link} from "react-router-dom";
import renderValue from "../../../../../../../../helpers/renderValue";

const AssignedFeePolicyContent = ({
                                      id,
                                      paid,
                                      forceRender,
                                      totalRecords,
                                      showPagination = true,
                                      isScholar = false,
                                      onPayableUpdate,
                                  }) => {
    const [rows, setRows] = useState([]);
    // const [dummyData,setDummyData] = ([{id:0}]);
    const [transactions, setTransactions] = useState([]);
    const refresh = () => {
    };

    useEffect(() => {
        Api.get('/FeeStructure/Transaction/GetEntities', {UserId: id}).then((response) => {
            setTransactions(response);
        });
    }, [id, forceRender]);


    useEffect(() => {
        Api.get(`/FeeStructure/ScholarFee/GetEntities/${id}`)
            .then((response) => {
                let left = paid;
                setRows(
                    response.ScholarFees.sort((ScholarFee1, ScholarFee2) => {
                        return ScholarFee1.Milestone.MilestoneOrder - ScholarFee2.Milestone.MilestoneOrder;
                    }).map((ScholarFee) => {
                        let paidAmount =
                            left > ScholarFee.PayableAmount
                                ? ScholarFee.PayableAmount
                                : left > 0
                                    ? left
                                    : 0;
                        let out = {
                            id: ScholarFee.id,
                            FeeHeader: ScholarFee.Milestone.FeeHeader,
                            PolicyFeeAmount: `₹${formatNumber(
                                ScholarFee.PolicyFeeAmount,
                            )}`,
                            PayableAmount: `₹${formatNumber(
                                ScholarFee.PayableAmount,
                            )}`,
                            Discount: `₹${formatNumber(
                                ScholarFee.PolicyFeeAmount -
                                ScholarFee.PayableAmount,
                            )}`,
                            PaidAmount: `₹${formatNumber(paidAmount)}`,
                            PendingAmountPlain:
                                ScholarFee.PayableAmount - paidAmount,
                            PendingAmount: `₹${formatNumber(
                                ScholarFee.PayableAmount - paidAmount,
                            )}`,
                            Paid: paidAmount === ScholarFee.PayableAmount,
                        };
                        left -= ScholarFee.PayableAmount;
                        return out;
                    }),
                );
            })
            .catch(console.log);
    }, [id, paid, forceRender]);
    const [checked, setChecked] = useState([]);

    useEffect(() => {
        let total = rows.reduce((acc, row) => {
            if (checked.indexOf(row.id) > -1) {
                return acc + row.PendingAmountPlain;
            }
            return acc;
        }, 0);
        if (onPayableUpdate) {
            onPayableUpdate(total);
        }
    }, [onPayableUpdate, rows, checked]);

    const column = [
        {
            heading: 'Deliverable',
            value: 'FeeHeader',
        },

        {
            heading: 'Amount',
            value: 'PolicyFeeAmount',
        },
        {
            heading: 'Fee Waiver',
            value: 'Discount',
        },
        {
            heading: 'Payable',
            value: 'PayableAmount',
        },
        {
            heading: 'Paid',
            value: 'PaidAmount',
        },
        {
            heading: 'Pending',
            value: 'PendingAmount',
        },
        {
            heading: 'Payment Status',
            render: (item) => {
                let status = item.Paid ? 'Paid' : 'Unpaid';
                if (item.PayableAmount === '₹0') {
                    status = 'Nil';
                }
                return <div className={status}>{status}</div>;
            },
        },
    ];

    const column1 = [
        {
            heading: 'Date',
            value: 'Date',
            render: (item) => {
                let date = item.Date
                try {
                    const transactionDetails = JSON.parse(item.TransactionDetails || '{}');
                    date = transactionDetails.date || item.Date.date.split(' ')[0];
                }catch (e){

                }
                return renderValue(date);
            }
        },
        {
            heading: 'Receipt No',
            render: item => {
                const transactionDetails = JSON.parse(item.TransactionDetails || '{}');
                return item.TransactionId || transactionDetails.transactionNumber;
            }
        },
        {
            heading: 'Payment Mode',
            value: 'PaymentType',
        },
        {
            heading: 'Transaction Details',
            render: (item) => {
                try {
                    const transactionDetails = JSON.parse(item.TransactionDetails || '{}');
                    const parts = [];
                    if(transactionDetails.bank_name){
                        parts.push(transactionDetails.bank_name);
                    }
                    if(transactionDetails.dd_neft_rtgs_cheque_number){
                        parts.push(transactionDetails.dd_neft_rtgs_cheque_number);
                    }
                    if(transactionDetails.dd_neft_rtgs_cheque_date){
                        parts.push(transactionDetails.dd_neft_rtgs_cheque_date);
                    }
                    return parts.join(', ').trim() || '-';
                }catch (e){
                    return '-';
                }
            }
        },
        {
            heading: 'Notes',
            render: (item) => {
                try {
                    const transactionDetails = JSON.parse(item.TransactionDetails || '{}');
                    return transactionDetails.notes || '-';
                }catch (e){
                    return '-';
                }
            }
        },
        {
            heading: 'Amount Paid',
            value: 'AmountPaid',
            render: (item) => {
                return `₹${formatNumber(item.AmountPaid)}`;
            }
        },
        {
            heading: 'Status',
            value: 'Status',
        },
        {
            heading: 'Receipt',
            render: (item) => {
                if (item.Status === 'Paid') {
                    return <div className="actions">
                        <Link
                            className="promote-undo"
                            to={`/assign-fee-policy/scholar/${id}/fee/${item.id}/receipt`}
                        >
                            Download
                        </Link>
                    </div>
                }
            }
        }
    ];

    if (isScholar) {
        column.splice(0, 0, {
            heading: '',
            render: (item) => {
                if (!item.PendingAmountPlain) {
                    return <></>;
                }
                return (
                    <CustomCheckbox
                        className="table-checkbox-scholar "
                        type="checkbox"
                        isChecked={checked.indexOf(item.id) > -1}
                        onChange={() => {
                            if (checked.indexOf(item.id) > -1) {
                                setChecked(
                                    checked.filter((id) => id !== item.id),
                                );
                            } else {
                                setChecked([...checked, item.id]);
                            }
                        }}
                    />
                );
            },
        });
    }

    // const renderColumn = (row, column, rendered, index) => {
    //     if (column.heading === 'Amount') {
    //         return (
    //             <td className="scholar-assign-fee-view" key={index}>
    //                 {rendered}
    //             </td>
    //         );
    //     } else if (column.heading === 'Discount') {
    //         <td className="scholar-assign-fee-view" key={index}>
    //             {rendered}
    //         </td>;
    //     } else if (column.heading === 'Payable') {
    //         <td className="scholar-assign-fee-view" key={index}>
    //             {rendered}
    //         </td>;
    //     }
    //     return rendered;
    // };

    return (
        <>
            <div className="main-wrapper">
                <Table
                    showCardHeading={true}
                    checkboxshouldView={false}
                    shouldView={false}
                    shouldEdit={false}
                    shouldDelete={false}
                    shouldProfile={false}
                    shouldAssignFee={false}
                    resource={'Campus'}
                    refresh={refresh}
                    data={rows}
                    totalRecords={totalRecords}
                    perPage={'all'}
                    setPerPage={() => {
                    }}
                    currentPage={1}
                    showPagination={false}
                    setCurrentPage={() => {
                    }}
                    column={column}
                    tableHeading="All Deliverables' Fee Status"
                />
            </div>
            <div>
                <div className="main-wrapper">
                    <Table
                        showCardHeading={true}
                        checkboxshouldView={false}
                        shouldView={false}
                        shouldEdit={false}
                        shouldDelete={false}
                        shouldProfile={false}
                        shouldAssignFee={false}
                        shouldShowReceipt={false}
                        resource={'Campus'}
                        refresh={refresh}
                        data={transactions}
                        totalRecords={totalRecords}
                        perPage={'all'}
                        setPerPage={() => {
                        }}
                        currentPage={1}
                        showPagination={false}
                        setCurrentPage={() => {
                        }}
                        column={column1}
                        tableHeading="All Transactions"
                    />
                </div>
            </div>
        </>
    );
};

export default AssignedFeePolicyContent;
