import React from 'react';
import { Link } from 'react-router-dom';

const ScholarNavigation = () => {
    return (
        <>
            <div className="scholar-main-navigation">
                <div className="scholar-main-nav-inner-content">
                    <Link to="/user">
                        <ul>Home</ul>
                    </Link>
                    <Link to="/user/profile/personal">
                        <ul>My Profile</ul>
                    </Link>
                    {/* <Link to="/user/deliverables">
                        <ul>Deliverables</ul>
                    </Link> */}
                    <Link to="/user/fee">
                        <ul>Fee</ul>
                    </Link>
                    <Link to="/user/settings">
                        <ul>Settings</ul>
                    </Link>
                    <ul
                        onClick={() => {
                            if (
                                !window.confirm(
                                    'Are you sure you want to logout?',
                                )
                            ) {
                                return;
                            }
                            localStorage.removeItem('token');
                            window.location.href = '/login';
                        }}
                    >
                        Logout
                    </ul>
                </div>
            </div>
        </>
    );
};

export default ScholarNavigation;
