import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import renderValue from '../../../helpers/renderValue';
import { Modal } from 'react-bootstrap';
import Pagination from '../Pagination/Pagination';
import PromoteScholar from '../Modals/Promote Scholar Modal/PromoteScholar';
import AssignRole from '../../main/Dashboard/Role/AssignRole';
import AssignSupervisors from '../Modals/Fee Modals/Assign Fee Policy/Subcomponents/Assign Fee Policy/AssignSupervisors';
import { LinkButton } from '../Buttons/LinkButton';
import { Button } from '../Buttons/Button';
import CollectionIcon from '../../../images/collection.svg';
import Api from '../../../api/api';
import RollNumberGenerator from '../Modals/Roll No Generator/RollNumberGenerator';

const NoRecordsFound = () => {
    return (
        <div className="no-records-incard">
            <span>No Records Found</span>
        </div>
    );
};

const renderField = (item, col, renderColumn) => {
    const rendered = renderValue(item, col);
    if (renderColumn) {
        return renderColumn(item, col, rendered);
    }
    return rendered;
};

const Card = ({
    showCardHeading,
    heading,
    column,
    data,
    renderColumn,
    onDelete,
    onPromoteUndo,
    onEdit,
    onView,
    isLoading,
    Loading,
    editName,
    onAssignDeliverable,
    onAssignSupervisor,
    onProfile,
    refresh,

    shouldView = true,
    shouldDelete = true,
    shouldEdit = true,
    shouldAssignDeliverable = true,
    shouldAssignSuperviosr = true,
    shouldRollNumberGenerator = true,
    shouldPromoteUndo = true,
    shouldAssignRole = true,
    shoulShowPaynow = true,
    shouldProfile = true,
}) => {
    const [showShowFeePolicyModal, setShowFeePolicyModal] = useState(false);
    const [showShowFeePolicyModalo, setShowFeePolicyModalo] = useState(false);
    const [showRollNumberGeneratorModal, setShowRollNumberGeneratorModal] = useState(false);
    const [showAssignRoleModal, setShowAssignRoleModal] = useState(false);
    const [selectedUsers, setSelectedUsers] = useState([]);
    return (
        <>
            <div>
                {showCardHeading ? <h6>{heading}</h6> : ''}
                {data.map((item) => (
                    <div className="card">
                        <div className="card-info">
                            {column.map((col) => {
                                return (
                                    <div className="card-info-content">
                                        <span>{col.heading}</span>
                                        <p className="card-right-info">
                                            {renderField(
                                                item,
                                                col,
                                                renderColumn,
                                            )}
                                        </p>
                                    </div>
                                );
                            })}
                        </div>

                        <div className="action-bottom">
                            <div className="bottom-line-card"></div>

                            <div className="card-actions">
                                {shouldView && (
                                    <Link
                                        className="card-action-edit"
                                        to="#"
                                        onClick={() => {
                                            onView(item);
                                        }}
                                    >
                                        View
                                    </Link>
                                )}
                                {shouldEdit && (
                                    <Link
                                        className="card-action-edit"
                                        to="#"
                                        onClick={() => {
                                            onEdit(item);
                                        }}
                                    >
                                        {editName || 'Edit'}
                                    </Link>
                                )}
                                {onProfile && shouldEdit && (
                                    <Link
                                        className="action-edit"
                                        to="#"
                                        onClick={() => {
                                            onProfile(item);
                                        }}
                                    >
                                        Profile
                                    </Link>
                                )}
                                {shouldDelete && (
                                    <Link
                                        className="card-action-delete"
                                        to="#"
                                        onClick={() => {
                                            onDelete(item);
                                        }}
                                    >
                                        Delete
                                    </Link>
                                )}
                                {shouldAssignDeliverable && (
                                    <Link
                                        className="card-action-edit"
                                        to="#"
                                        onClick={() => {
                                            // onAssignDeliverable(item);
                                            window.location.href = `/assign-deliverables/scholar/${item.Id}/deliverables`;
                                        }}
                                    >
                                        Assign Resource
                                    </Link>
                                )}
                                {shouldAssignSuperviosr && (
                                    <Link
                                        className="card-action-edit"
                                        to="#"
                                        onClick={() => {
                                            // onAssignDeliverable(item);
                                            setSelectedUsers([item]);
                                            setShowFeePolicyModal(true);
                                        }}
                                    >
                                        Assign Supervisors
                                    </Link>
                                )}
                                {shouldRollNumberGenerator && (
                                    <Link
                                        className="card-action-edit"
                                        to="#"
                                        onClick={() => {
                                            setShowRollNumberGeneratorModal(true);
                                        }}
                                    >
                                        Assign Roll Number
                                    </Link>
                                )}
                                {shouldAssignRole && (
                                    <Link
                                        className="card-action-edit"
                                        to="#"
                                        onClick={() => {
                                            // onAssignDeliverable(item);
                                            setSelectedUsers([item]);
                                            setShowFeePolicyModalo(true);
                                        }}
                                    >
                                        Assign Role
                                    </Link>
                                )}

                                {shouldPromoteUndo && (
                                    <Link
                                        className="promote-undo"
                                        to="#"
                                        onClick={() => {
                                            Api.post(
                                                `/AcademicStructure/ScholarMilestone/demoteScholar/${item.Id}`,
                                            )
                                                .then(() => {
                                                    refresh();
                                                    alert('Undo successfully');
                                                    // setAlertMessage(
                                                    //     'Undo successfully',
                                                    // );
                                                    // setShowAlertModal(true);
                                                })
                                                .catch((e) => {
                                                    // setAlertMessage(e.Message);
                                                    //     setShowAlertModal(true);
                                                    alert(e.Message);
                                                });
                                        }}
                                    >
                                        Undo
                                    </Link>
                                )}
                            </div>
                        </div>
                    </div>
                ))}
                {showShowFeePolicyModal && (
                    <Modal
                        show={true}
                        onHide={() => setShowFeePolicyModal(false)}
                        centered
                        className="assignfeeModal"
                    >
                        <AssignSupervisors
                            refresh={refresh}
                            users={selectedUsers}
                            ResearchCenterId={
                                selectedUsers[0].ResearchCenterId || ''
                            }
                            CampusId={selectedUsers[0].CampusId || ''}
                            onHide={() => {
                                setShowFeePolicyModal(false);
                            }}
                        />
                    </Modal>
                )}
                {showShowFeePolicyModalo && (
                    <Modal
                        show={true}
                        onHide={() => setShowFeePolicyModalo(false)}
                        centered
                        className="assignfeeModal"
                    >
                        <AssignRole
                            refresh={refresh}
                            users={selectedUsers}
                            FeePolicyId={selectedUsers[0].FeePolicyId}
                            ResearchCenterId={
                                selectedUsers[0].ResearchCenterId || ''
                            }
                            onHide={() => {
                                setShowFeePolicyModalo(false);
                            }}
                        />
                    </Modal>
                )}
                            {showRollNumberGeneratorModal && (
                <Modal
                    show={true}
                    onHide={() => setShowRollNumberGeneratorModal(false)}
                    centered
                    className="assignrollnoModal"
                >
                    <RollNumberGenerator
                        refresh={refresh}
                        scholars={selectedUsers}
                        onHide={() => {
                            setShowRollNumberGeneratorModal(false)
                        }}
                    />
                </Modal>
            )}
                {(!data || data.length === 0) && !isLoading && (
                    <NoRecordsFound />
                )}
                {isLoading && <Loading />}
            </div>
        </>
    );
};

export default Card;
