import React from 'react';
import Star from '../../../../images/star.svg';
import Avatar from '../../../../images/avatar-group.svg';

const LoginRight = () => {
    return (
        <div className="login-right">
            <div className="login-right-content">
                <div className="star-image">
                    <img src={Star} alt={'Star'} />
                </div>
                <div className="login-right-heading">
                    <h1>Explore your Passion and Aptitude for Research</h1>
                    <span>
                    Our Ph.D. programs help students embark on a challenging and meaningful experience, focusing on distinct fields of area of study. Students are given the exposure to diversify their skill set, establish new networks, and gain knowledge on how research can translate into business ideas.
                    </span>
                </div>
                <div className="avatar-group">
                    <img src={Avatar} alt={'Avatar'} />
                    <span>Join our Network</span>
                </div>
            </div>
        </div>
    );
};

export default LoginRight;
