import React from 'react';
import ScholarHeader from '../../ScholarHeader';
import DeliverableDetailProgressbar from './DeliverableDetailProgressbar';
import DeliverableHeading from './DeliverableHeading';
import useProfile from "../../../../../hooks/useProfile";

const DeliverableDetailHome = () => {
    const {profile, loading} = useProfile();

    return (
        <div>
            <ScholarHeader />
            <DeliverableHeading/>
            <DeliverableDetailProgressbar profile={profile}/>
        </div>
    );
};

export default DeliverableDetailHome;
