import React from 'react';
import Header from '../../../../../../../components/Header/Header';
import Heading from '../../../../../../../components/Heading/Heading';
import Progressbar from '../../../../../../../components/Progress Bar/Progressbar';
import MobileHeader from '../../../../../../../components/Sidebar/Mobile Design/Mobile Header/MobileHeader';
import Sidebar from '../../../../../../../components/Sidebar/Sidebar';
import MediaQuery from 'react-responsive';
import GenericContent from '../Generic/GenericContent';
import {useParams} from 'react-router-dom';
import Api from "../../../../../../../../api/api";

export const UploadDocumentsContent = ({scholarId}) => {
    const formRef = React.useRef(null);

    return <GenericContent
        id={scholarId}
        endpoint={'Document'}
        title={'Documents'}
        description={'Add your documents'}
        column={[
            {
                heading: 'Document Type',
                value: 'DocumentType',
            },
            {
                heading: 'File Name',
                value: 'FileName',
            },
            {
                heading: 'Uploaded On',
                value: 'UploadedOn',
            },
            {
                heading: 'Download',
                render: (row) => {
                    return <a href={`${Api.api_url}/uploads/${row.FilePath}`} target={"_blank"}>Download</a>
                }

            },
        ]}
        fields={
            [
                {
                    type: 'select',
                    field: 'DocumentType',
                    label: 'Document Type',
                    estric: '*',
                    options: {
                        type: 'enum',
                        value: 'DocumentType'
                    }
                },
                {
                    type: 'file',
                    field: 'File',
                    label: 'File',
                    description: 'This will be displayed on your profile.',
                    estric: '*',
                    showPreview: false,
                    onChange: (value) => {
                        let files = JSON.parse(value);
                        if(files.length === 0){
                            formRef.current.updateData({
                                FilePath: '',
                                FileName: '',
                                File:''
                            });
                            return;
                        }
                        formRef.current.updateData({
                            FilePath: files[0].FileName,
                            FileName: files[0].DisplayName,
                        });
                    },
                    required: true,
                },

            ]}
        initialValuesUpdated={(values)=>{
            console.log(values)
            formRef.current.updateData({
                File:JSON.stringify([
                    {
                        FileName: values.FilePath,
                        DisplayName: values.FileName,
                    }
                ])
            });
        }
        }
        ref={formRef}
        initialValues={{
            'FileName': '',
            "FilePath": "",
            "DocumentType": "",
            CreatedAt: null,
            CreatedBy: null,
            IsDeleted: false,
            UserProfile: scholarId,
        }}
    />
}

const AwardDetail = () => {
    let {scholarId} = useParams();
    return (
        <div className="main-wrapper">
            <MediaQuery maxWidth={1023}>
                <MobileHeader/>
            </MediaQuery>
            <MediaQuery minWidth={1024}>
                <Sidebar/>
            </MediaQuery>
            <div className="screen">
                {/* <Header
                    homelink="/dashboard"
                    title="Scholars"
                    breadcrumbs="My Profile"
                    href="/scholar"
                /> */}
                <Heading title="My Profile" description=""/>
                <div className="user-profile-section upload-doc-table">
                    <Progressbar/>
                    <UploadDocumentsContent scholarId={scholarId}/>
                </div>
            </div>
        </div>
    );
};

export default AwardDetail;
