import React, { useEffect, useState } from 'react';
import Api from '../../api/api';
import Loader from 'react-js-loader';

const Login = () => {
    const logout = () => {
        localStorage.clear();
        window.location.href = '/login';
    };

    useEffect(() => {
        Api.get('/Authentication/Login/Profile')
            .then((profile) => {
                if (profile.userType === 'Scholar') {
                    window.location.href = '/user';
                } else {
                    window.location.href = '/dashboard';
                }
            })
            .catch((e) => {
                logout();
            });
    }, []);

    return (
        <div className="home-page-loader">
                <Loader
                className="spinner"
                type="spinner-default"
                color="#FFFFFF"
                bgColor="#7f56d9"
                size={30}
                >
                </Loader>
        </div>
    );
};

export default Login;
