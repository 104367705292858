import React, { useRef, useState } from 'react';
import Plus from '../../../../../images/plus.svg';
import Sidebar from '../../../../components/Sidebar/Sidebar';
import Header from '../../../../components/Header/Header';
import Heading from '../../../../components/Heading/Heading';
import Table from '../../../../components/Table/Table';
import useListApi from '../../../../../hooks/useListApi';
import ImportPlus from '../../../../../images/importplus.svg';
import MediaQuery from 'react-responsive';
import MobileHeader from '../../../../components/Sidebar/Mobile Design/Mobile Header/MobileHeader';
import HeaderFilters from '../../../../components/Filters/HeaderFilters';
import qs from 'qs';
import ExportIcon from '../../../../../images/export.svg';
import PlusIcon from '../../../../../images/plus.svg';
import useExport from "../../../../../hooks/useExport";
import getFullName from "../../../../../helpers/getFullName";

const ScholarsList = () => {
    const [filters, setFilters] = useState(
        qs.parse(window.location.search, { ignoreQueryPrefix: true }),
    );
    const {
        rows,
        refresh,
        totalRecords,
        currentPage,
        recordsPerPage,
        setCurrentPage,
        setRecordsPerPage,
    } = useListApi('Users/Scholar', false, true, filters);
    const column = [
        {
            heading: 'Name',
            render: (item) => {
                return getFullName(item)
            }
        },
        {
            heading: 'Email',
            value: 'Email',
        },

        {
            heading: 'University Roll No.',
            value: 'UniversityRollNo',
        },
    ];
    const {doExport} = useExport('Users/Scholar', false, true, filters);

    const tableRef = useRef(null);

    return (
        <div className="main-wrapper">
            <MediaQuery maxWidth={1023}>
                <MobileHeader />
            </MediaQuery>
            <MediaQuery minWidth={1024}>
                <Sidebar />
            </MediaQuery>
            <div className="screen">
                {/* <Header homelink="/dashboard" breadcrumbs="Scholars" /> */}
                <Heading
                    title="Scholars"
                    description="List of Scholars"
                    linktext="Add Scholar"
                    icon={Plus}
                    plusicon={PlusIcon}
                    permissionPrefix={'Scholar'}
                    className="import-scholar"
                    width="auto"
                    height="40px"
                    padding="0px 20px"
                    href={`/scholar/new?ClusterId=${filters.ClusterId}&CampusId=${filters.CampusId}&ResearchCenterId=${filters.ResearchCenterId}&BatchId=${filters.BatchId}&ProgramId=${filters.ProgramId}&DisciplineId=${filters.DisciplineId}`}
                    tableRef={tableRef}
                    exporticon={ExportIcon}
                    onExport={() => {
                        doExport('All Staff.csv');
                    }}
                />
                <HeaderFilters
                    filters={filters}
                    availableFilter={[
                        'campus',
                        'cluster',
                        'researchCenter',
                        'batch',
                        'program',
                        'discipline',
                    ]}
                    setFilters={setFilters}
                />
                <Table
                    ref={tableRef}
                    shouldView={true}
                    permissionPrefix={'Scholar'}
                    shouldAssignFee={false}
                    data={rows}
                    totalRecords={totalRecords}
                    perPage={recordsPerPage}
                    setPerPage={setRecordsPerPage}
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                    column={column}
                    resource={'Users/Scholar'}
                    tableHeading="All Scholars"
                    name="Scholar"
                    refresh={refresh}
                    multipleDelete="Scholars"
                    onEdit={(item) => {
                        window.location.href = `/scholar/${item.Id}/edit?next=${encodeURIComponent(`${window.location.pathname}${window.location.search}`)}`;
                    }}
                    onView={(item) => {
                        window.location.href = `/scholar/${item.Id}/view?next=${encodeURIComponent(`${window.location.pathname}${window.location.search}`)}`;
                    }}
                    onProfile={(item) => {
                        window.location.href = `/scholar/${item.UserProfile_Id}/personal`;
                    }}
                />
            </div>
        </div>
    );
};

export default ScholarsList;
