import React, {useEffect, useRef, useState} from 'react';
import Sidebar from '../../../../../../../components/Sidebar/Sidebar';
import Header from '../../../../../../../components/Header/Header';
import Heading from '../../../../../../../components/Heading/Heading';
import Table from '../../../../../../../components/Table/Table';
import useListApi from '../../../../../../../../hooks/useListApi';
import MediaQuery from 'react-responsive';
import MobileHeader from '../../../../../../../components/Sidebar/Mobile Design/Mobile Header/MobileHeader';
import ExportIcon from '../../../../../../../../images/export.svg';
import {useParams} from "react-router-dom";
import {Modal} from "react-bootstrap";
import AssignDeliverable
    from "../../../../../../../components/Modals/Fee Modals/Assign Fee Policy/Subcomponents/Assign Fee Policy/AssignDeliverable";
import useDetailApi from "../../../../../../../../hooks/useDetailApi";
import Plus from "../../../../../../../../images/plus.svg";
import ImportPlus from "../../../../../../../../images/importplus.svg";
import Loader from "react-js-loader";
import Api from "../../../../../../../../api/api";

const ScholarsList = () => {
    const {id} = useParams();
    const [filters, setFilters] = useState({UserId:null});
    const {item:scholar} = useDetailApi('Users/Scholar',id);
    const [rows, setRows] = useState([]);

    useEffect(()=>{
        if(!scholar){
            return;
        }
        Api.get('/AcademicStructure/ScholarDeliverable/getEntities',{UserId:scholar.User_id,PageNo:1,PerPage:1000})
            .then(response=>{
                setRows(response.Data);
            })
    },[scholar]);
    const refresh = ()=>{
        Api.get('/AcademicStructure/ScholarDeliverable/getEntities',{UserId:scholar.User_id,PageNo:1,PerPage:1000})
            .then(response=>{
                setRows(response.Data);
            })
    }

    const [showFeePolicyModal, setShowFeePolicyModal] = useState(false);
    const column = [

        {
            heading: 'Resources',
            value: 'Deliverable.Title',
        },
        {
            heading: 'Deliverable',
            value: 'Deliverable.Milestone.Title',
        },
        {
            heading: 'Deliverable Type',
            value: 'Deliverable.DeliverableType',
        },
        {
            heading: 'Submission Type',
            value: 'Deliverable.SubmissionType',
        },
    ];

    const tableRef = useRef(null);

    if(!scholar){
        return <div className="main-wrapper">
            <MediaQuery maxWidth={1023}>
                <MobileHeader />
            </MediaQuery>
            <MediaQuery minWidth={1024}>
                <Sidebar />
            </MediaQuery>
            <div className="screen">
                {/* <Header
                    homelink="/dashboard"
                    breadcrumbs="Assign Sub Deliverables"
                /> */}
                <Loader/>
            </div>
        </div>
    }
    return (
        <div className="main-wrapper">
            <MediaQuery maxWidth={1023}>
                <MobileHeader />
            </MediaQuery>
            <MediaQuery minWidth={1024}>
                <Sidebar />
            </MediaQuery>
            <div className="screen">
                {/* <Header
                    homelink="/dashboard"
                    breadcrumbs="Assign Sub Deliverables"
                /> */}
                <Heading
                    title={`${scholar?.FirstName} ${scholar?.LastName}'s Resources`}
                    description={`Assign resources to the scholar`}
                    linktext="Edit"
                    permissionPrefix={'ScholarDeliverable'}
                    icon={Plus}
                    onClick={() => {
                    setShowFeePolicyModal(true)
                    }}

                    className="import-scholar"
                    width="auto"
                    height="40px"
                    padding="0px 20px"
                    importicon={ImportPlus}
                    tableRef={tableRef}
                    exporticon={ExportIcon}
                />

                <Table
                    ref={tableRef}
                    checkboxshouldView={false}
                    shouldAssignDeliverable={false}
                    shouldEdit={false}
                    shouldProfile={false}
                    shouldView={false}
                    shouldAssignFee={false}
                    onAssignDeliverable={(item) => {
                        window.location.href = `/scholar/${item.Id}/deliverables`;
                    }}
                    shouldDelete={true}
                    data={rows}
                    totalRecords={rows.length}
                    perPage={rows.length}
                    setPerPage={()=>{
                    }
                    }
                    currentPage={1}
                    setCurrentPage={()=>{
                    }
                    }
                    showPagination={false}
                    column={column}
                    resource={'Users/Scholar'}
                    tableHeading="Assigned Resources"
                    name="Scholar"
                    refresh={refresh}
                    multipleDelete="Scholars"
                    batchDelete={false}
                    onDelete={(item) => {
                        window.location.href = `/scholar/${item.Id}/fee`;
                    }}
                />
            </div>
            {showFeePolicyModal && scholar && (
                <Modal
                    show={true}
                    onHide={() => setShowFeePolicyModal(false)}
                    centered
                    className="assignfeeModal"
                >
                    <AssignDeliverable
                        refresh={refresh}
                        users={[scholar]}
                        Deliverables={[]}
                        MilestoneId={scholar.CurrentMilestone}
                        onHide={() => {
                            setShowFeePolicyModal(false);
                            refresh();
                        }}
                    />
                </Modal>
            )}
        </div>
    );
};

export default ScholarsList;
