import React, {useState} from 'react';
import Header from '../../../../../../../components/Header/Header';
import Heading from '../../../../../../../components/Heading/Heading';
import Progressbar from '../../../../../../../components/Progress Bar/Progressbar';
import MobileHeader from '../../../../../../../components/Sidebar/Mobile Design/Mobile Header/MobileHeader';
import Sidebar from '../../../../../../../components/Sidebar/Sidebar';
import MediaQuery from 'react-responsive';
import {useParams} from 'react-router-dom';
import GenericContent from '../Generic/GenericContent';

export const PatentDetailContent = ({scholarId}) => {

    const [hidePublishedDate, setHidePublishedDate] = useState(true);
    const [hideGrantedDate, setHideGrantedDate] = useState(true);
    return  <GenericContent
        title={'Patents'}
        initialValuesUpdated={(initialValues) => {
            const value = initialValues.Category;
            if (value === 'Published') {
                setHidePublishedDate(false);
                setHideGrantedDate(true);
            } else if (value === 'Granted') {
                setHidePublishedDate(false);
                setHideGrantedDate(false);
            } else {
                setHidePublishedDate(true);
                setHideGrantedDate(true);
            }
        }}
        description={'Add your patent details here'}
        initialValues={{
            "Title": "",
            "PatentNumber": "",
            "PatentURL": "",
            "PatentLevel": "",
            "Inventors": "",
            "Applicants": "",
            "PatentFileDate": null,
            "PatentPublishedDate": null,
            "PatentGrantedDate": null,
            "CreatedBy": null,
            "IsDeleted": false,
            "Category": "",
            UserProfile: scholarId,
        }}
        id={scholarId}
        column={[

            {
                heading: 'Title',
                value: 'Title',
            },
            {
                heading: 'Level',
                value: 'PatentLevel',
            },
            {
                heading: 'Patent Number',
                value: 'PatentNumber',
            },
            {
                heading: 'URL',
                value: 'PatentURL',
            },
        ]}
        endpoint={'Patent'}
        fields={[
            {
                type: 'select',
                field: 'PatentLevel',
                label: 'Patent Level',
                description: 'This will be displayed on your profile.',
                estric: '*',
                options: {
                    type: 'static',
                    options: [
                        {value: 'National', label: 'National'},
                        {value: 'International', label: 'International'},
                    ]
                }
            },
            {
                type: 'text',
                field: 'Title',
                label: 'Title',
                estric: '*',
            },
            {
                type: 'text',
                field: 'PatentNumber',
                label: 'Patent Number',
                description: 'This will be displayed on your profile.',
                estric: '*',
            },
            {
                type: 'text',
                field: 'Inventors',
                label: 'Inventor(s)',
                description: 'This will be displayed on your profile.',
                estric: '*'
            },
            {
                type: 'text',
                field: 'Applicants',
                label: 'Applicants',
                description: 'This will be displayed on your profile.',
                estric: '*'
            },
            {
                type: 'text',
                field: 'PatentURL',
                label: 'Patent URL',
                description: 'This will be displayed on your profile.',
                estric: '*'
            },
            {
                type: 'select',
                field: 'Category',
                label: 'Category',
                description: 'This will be displayed on your profile.',
                estric: '*',
                options: {
                    type: 'enum',
                    value: 'PatentCategory'
                },
                onChange: (value) => {
                    if (value === 'Published') {
                        setHidePublishedDate(false);
                        setHideGrantedDate(true);
                    } else if (value === 'Granted') {
                        setHidePublishedDate(false);
                        setHideGrantedDate(false);
                    } else {
                        setHidePublishedDate(true);
                        setHideGrantedDate(true);
                    }
                }
            },
            {
                type: 'date',
                field: 'PatentFileDate',
                label: 'Filing Date',
                description: 'This will be displayed on your profile.',
                estric: '*',
            },
            {
                type: 'date',
                field: 'PatentPublishedDate',
                label: 'Published Date',
                description: 'This will be displayed on your profile.',
                required: false,
                hide: hidePublishedDate,
            },
            {
                type: 'date',
                field: 'PatentGrantedDate',
                label: 'Granted Date',
                description: 'This will be displayed on your profile.',
                required: false,
                hide: hideGrantedDate,
            },
        ]
        }
    />
}
const PatentDetail = () => {
    const {scholarId} = useParams();
    return (
        <div className="main-wrapper">
            <MediaQuery maxWidth={1023}>
                <MobileHeader/>
            </MediaQuery>
            <MediaQuery minWidth={1024}>
                <Sidebar/>
            </MediaQuery>
            <div className="screen">
                {/* <Header
                    homelink="/dashboard"
                    title="Scholars"
                    breadcrumbs="My Profile"
                    href="/scholar"
                /> */}
                <Heading title="My Profile" description=""/>
                <div className="user-profile-section patent-table">
                    <Progressbar/>
                    <PatentDetailContent scholarId={scholarId}/>
                </div>
            </div>
        </div>
    );
};

export default PatentDetail;
