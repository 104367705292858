import React from 'react';
import Header from '../../../../../../../components/Header/Header';
import Heading from '../../../../../../../components/Heading/Heading';
import Progressbar from '../../../../../../../components/Progress Bar/Progressbar';
import MobileHeader from '../../../../../../../components/Sidebar/Mobile Design/Mobile Header/MobileHeader';
import Sidebar from '../../../../../../../components/Sidebar/Sidebar';
import MediaQuery from 'react-responsive';
import GenericContent from '../Generic/GenericContent';
import {useParams} from 'react-router-dom';

export const SocialSiteContent = ({scholarId}) => {
    return (
        <GenericContent
            id={scholarId}
            title={'Social Sites'}
            description={'Add your social sites'}
            endpoint={'SocialSites'}
            column={[
                {
                    heading: 'Social Site Type',
                    value: 'SocialSiteType',
                },
                {
                    heading: 'Link',
                    value: 'Link',
                }
            ]}
            fields={
                [
                    {
                        type: 'select',
                        field: 'SocialSiteType',
                        label: 'Social Site Type',
                        description: 'This will be displayed on your profile.',
                        estric: '*',
                        options: {
                            type: 'enum',
                            value: 'SocialSiteType'
                        }
                    },
                    {
                        type: 'text',
                        field: 'Link',
                        label: 'Link',
                        description: 'This will be displayed on your profile.',
                        estric: '*',
                    }
                ]}
            initialValues={{
                "SocialSiteType": "",
                "Link": "",
                CreatedAt: null,
                CreatedBy: null,
                IsDeleted: false,
                UserProfile: scholarId,
            }}
        />
    );
}
const AwardDetail = () => {
    let {scholarId} = useParams();
    return (
        <div className="main-wrapper">
            <MediaQuery maxWidth={1023}>
                <MobileHeader/>
            </MediaQuery>
            <MediaQuery minWidth={1024}>
                <Sidebar/>
            </MediaQuery>
            <div className="screen">
                {/* <Header
                    homelink="/dashboard"
                    title="Scholars"
                    breadcrumbs="My Profile"
                    href="/scholar"
                /> */}
                <Heading title="My Profile" description=""/>
                <div className="user-profile-section social-site-table">
                    <Progressbar/>
                    <SocialSiteContent scholarId={scholarId} />
                </div>
            </div>
        </div>
    );
};

export default AwardDetail;
