import React, { useEffect, useState } from 'react';
import ResearchIcon from '../../../../../images/research-icon.svg';
import DropdownIcon from '../../../../../images/dropdown-icon.svg';
import { Link } from 'react-router-dom';
import { Button } from '../../../../components/Buttons/Button';
import CompleteTick from '../../../../../images/compelete-tick.svg';
import useListApi from '../../../../../hooks/useListApi';
import Api from '../../../../../api/api';

const DeliverableDetailRightContent = ({ milestone, profile }) => {
    // const [bgColor, setBgColor] = useState();

    // const changeColor = () => {
    //     let purple = '#F9F5FF';
    //     setBgColor(purple);
    // };

    const [deliverables, setDeliverables] = useState([]);

    useEffect(() => {
        if (!milestone || !profile) {
            return;
        }
        setDeliverables([]);
        return () => {
            Api.get('AcademicStructure/ScholarDeliverable/getEntities', {
                UserId: profile.userId,
                PageNo: 1,
                PerPage: 1000,
            }).then((res) => {
                setDeliverables(res.Data);
            });
        };
    }, [milestone, profile]);

    const [showDrop, setShowDrop] = useState(0);
    if (!milestone) {
        return <></>;
    }
    return (
        <div className="deliverable-detail-right">
            <div className="deliverable-details-heading">
                <h3>{milestone.Title}</h3>
                <span>
                    <div
                        dangerouslySetInnerHTML={{
                            __html: milestone.Description,
                        }}
                    />{' '}
                </span>
                <div className="bottom-line-deliverable"></div>
            </div>
            <div className="deliverable-sub-detail-content">
                {deliverables
                    .filter(
                        ({ Deliverable }) =>
                            Deliverable.Milestone.id == milestone.id,
                    )
                    .map(({ Deliverable, Submission, id }, index) => {
                        const status = Submission ? 'Completed' : 'Pending';
                        return (
                            <div className="deliverable-sub-detail">
                                <div
                                    className="deliverable-sub-detail-dropdown"
                                    // style={{ background: bgColor }}
                                    // onClick={changeColor}
                                >
                                    <div
                                        className="dropdown-container"
                                        onClick={() =>
                                            setShowDrop(
                                                showDrop === index
                                                    ? null
                                                    : index,
                                            )
                                        }
                                    >
                                        <div className="dropdown">
                                            <div className="sub-detail-left">
                                                <img
                                                    className="research-icon"
                                                    src={ResearchIcon}
                                                    alt={ResearchIcon}
                                                />
                                                <h4>{Deliverable.Title}</h4>
                                                <span
                                                    className={status.toLowerCase()}
                                                >
                                                    ({status})
                                                </span>
                                            </div>
                                            <div className="sub-detail-right">
                                                {status === 'Completed' && (
                                                    <img 
                                                    className='completed-tick'
                                                    src={CompleteTick} />
                                                )}
                                                <img
                                                    onClick={() =>
                                                        setShowDrop(
                                                            showDrop === index
                                                                ? null
                                                                : index,
                                                        )
                                                    }
                                                    className="dropdown-icon"
                                                    src={DropdownIcon}
                                                    alt={DropdownIcon}
                                                />
                                            </div>
                                        </div>
                                        {showDrop === index && (
                                            <div className="dropdown-header-color-change">
                                                <div className="dropdown-content">
                                                    {status === 'Completed' && (
                                                        <div className="viwe-submission">
                                                            <h4></h4>
                                                            <Link
                                                                to={`/user/deliverables/${id}/submit`}
                                                            >
                                                                <p>
                                                                    View
                                                                    Submissions
                                                                </p>
                                                            </Link>
                                                        </div>
                                                    )}
                                                    <div className="drodown-sub-content">
                                                        <p>
                                                            <div
                                                                dangerouslySetInnerHTML={{
                                                                    __html: Deliverable.Description,
                                                                }}
                                                            />
                                                        </p>
                                                    </div>
                                                </div>
                                                {status === 'Pending' && (
                                                    <div className="submit-your-submission">
                                                        <Button
                                                            onClick={() => {
                                                                window.location.href = `/user/deliverables/${id}/submit`;
                                                            }}
                                                            width="auto"
                                                            height="40px"
                                                            padding="0px 20px !important"
                                                            hover="#5D43A5"
                                                            margin="0px 10px 20px 0px"
                                                        >
                                                            Submit Assignment
                                                        </Button>
                                                    </div>
                                                )}
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        );
                    })}
            </div>
        </div>
    );
};

export default DeliverableDetailRightContent;
