import React from 'react';
import ProgressBar from "./ProgressBar";
import {
    UploadDocumentsContent
} from "../../Dashboard/Users/Scholars/Subcomponents/UserProfile/Upload Documents/UploadDocuments";

const Personal = ({id}) => {
    return <>
        <div className="user-profile-section">
            <ProgressBar />
            <UploadDocumentsContent scholarId={id}/>
        </div>
    </>
}
export default Personal;
