import {useEffect, useState} from 'react';

const useFilters = (filters, filterValues, showAll = true) => {
    const [filterFields, setFilterFields] = useState([]);

    function getFilterConfiguration(filter, filterValues) {
        switch (filter) {
            case 'fromDate':
                return {
                    field: 'From',
                    type: 'date',
                    placeholder: 'From Date',
                };
            case 'toDate':
                return {
                    field: 'To',
                    type: 'date',
                    placeholder: 'To Date'
                };
            case 'campus':
                return {
                    field: 'CampusId',
                    placeholder: 'Select Campus',
                    options: {
                        type: 'dynamic',
                        endpoint: 'Infrastructure/Campus',
                        paged: true,
                        valueKey: 'Id',
                        labelKey: 'DisplayName',
                        all: showAll,
                        showAllLabel: 'All Campuses',
                        showAllValue: null,
                    },
                };
            case 'cluster':
                return {
                    field: 'ClusterId',
                    placeholder: 'Select Registry',
                    options: {
                        type: 'dynamic',
                        endpoint: `Infrastructure/Cluster`,
                        endpointQueryParams: filterValues.CampusId
                            ? {CampusId: filterValues.CampusId}
                            : {},
                        paged: true,
                        optionsFilterCallback: (cluster, formValue) => {
                            return cluster.CampusId === formValue.CampusId;
                        },
                        valueKey: 'Id',
                        labelKey: 'Name',
                        all: showAll,
                        showAllLabel: 'All Registries',
                        showAllValue: null,
                    },
                };
            case 'researchCenter':
                return {
                    field: 'ResearchCenterId',
                    placeholder: 'Select Discipline',
                    options: {
                        type: 'dynamic',
                        endpoint: `Infrastructure/ResearchCenter`,
                        endpointQueryParams: filterValues.ClusterId
                            ? {ClusterId: filterValues.ClusterId}
                            : {},
                        paged: true,
                        optionsFilterCallback: (cluster, formValue) => {
                            return cluster.ClusterId === formValue.ClusterId;
                        },
                        valueKey: 'Id',
                        labelKey: 'Name',
                        all: showAll,
                        showAllLabel: 'All Disciplines',
                        showAllValue: null,
                    },
                };
            case 'academicYear':
                return {
                    field: 'AcademicYearId',
                    placeholder: 'Select Academic Year',
                    options: {
                        type: 'dynamic',
                        endpoint: `AcademicStructure/AcademicYear`,
                        endpointQueryParams: filterValues.ResearchCenterId
                            ? {
                                CampusId: filterValues.CampusId,
                            }
                            : {},
                        paged: true,
                        optionsFilterCallback: (cluster, formValue) => {
                            return cluster.CampusId === formValue.CampusId;
                        },
                        valueKey: 'Id',
                        labelKey: 'Name',
                        all: showAll,
                        showAllLabel: 'All Academic Years',
                        showAllValue: null,
                    },
                };
            case 'batch':
                return {
                    field: 'BatchId',
                    placeholder: 'Select Batch',
                    options: {
                        type: 'dynamic',
                        endpoint: `AcademicStructure/Batch`,
                        endpointQueryParams: filterValues.ResearchCenterId
                            ? {
                                ResearchCenterId:
                                filterValues.ResearchCenterId,
                            }
                            : {},
                        paged: true,
                        optionsFilterCallback: (cluster, formValue) => {
                            return (
                                cluster.ResearchCenterId ===
                                formValue.ResearchCenterId
                            );
                        },
                        valueKey: 'Id',
                        labelKey: 'Name',
                        all: showAll,
                        showAllLabel: 'All Batches',
                        showAllValue: null,
                    },
                };
            case 'discipline':
                return {
                    field: 'DisciplineId',
                    placeholder: 'Select Specialization',
                    options: {
                        type: 'dynamic',
                        endpoint: `AcademicStructure/Discipline`,
                        endpointQueryParams: filterValues.ResearchCenterId
                            ? {
                                ResearchCenterId: filterValues.ResearchCenterId,
                            }
                            : {},
                        paged: true,
                        optionsFilterCallback: (cluster, formValue) => {
                            return cluster.ResearchCenterId === formValue.ResearchCenterId;
                        },
                        valueKey: 'Id',
                        labelKey: 'Name',
                        all: showAll,
                        showAllLabel: 'All Specializations',
                        showAllValue: null,
                    },
                };
            case 'milestone':
                return {
                    field: 'MilestoneId',
                    placeholder: 'Select Deliverable',
                    options: {
                        type: 'dynamic',
                        endpoint: `AcademicStructure/Milestone`,
                        endpointQueryParams: filterValues.ResearchCenterId
                            ? {
                                ResearchCenterId:
                                filterValues.ResearchCenterId,
                            }
                            : {},
                        paged: true,
                        optionsFilterCallback: (cluster, formValue) => {
                            return (
                                cluster.ResearchCenterId ===
                                formValue.ResearchCenterId
                            );
                        },
                        valueKey: 'id',
                        labelKey: 'Title',
                        all: showAll,
                        showAllLabel: 'All Deliverables',
                        showAllValue: null,
                    },
                };
            case 'StaffType':
                return {
                    field: 'StaffType',
                    placeholder: 'Staff Type',
                    options: {
                        type: 'enum',
                        value: 'StaffType',
                        all: showAll,
                        showAllLabel: 'Both',
                        showAllValue: null,
                    },
                };
            case 'quota':
                return {
                    field: 'Quota',
                    placeholder: 'Quota',
                    options: {
                        type: 'enum',
                        value: 'Quota',
                        all: showAll,
                        showAllLabel: 'Both',
                        showAllValue: null,
                    },
                };
            case 'feePolicyStatus':
                return {
                    field: 'PolicyStatus',
                    placeholder: 'Policy Status',
                    options: {
                        type: 'static',
                        options: [
                            {'value': 'Assigned', label: 'Assigned'},
                            {'value': 'Unassigned', label: 'Not Assigned'},
                        ],
                        all: showAll,
                        showAllLabel: 'Both',
                        showAllValue: null,
                    },
                };
            case 'FacultyType':
                if (filterValues.StaffType === 'Teaching') {
                    return {
                        field: 'FacultyType',
                        placeholder: 'Faculty Type',
                        options: {
                            type: 'static',
                            options: [
                                {'value': 'Internal', label: 'Internal'},
                                {'value': 'External', label: 'External'},
                            ],
                            all: showAll,
                            showAllLabel: 'Both',
                            showAllValue: null,
                        },
                    };
                }
                return null;
            case 'StaffStatus':
                return {
                    field: 'StaffStatus',
                    placeholder: 'Staff Status',
                    options: {
                        type: 'enum',
                        value: 'StaffStatus',
                        all: showAll,
                        showAllLabel: 'All',
                        showAllValue: null,
                    },
                };
            default:
                return null;
        }
    }

    useEffect(() => {
        let ff = [];
        filters.forEach((filter) => {
            let value = getFilterConfiguration(filter, filterValues);
            if (value) {
                ff.push(value);
            }
        });
        setFilterFields(ff);
    }, [filters, filterValues]);

    return {filterFields};
};
export default useFilters;
