import React from 'react';
import GenericProfileForm from '../../../../Form/GenericProfileForm';

const AddProfessional = ({onClose, id, scholarId}) => {
    const initialValues = {
        "Title": "",
        "Publisher": "",
        "PublicationDate": "",
        "Year": '',
        "Authors": "",
        "URL": "",
        "Description": "",
        "CreatedBy": null,
        "IsDeleted": false,
        "PublicationType": "",
        "PublicationStatus": "",
        "PublicationLevel": "",
        "PublicationName": "",
        "VolumeNumber": "",
        "IssueNumber": "",
        "PageNumber": '',
        "AuthorsCount": '',
        "DOINumber": "",
        "ISSN": "",
        "HIndex": "",
        "ImpactFactor": "",
        UserProfile: scholarId,
    };

    const fields = [
        {
            type: 'select',
            field: 'PublicationType',
            label: 'Publication Type',
            description: 'This will be displayed on your profile.',
            estric: '*',
            options: {
                type: 'enum',
                value: 'PublicationType',
            }
        },
        {
            type: 'text',
            field: 'Authors',
            label: 'Name of Authors',
            description: 'This will be displayed on your profile.',
            estric: '*',
        },
        {
            type: 'text',
            field: 'Title',
            label: 'Publication Title',
            description: 'This will be displayed on your profile.',
            estric: '*',
        },
        {
            type: 'text',
            field: 'AuthorsCount',
            label: 'Number of Authors',
            description: 'This will be displayed on your profile.',
            estric: '*'
        },
        {
            type: 'text',
            field: 'Year',
            label: 'Year of Publication',
            description: 'This will be displayed on your profile.',
            estric: '*',
        },
        {
            type: 'text',
            field: 'PublicationName',
            label: 'Name of Journal/Book/Conference',
            description: 'This will be displayed on your profile.',
            estric: '*'
        },
        {
            type: 'text',
            field: 'Publisher',
            label: 'Publisher',
            description: 'This will be displayed on your profile.',
            estric: '*',
        },
        {
            type: 'text',
            field: 'VolumeNumber',
            label: 'Volume Number',
            description: 'This will be displayed on your profile.',
            required: false,
        },
        {
            type: 'text',
            field: 'IssueNumber',
            label: 'Issue Number',
            description: 'This will be displayed on your profile.',
            required: false,
        },
        {
            type: 'text',
            field: 'PageNumber',
            label: 'Page Number',
            description: 'This will be displayed on your profile.',
            required: false,
        },
        {
            type: 'text',
            field: 'URL',
            label: 'Online URL',
            description: 'This will be displayed on your profile.',
            estric: '*',
        },
        {
            type: 'text',
            field: 'ISSN',
            label: 'ISSN/ISBN Number',
            description: 'This will be displayed on your profile.',
            estric: '*',
        },
        {
            type: 'text',
            field: 'ImpactFactor',
            label: 'Impact Factor',
            description: 'This will be displayed on your profile.',
            required: false
        },
        {
            type: 'text',
            field: 'HIndex',
            label: 'H Index',
            description: 'This will be displayed on your profile.',
            required: false
        },
        {
            type: 'select',
            field: 'PublicationStatus',
            label: 'Publication Status',
            description: 'This will be displayed on your profile.',
            estric: '*',
            options: {
                type: 'static',
                options: [
                    {value: 'Accepted', label: 'Accepted'},
                    {value: 'Published', label: 'Published'},
                ]
            }
        },
        {
            type: 'select',
            field: 'PublicationLevel',
            label: 'Level',
            description: 'This will be displayed on your profile.',
            estric: '*',
            options: {
                type: 'static',
                options: [
                    {value: 'National', label: 'National'},
                    {value: 'International', label: 'International'},
                ]
            }
        },
        {
            type: 'date',
            field: 'PublicationDate',
            label: 'Publication Date',
            description: 'This will be displayed on your profile.',
            required: false
        },
        {
            type: 'text',
            field: 'DOINumber',
            label: 'DOI Number',
            description: 'This will be displayed on your profile.',
            required: false
        },
    ];

    return (
        <div className="userprofile-modal">
            <div className="modal-box">
                <div className="modal-heading">
                    <h5>{id ? 'Edit':'Add'} Publication Details</h5>
                    <span>Update your publication details here</span>
                </div>
                <img
                    className="modal-cross-icon"
                    onClick={onClose}
                    src="/images/cross.svg"
                    alt={'Close Modal'}
                />
            </div>

            <GenericProfileForm
                fields={fields}
                id={id}
                initialValues={initialValues}
                onClose={onClose}
                endpoint={'Publication'}
            />
        </div>
    );
};

export default AddProfessional;
