import React, { useRef } from 'react';
import GenericForm from '../../../../../components/Form/GenericForm';
import qs from 'qs';

const AddBatchContent = () => {
    let filters = qs.parse(window.location.search, { ignoreQueryPrefix: true });

    const formRef = useRef(null);

    const initialValues = {
        Name: '',
        Code: '',
        Contact: null,
        ClusterId: filters.ClusterId || 0,
        CampusId: filters.CampusId || 0,
        ResearchCenterId: filters.ResearchCenterId || 0,
        AcademicYearId: filters.AcademicYearId || 0,
    };

    const fields = [
        {
            type: 'select',
            field: 'CampusId',
            label: 'Select Campus',
            description: 'Batch will be added to the selected campus',
            estric: '*',
            options: {
                type: 'dynamic',
                endpoint: 'Infrastructure/Campus',
                paged: true,
                valueKey: 'Id',
                labelKey: 'DisplayName',
            },
            onChange: (value) => {
                formRef.current.updateData({
                    ClusterId: '',
                    ResearchCenterId: '',
                    AcademicYearId: '',
                });
            },
            shouldUpdateQueryParams: true
        },
        {
            type: 'select',
            field: 'ClusterId',
            label: 'Select Registry',
            description: 'Batch will be added to the selected Registry',
            estric: '*',
            options: {
                type: 'dynamic',
                endpoint: 'Infrastructure/Cluster',
                paged: true,
                valueKey: 'Id',
                labelKey: 'Name',
                optionsFilterCallback: (cluster, formValue) => {
                    return cluster.CampusId === formValue.CampusId;
                },
            },
            onChange: (value) => {
                formRef.current.updateData({ ResearchCenterId: '' });
            },
            shouldUpdateQueryParams: true
        },
        {
            type: 'select',
            field: 'ResearchCenterId',
            label: 'Select Discipline',
            description: 'Batch will be added to the selected Discipline',
            estric: '*',
            options: {
                type: 'dynamic',
                endpoint: 'Infrastructure/ResearchCenter',
                paged: true,
                valueKey: 'Id',
                labelKey: 'Name',
                optionsFilterCallback: (cluster, formValue) => {
                    return cluster.ClusterId === formValue.ClusterId;
                },
            },
            shouldUpdateQueryParams: true
        },
        {
            type: 'select',
            field: 'AcademicYearId',
            label: 'Select Academic Year',
            description: 'Batch will be added to the selected academic year',
            estric: '*',
            options: {
                type: 'dynamic',
                endpoint: 'AcademicStructure/AcademicYear',
                valueKey: 'Id',
                labelKey: 'Name',
                optionsFilterCallback: (cluster, formValue) => {
                    return cluster.CampusId === formValue.CampusId;
                },
            },
            shouldUpdateQueryParams: true
        },
        {
            type: 'text',
            field: 'Name',
            label: 'Batch name',
            description: 'Name of the Batch',
            estric: '*',
        },
        {
            type: 'text',
            field: 'Code',
            label: 'Batch Code',
            description: 'Unique identifier for the batch',
            estric: '*',
        },

        {
            type: 'text',
            field: 'Contact',
            label: 'Contact Person',
            description:
                'Name and phone number of the someone to be contacted for batch-related queries',
            required: false,
        },
    ];

    return (
        <GenericForm
            ref={formRef}
            fields={fields}
            initialValues={initialValues}
            endpoint={'AcademicStructure/Batch'}
            redirectTo={'/batch'}
        />
    );
};

export default AddBatchContent;
