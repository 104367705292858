import React, {useRef} from 'react';
import GenericProfileForm from '../../../../Form/GenericProfileForm';
import ModalHeading from '../../../Modal Heading/ModalHeading';

const AddProfessional = ({ onClose, id, scholarId }) => {


    const formRef = useRef(null);
    const initialValues = {
        id: 1,
        SchoolName: '',
        FieldOfStudy: '',
        Degree: '',
        Grade: '',
        PassingOutYear: '',
        Achievement: '',
        CreatedBy: null,
        IsDeleted: false,
        UserProfile: scholarId,
        Description: '',
    };

    const fields = [
        {
            type: 'text',
            field: 'SchoolName',
            label: 'Institution',
            description: 'This will be displayed on your profile.',
            estric: '*',
        },
        {
            type: 'text',
            field: 'FieldOfStudy',
            label: 'Field Of Study',
            description: 'This will be displayed on your profile.',
            estric: '*',
        },
        {
            type: 'select',
            field: 'QualificationType',
            label: 'Qualification Type',
            description: 'This will be displayed on your profile.',
            estric: '*',
            options: {
                type: 'enum',
                value: 'QualificationType',
            },
        },
        {
            type: 'text',
            field: 'Degree',
            label: 'Degree',
            description: 'This will be displayed on your profile.',
            estric: '*',
            required: true,
        },
        {
            type: 'text',
            field: 'Affiliation',
            label: 'Affiliation',
            description: 'This will be displayed on your profile.',
            estric: '*',
            required: true,
        },

        {
            type: 'text',
            field: 'PassingOutYear',
            label: 'Passing Out Year',
            description: 'This will be displayed on your profile.',
            estric: '*',
            required: true,
        },
        {
            type: 'text',
            field: 'MarksObtained',
            label: 'Marks Obtained',
            description: 'This will be displayed on your profile.',
            required: false,
            onChange: (value) => {
                const marksObtained = parseFloat(value)
                if (!marksObtained) {
                    formRef.current.updateData({
                        Percentage: '',
                    });
                    return;
                }
                const max =parseFloat(formRef.current.getData().MaximumMarks);
                if (!max) {
                    formRef.current.updateData({
                        Percentage: '',
                    });
                }
                const percentage = (marksObtained / max) * 100;
                formRef.current.updateData({
                    Percentage: `${percentage.toFixed(2)}%`,
                });
            }
        },
        {
            type: 'text',
            field: 'MaximumMarks',
            label: 'Maximum Marks',
            description: 'This will be displayed on your profile.',
            required: false,
            onChange: (value) => {
                const max = parseFloat(value)
                if (!max) {
                    formRef.current.updateData({
                        Percentage: '',
                    });
                    return;
                }
                const marksObtained =parseFloat(formRef.current.getData().MarksObtained);
                if (!marksObtained) {
                    formRef.current.updateData({
                        Percentage: '',
                    });
                }
                const percentage = (marksObtained / max) * 100;
                const v = percentage ? `${percentage.toFixed(2)}%` : '';
                formRef.current.updateData({
                    Percentage: v,
                });
            }
        },
        {
            type: 'text',
            field: 'Percentage',
            label: 'Percentage/CGPA',
            description: 'This will be displayed on your profile.',
            required: false,
            disabled: true,
        },

        {
            type: 'textarea',
            field: 'Description',
            label: 'Description',
            description: 'This will be displayed on your profile.',
            required: false,
        },
        {
            type: 'file',
            field: 'File',
            label: 'File',
            description: 'This will be displayed on your profile.',
            estric: '*',
            showPreview: false,
            onChange: (value) => {
                let files = JSON.parse(value);
                console.log({files})
                if(files.length === 0){
                    formRef.current.updateData({
                        Attachments:"",
                        File:""
                    });
                    return;
                }
                formRef.current.updateData({
                    Attachments:JSON.stringify(files[0])
                });
            },
            required: true,
        },
    ];

    return (
        <div className="userprofile-modal">
            <div className="modal-box">
                <div className="modal-heading">
                    <ModalHeading
                        title={`${id ? 'Edit':'Add'} Qualification`}
                    />
                </div>
                <img
                    className="modal-cross-icon"
                    onClick={onClose}
                    src="/images/cross.svg"
                    alt={'Close Modal'}
                />
            </div>

            <GenericProfileForm
                fields={fields}
                id={id}
                initialValuesUpdated={(values)=>{
                    if(!values.Attachments){
                        return;
                    }
                    formRef.current.updateData({
                        File:JSON.stringify([
                            JSON.parse(values.Attachments)
                        ])
                    });
                }}
                ref={formRef}
                initialValues={initialValues}
                onClose={onClose}
                endpoint={'Qualification'}
            />
        </div>
    );
};

export default AddProfessional;
