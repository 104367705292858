import * as axios from 'axios';

export default class Api {
    static api_url = process.env.REACT_APP_BACKEND_BASE_URL;

    static init(authorize = true) {
        const headers = {
            Accept: 'application/json',
        };

        if (authorize) {
            headers.Authorization = localStorage.getItem('token');
        }

        const subDomain = window.location.host.split('.');

        if (subDomain.length > 0) {
            // eslint-disable-next-line prefer-destructuring
            // headers['X-App-Env'] = subDomain[0];
        }

        return axios.create({
            baseURL: Api.api_url,
            timeout: 31000,
            headers,
        });
    }

    static post(url, data, authorize = true) {
        return new Promise(async (resolve, reject) => {
            Api.init(authorize)
                .post(url, data)
                .then((response) => {
                    resolve(response.data);
                })
                .catch((e) => {
                    reject(e.response ? e.response.data : {});
                });
        });
    }

    static get(url, params, onUnAuthorized) {
        return new Promise(async (resolve, reject) => {
            Api.init()
                .get(url, { params })
                .then((response) => {
                    resolve(response.data);
                })
                .catch((e) => {
                    if(e.response && e.response.status === 403) {
                        window.location.href = '/unauthorized-access'
                        return;
                    }
                    if(e.response && e.response.status === 401) {
                        window.location.href = '/login'
                        return;
                    }
                    reject(e.response ? e.response.data : {});
                });
        });
    }

    static delete(url) {
        return new Promise(async (resolve, reject) => {
            Api.init()
                .delete(url)
                .then((response) => {
                    resolve(response.data);
                })
                .catch((e) => {
                    reject(e.response ? e.response.data : {});
                });
        });
    }

    static login(params) {
        return Api.post('/users/login', params, false);
    }

    static getCampuses(page = 1, limit = 10) {
        return Api.get('/Infrastructure/Campus/getEntitiesPaged', {
            PageNo: page,
            PerPage: limit,
        });
    }

    static createCampus(data) {
        return Api.post('/Infrastructure/Campus/postEntity', data);
    }

    static createCluster(data, id) {
        if (!id) {
            return Api.post('/Infrastructure/Cluster/postEntity', data);
        } else {
            return Api.post(`/Infrastructure/Cluster/putEntity/${id}`, data);
        }
    }

    static createObject(endpoint, data, id, doNotAppendWithPutPostEntity) {
        if (!id) {
            return Api.post(`/${endpoint}/${doNotAppendWithPutPostEntity? '':'postEntity'}`, data);
        } else {
            return Api.post(`/${endpoint}/${doNotAppendWithPutPostEntity? '':'putEntity/'}${id}`, data);
        }
    }

    static createProfileObject(endpoint, data, id) {
        if (!id) {
            return Api.post(`/UserProfile/${endpoint}/PostEntity`, data);
        } else {
            return Api.post(`/UserProfile/${endpoint}/PutEntity/${id}`, data);
        }
    }
}
