import React, {useState} from 'react';
import Header from '../../../components/Header/Header';
import Heading from '../../../components/Heading/Heading';
import Sidebar from '../../../components/Sidebar/Sidebar';
import GeneralView from '../View Campus/Subcomponents/GeneralView';
import {useParams} from 'react-router-dom';
import useDetailApi from '../../../../hooks/useDetailApi';
import Backarrow from '../../../../images/back-arrow.svg';
import MediaQuery from 'react-responsive';
import MobileHeader from '../../../components/Sidebar/Mobile Design/Mobile Header/MobileHeader';
import Api from "../../../../api/api";

const ViewDepartment = () => {
    const {id} = useParams();
    const {loading, item} = useDetailApi('Users/Staff', id);

    const fields = [
        {
            type: 'file',
            fileType: 'image/*',
            field: 'ProfilePhoto',
            label: 'Image',
            required: true,
            fullWidth: true,
            render: (item) => {
                if(!item.ProfilePhoto){
                    return '-';
                }
                return <img src={`${Api.api_url}/uploads/${item.ProfilePhoto}`} alt="image" width={100} height={100}/>;
            }
        },
        {
            type: 'select',
            field: 'CampusName',
            label: 'Campus',
            description: 'The employee will be added to the selected campus',
            estric: '*',
            options: {
                type: 'dynamic',
                endpoint: 'Infrastructure/Campus',
                paged: true,
                valueKey: 'Id',
                labelKey: 'DisplayName',
            },

        },
        {
            type: 'select',
            field: 'ClusterName',
            label: 'Registry',
            description: 'The employee will be added to the selected Registry',
            estric: '*',
            options: {
                type: 'dynamic',
                endpoint: 'Infrastructure/Cluster',
                paged: true,
                valueKey: 'Id',
                labelKey: 'Name',
                optionsFilterCallback: (cluster, formValue) => {
                    return cluster.CampusId === formValue.CampusId;
                },
            },

        },
        {
            type: 'select',
            field: 'ResearchCenterName',
            label: 'Discipline',
            description:
                'The employee will be added to the selected discipline',
            estric: '*',
            className: 'select-multi',
            options: {
                type: 'dynamic',
                endpoint: 'Infrastructure/ResearchCenter',
                paged: true,
                valueKey: 'Id',
                labelKey: 'Name',
                multi: true,
                optionsFilterCallback: (cluster, formValue) => {
                    return cluster.ClusterId === formValue.ClusterId;
                },
            },

        },
        {
            type: 'select',
            field: 'SpecializationName',
            label: 'Specializations',
            description: 'This will be displayed on your profile.',
            estric: '*',
            options: {
                type: 'dynamic',
                endpoint: 'AcademicStructure/Discipline',
                paged: true,
                valueKey: 'Id',
                labelKey: 'Name'
            },
            render: (item) => {
                return item.Disciplines.map((discipline) => {
                    return discipline.Discipline.Name;
                }).join(', ');
            },
            onChange: (value) => {},
        },
        {
            type: 'text',
            field: 'EmployeeId',
            label: 'Employee Id',
            description: ' Unique identifier for the employee',
            estric: '*',
        },
        {
            type: 'text',
            field: 'Designation',
            label: 'Designation',
            description: 'The position of the employee',
            estric: '*',
        },
        {
            type: 'select',
            field: 'Honorifics',
            label: 'Title',
            description: 'Title to be added before the name of the employee',
            estric: '*',
            options: {
                type: 'enum',
                value: 'Honorifics',
            },
        },

        {
            type: 'text',
            field: 'FirstName',
            label: 'First Name',
            description: 'First name of the employee',
            estric: '*',
        },
        {
            type: 'text',
            field: 'MiddleName',
            label: 'Middle Name',
            description: 'Last name of the employee',
            required: false
        },
        {
            type: 'text',
            field: 'LastName',
            label: 'Last Name',
            description: 'Last name of the employee',
            required: false
        },
        {
            type: 'select',
            field: 'Gender',
            label: 'Gender',
            description: 'Gender of the employee',
            estric: '*',
            options: {
                type: 'enum',
                value: 'Gender',
            },
        },
        {
            type: 'text',
            field: 'Email',
            label: 'Email',
            description: 'Email address of the employee',
            estric: '*',
        },
        {
            type: 'text',
            field: 'PhoneNumber',
            label: 'Phone Number',
            description: 'The phone number of the employee',
            estric: '*',
        },
        {
            type: 'date',
            field: 'DateOfJoining',
            label: 'Date of joining',
            description: 'Is this user a faculty member?',
            estric: '*'
        },
        {
            type: 'text',
            field: 'StaffStatus',
            label: 'Staff Status',
            description: 'Is this user a faculty member?',
            estric: '*'
        },
        {
            type: 'select',
            field: 'StaffType',
            label: 'Staff Type',
            description: 'Is this user a faculty member?',
            estric: '*'
        }
    ];

    if(item && item.StaffType === 'Teaching'){
        fields.push({
            type: 'select',
            field: 'FacultyType',
            label: 'Faculty Type',
            description: 'Is this internal or external faculty',
            estric: '*',
            options: {
                type: 'static',
                options: [{
                    value: 'Internal', label: 'Internal'
                }, {
                    value: 'External', label: 'External'
                }]
            },
        });
    }

    const [sendingLoginCredentials, setSendingLoginCredentials] = useState(false);
    const resendLoginCredentials = ()=>{
        if(sendingLoginCredentials){
            return;
        }
        setSendingLoginCredentials(true)
        Api.post(`/Users/Staff/ResendEmail/${id}`).then((res)=>{
            alert('Login credentials successfully sent.')
        }).catch((err)=>{
            alert('Error sending login credentials: '.err.message)
        }).finally(()=>{
            setSendingLoginCredentials(false)
        });
    };
    return (
        <div className="main-wrapper">
            <MediaQuery maxWidth={1023}>
                <MobileHeader/>
            </MediaQuery>
            <Sidebar/>

            <div className="screen">
                {/* <Header
                    homelink="/dashboard"
                    breadcrumb="Staff"
                    breadcrumbs="View Staff"
                    innerpath="/staff"
                /> */}
                <Heading
                    title="Staff Details"
                    description="View all details of the Staff below"
                    link={sendingLoginCredentials ? 'Sending...' : "Resend login credentials to staff"}
                    onLinkClick={resendLoginCredentials}
                    linktext="Back to Staff"
                    icon={Backarrow}
                    href="/staff"
                    width="auto"
                    height="44px"
                    backgroundcolor="none"
                    color="#5D43A5"
                    fontsize="16px"
                    hover="#F9F5FF"
                    padding="0px 20px !important"
                    className="view-back-button"
                />
                {loading ? (
                    <div> Loading... </div>
                ) : (
                    <>
                        <GeneralView campus={item} fields={fields}/>
                    </>
                )}
            </div>
        </div>
    );
};

export default ViewDepartment;
