import React, {useState} from 'react';
import Heading from '../../../../../../../components/Heading/Heading';
import Plus from '../../../../../../../../images/plus.svg';
import {Modal} from 'react-bootstrap';
import AddProfessional
    from '../../../../../../../components/Modals/User Profile Modals/Subcomponents/Add Professional Details/AddProfessional';
import useProfileListApi from '../../../../../../../../hooks/useProfileListApi';
import ProfileTable from '../../../../../../../components/Table/ProfileTable';

const ProfessionalDetailContent = ({id}) => {
    const {rows, refresh} = useProfileListApi('ProfessionInfo', id);
    const [objectId, setObjectId] = useState(null);
    const [totalRecords, setTotalRecords] = useState(0);
    const [recordsPerPage, setRecordsPerPage] = useState(0);
    const [currentPage, setCurrentPage] = useState(0);

    const [userprofileModal, setuserprofileModal] = useState(false);
    const userprofileModalClose = () => {
        refresh();
        setuserprofileModal(false);
    };
    const userprofileModalShow = () => setuserprofileModal(true);

    const column = [
        {
            heading: 'Designation',
            value: 'Headline',
        },
        {
            heading: 'Organization Name',
            value: 'OrganizationName',
        },
        {
            heading: 'Experience Type',
            value: 'ExperienceType',
        },
        {
            heading: 'Start Date',
            value: 'StartDate',
        },
        {
            heading: 'End Date',
            value: 'EndDate',
        },
        {
            heading: 'Still Working',
            render: (item) => (item.JobStatus ? 'Yes' : 'No')
        },
    ];

    return (
        <div className="profile-screen">
            <div className="profile-heading">
                <Heading
                    title="Experience"
                    description="Add your experiences here."
                    linktext="Add More"
                    icon={Plus}
                    width="auto"
                    height="40px"
                    padding="0px 20px"
                    onClick={() => {
                        setObjectId(null)
                        userprofileModalShow()
                    }}

                />
            </div>

            <ProfileTable
                shouldView={false}
                checkboxshouldView={false}
                showaction={true}
                resource={'ProfessionInfo'}
                refresh={refresh}
                shouldAssignFee={false}
                data={rows}
                totalRecords={totalRecords}
                perPage={recordsPerPage}
                setPerPage={setRecordsPerPage}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                column={column}
                title="Experience"
                tableHeading="All experiences"
                onEdit={(item) => {
                    setObjectId(item.id);
                    setuserprofileModal(true);
                }}
            />

            {/* Modal */}

            <Modal
                show={userprofileModal}
                onHide={userprofileModalClose}
                centered
                className="userprofileModal"
            >
                <AddProfessional
                    onClose={userprofileModalClose}
                    scholarId={id}
                    id={objectId}
                />
            </Modal>
        </div>
    );
};

export default ProfessionalDetailContent;
