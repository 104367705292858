import React, { useEffect, useState } from 'react';
import ReactPaginate from 'react-paginate';
import Leftarrow from '../../../images/left-arrow.svg';
import Rightarrow from '../../../images/right-arrow.svg';
import Leftdiabledarrow from '../../../images/left-arrow-disabled.svg';
import Rightdisabledarrow from '../../../images/right-arrow-disabled.svg';

const LEFT_PAGE = 'LEFT';
const RIGHT_PAGE = 'RIGHT';
const range = (from, to, step = 1) => {
    let i = from;
    const range = [];

    while (i <= to) {
        range.push(i);
        i += step;
    }

    return range;
};

const Pagination = (props) => {
    const {
        totalRecords,
        pageLimit,
        pageNeighbours,
        onPageChanged,
        currentPage,
        setPerPage,
        onPageChange,
        exportTable,
        exportFileName,
    } = props;
    const [totalPages, setTotalPages] = useState(100);
    useEffect(() => {
        setTotalPages(Math.ceil(totalRecords / pageLimit));
    }, [pageLimit, setTotalPages, totalRecords]);

    const [exportData, setExportData] = useState([]);

    let pageCount = Math.ceil(totalRecords / pageLimit);
    return (
        <>
            <div className="pagination-content">
                <div className="pagination">
                    <div className="select-items-pagination">
                        <span>Show Items</span>
                        <select
                            value={pageLimit}
                            onChange={(e) => {
                                setPerPage(e.target.value);
                            }}
                        >
                            <option value={10}>10</option>
                            <option value={20}>20</option>
                            <option value={50}>50</option>
                            <option value={100}>100</option>
                            <option value={500}>500</option>
                            <option value={1000}>1000</option>
                            <option value="all">All</option>
                        </select>
                    </div>
                </div>

                <div className="pagination-right">
                    <nav aria-label="Countries Pagination">
                        <ul className="pagination">
                            {pageLimit !== 'all' && (
                                <ReactPaginate
                                    nextLabel={
                                        <div className="arrows-page-next">
                                            <span>Next</span>
                                            {currentPage < pageCount ? (
                                                <img
                                                    className="right-arrow"
                                                    src={Rightarrow}
                                                    style={{ marginLeft: 10 }}
                                                />
                                            ) : (
                                                <img
                                                    className="right-arrow"
                                                    src={Rightdisabledarrow}
                                                    style={{ marginLeft: 10 }}
                                                />
                                            )}
                                        </div>
                                    }
                                    onPageChange={(e) => {
                                        onPageChange(e.selected + 1);
                                    }}
                                    pageCount={totalPages}
                                    previousLabel={
                                        <div className="arrows-page">
                                            {currentPage === 1 ? (
                                                <img
                                                    className="left-arrow"
                                                    src={Leftdiabledarrow}
                                                    style={{ marginRight: 10 }}
                                                />
                                            ) : (
                                                <img
                                                    className="left-arrow"
                                                    src={Leftarrow}
                                                    style={{ marginRight: 10 }}
                                                />
                                            )}
                                            <span>Previous</span>
                                        </div>
                                    }
                                    pageClassName="page-item"
                                    pageLinkClassName="page-link"
                                    previousClassName="page-item border-radius-left"
                                    previousLinkClassName="page-link"
                                    nextClassName="page-item border-radius-right"
                                    forcePage={currentPage - 1}
                                    nextLinkClassName="page-link"
                                    breakLabel="..."
                                    breakClassName="page-item"
                                    breakLinkClassName="page-link"
                                    containerClassName="pagination"
                                    activeClassName="active"
                                    disabledClassName="disable"
                                    renderOnZeroPageCount={null}
                                />
                            )}
                        </ul>
                    </nav>
                </div>
            </div>
        </>
    );
};

export default Pagination;
