import React, {useRef, useState} from 'react';
import {Button} from '../../../../../../../components/Buttons/Button';
import {ExportButton} from '../../../../../../../components/Buttons/ExportButton';
import Heading from '../../../../../../../components/Heading/Heading';
import {SimpleInput} from '../../../../../../../components/Inputs/SimpleInput';
import Userprofile from '../../../../../../../../images/profile.svg';
import UploadDragDrop from '../../../../../../../components/Upload File/UploadDragDrop';
import Calendar from '../../../../../../../../images/calendar.svg';
import Email from '../../../../../../../../images/email.svg';
import Call from '../../../../../../../../images/call.svg';
import SelectInput from '../../../../../../../components/Inputs/SelectInput';
import AddScholarContent from '../../Add Scholars/AddScholarContent';
import GenericForm from '../../../../../../../components/Form/GenericForm';
import qs from 'qs';
import {useParams} from 'react-router-dom';
import Download from '../../../../../../../../images/download-resume.svg';
import useDetailApi from "../../../../../../../../hooks/useDetailApi";
import GeneralView from "../../../../../View Campus/Subcomponents/GeneralView";
import Api from "../../../../../../../../api/api";

const gender = [{value: 'male', label: 'Male'}, {value: 'female', label: 'Female'}, {value: 'other', label: 'Others'},];

const bloodgroup = [{value: 'a', label: 'A+'}];

const nationality = [{value: 'indian', label: 'Indian'}];

const maritalstatus = [{value: 'single', label: 'Single'}, {value: 'married', label: 'Married'},];

const physicallydisabled = [{value: 'yes', label: 'Yes'}, {value: 'no', label: 'No'},];

const quota = [{value: 'defence', label: 'Defence'}];

const category = [{value: 'sc', label: 'SC'}];

const PersonalInfoContent = ({id, redirecTo, disabled}) => {
    let {scholarId} = useParams();

    if (id) {
        scholarId = id;
    }
    const {loading: initialising, item} = useDetailApi('UserProfile/UserProfile/getPersonalInfo', id, true,);

    const isStaff = window.location.pathname.startsWith('/staff');
    console.log({isStaff})

    let filters = qs.parse(window.location.search, {ignoreQueryPrefix: true});

    const initialValues = {
        UniversityRollNo: '',
        CampusId: filters.CampusId || '',
        ClusterId: filters.ClusterId || '',
        ResearchCenterId: filters.ResearchCenterId || '',
        BatchId: filters.BatchId || '',
        ProgramId: filters.ProgramId || '',
        DisciplineId: filters.DisciplineId || '',
        Honorifics: '',
        FirstName: '',
        MiddleName: '',
        Lastname: '',
        Gender: '',
        Email: '',
        PhoneNumber: '',
        MotherName: '',
        FatherName: '',
        BirthDate: '',
        Domicile: '',
        Category: '',
        Quota: '',
        City: '',
        Nationality: '',
        CurrentAddress: '',
        PermanentAddress: '',
        ProfilePhoto: '',
        AadharCardNumber: '',
        PanCardNumber: '',
        CreatedBy: null,
    };

    const formRef = useRef(null);

    const fields = [{
        type: 'file',
        fileType: 'image/*',
        field: 'ProfilePhoto',
        required: false,
        fullWidth: true,
        label: 'Profile Photo',
        className: 'file-upload-admin',
        render: (item) => {
            if (!item.ProfilePhoto) {
                return '-';
            }
            return <img src={`${Api.api_url}/uploads/${item.ProfilePhoto}`} alt="image" width={100} height={100}/>;
        }
    }, {}, {
        type: 'select',
        field: 'Honorifics',
        label: 'Title',
        description: 'This will be displayed on your profile.',
        estric: '*',
        options: {
            type: 'enum', value: 'Honorifics',
        },
    },

        {
            type: 'text',
            field: 'FirstName',
            label: 'First Name',
            description: 'This will be displayed on your profile.',
            estric: '*',
            preChange: (e, handleChange) => {
                e.target.value = e.target.value.toUpperCase();
                handleChange(e);
            }
        }, {
            type: 'text',
            field: 'MiddleName',
            label: 'Middle Name',
            description: 'This will be displayed on your profile.',
            required: false,
            preChange: (e, handleChange) => {
                e.target.value = e.target.value.toUpperCase();
                handleChange(e);
            }
        }, {
            type: 'text',
            field: 'LastName',
            label: 'Last Name',
            description: 'This will be displayed on your profile.',
            required: false,
            preChange: (e, handleChange) => {
                e.target.value = e.target.value.toUpperCase();
                handleChange(e);
            }
        }, {
            type: 'select',
            field: 'Gender',
            label: 'Gender',
            description: 'This will be displayed on your profile.',
            estric: '*',
            options: {
                type: 'enum', value: 'Gender',
            },
        }, {
            type: 'text',
            field: 'Email',
            label: 'Email',
            description: 'This will be displayed on your profile.',
            estric: '*',
        }, {
            type: 'text',
            field: 'PhoneNumber',
            label: 'Phone Number',
            description: 'This will be displayed on your profile.',
            estric: '*',
        }, {
            type: 'text',
            field: 'MotherName',
            label: 'Mother Name',
            description: 'This will be displayed on your profile.',
            estric: '*',
        }, {
            type: 'text',
            field: 'FatherName',
            label: 'Father Name',
            description: 'This will be displayed on your profile.',
            estric: '*',
        }, {
            type: 'date',
            field: 'BirthDate',
            label: 'Date of Birth',
            description: 'This will be displayed on your profile.',
            estric: '*',
        }, {
            type: 'select',
            field: 'Domicile',
            label: 'Domicile',
            description: 'This will be displayed on your profile.',
            estric: '*',
            options: {
                type: 'enum', value: 'Domicile',
            },
        }, {
            type: 'select',
            field: 'Category',
            label: 'Category',
            description: 'This will be displayed on your profile.',
            estric: '*',
            options: {
                type: 'enum', value: 'Category',
            },
        }, {
            type: 'select',
            field: 'Quota',
            label: 'Quota',
            description: 'This will be displayed on your profile.',
            estric: '*',
            options: {
                type: 'enum', value: 'Quota',
            },
            hide: isStaff,
            required: !isStaff,
        }, {
            type: 'text',
            field: 'City',
            label: 'City',
            description: 'This will be displayed on your profile.',
            estric: '*',
        }, {
            type: 'text',
            field: 'Nationality',
            label: 'Nationality',
            description: 'This will be displayed on your profile.',
            estric: '*',
        }, {
            type: 'text',
            field: 'PanCardNumber',
            label: 'PAN Card Number',
            description: 'This will be displayed on your profile.',
            required: false,
        }, {
            type: 'text',
            field: 'AadharCardNumber',
            label: 'Aadhar Card Number',
            description: 'This will be displayed on your profile.',
            required: false,
        },
        {
            type: 'text',
            field: 'PassportNumber',
            label: 'Passport Number',
            description: 'This will be displayed on your profile.',
            required: false,
        },
    ];


    return (<div className="profile-screen">
        <div className="profile-heading">
            <Heading
                title="Personal info"
                description="View your personal details and profile photo here"
                width="auto"
                padding="0px 10px 0px 10px"
                linktext="Download Resume"
                icon={Download}
                href={`/scholar/${scholarId}/resume`}
            />
        </div>

        <div className="personal-info">
            {initialising && <>Please wait..</>}
            {!initialising && <>
                {disabled ? <GeneralView fields={fields} campus={item}/> : <GenericForm

                    id={scholarId}
                    twoColumns={true}
                    ref={formRef}
                    fields={fields}
                    initialValues={initialValues}
                    endpoint={'UserProfile/UserProfile/getPersonalInfo'}
                    putEndpoint={'UserProfile/UserProfile'}
                    doNotAppendWithGetEntity={true}
                    redirectTo={window.location.href}
                />}
            </>}
        </div>
    </div>);
};

export default PersonalInfoContent;
