import React from 'react';

const Switch = ({
    value,
    useBoolean,
    name,
    onChange,
    onLabel = '',
    offLabel = '',
}) => {
    return (
        <div className={`switch ${onChange || 'readonly'}`}>
            <div
                onClick={(e) => {
                    if (!onChange) {
                        return;
                    }
                    onChange({
                        target: { name, value: value == 1 ? 0 : 1 },
                    });
                }}
            >
                {value == 1 ? (
                    <img src="/images/switch-on.svg" />
                ) : (
                    <img src="/images/switch-off.svg" />
                )}
                {value == 1 ? onLabel : offLabel}
            </div>
        </div>
    );
};

export default Switch;
