import {useEffect, useState} from 'react';
import Api from '../api/api';

const useScholarProfile = () => {
    const [profile, setItem] = useState({});
    const [loading, setLoading] = useState(true);
    const [researchCenter, setResearchCenter] = useState(null);


    useEffect(() => {
        setLoading(true);
        Api.get(`/UserProfile/UserProfile/getScholarProfileInfo`)
            .then((response) => {
                setItem(response);
            })
            .catch(() => {
            })
            .finally(() => {
                setLoading(false);
            });
    }, []);

    useEffect(() => {
        if(profile.researchCenterId) {
            Api.get(`/Infrastructure/ResearchCenter/getEntity/${profile.researchCenterId}`)
                .then((response) => {
                    setResearchCenter(response);
                })
                .catch(() => {
                })
                .finally(() => {
                });
        }
    }, [profile]);


    return {loading, profile,researchCenter};
};

export default useScholarProfile;
