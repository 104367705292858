import React from 'react';
import ProgressBar from "./ProgressBar";
import {AwardDetailContent} from "../../Dashboard/Users/Scholars/Subcomponents/UserProfile/Award Details/AwardDetail";
const Personal = ({id}) => {
    return <>
        <div className="user-profile-section">
            <ProgressBar />
            <AwardDetailContent id={id}/>
        </div>
    </>
}
export default Personal;
