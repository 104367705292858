import React from 'react';
import ProgressBar from "./ProgressBar";
import AddressContent from "../../Dashboard/Users/Scholars/Subcomponents/UserProfile/Address/AddressContent";

const Personal = ({id,redirectTo}) => {
    return <>
        <div className="user-profile-section">
            <ProgressBar />
            <AddressContent id={id} redirectTo={redirectTo}/>
        </div>
    </>
}
export default Personal;
