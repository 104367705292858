import React from 'react';
import Header from '../../../components/Header/Header';
import Heading from '../../../components/Heading/Heading';
import Sidebar from '../../../components/Sidebar/Sidebar';
import GeneralView from '../View Campus/Subcomponents/GeneralView';
import { useParams } from 'react-router-dom';
import useDetailApi from '../../../../hooks/useDetailApi';
import Backarrow from '../../../../images/back-arrow.svg';
import useEnums from '../../../../hooks/useEnums';
import MobileHeader from '../../../components/Sidebar/Mobile Design/Mobile Header/MobileHeader';
import MediaQuery from 'react-responsive';

const ViewDepartment = () => {
    const { id } = useParams();
    const { loading, item } = useDetailApi('Infrastructure/Department', id);
    const { renderEnum } = useEnums();

    const fields = [
        {
            type: 'select',
            field: 'CampusName',
            label: 'Campus Name',
            description: 'This will be displayed on your profile.',
            estric: '*',
        },
        {
            type: 'text',
            field: 'Name',
            label: 'Department name',
            description: 'This will be displayed on your profile.',
            estric: '*',
        },
        {
            type: 'text',
            field: 'Code',
            label: 'Department Code',
            description: 'This will be displayed on your profile.',
            estric: '*',
        },
        {
            type: 'textarea',
            field: 'Description',
            label: 'Description',
            description: 'This will be displayed on your profile.',
            required: false,
        },
        {
            type: 'select',
            field: 'DepartmentType',
            label: 'Department Type',
            description: 'This will be displayed on your profile.',
            estric: '*',
            options: {
                type: 'enum',
                value: 'DepartmentType',
            },

            render: (item) => {
                return renderEnum('DepartmentType', item.DepartmentType);
            },
        },
        {
            type: 'select',
            field: 'DepartmentFunction',
            label: 'Department Function',
            description: 'This will be displayed on your profile.',
            estric: '*',
            render: (item) => {
                return renderEnum(
                    'DepartmentFunction',
                    item.DepartmentFunction,
                );
            },
        },
    ];

    return (
        <div className="main-wrapper">
            <MediaQuery maxWidth={1023}>
                <MobileHeader/>
            </MediaQuery>
            
            <Sidebar />

            <div className="screen">
                <Header
                    homelink="/dashboard"
                    breadcrumb="Departments"
                    breadcrumbs="View Department"
                    innerpath="/department"
                />
                <Heading
                    title="Department Details"
                    description="View all details of the department below"
                    linktext="Back to Departments"
                    icon={Backarrow}
                    href="/department"
                    width="auto"
                    height="44px"
                    backgroundcolor="none"
                    color="#5D43A5"
                    fontsize="16px"
                    hover="#F9F5FF"
                    padding="0 20px 0 20px !important"
                    className="view-back-button"
                />
                {loading ? (
                    <div> Loading... </div>
                ) : (
                    <>
                        <GeneralView campus={item} fields={fields} />
                    </>
                )}
            </div>
        </div>
    );
};

export default ViewDepartment;
