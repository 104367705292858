import React from 'react';
import GenericForm from '../../../components/Form/GenericForm';

const AddCampusContent = () => {
    const initialValues = {
        Role: '',
        Permissions: []
    };

    const fields = [
        {
            type: 'text',
            field: 'Role',
            label: 'Name',
            description: 'Name of the role',
            estric: '*',
            required: true,
        },
        {
            type: 'select',
            field: 'Permissions',
            label: 'Select Permissions',
            description: 'These permissions will be assigned to the role',
            estric: '*',
            required:true,
            options: {
                type: 'enum',
                value: 'Permissions',
                multi: true,
            },
            errorMessage:{
                required: 'Field is required'
            }
        },

    ];

    return (
        <GenericForm
            fields={fields}
            initialValues={initialValues}
            endpoint={'Authorization/Role'}
            redirectTo={'/roles'}
        />
    );
};

export default AddCampusContent;
