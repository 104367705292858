import React, { useRef, useState } from 'react';
import Sidebar from '../../../components/Sidebar/Sidebar';
import Header from '../../../components/Header/Header';
import Heading from '../../../components/Heading/Heading';
import Table from '../../../components/Table/Table';
import useListApi from '../../../../hooks/useListApi';
import Plus from '../../../../images/plus.svg';
import MediaQuery from 'react-responsive';
import MobileHeader from '../../../components/Sidebar/Mobile Design/Mobile Header/MobileHeader';
import qs from 'qs';
import HeaderFilters from '../../../components/Filters/HeaderFilters';
import ExportIcon from '../../../../images/export.svg';

const ClustersList = () => {
    const [filters, setFilters] = useState(
        qs.parse(window.location.search, { ignoreQueryPrefix: true }),
    );
    const {
        rows,
        refresh,
        totalRecords,
        currentPage,
        recordsPerPage,
        setCurrentPage,
        setRecordsPerPage,
    } = useListApi('Authorization/Role', false, true, filters,10);
    const column = [
        {
            heading: 'Name',
            value: 'Role',
        },
        {
            heading: 'Permissions',
            render: (row) => {
                return row.Permissions.join(', ');
            }
        }
    ];


    const tableRef = useRef(null);

    return (
        <div className="main-wrapper">
            <MediaQuery maxWidth={1023}>
                <MobileHeader />
            </MediaQuery>
            <MediaQuery minWidth={1024}>
                <Sidebar />
            </MediaQuery>
            <div className="screen">
                {/* <Header
                    homelink="/dashboard"
                    title="Roles"
                    breadcrumbs="Roles"
                    href="/roles"
                /> */}
                <Heading
                    title="Roles"
                    description="List of Roles"
                    permissionPrefix={'Role'}
                    linktext="Add Role"
                    icon={Plus}
                    width="auto"
                    height="40px"
                    padding="0px 20px"
                    tableRef={tableRef}
                    exporticon={ExportIcon}
                    href={`/roles/new`}
                />

                <Table
                    ref={tableRef}
                    shouldView={false}
                    permissionPrefix={'Role'}
                    shouldAssignFee={false}
                    refresh={refresh}
                    resource={'Authorization/Role'}
                    data={rows}
                    totalRecords={totalRecords}
                    perPage={recordsPerPage}
                    setPerPage={setRecordsPerPage}
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                    column={column}
                    tableHeading="All Roles"
                    name="Role"
                    multipleDelete="Roles"
                    onView={(item) => {
                        window.location.href = `/roles/${item.id}`;
                    }}
                    onEdit={(item) => {
                        window.location.href = `/roles/${item.id}/edit?next=${encodeURIComponent(`${window.location.pathname}${window.location.search}`)}`;
                    }}
                />
            </div>
        </div>
    );
};

export default ClustersList;
