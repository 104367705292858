import {useCallback, useEffect, useState} from 'react';
import Api from '../api/api';
import { clearConfigCache } from 'prettier';

const useListApi = (
    url,
    paged = true,
    isNewListApi = false,
    filters = null,
    perPage = 10,
    appendGetEntities = true
) => {
    const [currentPage, setCurrentPage] = useState(1);
    const [totalRecords, setTotalRecords] = useState(0);
    const [recordsPerPage, setRecordsPerPage] = useState(perPage);
    const [rows, setRows] = useState([]);
    const [loading, setLoading] = useState(true);

    const refresh = useCallback(() => {
        setLoading(true);
        Api.get(`/${url}${appendGetEntities ? paged ? '/getEntitiesPaged' : '/getEntities' : ''}`, {
            ...filters,
            ...(recordsPerPage !== 'all' && {
                PageNo: currentPage,
                PerPage: recordsPerPage,
            }),
        }, () => {
            window.location.href = '/unauthorized-access';
        })
            .then((response) => {
                console.log("asdas");
                console.log(response);
                if ((paged || isNewListApi) && response.Count !== undefined) {
                    setTotalRecords(response.Count);
                    setRows(response.Data);
                } else {
                    setRows(response);
                    setTotalRecords(response.length);
                }
            })
            .catch(() => {
            })
            .finally(() => {
                setLoading(false);
            });
    }, [currentPage, filters, isNewListApi, paged, recordsPerPage, url]);

    useEffect(() => {
        refresh();
    }, [refresh]);
    return {
        loading,
        rows,
        recordsPerPage,
        currentPage,
        totalRecords,
        setCurrentPage,
        setRecordsPerPage,
        refresh,
    };
};

export default useListApi;
