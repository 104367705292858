import React from 'react';
import ProgressBar from "./ProgressBar";
import ProfessionalDetailContent
    from "../../Dashboard/Users/Scholars/Subcomponents/UserProfile/Professional Details/ProfessionalDetailContent";

const Personal = ({id}) => {
    return <>
        <div className="user-profile-section">
            <ProgressBar />
            <ProfessionalDetailContent id={id}/>
        </div>
    </>
}
export default Personal;
