import React from 'react';
import ProgressBar from "./ProgressBar";
import {ProjectDetail} from "../../Dashboard/Users/Scholars/Subcomponents/UserProfile/Projects/Project";
const Personal = ({id}) => {
    return <>
        <div className="user-profile-section">
            <ProgressBar />
            <ProjectDetail id={id}/>
        </div>
    </>
}
export default Personal;
