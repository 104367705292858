import React from 'react';
import ScholarHeader from './ScholarHeader';
import ScholarFeeIcon from '../../../images/scholar-fee.svg';
import { Link } from 'react-router-dom';
import ScholarProfileIcon from '../../../images/scholar-profile.svg';
import useProfile from '../../../hooks/useProfile';
import Loader from 'react-js-loader';
import DeliverableProgressBar from './Scholar Home/DeliverableProgressBar';
import DeliverableAssignHeader from './Scholar Home/Deliverable Assign & Submission/DeliverableAssignHeader';
import DeliverableIcon from '../../../images/deliverable-icon.svg';
import MediaQuery from 'react-responsive';
import ScholarNavHeader from './Scholar Sidebar/ScholarNavHeader';
import useScholarProfile from "../../../hooks/useScholarProfile";
import getFullName from "../../../helpers/getFullName";

const Home = () => {
    const { profile: scholarProfile, loading } = useScholarProfile();
    if (loading) {
        return (
            <>
                <div>
                    <ScholarHeader active={'home'} />
                    Loading..
                </div>
            </>
        );
    }
    return (
        <div className="scholar-home-page">
            <ScholarHeader active={'home'} />
            <>
                <MediaQuery maxWidth={1023}>
                    <ScholarNavHeader />
                </MediaQuery>
            </>
            <div className="scholar-heading">
                <div className='scholar-name'>
                    <h4>
                        Hello {getFullName(scholarProfile)}
                    </h4>
                </div>
                <div className="scholar-home-main">
                    <div className="scholar-dashboard-content">
                        <div className="scholar-home-heading">
                            <p>
                                Campus
                                <div className="scholar-home-sub-heading">
                                    {scholarProfile.CampusName}
                                </div>
                            </p>
                        </div>

                        <div className="scholar-home-heading">
                            <p>
                                Supervisor{' '}
                                <div className="scholar-home-sub-heading">
                                    {scholarProfile.SupervisorName ?
                                        <div className='superviosr-profile-link'><a
                                            href={`/scholar/${scholarProfile.SupervisorId}/resume`}>{scholarProfile.SupervisorName}</a>
                                        </div>
                                        : '-'
                                    }
                                </div>
                            </p>
                        </div>
                    </div>
                    <div className="scholar-dashboard-content">
                        <div className="scholar-home-heading">
                            <p>
                                Registry{' '}
                                <div className="scholar-home-sub-heading">
                                    {scholarProfile.ClusterName}
                                </div>
                            </p>
                        </div>
                        <div className="scholar-home-heading">
                            <p>
                                Co-supervisor 1{' '}
                                <div className="scholar-home-sub-heading">
                                    {scholarProfile.CoSupervisorName ?
                                        <div className='superviosr-profile-link'><a
                                            href={`/scholar/${scholarProfile.CoSupervisorId}/resume`}>{scholarProfile.CoSupervisorName}</a>
                                        </div>
                                        : '-'}
                                </div>
                            </p>
                        </div>
                    </div>
                    <div className="scholar-dashboard-content">
                        <div className="scholar-home-heading">
                            <p>
                                Discipline{' '}
                                <div className="scholar-home-sub-heading">
                                    {scholarProfile.ResearchCenterName}
                                </div>
                            </p>
                        </div>
                        <div className="scholar-home-heading">
                            <p>
                                Co-supervisor 2{' '}
                                <div className="scholar-home-sub-heading">
                                    {scholarProfile.CoSupervisor2Name ?
                                        <div className='superviosr-profile-link'><a
                                            href={`/scholar/${scholarProfile.CoSupervisor2Id}/resume`}>{scholarProfile.CoSupervisor2Name}</a>
                                        </div>
                                        : '-'}
                                </div>
                            </p>
                        </div>
                        
                        {/* {scholarProfile.CoSupervisorName ? (<div className="scholar-home-heading">
                            <p>
                                Co-supervisor 1{' '}
                                <div className="scholar-home-sub-heading">
                                    {scholarProfile.CoSupervisorName ?
                                        <div className='superviosr-profile-link'><a
                                            href={`/scholar/${scholarProfile.CoSupervisorId}/resume`}>{scholarProfile.CoSupervisorName}</a>
                                        </div>
                                        : '-'}
                                </div>
                            </p>
                        </div>): {}} */}

                        {/* <div className="scholar-home-heading">
                            <p>
                                SRAC Details{' '}
                                <div className="scholar-home-sub-heading">
                                    {' '}
                                    {researchCenter
                                        ? researchCenter.CampusName
                                        : ''}{' '}
                                </div>
                            </p>
                        </div> */}
                    </div>
                    <div className="scholar-dashboard-content">
                        <div className="scholar-home-heading">
                            <p className="scholar-roll-no">
                                Scholar Roll Number{' '}
                                <div className="scholar-home-sub-heading">
                                    {scholarProfile.UniversityRollNo ?
                                        <div>
                                        {scholarProfile.UniversityRollNo}
                                        </div>
                                        : '-'}
                                </div>
                            </p>
                        </div>
                        <div className="scholar-home-heading">
                            <p className="scholar-roll-no">
                                {/* Scholar Roll Number:{' '}
                                <div className="scholar-home-sub-heading">
                                    {' '}
                                    {researchCenter
                                        ? researchCenter.CampusName
                                        : ''}{' '}
                                </div> */}
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            {/*
                    <div className=“scholar-home-heading”>
                        <p>
                            Registry:{' ’}
                            <div className=‘scholar-home-sub-heading’>
                                {researchCenter
                                    ? researchCenter.ClusterName
                                    : ‘’}{' ‘}
                            </div>
                        </p>
                    </div> */}
            {/* <div className=“scholar-home-heading”>
                        <p>
                            Discipline:{’ ’}
                            <div className=‘scholar-home-sub-heading’>
                                {researchCenter ? researchCenter.Name : ‘’}
                            </div>
                        </p>
                    </div> */}

            <div className="scholar-home">
                <MediaQuery maxWidth={1023}>
                    <div className='scholar-name-heading'>
                        <h4>
                            Hello {getFullName(scholarProfile)}
                        </h4>
                    </div>
                </MediaQuery>
                {/* <div className="scholar-card">
                    <div className="scholar-fee-icon">
                        <img
                            className="scholar-card-icon"
                            src={DeliverableIcon}
                            alt={'DeliverableIcon'}
                        />
                        <h3>Deliverables</h3>
                    </div>
                    <div className="scholar-card-info">
                        <p></p>
                        <h5></h5>
                    </div>
                    <div className="action-bottom">
                        <div className="scholar-bottom-line-card"></div>
                        <div className="scholar-card-actions">
                            <Link
                                className="scholar-card-action"
                                to="/user/deliverables"
                            >
                                View Deliverables
                            </Link>
                        </div>
                    </div>
                </div> */}
                <a href="/user/fee" className="scholar-card">
                    <div>
                        <div className="scholar-fee-icon">
                            <img
                                className="scholar-card-icon"
                                src={ScholarFeeIcon}
                                alt={ScholarFeeIcon}
                            />
                            <h3>Fees</h3>
                        </div>
                        <div className="scholar-card-info">
                            <p></p>
                            <h5></h5>
                        </div>
                        <a href="/user/fee" className="card-action">
                            <div className="action-bottom">
                                <div className="scholar-bottom-line-card"></div>
                                <div className="scholar-card-actions">
                                    <Link
                                        className="scholar-card-action"
                                        to="/user/fee"
                                    >
                                        View Fees
                                    </Link>
                                </div>
                            </div>
                        </a>
                    </div>
                </a>

                <a href="user/profile/personal" className="scholar-card">
                    <div>
                        <div className="scholar-fee-icon">
                            <img
                                className="scholar-card-icon"
                                src={ScholarProfileIcon}
                                alt={'ScholarProfileIcon'}
                            />
                            <h3 className='card-main-heading'>My Profile</h3>
                        </div>
                        <div className="scholar-card-info">
                            <p></p>
                            <h5></h5>
                        </div>
                        <a href="/user/fee" className="card-action">
                            <div className="action-bottom">
                                <div className="scholar-bottom-line-card"></div>
                                <div className="scholar-card-actions">
                                    <Link
                                        className="scholar-card-action"
                                        to="/user/profile/personal"
                                    >
                                        View Profile
                                    </Link>
                                </div>
                            </div>
                        </a>
                    </div>
                </a>
            </div>

            <div className="scholar-deliverable-info">
                {/* <DeliverableAssignHeader/> */}
                {/* <DeliverableProgressBar/> */}
            </div>
        </div>
    );
};

export default Home;
