import React, { useRef, useState } from 'react';
import Sidebar from '../../../../../components/Sidebar/Sidebar';
import Header from '../../../../../components/Header/Header';
import Heading from '../../../../../components/Heading/Heading';
import Table from '../../../../../components/Table/Table';
import useListApi from '../../../../../../hooks/useListApi';
import MediaQuery from 'react-responsive';
import MobileHeader from '../../../../../components/Sidebar/Mobile Design/Mobile Header/MobileHeader';
import HeaderFilters from '../../../../../components/Filters/HeaderFilters';
import qs from 'qs';
import Api from '../../../../../../api/api';
import ExportIcon from '../../../../../../images/export.svg';
import Backarrow from '../../../../../../images/back-arrow.svg';
import { Modal } from 'react-bootstrap';
import PromoteScholar from '../../../../../components/Modals/Promote Scholar Modal/PromoteScholar';
import AlertModal from '../../../../../components/Modals/Alert Modal/AlertModal';

const ScholarsList = () => {
    const [filters, setFilters] = useState(
        qs.parse(window.location.search, { ignoreQueryPrefix: true }),
    );

    const [selectedScholar, setSelectedScholar] = useState(null);
    const [alertMessage, setAlertMessage] = useState('');
    const [showPromoteModal, setShowPromoteModal] = useState(false);
    const [showAlertModal, setShowAlertModal] = useState(false);
    const alertModalClose = () => setShowAlertModal(false);
    const {
        rows,
        refresh,
        totalRecords,
        currentPage,
        recordsPerPage,
        setCurrentPage,
        setRecordsPerPage,
    } = useListApi('Users/Scholar', false, true, filters);
    const column = [
        {
            heading: 'Name',
            render: (item) => {
                return `${item.FirstName} ${item.LastName}`;
            },
        },

        {
            heading: 'University Roll No.',
            value: 'UniversityRollNo',
        },
        {
            heading: 'Discipline',
            value: 'ResearchCenterName',
        },
        {
            heading: 'Batch',
            value: 'BatchName',
        },
        {
            heading: 'Current Deliverable',
            value: 'MilestoneTitle',
        },
    ];

    const tableRef = useRef(null);

    return (
        <div className="main-wrapper">
            <MediaQuery maxWidth={1023}>
                <MobileHeader />
            </MediaQuery>
            <MediaQuery minWidth={1024}>
                <Sidebar />
            </MediaQuery>
            <div className="screen">
                {/* <Header homelink="/dashboard" breadcrumbs="Promote Scholars" /> */}
                <Heading
                    title="Promote Scholar"
                    description="Promote scholars to next deliverable"
                    width="auto"
                    height="40px"
                    padding="0px 20px"
                    tableRef={tableRef}
                    exporticon={ExportIcon}
                    linktext="Back to Scholars"
                    backgroundcolor="none"
                    color="#5D43A5"
                    fontsize="16px"
                    hover="#F9F5FF"
                    className="view-back-button"
                    href="/scholar"
                    icon={Backarrow}
                />
                <HeaderFilters
                    filters={filters}
                    availableFilter={[
                        'campus',
                        'cluster',
                        'researchCenter',
                        'batch',
                    ]}
                    setFilters={setFilters}
                />
                <Table
                    permissionPrefix={'PromoteScholar'}
                    ref={tableRef}
                    shouldView={false}
                    shouldAssignFee={false}
                    shouldDelete={false}
                    shouldPromoteUndo={true}
                    data={rows}
                    totalRecords={totalRecords}
                    perPage={recordsPerPage}
                    setPerPage={setRecordsPerPage}
                    checkboxshouldView={false}
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                    column={column}
                    resource={'Users/Scholar'}
                    tableHeading="All Scholars"
                    name="Scholar"
                    refresh={refresh}
                    multipleDelete="Scholars"
                    editName={'Promote'}
                    onEdit={(item) => {
                        setShowPromoteModal(true);
                        setSelectedScholar(item);
                    }}
                    onPromoteUndo={(item) => {
                        Api.post(
                            `/AcademicStructure/ScholarMilestone/demoteScholar/${item.Id}`,
                        )
                            .then(() => {
                                refresh();
                                alert('Undo successfully');
                                // setAlertMessage(
                                //     'Undo successfully',
                                // );
                                // setShowAlertModal(true);
                            })
                            .catch((e) => {
                                setAlertMessage(e.Message);
                                    setShowAlertModal(true);
                                // alert(e.Message);
                            });
                    }}
                />
            </div>
            {showPromoteModal && (
                <Modal
                    show={true}
                    onHide={() => setShowPromoteModal(false)}
                    centered
                    className="promoteModal"
                >
                    <PromoteScholar
                        onPromote={() => {
                            setShowPromoteModal(false);
                            refresh();
                            Api.post(
                                `/AcademicStructure/ScholarMilestone/promoteScholar/${selectedScholar.Id}`,
                            )
                                .then(() => {
                                    refresh();
                                    alert('Scholar promoted successfully');
                                    // setAlertMessage(
                                    //     'Scholar promoted successfully',
                                    // );
                                    // setShowAlertModal(true);
                                })
                                .catch((e) => {
                                    setAlertMessage(e.Message);
                                    // setShowAlertModal(true);
                                    alert(e.Message);
                                });
                        }}
                        scholar={selectedScholar}
                        onHide={() => {
                            setShowPromoteModal(false);
                        }}
                    />
                </Modal>
            )}
            {/* {showAlertModal && (
                <Modal show={true} centered className="alertModal">
                    <AlertModal
                        onClose={alertModalClose}
                        errorMessage={alertMessage}
                    />
                </Modal>
            )} */}
        </div>
    );
};

export default ScholarsList;
