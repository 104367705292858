import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Settings from '../../../../images/settings.svg';
import MyProfile from '../../../../images/drc.svg';
import Logout from '../../../../images/logout.svg';
import Api from '../../../../api/api';
import getInitials from '../../../../helpers/getInitials';

const Footer = () => {
    const [profile, setProfile] = useState({});

    const logout = () => {
        localStorage.clear();
        window.location.href = '/';
    };

    useEffect(() => {
        Api.get('/Authentication/Login/Profile')
            .then((response) => {
                setProfile(response);
                if (response.userType === 'Scholar') {
                    window.location.href = '/user';
                }
            })
            .catch((e) => {
                logout();
            });
    }, []);

    return (
        <ul className="footer-navigation">
            <li>
                {/* <Link to="#">
                    <div className="support">
                        <img
                            className="footer-icon"
                            src={Support}
                            alt={'Support'}
                        />
                        <span>Support</span>
                    </div>
                </Link> */}

                <Link to={`/staff/${profile.userProfileId}/personal`}>
                    <div className="settings">
                        <img
                            className="footer-icon"
                            src={MyProfile}
                            alt={'My Profile'}
                        />
                        <span>My Profile</span>
                    </div>
                </Link>

                <Link to="/settings/password">
                    <div className="settings">
                        <img
                            className="footer-icon"
                            src={Settings}
                            alt={'Settings'}
                        />
                        <span>Settings</span>
                    </div>
                </Link>
                <hr></hr>
                <Link className="footer-content" to="#">
                    <div className="avtar-icon">
                        <span>
                            {getInitials(
                                `${profile.firstName || ''} ${
                                    profile.lastName || ''
                                }`.trim(),
                            )}
                        </span>
                    </div>
                    <div className="footer-avatar">
                        <div className="user-info">
                            <span>
                                {`${profile.firstName || ''} ${
                                    profile.lastName || ''
                                }`.trim()}
                            </span>
                            <img
                            className="logout-icon"
                            src={Logout} onClick={logout} alt={'Logout'} />
                        </div>
                        <p>{profile.email}</p>
                    </div>
                </Link>
            </li>
        </ul>
    );
};

export default Footer;
