import React, {useEffect, useState} from 'react';
import {Link, useParams} from 'react-router-dom';
import Completeprofile from '../../../images/complete-profile.svg';
import ActiveLine from '../../../images/line-active.svg';
import InactiveLine from '../../../images/line-inactive.svg';
import MediaQuery from 'react-responsive';
import Api from "../../../api/api";


// const Complete = () => {
//     return (
//         <img
//             className="progress-complete"
//             src={Completeprofile}
//             alt={'Inactiveprofile'}
//         />
//     );
// };
// const InComplete = () => {
//     return (
//         <img
//             className="progress-complete"
//             src={Inactiveprofile}
//             alt={'Inactiveprofile'}
//         />
//     );
// };

const Complete = () => {
    return (
        <img
            className="progress-complete"
            src={Completeprofile}
            alt={'Inactiveprofile'}
        />
    );
};
const InComplete = () => {
    return (
        <img
            className="progress-complete"
            src={InactiveLine}
            alt={'Inactiveprofile'}
        />
    );
};
const Active = () => {

    return (
        <div className='profile-status-line'>
        <img
            className="progress-complete"
            src={ActiveLine}
            alt={'Inactiveprofile'}
        />
        </div>
    );
};


const StatusIcon = ({section}) => {
    if (window.location.href.endsWith(`/${section}`)) {
        return <Active/>;
    }
    // return <InComplete/>;
};

const Progressbar = () => {
    const [condition] = useState(false);
    const {scholarId} = useParams();

    const [isStaff, setIsStaff] = useState(window.location.pathname.startsWith('/staff'));

    const pathPrefix = isStaff ? 'staff':'scholar';

    return (
        <div className="user-profile-select">
            <div className="progress-bar">
                <div className="progress-bar-content">
                    <ul>
                        <li>
                            <Link to={`/${pathPrefix}/${scholarId}/personal`}>
                                <div className="personal-info-tab">
                                    {/* <StatusIcon section={'personal'}/> */}
                                    <span className={window.location.pathname === `/${pathPrefix}/${scholarId}/personal` ? 'active':''}>Personal Details</span>
                                </div>
                                <StatusIcon
                                className='profile-status-line'
                                section={'personal'}/>
                            </Link>
                        </li>

                        <li>
                            <Link to={`/${pathPrefix}/${scholarId}/address`}>
                                <div className="personal-info-tab">
                                    {/* <StatusIcon section={'address'}/> */}
                                    <span className={window.location.pathname === `/${pathPrefix}/${scholarId}/address` ? 'active':''}>Address</span>
                                </div>
                                <StatusIcon section={'address'}/>
                            </Link>
                        </li>


                        <li>
                            <Link to={`/${pathPrefix}/${scholarId}/experience`}>
                                <div className="personal-info-tab">
                                    {/* <StatusIcon section={'experience'}/> */}
                                    <span className={window.location.pathname === `/${pathPrefix}/${scholarId}/experience` ? 'active':''} >Experience</span>
                                </div>
                                <StatusIcon section={'experience'}/>
                            </Link>
                        </li>

                        <li>
                            <Link to={`/${pathPrefix}/${scholarId}/qualifications`}>
                                <div className="personal-info-tab">
                                    {/* <StatusIcon section={'qualifications'}/> */}
                                    <span className={window.location.pathname === `/${pathPrefix}/${scholarId}/qualifications` ? 'active':''}>Qualification</span>
                                </div>
                                <StatusIcon section={'qualifications'}/>
                            </Link>
                        </li>

                        <li>
                            <Link to={`/${pathPrefix}/${scholarId}/projects`}>
                                <div className="personal-info-tab">
                                    {/* <StatusIcon section={'projects'}/> */}
                                    <span className={window.location.pathname === `/${pathPrefix}/${scholarId}/projects` ? 'active':''}>Projects</span>
                                </div>
                                <StatusIcon section={'projects'}/>
                            </Link>
                        </li>

                        <li>
                            <Link to={`/${pathPrefix}/${scholarId}/patents`}>
                                <div className="personal-info-tab">
                                    {/* <StatusIcon section={'patents'}/> */}
                                    <span className={window.location.pathname === `/${pathPrefix}/${scholarId}/patents` ? 'active':''}>Patent Details</span>
                                </div>
                                <StatusIcon section={'patents'}/>
                            </Link>
                        </li>

                        {isStaff &&
                            <li>
                                <Link to={`/${pathPrefix}/${scholarId}/scholars`}>
                                    <div className="personal-info-tab">
                                        {/* <StatusIcon section={'scholars'}/> */}
                                        <span className={window.location.pathname === `/${pathPrefix}/${scholarId}/scholars` ? 'active':''}>Research Scholar Info</span>
                                    </div>
                                    <StatusIcon section={'scholars'}/>
                                </Link>
                            </li>
                        }

                        <li>
                            <Link to={`/${pathPrefix}/${scholarId}/awards`}>
                                <div className="personal-info-tab">
                                    {/* <StatusIcon section={'awards'}/> */}
                                    <span className={window.location.pathname === `/${pathPrefix}/${scholarId}/awards` ? 'active':''}>Award Details</span>
                                </div>
                                <StatusIcon section={'awards'}/>
                            </Link>
                        </li>
                        {/*

                        <li>
                            <Link to={`/${pathPrefix}/${scholarId}/scholars`}>
                                <div className="personal-info-tab">
                                    <StatusIcon section={'scholars'} />
                                    <span>Prior Scholar Information</span>
                                </div>
                            </Link>
                        </li>
*/}

                        <li>
                            <Link to={`/${pathPrefix}/${scholarId}/social`}>
                                <div className="personal-info-tab">
                                    {/* <StatusIcon section={'social'}/> */}
                                    <span className={window.location.pathname === `/${pathPrefix}/${scholarId}/social` ? 'active':''}>Social Sites</span>
                                </div>
                                <StatusIcon section={'social'}/>
                            </Link>
                        </li>


                        <li>
                            <Link to={`/${pathPrefix}/${scholarId}/publications`}>
                                <div className="personal-info-tab">
                                    {/* <StatusIcon section={'publications'}/> */}
                                    <span className={window.location.pathname === `/${pathPrefix}/${scholarId}/publications` ? 'active':''}>Publications Details</span>
                                </div>
                                <StatusIcon section={'publications'}/>
                            </Link>
                        </li>

                        <li>
                            <Link to={`/${pathPrefix}/${scholarId}/documents`}>
                                <div className="personal-info-tab">
                                    {/* <StatusIcon section={'documents'}/> */}
                                    <span className={window.location.pathname === `/${pathPrefix}/${scholarId}/documents` ? 'active':''}>Upload Documents</span>
                                </div>
                                <StatusIcon section={'documents'}/>
                            </Link>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    );
};

export default Progressbar;
