import React, {useState} from 'react';

const DeliverableSubmission = ({deliverable}) => {
    const [showDrop, setShowDrop] = useState(0);
    return (
        <>
            <div className="deliverable-submission">
                <h3>{deliverable.Deliverable.Title}</h3>
                <span>
                    <div dangerouslySetInnerHTML={{__html: deliverable.Deliverable.Description}}/>
                </span>
            </div>
        </>
    );
};

export default DeliverableSubmission;
