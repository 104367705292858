import React from 'react';
import Select from 'react-select';
import styled from 'styled-components';

const InputContainer = styled.div`
    > div {
        span {
            font-size: 14px;
            font-weight: 400;
            color: #f04438;
        }

        placeholder {
            color: #c1c1c1;
        }
    }
`;

const SelectInput = (props) => {
    const getValue = (v, options) => {
        if (props.multi) {
            return options.filter(
                (option) => v && v.indexOf(option.value) > -1,
            );
        }
        return options.find((option) => option.value === v) || '';
    };
    return (
        <InputContainer>
            <div className={props.className}>
                <Select
                    isDisabled={props.disabled}
                    isMulti={props.multi}
                    isClearable={props.clearable !== false}
                    value={getValue(props.value, props.options)}
                    onChange={props.onChange}
                    options={props.options}
                    height={props.height}
                    width={props.width}
                    placeholder={props.placeholder}
                    className={props.error ? 'selecterror' : 'select-error'}
                />
                <span>{props.error}</span>
            </div>
        </InputContainer>
    );
};

export default SelectInput;
