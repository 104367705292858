import React from 'react';
import GenericForm from '../../../../../components/Form/GenericForm';
import qs from 'qs';

const AddClusterContent = () => {
    let filters = qs.parse(window.location.search, { ignoreQueryPrefix: true });
    const initialValues = {
        Name: '',
        Code: '',
        CampusId: filters.CampusId,
        CreatedBy: null,
    };

    const fields = [
        {
            type: 'select',
            field: 'CampusId',
            label: 'Select Campus',
            description: 'Registry will be added to the selected campus',
            estric: '*',
            options: {
                type: 'dynamic',
                endpoint: 'Infrastructure/Campus',
                paged: true,
                valueKey: 'Id',
                labelKey: 'DisplayName',
            },
            shouldUpdateQueryParams: true
        },
        {
            type: 'text',
            field: 'Name',
            label: 'Registry Name',
            description: 'Name of the registry',
            estric: '*',
        },
        {
            type: 'text',
            field: 'Code',
            label: 'Registry Code',
            description: 'Unique identifier for the registry',
            estric: '*',
        },
    ];

    return (
        <GenericForm
            fields={fields}
            initialValues={initialValues}
            endpoint={'Infrastructure/Cluster'}
            redirectTo={'/registry'}
        />
    );
};

export default AddClusterContent;
