import { useEffect, useState } from 'react';
import Api from '../api/api';

const useDetailApi = (url, id, doNotAppendWithGetEntity = false) => {
    const [item, setItem] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (!id) {
            setLoading(false);
            return;
        }
        Api.get(`/${url}/${doNotAppendWithGetEntity ? '':'getEntity/'}${id}`, {}, ()=>{
            window.location.href = '/unauthorized-access';
        })
            .then((response) => {
                setItem(response);
            })
            .catch(() => {})
            .finally(() => {
                setLoading(false);
            });
    }, [id, url]);

    return { loading, item };
};

export default useDetailApi;
