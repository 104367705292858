import React, { useRef, useState } from 'react';
import useListApi from '../../../../../../hooks/useListApi';
import Header from '../../../../../components/Header/Header';
import Heading from '../../../../../components/Heading/Heading';
import Sidebar from '../../../../../components/Sidebar/Sidebar';
import Table from '../../../../../components/Table/Table';
import Plus from '../../../../../../images/plus.svg';
import MediaQuery from 'react-responsive';
import MobileHeader from '../../../../../components/Sidebar/Mobile Design/Mobile Header/MobileHeader';
import HeaderFilters from '../../../../../components/Filters/HeaderFilters';
import qs from 'qs';
import ExportIcon from '../../../../../../images/export.svg';

const MilestoneList = () => {
    const [filters, setFilters] = useState(
        qs.parse(window.location.search, { ignoreQueryPrefix: true }),
    );
    const {
        rows,
        refresh,
        totalRecords,
        currentPage,
        recordsPerPage,
        setCurrentPage,
        setRecordsPerPage,
    } = useListApi('AcademicStructure/Milestone', false, true, filters);
    const column = [
        {
            heading: 'Name',
            value: 'Title',
        },
        {
            heading: 'Code',
            value: 'Code',
        },
        {
            heading: 'Discipline',
            value: 'ResearchCenterName',
        },
        {
            heading: 'Resources',
            value: 'deliverable',
        },
    ];

    const renderColumn = (row, column, rendered) => {
        if (column.value === 'deliverable') {
            return (
                <td>
                    <a
                        href={`/sub-deliverable?MilestoneId=${row.id}&CampusId=${row.CampusId}&ClusterId=${row.ClusterId}&ResearchCenterId=${row.ResearchCenterId}`}
                    >
                        Resources
                    </a>
                </td>
            );
        }
        return rendered;
    };

    const tableRef = useRef(null);

    return (
        <div className="main-wrapper">
            <MediaQuery maxWidth={1023}>
                <MobileHeader />
            </MediaQuery>
            <MediaQuery minWidth={1024}>
                <Sidebar />
            </MediaQuery>
            <div className="screen">
                {/* <Header homelink="/dashboard" breadcrumbs="Deliverables" /> */}
                <Heading
                    permissionPrefix={'Milestone'}
                    title="Deliverables"
                    description="List of Deliverables"
                    linktext="Add Deliverable"
                    icon={Plus}
                    width="auto"
                    height="40px"
                    padding="0px 20px"
                    tableRef={tableRef}
                    exporticon={ExportIcon}
                    href={`/deliverable/new?ClusterId=${filters.ClusterId}&CampusId=${filters.CampusId}&ResearchCenterId=${filters.ResearchCenterId}`}
                />
                <HeaderFilters
                    filters={filters}
                    availableFilter={['campus', 'cluster', 'researchCenter']}
                    setFilters={setFilters}
                />
                <Table
                    ref={tableRef}
                    shouldView={true}
                    shouldAssignFee={false}
                    shouldProfile = {false}
                    data={rows}
                    permissionPrefix={'Milestone'}
                    totalRecords={totalRecords}
                    perPage={recordsPerPage}
                    setPerPage={setRecordsPerPage}
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                    refresh={refresh}
                    resource={'AcademicStructure/Milestone'}
                    column={column}
                    renderColumn={renderColumn}
                    tableHeading="All  Deliverables"
                    name="Deliverable"
                    multipleDelete="Deliverables"
                    onEdit={(item) => {
                        window.location.href = `/deliverable/${item.id}/edit?next=${encodeURIComponent(`${window.location.pathname}${window.location.search}`)}`;
                    }}
                    onView={(item) => {
                        window.location.href = `/deliverable/${item.id}/view?next=${encodeURIComponent(`${window.location.pathname}${window.location.search}`)}`;
                    }}
                />
            </div>
        </div>
    );
};

export default MilestoneList;
