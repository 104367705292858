import React from 'react';
import { Button } from '../Buttons/Button';
import Modaldelete from '../../../images/modal-delete.svg';

const DeleteModal = (props) => {
    return (
        <div className="delete-modal">
            <div className="delete-modal-img">
                <img src={Modaldelete} alt={'Modaldelete'} />
                <img onClick={props.onClose} src="/images/cross.svg" />
            </div>
            {props.isMulti ? (
                <div className="delete-modal-heading">
                    <h5>{props.heading}</h5>
                    <span>
                        Are you sure you want to delete the selected records ?
                        This action cannot be undone.
                    </span>
                </div>
            ) : (
                <div className="delete-modal-heading">
                    <h5>{props.heading || 'Delete Record'}</h5>
                    <span>
                        {/* Are you sure you want to delete this record? This action
                        cannot be undone. */}
                        {props.errorText || 'Are you sure you want to delete this record? This action cannot be undone.'}
                    </span>
                </div>
            )}

            <div className="modals-cb-buttons">
                <Button
                    width="170px"
                    height="44px"
                    color="#1D2939"
                    border="1px solid #D0D5DD"
                    backgroundcolor="#ffffff"
                    fontweight="300"
                    hover="#F9FAFB"
                    onClick={props.onClose}
                    marginright="12px"
                >
                    No, keep it
                </Button>

                <Button
                    onClick={props.onDelete}
                    width="170px"
                    height="44px"
                    backgroundcolor="#D92D20"
                    fontweight="300"
                    hover="#B42318"
                >
                    Yes, delete
                </Button>
            </div>
        </div>
    );
};

export default DeleteModal;
