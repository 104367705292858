import React, { useState } from 'react';
import { Button } from '../../../components/Buttons/Button';
import { SimpleInput } from '../../../components/Inputs/SimpleInput';
import Email from '../../../../images/email.svg';
import PhdCentralLogo from '../../../../images/phd-central-logo.png';
import Api from '../../../../api/api';
import { Link } from 'react-router-dom';
import { LinkButton } from '../../../components/Buttons/LinkButton';

const LoginLeft = (placeholder) => {
    const initialValues = { email: '' };
    const [formValues, setFormValues] = useState(initialValues);
    const [formErrors, setFormErrors] = useState({});
    const [successMsg, setSuccessMsg] = useState();
    const [showPassword, setShowPassword] = useState(false);
    const [loading, SetLoading] = useState(false);
    const [emailSent, setEmailSent] = useState(false);
    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    const handleChange = (event) => {
        let i = { ...formValues };
        i[event.target.name] = event.target.value;
        setFormValues(i);
    };

    const handleSignin = (e) => {
        e.preventDefault();
        let errors = validate(formValues);
        setFormErrors(errors);
        if (Object.keys(errors).length > 0) {
            return;
        }
        SetLoading(true);
        Api.post(
            '/Authentication/login/ForgotPassword',
            {
                Email: formValues.email,
            },
            false,
        )
            .then((response) => {
                setEmailSent(true);
            })
            .catch((e) => {
                if (e.Message) {
                    setFormErrors({ error: e.Message });
                } else {
                    setFormErrors({
                        error: 'Something went wrong',
                    });
                }
                SetLoading(false);
            });
    };

    const validate = (values) => {
        setSuccessMsg();
        const errors = {};

        const passwordValue = values.password;

        if (!values.email) {
            errors.email = 'Email is required';
        }

        return errors;
    };

    if (emailSent) {
        return (
            <>
                <div className="login-page">
                    <div className="login-left-content">
                        <div className="login-container">
                            <div className="welcome-heading">
                                <div className="phd-logo-login">
                                    <img
                                        className="phd-logo-login"
                                        src={PhdCentralLogo}
                                        alt={'PhdCentralLogo'}
                                    />
                                </div>
                                <h1>Forgot Password</h1>
                                <div className="forgot-password-description">
                                    <span>
                                        An email with password reset link has
                                        been sent to your registered email
                                        address
                                    </span>
                                </div>
                            </div>

                            <div className="forgot-back-to-login">
                                <LinkButton
                                    className="forgot-back-to-login"
                                    href="/login"
                                    linktext="Back to login"
                                ></LinkButton>
                            </div>
                        </div>
                        <div className="footer">
                            <span>© Chitkara University 2022</span>
                            <a href="mailto:help@chitkara.edu.in">
                                <img
                                    className="email"
                                    src={Email}
                                    alt={'Email'}
                                />
                                help@chitkara.edu.in
                            </a>
                        </div>
                    </div>
                </div>
            </>
        );
    }
    return (
        <>
            <div className="login-page">
                <form>
                    <div className="login-left-content">
                        <div className="login-container">
                            <div className="welcome-heading">
                                <div className="phd-logo-login">
                                    <img
                                        className="phd-logo-login"
                                        src={PhdCentralLogo}
                                        alt={'PhdCentralLogo'}
                                    />
                                </div>
                                <h1>Forgot Password</h1>
                            </div>

                            <div className="login-input">
                                <SimpleInput
                                    className="login-input-field"
                                    type="email"
                                    placeholder="Enter your email"
                                    name="email"
                                    value={formValues.email}
                                    onChange={handleChange}
                                    error={formErrors.email}
                                    label="Email*"
                                />
                            </div>

                            <div className={'error text-danger'}>
                                {formErrors.error}
                            </div>

                            <div className="signin-button">
                                <Button
                                    onClick={handleSignin}
                                    loading={loading}
                                    hover="#5D43A5"
                                >
                                    Submit
                                </Button>
                            </div>
                            <div className="back-to-login">
                                <Link to={'/login'}>Back to login</Link>
                            </div>
                        </div>
                        <div className="footer">
                            <span>© Chitkara University 2022</span>
                            <a href="mailto:help@chitkara.edu.in">
                                <img
                                    className="email"
                                    src={Email}
                                    alt={'Email'}
                                />
                                help@chitkara.edu.in
                            </a>
                        </div>
                    </div>
                </form>
            </div>
        </>
    );
};

export default LoginLeft;
