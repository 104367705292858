import React from 'react';
import Header from '../../../../Header/Header';
import Heading from '../../../../Heading/Heading';
import Progressbar from '../../../../Progress Bar/Progressbar';
import MobileHeader from '../../../../Sidebar/Mobile Design/Mobile Header/MobileHeader';
import Sidebar from '../../../../Sidebar/Sidebar';
import AddBookChapterDefaultContent from './AddBookChapterDefaultContent';
import MediaQuery from 'react-responsive';

const AddBookChapterDefault = () => {
    return (
        <div className="main-wrapper">
            <MediaQuery maxWidth={1023}>
                <MobileHeader />
            </MediaQuery>
            <MediaQuery minWidth={1024}>
                <Sidebar />
            </MediaQuery>
            <div className="screen">
                <Header
                    homelink="/dashboard"
                    breadcrumb="Scholars"
                    breadcrumbs="Add Scholar"
                    innerpath="/scholar"
                />
                <Heading
                    title="My Profile"
                    description="Upload CSV files to upload scholars"
                />
                <div className="user-profile-section">
                    <Progressbar />
                    <AddBookChapterDefaultContent
                        heading="No book chapter details found"
                        subheading="Update your book chapter details here."
                    />
                </div>
            </div>
        </div>
    );
};

export default AddBookChapterDefault;
