import React, { useEffect, useState } from 'react';

import { ExportButton } from '../../../components/Buttons/ExportButton';
import SelectInput from '../../../components/Inputs/SelectInput';
import { isArray } from 'lodash';
import { Button } from '../../../components/Buttons/Button';
import Api from '../../../../api/api';

const selectmode = [{ value: 'Role 2022', label: 'Role 2022' }];

const AssignRole = ({ users, onHide, RoleId, refresh }) => {
    const [feePolicies, setFeePolicies] = useState([]);
    const [feePolicyId, setFeePolicyId] = useState(RoleId);
    const [feePolicy, setFeePolicy] = useState(null);

    useEffect(() => {
        Api.get('/Authorization/Role/getEntities').then((response) => {
            setFeePolicies(response);
        });
    }, []);

    useEffect(() => {
        if (!feePolicyId) {
            return;
        }
        Api.get(`Authorization/Role/getEntity/${feePolicyId}`).then(
            (response) => {
                setFeePolicy(response);
            },
        );
    }, [feePolicyId]);

    const submit = () => {
        Api.post(`/Authorization/Role/AssignRole/${users[0].User_id}`, {
            Role: feePolicyId,
        })
            .then(() => {
                onHide();
                refresh();
            })
            .catch(console.log);
    };

    return (
        <div className="fee-policy-modal">
            <div className="modal-box">
                <div className="modal-heading">
                    <h5>Assign Role</h5>
                </div>
                <img
                    className="modal-cross-icon"
                    onClick={onHide}
                    src="/images/cross.svg"
                />
            </div>

            <div className="fee-policy-content">
                <div className="assign-fee-policy-content">
                    <div className="assign-fee-policy-amount">
                        <div className="assign-fees">Role</div>
                        <SelectInput
                            placeholder="Select Role"
                            options={feePolicies.map((feePolicy) => ({
                                value: feePolicy.id,
                                label: feePolicy.Role,
                            }))}
                            onChange={(e) => {
                                setFeePolicyId(e.value);
                            }}
                            value={parseInt(feePolicyId)}
                        />
                    </div>
                </div>
                {feePolicy && (
                    <>
                        <div className="assign-fee-policy-content">
                            <div className="assign-fee-policy-amount">
                                {feePolicy.Permissions.join(', ')}
                            </div>
                        </div>
                    </>
                )}
            </div>

            <div className="bottom-line-assign-fee-policy"></div>

            <div className="cb-buttons-assign-fee-policy">
                <ExportButton
                    width="220px"
                    height="40px"
                    hover="#F9FAFB"
                    border="1px solid #D0D5DD"
                    onClick={onHide}
                >
                    Cancel
                </ExportButton>
                <Button
                    onClick={submit}
                    width="220px"
                    height="40px"
                    padding="0px 20px !important"
                    hover="#5D43A5"
                >
                    Save
                </Button>
            </div>
        </div>
    );
};

export default AssignRole;
