import React from 'react';
import Checkbox from '../../../images/checkbox.svg';
import Check from '../../../images/check.svg';

const CustomCheckbox = ({ isChecked, onChange, disabled }) => {
    return (
        <div className="checkbox">
            <div
                onClick={() => {
                    if (!disabled) {
                        onChange();
                    }
                }}
            >
                {isChecked ? (
                    <img src={Checkbox} alt={'Checkbox'} />
                    
                ) : (
                    <img src={Check} alt={'Check'} />
                    
                )}
            </div>
        </div>
    );
};

export default CustomCheckbox;
