import React, {useEffect, useState} from 'react';
import useFilters from '../../../hooks/useFilters';
import {RenderFormControl} from '../Form/GenericForm';
import qs from "qs";

function RenderFilters({
                           filters,
                           handleChange,
                           values,
                           className,
                           showAll,
                           showReportCalendar,
                       }) {
    let {filterFields} = useFilters(filters, values, showAll);

    return filterFields.map((field) => {
        return (
            <div
                className="header-filter-input"
                style={{marginRight: 12, marginTop: 20,}}
            >
                <div>{field.placeholder}</div>
                <RenderFormControl
                    className={className}
                    options={field.options}
                    type={field.type || 'select'}
                    formErrors={[]}
                    placeholder={field.placeholder}
                    handleChange={handleChange}
                    field={field.field}
                    formValues={values}
                />
            </div>
        );
    });
}

const HeaderFilters = ({
                           availableFilter,
                           filters,
                           setFilters,
                           showAll = true,
                           saveState = true,
                       }) => {


    useEffect(() => {
        if (!saveState) {
            return;
        }
        const urlFilters = qs.parse(window.location.search, {ignoreQueryPrefix: true});
        if (Object.keys(urlFilters).length > 0) {
            setFilters({...filters, ...urlFilters});
        }
    }, [saveState]);


    const handleChangeLocal = (e) => {
        let {name, value} = e.target;

        let f = {
            ...filters,
            [name]: value,
        };
        if (name === 'CampusId') {
            f['ClusterId'] = null;
            f['ResearchCenterId'] = null;
            f['AcademicYearId'] = null;
            f['MilestoneId'] = null;
            f['BatchId'] = null;
            f['ProgramId'] = null;
            f['DisciplineId'] = null;
        }
        setFilters(f);
        updateQueryParams(f)
    };

    const updateQueryParams = (filters) => {
        if ('URLSearchParams' in window) {
            const searchParams = new URLSearchParams(window.location.search)
            Object.keys(filters).forEach((key) => {
                if (filters[key]) {
                    searchParams.set(key, filters[key]);
                }
            })
            const newRelativePathQuery = window.location.pathname + '?' + searchParams.toString();
            window.history.replaceState(null, '', newRelativePathQuery);
        }
    }

    return (
        <div className="header-filter" style={{display: 'flex'}}>
            <RenderFilters
                showAll={showAll}
                filters={availableFilter}
                values={filters}
                handleChange={handleChangeLocal}
                className={'select-drop-down'}
            />
        </div>
    );
};

export default HeaderFilters;
