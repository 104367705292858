import React, {useState} from 'react';
import Heading from '../../../../../../../components/Heading/Heading';
import Progressbar from '../../../../../../../components/Progress Bar/Progressbar';
import MobileHeader from '../../../../../../../components/Sidebar/Mobile Design/Mobile Header/MobileHeader';
import Sidebar from '../../../../../../../components/Sidebar/Sidebar';
import MediaQuery from 'react-responsive';
import GenericContent from '../Generic/GenericContent';
import {useParams} from 'react-router-dom';

export const ProjectDetail = ({id}) => {

    const [hideEndDate, setHideEndDate] = useState(false);

    return <GenericContent
        id={id}
        endpoint={'Project'}
        title={'Projects'}
        initialValuesUpdated={(initialValues) => {
            const value = initialValues.ProjectStatus;
            if (value === 'Completed') {
                setHideEndDate(false);
            } else {
                setHideEndDate(true);
            }
        }}
        description={'Add your project details here'}
        column={[
            {
                heading: 'Title',
                value: 'Title',
            },
            {
                heading: 'Funding Agency',
                value: 'FundingAgency',
            },
            {
                heading: 'Principal Investigator',
                value: 'PrincipleInvestigator',
            },
            {
                heading: 'Sanctioned Amount',
                value: 'AmountFunded',
            }
        ]}
        fields={
            [
                {
                    type: 'text',
                    field: 'Title',
                    label: 'Project Title',
                    estric: '*',
                },
                {
                    type: 'text',
                    field: 'Duration',
                    label: 'Project Duration',
                    description: 'This will be displayed on your profile.',
                    estric: '*',
                },
                {
                    type: 'text',
                    field: 'FundingAgency',
                    label: 'Funding Agency',
                    description: 'This will be displayed on your profile.',
                    estric: '*'
                },
                {
                    type: 'text',
                    field: 'AmountFunded',
                    label: 'Sanctioned Amount',
                    description: 'This will be displayed on your profile.',
                    estric: '*'
                },
                {
                    type: 'text',
                    field: 'PrincipleInvestigator',
                    label: 'Principal Investigator',
                    description: 'This will be displayed on your profile.',
                    estric: '*'
                },
                {
                    type: 'text',
                    field: 'CoInvestigator',
                    label: 'Co-Principal Investigator',
                    description: 'This will be displayed on your profile.',
                    estric: '*'
                },
                {
                    type: 'date',
                    field: 'StartDate',
                    label: 'Start Date',
                    description: 'This will be displayed on your profile.',
                    estric: '*'
                },
                {
                    type: 'date',
                    field: 'EndDate',
                    label: 'End Date',
                    description: 'This will be displayed on your profile.',
                    estric: '*',
                    required: !hideEndDate,
                    hide: hideEndDate,
                },
                {
                    type: 'select',
                    field: 'ProjectStatus',
                    label: 'Status',
                    description: 'This will be displayed on your profile.',
                    required: true,
                    options: {
                        type: 'enum',
                        value: 'ProjectStatus'
                    },
                    onChange: (value) => {
                        if (value === "Completed") {
                            setHideEndDate(false);
                        } else {
                            setHideEndDate(true);
                        }
                    }
                }
            ]}
        initialValues={{
            "Title": "",
            "Duration": null,
            "FundingAgency": "",
            "CoInvestigator": "",
            "PrincipleInvestigator": "",
            "ProjectStatus": true,
            "StartDate": null,
            "EndDate": null,
            CreatedAt: null,
            CreatedBy: null,
            IsDeleted: false,
            UserProfile: id,
        }}
    />
}
const AwardDetail = () => {
    let {scholarId} = useParams();

    return (
        <div className="main-wrapper">
            <MediaQuery maxWidth={1023}>
                <MobileHeader/>
            </MediaQuery>
            <MediaQuery minWidth={1024}>
                <Sidebar/>
            </MediaQuery>
            <div className="screen">
                {/* <Header
                    homelink="/dashboard"
                    title="Scholars"
                    breadcrumbs="My Profile"
                    href="/scholar"
                /> */}
                <Heading title="My Profile" description=""/>
                <div className="user-profile-section">
                    <Progressbar/>
                    <ProjectDetail id={scholarId}/>
                </div>
            </div>
        </div>
    );
};

export default AwardDetail;
