import React, {useCallback, useEffect, useState} from 'react';
import ScholarHeader from "./ScholarHeader";
import AssigendFeePolicyAmount
    from "../Dashboard/Fee/Subcomponnets/Assign Fee Policy/Subcomponents/Assigned Fee Policy/AssigendFeePolicyAmount";
import AssignedFeePolicyContent
    from "../Dashboard/Fee/Subcomponnets/Assign Fee Policy/Subcomponents/Assigned Fee Policy/AssignedFeePolicyContent";
import Api from "../../../api/api";
import MediaQuery from 'react-responsive';
import ScholarNavHeader from './Scholar Sidebar/ScholarNavHeader';
import { LinkButton } from '../../components/Buttons/LinkButton';
import CollectionIcon from '../../../images/collection.svg';

const Home = () => {

    const [summary, setSummary] = useState(null);
    const [scholar, setScholar] = useState(null);

    const [forceRender, setForceRender] = useState(0);

    const [id, setId] = useState(null);

    useEffect(() => {
        Api.get('/Authentication/Login/Profile')
            .then((response) => {
                setId(response.userId);
            })
            .catch((e) => {
                //window.location.href = '/login'
            });
    }, []);

    useEffect(() => {
        if (!id) {
            return;
        }
        setForceRender((forceRender) => forceRender + 1);
        Api.get(
            `/FeeStructure/Transaction/GetUserPayments?UserId=${id}`,
        )
            .then(setSummary)
            .catch(console.log);
    }, [id]);

    const [payable, setPayable] = useState(0);

    const onPayableUpdate = useCallback(
        (total) => {
            setPayable(total);
        },
        [],
    );

    if (!summary || !id) {
        return <div>
            <ScholarHeader/>
            Loading..
        </div>
    }

    return <div>
                <ScholarHeader active={'home'} />
            <>
                <MediaQuery maxWidth={1023}>
                    <ScholarNavHeader/>
                </MediaQuery>
            </>
        <ScholarHeader active={'fee'}/>
        <div className='scholar-fee-dash'>
            <div className='scholar-fee-page'>
            <div className="scholar-fee">
                <AssigendFeePolicyAmount
                    isScholar={true}
                    summary={summary}
                    id={id}
                    payable={payable}
                    userId={id}
                    refresh={()=>{}}
                />
                <AssignedFeePolicyContent
                    onPayableUpdate={onPayableUpdate}
                    isScholar={true}
                    showPagination={false}
                    forceRender={forceRender}
                    id={id}
                    paid={summary.AmountPaid}
                />
            </div>
            </div>
        </div>
    </div>;
}

export default Home;
