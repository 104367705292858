import React from 'react';
import Header from '../../Header/Header';
import Sidebar from '../../Sidebar/Sidebar';
import DRCFacultyDefaultContent from './DRCFacultyDefaultContent';
import MediaQuery from 'react-responsive';
import MobileHeader from '../../Sidebar/Mobile Design/Mobile Header/MobileHeader';

const DRCFacultyDefault = () => {
    return (
        <div className="main-wrapper">
            <MediaQuery maxWidth={1023}>
                <MobileHeader />
            </MediaQuery>
            <MediaQuery minWidth={1024}>
                <Sidebar />
            </MediaQuery>
            <div className="screen">
                <Header
                    homelink="/dashboard"
                    breadcrumb="DRC Faculty"
                    breadcrumbs="DRC Faculty List"
                    innerpath="/staff"
                />
                <div className="user-profile-section">
                    <DRCFacultyDefaultContent
                        heading="No drc faculty found"
                        subheading="There are no drc faculty added yet. Please Import or add drc faculty from below."
                    />
                </div>
            </div>
        </div>
    );
};

export default DRCFacultyDefault;
