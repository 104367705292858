import React, {useEffect, useState} from 'react';
import {LinkButton} from '../../../../../components/Buttons/LinkButton';
import {SimpleInput} from '../../../../../components/Inputs/SimpleInput';
import Plus from '../../../../../../images/add-plus.svg';
import UploadDragDrop from '../../../../../components/Upload File/UploadDragDrop';
import {TextAreaInput} from '../../../../../components/Inputs/TextAreaInput';
import {ExportButton} from '../../../../../components/Buttons/ExportButton';
import {Button} from '../../../../../components/Buttons/Button';
import Attachment from '../../../../../components/Attachment/Attachment';
import LeftIcon from '../../../../../../images/link-submission.svg';
import Api from '../../../../../../api/api';
import {FilesInput} from '../../../../../components/Inputs/FilesInput';

import MediaQuery from 'react-responsive';
import ScholarNavHeader from '../../../Scholar Sidebar/ScholarNavHeader';

const SubmissionContent = ({deliverable}) => {
    const [val, setVal] = useState(Array([]));
    const [data, setData] = useState({files: JSON.stringify([]), links: []});

    const handleAdd = () => {
        const indexes = [...val, []];
        setVal(indexes);
    };

    const removeOption = (i) => {
        const removeVal = [...val];

        if (i != 0) {
            removeVal.splice(i, 1);
        }
        setVal(removeVal);
    };

    const handleChange = (e) => {
        console.log({e});
        let d = {...data};
        d[e.target.name] = e.target.value;
        setData(d);
    };
    useEffect(() => {
        if (deliverable.Submission) {
            let d = JSON.parse(deliverable.Submission.Data);
            if (!d.links) {
                d.links = [];
            }
            if (!d.files) {
                d.files = JSON.stringify([]);
            }

            setData(d);
        }
    }, [deliverable]);

    const [loading, setLoading] = useState(false);

    const validate = () => {
        if (
            deliverable.Deliverable.SubmissionType === 'Link' ||
            deliverable.Deliverable.SubmissionType === 'Both (Link & Document)'
        ) {
            if (!data.links || !data.links.length) {
                alert('Please enter at least one link');
                return false;
            }
        }
        if (
            deliverable.Deliverable.SubmissionType === 'Document' ||
            deliverable.Deliverable.SubmissionType === 'Both (Link & Document)'
        ) {
            if (!data.files || !JSON.parse(data.files).length) {
                alert('Please enter at least one document');
                return false;
            }
        }
        return true;
    };

    const submit = () => {
        if (!validate()) {
            return;
        }
        setLoading(true);
        Api.post('/AcademicStructure/ScholarSubmission/postEntity', {
            ScholarDeliverableId: deliverable.id,
            Data: JSON.stringify(data),
        })
            .then((res) => {
                window.location.href = '/user/deliverables';
            })
            .catch((err) => {
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const readOnly = !!deliverable.Submission;

    return (
        <>
      
            <div className="submission-detail-heading">
                
                <h3>Submission</h3>
                <span>Manage your submission</span>
                <div className="bottom-line-submission"></div>
            </div>
            <div className="submission-content">
                {(deliverable.Deliverable.SubmissionType === 'Link' ||
                    deliverable.Deliverable.SubmissionType ===
                    'Both (Link & Document)') && (
                    <div className="university-input">
                        <div className="university-input-heading">
                            <h4>Links</h4>
                            <div className="span-text">
                                <span>
                                    Get your projects up and running faster by
                                    inviting your team to collaborate.
                                </span>
                            </div>
                        </div>

                        <div className="university">
                            <div className="university-name-input"></div>

                            {Object.keys(val).map((key, i) => {
                                return (
                                    <>
                                        <SimpleInput
                                            readOnly={readOnly}
                                            name={'link' + i}
                                            onChange={(e) => {
                                                let d = {...data};
                                                d.links[i] = e.target.value;
                                                setData(d);
                                            }}
                                            value={data.links ? data.links[i] || '' : ''}
                                            lefticon={LeftIcon}
                                            placeholder=""
                                        />
                                        <br/>

                                        {i != 0 ? (
                                            <button
                                                className="remove-button"
                                                onClick={() => removeOption(i)}
                                            >
                                                Remove
                                            </button>
                                        ) : (
                                            ''
                                        )}
                                        <br/>
                                    </>
                                );
                            })}
                            {!readOnly && (
                                <div>
                                    <LinkButton
                                        className="add-another-button"
                                        linktext="Add another"
                                        backgroundcolor="none"
                                        color="#667085"
                                        hover="none"
                                        font-size="14px"
                                        icon={Plus}
                                        onClick={handleAdd}
                                    />
                                </div>)}
                        </div>
                    </div>
                )}
                <div className="bottom-line-submission"></div>
                {(deliverable.Deliverable.SubmissionType === 'Document' ||
                    deliverable.Deliverable.SubmissionType ===
                    'Both (Link & Document)') && (
                    <div className="university-input">
                        <div className="university-input-heading">
                            <h4>Upload Files</h4>
                            <div className="span-text">
                                <span>
                                    Get your projects up and running faster by
                                    inviting your team to collaborate.
                                </span>
                            </div>
                        </div>

                        <div className="university">
                            <div className="university-name-input"></div>

                            <FilesInput
                                readOnly={readOnly}
                                name={'files'}
                                onChange={handleChange}
                                value={data.files}
                            />
                            <div></div>
                        </div>
                    </div>
                )}
                <div className="bottom-line-submission"></div>

                <div className="university-input">
                    <div className="university-input-heading">
                        <h4>Description</h4>
                        <div className="span-text">
                            <span>
                                Get your projects up and running faster by
                                inviting your team to collaborate.
                            </span>
                        </div>
                    </div>

                    <div className="university">
                        <div className="university-name-input"></div>
                        <TextAreaInput
                            readOnly={readOnly}
                            placeholder="Enter description"
                            value={data.description}
                            name={'description'}
                            onChange={handleChange}
                        />
                    </div>
                </div>
                <div className="bottom-line-submission"></div>

                <div className="cb-buttons">
                    <ExportButton
                        onClick={() => {
                            window.location.href = '/user/deliverables';
                        }}
                        hover="#f9fafb"
                    >
                        Cancel
                    </ExportButton>
                    {!readOnly && (
                        <Button
                            disabled={readOnly}
                            loading={loading}
                            onClick={submit}
                            width="auto"
                            height="40px"
                            padding="0px 20px !important"
                            hover="#5D43A5"
                        >
                            {loading ? 'Loading...' : 'Submit Assignment'}
                        </Button>
                    )}
                </div>
            </div>
        </>
    );
};
export default SubmissionContent;
