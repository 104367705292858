import {useEffect, useState} from "react";

const usePermissions = () => {
    const [rawPermissions, setRawPermissions] = useState([]);
    const [menus, setMenus] = useState({
        setup: false,
        infrastructure: false,
        academicSetup: false,
        staff: false,
        scholars: false,
        fees: false,
        roles: false,
    });

    useEffect(() => {
        setRawPermissions(JSON.parse(localStorage.getItem('permissions')) || []);
    }, []);

    useEffect(() => {
        setMenus({
            setup: hasPermissionStartingWith('CAMPUS'),
            infrastructure: hasPermissionStartingWith('CLUSTER') || hasPermissionStartingWith('ResearchCenter'),
            academicSetup: hasPermissionStartingWith('AcademicYear') || hasPermissionStartingWith('Batch') || hasPermissionStartingWith('Program') || hasPermissionStartingWith('Discipline') || hasPermissionStartingWith('Milestone') || hasPermissionStartingWith('Deliverable'),
            staff: hasPermissionStartingWith('Staff'),
            scholars: hasPermissionStartingWith('Scholar'),
            fees: hasPermissionStartingWith('FeePolicy'),
            roles: hasPermissionStartingWith('Role'),
        })
    }, [rawPermissions]);


    const hasPermissionStartingWith = (permission) => {
        return rawPermissions.filter((p) => p.toLowerCase().startsWith(permission.toLowerCase())).length > 0;
    }
    const hasPermission = (permission) => {
        return rawPermissions.find((p) => p.toLowerCase() === permission.toLowerCase());
    }
    return {hasPermissionStartingWith, menus, hasPermission};
}

export default usePermissions;
