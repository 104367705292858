import React from 'react';
import Header from '../../Header/Header';
import MobileHeader from '../../Sidebar/Mobile Design/Mobile Header/MobileHeader';
import Sidebar from '../../Sidebar/Sidebar';
import SupervisorDefaultContent from './SupervisorDefaultContent';
import MediaQuery from 'react-responsive';

const SupervisorDefault = () => {
    return (
        <div className="main-wrapper">
            <MediaQuery maxWidth={1023}>
                <MobileHeader />
            </MediaQuery>
            <MediaQuery minWidth={1024}>
                <Sidebar />
            </MediaQuery>
            <div className="screen">
                <Header
                    homelink="/dashboard"
                    breadcrumb="Supervisors"
                    breadcrumbs="Superviosr List"
                    innerpath="/supervisor"
                />
                <div className="user-profile-section">
                    <SupervisorDefaultContent
                        heading="No supervisors found"
                        subheading="There are no supervisors added yet. Please Import or add supervisors from below."
                    />
                </div>
            </div>
        </div>
    );
};

export default SupervisorDefault;
