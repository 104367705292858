import React, { useEffect, useState, useRef } from 'react';
import ChitkaraLogo from '../../../../../../images/chitkara-logo 2.png';
import Table from '../../../../../components/Table/Table';
import { useParams } from 'react-router-dom';
import Api from '../../../../../../api/api';
import formatNumber from '../../../../../../helpers/formatNumber';
import getFullName from '../../../../../../helpers/getFullName';
import converter from 'number-to-words';
// import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import { Link } from 'react-router-dom';
import { useReactToPrint } from 'react-to-print';

const FeeReceipt = () => {

    const componentRef = useRef();
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
        documentTitle: 'download-receipt',
        orientation: 'landscape',
        // onAfterPrint: () => alert('Print success')
    });
    const { id, transactionId } = useParams();

    const [summary, setSummary] = useState({
        totalPayable: '',
        previousPaid: '',
        currentPaid: '',
    });
    const [rows, setRows] = useState([]);
    const [currentTransaction, setCurrentTransaction] = useState(null);
    const [scholar, setScholar] = useState(null);

    useEffect(() => {
        Api.get('/FeeStructure/Transaction/GetEntities', { UserId: id }).then(
            (response) => {
                let previousPaid = 0;
                let currentPaid = 0;
                response
                    .filter((transaction) => transaction.Status === 'Paid')
                    .forEach((transaction) => {
                        if (transaction.id < transactionId) {
                            previousPaid += transaction.AmountPaid;
                        }
                        if (transaction.id == transactionId) {
                            currentPaid = transaction.AmountPaid;
                            setCurrentTransaction(transaction);
                        }
                    });
                setSummary((sum) => ({ ...sum, previousPaid, currentPaid }));
            },
        );
    }, [id, transactionId]);

    useEffect(() => {
        Api.get(`/FeeStructure/ScholarFee/GetEntities/${id}`)
            .then((response) => {
                let totalPayable = 0;
                let left = 1;
                let r = response.ScholarFees.sort(
                    (ScholarFee1, ScholarFee2) => {
                        return (
                            ScholarFee1.Milestone.MilestoneOrder -
                            ScholarFee2.Milestone.MilestoneOrder
                        );
                    },
                ).map((ScholarFee, index) => {
                    totalPayable += ScholarFee.PayableAmount;
                    let paidAmount =
                        left > ScholarFee.PayableAmount
                            ? ScholarFee.PayableAmount
                            : left > 0
                                ? left
                                : 0;
                    let out = {
                        id: ScholarFee.id,
                        index: index + 1,
                        FeeHeader: ScholarFee.Milestone.FeeHeader,
                        PolicyFeeAmount: `${formatNumber(
                            ScholarFee.PolicyFeeAmount,
                        )}`,
                        PayableAmount: `${formatNumber(
                            ScholarFee.PayableAmount,
                        )}`,
                        Discount: `${formatNumber(
                            ScholarFee.PolicyFeeAmount -
                            ScholarFee.PayableAmount,
                        )}`,
                        PaidAmount: `${formatNumber(paidAmount)}`,
                        PendingAmountPlain:
                            ScholarFee.PayableAmount - paidAmount,
                        PendingAmount: `${formatNumber(
                            ScholarFee.PayableAmount - paidAmount,
                        )}`,
                        Paid: paidAmount === ScholarFee.PayableAmount,
                    };
                    left -= ScholarFee.PayableAmount;
                    return out;
                });
                setSummary((sum) => ({ ...sum, totalPayable }));
                setRows([...r]);
                setScholar({
                    ...response.ScholarFees[0].Scholar,
                    ScholarDRC: response.ScholarDRC,
                });
            })
            .catch(console.log);
    }, [id]);

    const refresh = () => { };

    const column = [
        {
            heading: 'Sr. No',
            value: 'index',
        },
        {
            heading: 'Particulars',
            value: 'FeeHeader',
        },
        {
            heading: 'Amount',
            value: 'PayableAmount',
        },
    ];
    console.log(rows);

    if (!scholar) {
        return <div>Loading...</div>;
    }
    const transactionDetails = JSON.parse(
        currentTransaction.TransactionDetails || '{}',
    );
    const txId =
        currentTransaction.TransactionId ||
        transactionDetails.transactionNumber;
    const txDate =
        transactionDetails.date || currentTransaction.Date.date.split(' ')[0];

    // const printDocument = () => {
    //     const input = document.getElementById('divToPrint');
    //     const pdf = new jsPDF({
    //         unit: 'px',
    //         format: 'letter',
    //         userUnit: 'px',
    //         orientation: 'landscape(vertical)',

    //     });
    //     pdf.html(input, { html2canvas: { scale: 0.43, imageTimeout: 0 } }).then(
    //         () => {
    //             pdf.save(`fee-receipt.pdf`);
    //         },
    //     );
    // };

    return (
        <>
            <div className="fee-receipt-download-pdf">
                {/* <Link
                    onClick={() => printDocument()}
                    hover="#53389e"
                    width="auto"
                    padding="0px 20px 0px 20px"
                    border-radius="4px"

                >
                    Download Fee Receipt{' '}
                </Link> */}
                <Link
                    className='download-pdf' onClick={handlePrint}
                >Download Fee Receipt</Link>
            </div>
            <div ref={componentRef} style={{ width: '100%', height: window.innerHeight }}>
                <div className="main-container-chitkara-logo">
                    <img className="chitkara-logo" src={ChitkaraLogo} />
                </div>

                <div className="receipt-content">
                    <div className="receipt-cheque">
                        <div className="receipt-info">
                            <h4>Receipt No : </h4>
                            <p>{txId}</p>
                        </div>
                        <div className="receipt-info">
                            <h4>DD / NEFT / RTGS / Cheque Number : </h4>
                            <p>{transactionDetails.dd_neft_rtgs_cheque_number || '-'}</p>
                        </div>
                    </div>
                    <div className="date-info">
                        <h4>Date :</h4>
                        <p>{txDate}</p>
                    </div>
                </div>

                <div className="student-data">
                    <div className="student-content">
                        <div className="student-personal-info">
                            <div className="student-info">
                                <h4>Student Name</h4>
                                <h4>Roll No</h4>
                                <h4>Course</h4>
                                <h4>Semester</h4>
                            </div>
                        </div>
                        <div className="student-personal-info">
                            <div className="student-info">
                                <p> : {getFullName(scholar.User)}</p>
                                <p> : {scholar.UniversityRollNo}</p>
                                <p> : PhD</p>
                                <p> : -</p>
                            </div>
                        </div>
                    </div>

                    <div className="student-content">
                        <div className="student-personal-info">
                            <div className="student-info">
                                <h4>Father's Name</h4>
                                <h4>Reg No</h4>
                                <h4>Branch</h4>
                                <h4>Mode</h4>
                            </div>
                        </div>
                        <div className="student-personal-info">
                            <div className="student-info">
                                <p> : {scholar.User.UserProfile.FatherName}</p>
                                <p> : {scholar.AcceptanceLetter}</p>
                                <p> : {scholar.ScholarDRC.Name}</p>
                                <p> : {currentTransaction.PaymentType}</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="main-wrapper">
                    <div className="receipt-table">
                        <Table
                            checkboxshouldView={false}
                            shouldView={false}
                            shouldEdit={false}
                            shouldDelete={false}
                            shouldProfile={false}
                            shouldAssignFee={false}
                            showPagination={false}
                            resource={'Campus'}
                            refresh={refresh}
                            data={rows}
                            column={column}
                            tableHeading="Fee Receipt"
                            showSortIcon={false}
                        />
                    </div>
                    <div className="total-amounts">
                        <div className="total-amount-content">
                            <div className="amount-info">
                                <div className="amount-infos">
                                    <h4>Total Fees</h4>
                                    <h4>Pre. Paid Amount</h4>
                                    <h4>Paid Amount</h4>
                                    <h4>Balance</h4>
                                </div>
                            </div>
                            <div className="amount-info">
                                <div className="amount-infos">
                                    <p> : Rs {summary.totalPayable}</p>
                                    <p> : Rs {summary.previousPaid}</p>
                                    <p> : Rs {summary.currentPaid}</p>
                                    <p>
                                        {' '}
                                        : Rs {' '}
                                        {summary.totalPayable -
                                            summary.previousPaid -
                                            summary.currentPaid}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="total-amounts">
                        <div className="amount-in-words">
                            <h4>Amount in words </h4>
                            <p> : {converter.toWords(summary.currentPaid)}</p>
                        </div>
                    </div>
                </div>

                <div className="receipt-footer">
                    <h4>
                        This is a computer generated receipt. No signature is
                        required.
                    </h4>
                </div>
                <div className="bottom-line-receipt"></div>
                <div className="receipt-footer-content">
                    <p>
                        Chandigarh - Patiala National Highway (NH-64) Village
                        Jhansla, Tehsli, Rajpura District Patiala- 140 401 Tel-
                        911762507084 507085
                    </p>
                </div>
            </div>
        </>
    );
};

FeeReceipt.whyDidYouRender = true;
export default FeeReceipt;

