import React from 'react';
import ScholarHeader from '../../ScholarHeader';
import DeliverableSubmission from './Deliverable Heading/DeliverableSubmission';
import SubmissionContent from './Submission Content/SubmissionContent';
import useDetailApi from '../../../../../hooks/useDetailApi';
import { useParams } from 'react-router-dom';
import ScholarNavHeader from '../../Scholar Sidebar/ScholarNavHeader';
import MediaQuery from 'react-responsive';

const SubmissionHome = () => {
    const { id } = useParams();
    const { item: deliverable, loading } = useDetailApi(
        'AcademicStructure/ScholarDeliverable',
        id,
    );
    return (
        <>
            <ScholarHeader />
            <div className="scholar-home-page ">
                {!loading && (
                    <div className="submission-home">
                        <MediaQuery maxWidth={1023}>
                            <ScholarNavHeader />
                        </MediaQuery>
                        <DeliverableSubmission deliverable={deliverable} />
                        <div className="submission-heading">
                            <SubmissionContent deliverable={deliverable} />
                        </div>
                    </div>
                )}
            </div>
        </>
    );
};

export default SubmissionHome;
