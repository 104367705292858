import React from 'react';
import { useParams } from 'react-router-dom';
import ModalFormHeading from './ModalFormHeading';

const ModalHeading = (props) => {
    let {title} = props;
    let {id} = useParams();
    let {description} = props;

    if (id && title.includes('Add')) {
       
        console.log(id);
        title = title.replace('Add', 'Edit ');
    }

    if (id) {
        description = 'Update the following details';
    }
    return (
    <>
            <ModalFormHeading {...props} title={title} description={description}/>
    </>);
};

export default ModalHeading;
