import React from 'react';
import {Button} from '../../../components/Buttons/Button';
import UnauthorizedIcon from '../../../../images/unauthorized-icon.svg';

const UnauthorizedAccess = () => {
    return (
        <div className="unauthorized">
            <div className="unauthorized-icon">
                <img src={UnauthorizedIcon} alt={UnauthorizedIcon}/>
            </div>
            <div className="unauthorized-access">
                <h4>Unauthorized Access</h4>
                <p>
                    You do not have the permissions to view this page. Please
                    contact your system administrator for assistance in gaining
                    the appropriate access.
                </p>
            </div>
            <div className="unauthorized-access-button">
                <Button hover="#5d43a5" onClick={() => {
                    window.location.href = '/';
                }}>Go back</Button>
            </div>
            <>OR</>
            <div className="unauthorized-access-button">
                <Button hover="#5d43a5" onClick={() => {
                        localStorage.clear();
                        window.location.href = '/login';
                }}>Logout</Button>
            </div>
        </div>
    );
};

export default UnauthorizedAccess;
