import React, { useRef, useState } from 'react';
import Sidebar from '../../../components/Sidebar/Sidebar';
import Header from '../../../components/Header/Header';
import Heading from '../../../components/Heading/Heading';
import Table from '../../../components/Table/Table';
import useListApi from '../../../../hooks/useListApi';
import MediaQuery from 'react-responsive';
import MobileHeader from '../../../components/Sidebar/Mobile Design/Mobile Header/MobileHeader';
import HeaderFilters from '../../../components/Filters/HeaderFilters';
import qs from 'qs';
import { Modal } from 'react-bootstrap';
import ExportIcon from '../../../../images/export.svg';
import Backarrow from '../../../../images/back-arrow.svg';
import AssignRole from "./AssignRole";

const ScholarsList = () => {
    const [filters, setFilters] = useState(
        qs.parse(window.location.search, { ignoreQueryPrefix: true }),
    );
    const {
        rows,
        refresh,
        totalRecords,
        currentPage,
        recordsPerPage,
        setCurrentPage,
        setRecordsPerPage,
    } = useListApi('Users/Staff', false, true, filters);
    const column = [
        {
            heading: 'Name',
            render: (item) => {
                return `${item.FirstName} ${item.LastName}`;
            },
        },
        {
            heading: 'Role',
            value: 'Role',
        },
    ];

    const [showFeePolicyModal, setShowFeePolicyModal] = useState(false);
    const [selectedUsers, setSelectedUsers] = useState([]);

    const tableRef = useRef(null);

    return (
        <div className="main-wrapper">
            <MediaQuery maxWidth={1023}>
                <MobileHeader />
            </MediaQuery>
            <MediaQuery minWidth={1024}>
                <Sidebar />
            </MediaQuery>
            <div className="screen">
                {/* <Header homelink="/dashboard" breadcrumbs="Assign Role" /> */}
                <Heading
                    title="Assign Role"
                    description="Assign Role to users"
                    width="auto"
                    height="40px"
                    padding="0px 20px"
                    tableRef={tableRef}
                    exporticon={ExportIcon}
                    linktext="Back to Roles"
                    backgroundcolor="none"
                    color="#5D43A5"
                    fontsize="16px"
                    hover="#F9F5FF"
                    className="view-back-button"
                    href="/roles"
                    icon={Backarrow}
                />
                <HeaderFilters
                    filters={filters}
                    availableFilter={[
                        'campus',
                        'cluster',
                        'researchCenter',
                        'batch',
                    ]}
                    setFilters={setFilters}
                />
                <Table
                    permissionPrefix={'AssignRole'}
                    ref={tableRef}
                    shouldView={false}
                    shouldAssignFee={false}
                    shouldDelete={false}
                    shouldEdit={false}
                    shouldAssignRole={true}
                    shouldProfile = {false}
                    checkboxshouldView={false}
                    data={rows}
                    totalRecords={totalRecords}
                    perPage={recordsPerPage}
                    setPerPage={setRecordsPerPage}
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                    column={column}
                    resource={'Users/Staff'}
                    tableHeading="All Users"
                    name="Scholar"
                    refresh={refresh}
                    multipleDelete="Scholars"
                    onAssignRole={(item) => {
                        setSelectedUsers([item]);
                        setShowFeePolicyModal(true);
                    }}
                    onView={(item) => {
                        window.location.href = `/scholar/${item.Id}/fee`;
                    }}
                />
            </div>
            {showFeePolicyModal && (
                <Modal
                    show={true}
                    onHide={() => setShowFeePolicyModal(false)}
                    centered
                    className="assignfeeModal"
                >
                    <AssignRole
                        refresh={refresh}
                        users={selectedUsers}
                        FeePolicyId={selectedUsers[0].FeePolicyId}
                        RoleId={
                            selectedUsers[0].RoleId || ''
                        }
                        onHide={() => {
                            setShowFeePolicyModal(false);
                        }}
                    />
                </Modal>
            )}
        </div>
    );
};

export default ScholarsList;
