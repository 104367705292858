import React from 'react';
import Plus from '../../../../images/plus.svg';
import ImportPlus from '../../../../images/importplus.svg';
import { LinkButton } from '../../Buttons/LinkButton';

const ScholarDefaultContent = (props) => {
    return (
        <div className="profile-screen-default-page">
            <div className="default-page">
                <div className="default-content-scholar">
                    <h6>{props.heading}</h6>
                    <span>{props.subheading}</span>
                    <div className="default-button">
                        <div className="default-link-buttons">
                            <LinkButton
                                width="auto"
                                height="40px"
                                padding="0px 20px !important"
                                backgroundcolor="#ffffff"
                                border="1px solid #EAECF0"
                                color="#344054"
                                hover="#f9fafb"
                                icon={ImportPlus}
                                linktext="Add Scholar"
                                href="/scholar/new"
                            ></LinkButton>
                        </div>
                        <div className="default-link-buttons">
                            <LinkButton
                                width="auto"
                                height="40px"
                                padding="0px 20px !important"
                                hover="#5D43A5"
                                icon={Plus}
                                linktext="Import Scholars"
                                href="/scholar/import"
                            ></LinkButton>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ScholarDefaultContent;
