import React, { useState } from 'react';
import { Button } from '../../../../../../components/Buttons/Button';
import CustomCheckbox from '../../../../../../components/Inputs/CustomCheckbox';
import { SimpleInput } from '../../../../../../components/Inputs/SimpleInput';
import { Link } from 'react-router-dom';

const InviteSupervisorContent = () => {
    const [checked, setChecked] = useState(false);

    return (
        <div className="invite-supervisor-content">
            <div className="invite-supervisor">
                <div className="invite-supervisor-inputs">
                    <div className="supervisor-name">
                        <SimpleInput
                            placeholder="Enter your first name"
                            label="First Name"
                        />
                    </div>

                    <div className="supervisor-name">
                        <SimpleInput
                            placeholder="Enter your last name"
                            label="Last Name"
                        />
                    </div>
                </div>
                <div className="invite-supervisor-inputs">
                    <div className="supervisor-name">
                        <SimpleInput
                            placeholder="Enter your email"
                            label="Email"
                        />
                    </div>
                    <div className="supervisor-name">
                        <SimpleInput
                            placeholder="Enter your phone number"
                            label="Phone Number"
                        />
                    </div>
                </div>

                <div className="supervisor-disciplines-heading">
                    <h5>Specializations</h5>
                </div>

                <div className="supervisor-switch-content">
                    <div className="supervisor-disciplines-switch">
                        <div className="supervisor-switch-text">
                            <CustomCheckbox
                                onChange={() => setChecked(!checked)}
                                isChecked={checked}
                            />
                            <span>International Business</span>
                        </div>
                    </div>
                    <div className="supervisor-disciplines-switch">
                        <div className="supervisor-switch-text">
                            <CustomCheckbox
                                onChange={() => setChecked(!checked)}
                                isChecked={checked}
                            />
                            <span>Hotel Management</span>
                        </div>
                    </div>
                    <div className="supervisor-disciplines-switch">
                        <div className="supervisor-switch-text">
                            <CustomCheckbox
                                onChange={() => setChecked(!checked)}
                                isChecked={checked}
                            />
                            <span>Marketing</span>
                        </div>
                    </div>
                </div>

                <div className="supervisor-switch-content">
                    <div className="supervisor-disciplines-switch">
                        <div className="supervisor-switch-text">
                            <CustomCheckbox
                                onChange={() => setChecked(!checked)}
                                isChecked={checked}
                            />
                            <span>Economics</span>
                        </div>
                    </div>
                    <div className="supervisor-disciplines-switch">
                        <div className="supervisor-switch-text">
                            <CustomCheckbox
                                onChange={() => setChecked(!checked)}
                                isChecked={checked}
                            />
                            <span>Human Resource</span>
                        </div>
                    </div>
                    <div className="supervisor-disciplines-switch">
                        <div className="supervisor-switch-text">
                            <CustomCheckbox
                                onChange={() => setChecked(!checked)}
                                isChecked={checked}
                            />
                            <span>Accounting</span>
                        </div>
                    </div>
                </div>

                <div className="supervisor-switch-content">
                    <div className="supervisor-disciplines-switch">
                        <div className="supervisor-switch-text">
                            <CustomCheckbox
                                onChange={() => setChecked(!checked)}
                                isChecked={checked}
                            />
                            <span>Supply Chain Management</span>
                        </div>
                    </div>
                    <div className="supervisor-disciplines-switch">
                        <div className="supervisor-switch-text">
                            <CustomCheckbox
                                onChange={() => setChecked(!checked)}
                                isChecked={checked}
                            />
                            <span>Healthcare Management</span>
                        </div>
                    </div>
                    <div className="supervisor-disciplines-switch">
                        <div className="supervisor-switch-text">
                            <CustomCheckbox
                                onChange={() => setChecked(!checked)}
                                isChecked={checked}
                            />
                            <span>Tourism</span>
                        </div>
                    </div>
                </div>

                <div className="supervisor-switch-content">
                    <div className="supervisor-disciplines-switch">
                        <div className="supervisor-switch-text">
                            <CustomCheckbox
                                onChange={() => setChecked(!checked)}
                                isChecked={checked}
                            />
                            <span>Finance</span>
                        </div>
                    </div>
                    <div className="supervisor-disciplines-switch">
                        <div className="supervisor-switch-text">
                            <CustomCheckbox
                                onChange={() => setChecked(!checked)}
                                isChecked={checked}
                            />
                            <span>Business Analytics</span>
                        </div>
                    </div>
                    <div className="supervisor-disciplines-switch"></div>
                </div>
            </div>

            <div className="cb-buttons-users">
                <Link to="/invite-Supervisor" className="heading-link-back">
                    Back to Invite Supervisor
                </Link>
                <Button
                    width="auto"
                    height="40px"
                    padding="0px 20px !important"
                    hover="#5D43A5"
                >
                    Send Invite
                </Button>
            </div>
        </div>
    );
};

export default InviteSupervisorContent;
