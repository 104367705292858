import React, {forwardRef, useImperativeHandle, useRef, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import Heading from '../../../../../../../components/Heading/Heading';
import Plus from '../../../../../../../../images/plus.svg';
import {Modal} from 'react-bootstrap';
import useProfileListApi from '../../../../../../../../hooks/useProfileListApi';
import ProfileTable from '../../../../../../../components/Table/ProfileTable';
import GenericAdd from './GenericAdd';

const PatentDetailContent = ({
                                 id,
                                 column,
                                 endpoint,
                                 fields,
                                 initialValues,
                                 title,
                                 description,
                                 initialValuesUpdated
                             }, ref) => {
    const {rows, refresh} = useProfileListApi(endpoint, id);

    const navigate = useNavigate();

    const [objectId, setObjectId] = useState(null);
    const [userprofileModal, setuserprofileModal] = useState(false);
    const userprofileModalClose = () => {
        setuserprofileModal(false);
        refresh();
    };

    const formRef = useRef(null);
    useImperativeHandle(ref, () => ({
        updateData: (d) => {
            formRef.current.updateData(d);
        },
    }));
    const userprofileModalShow = () => setuserprofileModal(true);

    const renderColumn = (row, column, rendered) => {
        if (column.value === 'cluster') {
            return (
                <td>
                    <a href={`/registry?CampusId=${row.CampusId}`}>Registry</a>
                </td>
            );
        }
        return rendered;
    };

    return (
        <div className="profile-screen">
            <div className="profile-heading">
                <Heading
                    title={title}
                    description={description}
                    linktext="Add More"
                    icon={Plus}
                    width="auto"
                    height="40px"
                    padding="0px 20px"
                    onClick={() => {
                        setObjectId(null)
                        userprofileModalShow()
                    }}
                />
            </div>

            <ProfileTable
                shouldView={false}
                checkboxshouldView={false}
                resource={endpoint}
                refresh={refresh}
                data={rows}
                column={column}
                renderColumn={renderColumn}
                tableHeading={`All ${title}`}
                onEdit={(item) => {
                    setObjectId(item.id);
                    setuserprofileModal(true);
                }}
            />

            {/* Modal */}

            <Modal
                show={userprofileModal}
                onHide={userprofileModalClose}
                centered
                className="userprofileModal"
            >
                <GenericAdd
                    title={title}
                    initialValues={initialValues}
                    onShow={userprofileModal}
                    onClose={userprofileModalClose}
                    id={objectId}
                    initialValuesUpdated={initialValuesUpdated}
                    scholarId={id}
                    endpoint={endpoint}
                    fields={fields}
                    ref={formRef}
                />
            </Modal>
        </div>
    );
};

export default forwardRef(PatentDetailContent);
